import { useState, useEffect } from "react";
import Countdown from "react-countdown";
import * as anchor from "@project-serum/anchor";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import dragonz from "./genesis_metadata_map";
import "./Dragonz.css";
import { decodeDragonzState } from "../contracts/decoder";

interface Dragon {
  name: string;
  mint: string;
  image: string;
  traits: Array<Trait>;
}

interface Trait {
  trait_type: string;
  value: string;
}

export interface HomeProps {
  connection: anchor.web3.Connection;
  match: any;
}

const DragonDetails = (props: HomeProps) => {
  const { mint } = props.match.params;

  const [bred, setBreeding] = useState({
    timesBred: "",
    lastBreedTs: "",
  });

  const dragonObj: Dragon = dragonz[mint];
  const [dragon, setDragonData] = useState(dragonObj);

  const GetDragon = async () => {
    try {
      const { timesBred, lastBreedTs } = await decodeDragonzState(props.connection, mint);
      setBreeding({ timesBred: timesBred.toString(), lastBreedTs: lastBreedTs.toString() });
    } catch (err) {
      console.error("error", err);
      setBreeding({ timesBred: "Error when loading...", lastBreedTs: "Error" });
    }
  };

  useEffect(()=>{
      GetDragon();
  }, [])

  return (
    <main>
      <section>
        <header className="header">
          <Nav />
          <h1 className="heading-main is-flex-justify-center is-uppercase u-margin-top_large mb-3">
            <img src="../title-arrow.svg" />
            Genesis Dragonz
          </h1>
        </header>

        <div className="container content-wrapper">
          <h2 className="row mt-5 dragon-details--title">{dragon.name}</h2>
          <div className="row dragon-details--wrapper">
            <div className="col-12 col-md-6 col p-5 is-flex is-flex-align-center">
              <img className="has-box-shadow" src={dragon.image} />
            </div>
            <div className="col-12 col-md-6 col dragon-details--info is-flex is-flex-align-center">
              <div className="is-flex-item">
                {dragon.traits.map((trait: any) => (
                  <div
                    className="row dragon-details--info__item is-flex-align-center"
                    key={trait.trait_type}
                  >
                    <div className="col-md-4 dragon-details--info__trait-type">
                      <strong>{trait.trait_type}</strong>
                    </div>
                    <div className="col dragon-details--info__trait-value">
                      {trait.value}
                    </div>
                  </div>
                ))}

                <div className="row dragon-details--info__item is-flex-align-center">
                  <div className="col-md-4 dragon-details--info__trait-type">
                    <strong>Cooldown</strong>
                  </div>
                </div>

                <div className="row dragon-details--info__item is-flex-align-center">
                  <div className="col-md-4 dragon-details--info__trait-type">
                    <strong>Breeding count</strong>
                  </div>
                  <div className="col dragon-details--info__trait-value">
                  <div className="col dragon-details--info__trait-value">
                  {bred.timesBred !== null ? bred.timesBred : "Error: Data not loaded"}
                </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default DragonDetails;
