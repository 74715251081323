import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import "./Pub.css";

const Pub = () => {
  return (
    <div className="pub-container">
      <div className="container">
        <div className="pub-content has-box-shadow">
          <h2 className="is-uppercase m-b-md pub-content-heading">
            Bōryoku Pub
          </h2>
          <p className="m-b-md">
            Dragonz are masterful Brewmaster. Try your skill and brew out more
            BOKU for you
          </p>
          <div className="text-center">
            <a
              href="https://pub.boryokudragonz.io/"
              target="_blank"
              className="button is-primary-alt"
            >
              Start Brewing
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pub;
