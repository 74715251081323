import * as anchor from "@project-serum/anchor";
import {
  Connection,
  Keypair,
  PublicKey,
  TransactionInstruction,
} from "@solana/web3.js";
import {
  MintLayout,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
} from "@solana/spl-token";

import {
  getMasterEdition,
  getMetadata,
  getTokenFromWallet,
  getATokenAddr,
  createAssociatedTokenAccountInstruction,
  TOKEN_METADATA_PROGRAM_ID,
} from "./utils";

import { SPL_MINT } from "./constants";

export const BREEDING_PROGRAM_ID = new PublicKey(
  "brd516WNwrbXUEAukyP61zLtY869c2n4JyKE5F2ZUNY"
);


let BOKU_KEY = new PublicKey("BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra");
let BONK_KEY = new PublicKey("DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263");
if (process.env.REACT_APP_ENV === "dev") {
  BOKU_KEY = new PublicKey("BkW2v5uv6skTW5c5GYjBctkbY9nuyyHs3gry1dCo5Hra");
  BONK_KEY = new PublicKey("meebAU3nZrU5PbUt3dVK6ExgbNWCUAkV7C3DaJKMZZ4");
}

export const BOKU_MINT = BOKU_KEY;
export const BONK_MINT = BONK_KEY;

const PREFIX = "breeding";
const PREFIX_EGG = "egg";
const PREFIX_BABY = "baby";


export const getProgram = async (
  wallet: anchor.Wallet,
  programId: PublicKey,
  connection: Connection
): Promise<anchor.Program> => {
  const provider = new anchor.Provider(connection, wallet, {
    preflightCommitment: "recent",
    // skipPreflight: true,
  });
  const idl = await anchor.Program.fetchIdl(programId, provider);
  const program = new anchor.Program(idl as anchor.Idl, programId, provider);
  return program;
};

export interface BreedingState {
  eggCnt: number;
  babyCnt: number;
}

export interface DragonzState {
  mint: string;
  lastBreedTs: number;
  breeded: number;
}

export interface EggState {
  mint: string;
  dragonz1: string;
  dragonz2: string;
  startTs: number;
}

export const getBreedingState = async (
  program: anchor.Program
): Promise<BreedingState> => {
  const [breedingState, _] = await PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX))],
    program.programId
  );

  const breedingStateAccount = await program.account.breedingState.fetch(
    breedingState
  );

  return {
    eggCnt: breedingStateAccount.eggsCnt.toNumber(),
    babyCnt: breedingStateAccount.babyDragonzCnt.toNumber(),
  };
};

export const getDragonzState = async (
  program: anchor.Program,
  dragonz: PublicKey
): Promise<DragonzState> => {
  const [dragonzState, _] = await PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX)), dragonz.toBuffer()],
    program.programId
  );
  const dragonzStateAccount = await program.account.dragonzState.fetch(
    dragonzState
  );
  return {
    mint: dragonzStateAccount.mint.toString(),
    lastBreedTs: dragonzStateAccount.lastBreedTs.toNumber(),
    breeded: dragonzStateAccount.breeded.toNumber(),
  };
};

export const getEggState = async (
  program: anchor.Program,
  egg: PublicKey
): Promise<EggState> => {
  const [eggState, _] = await PublicKey.findProgramAddress(
    [Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX)), egg.toBuffer()],
    program.programId
  );
  const eggStateAccount = await program.account.eggState.fetch(eggState);

  return {
    mint: eggStateAccount.mint.toString(),
    dragonz1: eggStateAccount.dragonz1.toString(),
    dragonz2: eggStateAccount.dragonz2.toString(),
    startTs: eggStateAccount.startTs.toNumber(),
  };
};

export const singleBreed = async (
  program: anchor.Program,
  connection: Connection,
  walletKey: PublicKey,
  dragonz1: PublicKey,
): Promise<string> => {
  const bokuToken = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), BOKU_MINT.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];

  const dragonz1Token = await getATokenAddr(connection, walletKey, dragonz1);

  const dragonz1Metadata = await getMetadata(dragonz1);

  const dragonz1State = (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX)),
        dragonz1.toBuffer(),
      ],
      program.programId
    )
  )[0];

  const breedingState = (
    await PublicKey.findProgramAddress(
      [Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX))],
      program.programId
    )
  )[0];

  const rent = await connection.getMinimumBalanceForRentExemption(
    MintLayout.span
  );


  const babyKP = Keypair.generate();
  const babyMint = babyKP.publicKey;

  const babyMetadata = await getMetadata(babyMint);
  const babyMasterEdition = await getMasterEdition(babyMint);
  const babyCreator = (
    await PublicKey.findProgramAddress(
      [Buffer.from(PREFIX), Buffer.from(PREFIX_BABY)],
      program.programId
    )
  )[0];

  const babyToken = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), babyMint.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];

  console.log("systemProgram: " + anchor.web3.SystemProgram.programId);
  console.log("tokenProgram: " + TOKEN_PROGRAM_ID);
  console.log("tokenMetadataProgram: " + TOKEN_METADATA_PROGRAM_ID);
  console.log("clock: " +  anchor.web3.SYSVAR_CLOCK_PUBKEY);
  console.log("rent: " +   anchor.web3.SYSVAR_RENT_PUBKEY);

  try {
    const tx = await program.transaction.breedBabySingleDragon({
      accounts: {
        owner: walletKey,
        bokuMint: BOKU_MINT,
        bokuToken,
        dragonz1Mint: dragonz1,
        dragonz1Token,
        dragonz1Metadata,
        dragonz1State,
        breedingState,
        babyMint,
        babyMetadata,
        babyMasterEdition,
        babyCreator,
        dragonzMain: new PublicKey(
          "DRGnhq8axWdQqehZmj3tiLuCkuHeUvkS66dPmH7jA9QT"
        ),

        systemProgram: anchor.web3.SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      },
      signers: [babyKP],
      instructions: [
        anchor.web3.SystemProgram.createAccount({
          fromPubkey: walletKey,
          newAccountPubkey: babyMint,
          space: MintLayout.span,
          lamports: rent,
          programId: TOKEN_PROGRAM_ID,
        }),
        Token.createInitMintInstruction(
          TOKEN_PROGRAM_ID,
          babyMint,
          0,
          walletKey,
          walletKey
        ),
        createAssociatedTokenAccountInstruction(
          babyToken,
          walletKey,
          walletKey,
          babyMint
        ),
        Token.createMintToInstruction(
          TOKEN_PROGRAM_ID,
          babyMint,
          babyToken,
          walletKey,
          [],
          1
        ),
      ],
    });

tx.feePayer = program.provider.wallet.publicKey;
tx.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

console.log(tx);

tx.partialSign(babyKP); //sign the transaction with the keypair of the babyZ mint

let signed = await program.provider.wallet.signTransaction(tx); //sign transaction with user wallet
console.log("signed " +signed.toString());

let txid = await connection.sendRawTransaction(signed.serialize()); //send transaction to the network
console.log("txid" +txid.toString);
    return babyMint.toString();
  } catch (e) {
    console.log("breeding failed");
    throw e;
  }
};

export const breedBaby = async (
  program: anchor.Program,
  connection: Connection,
  walletKey: PublicKey,
  dragonz1: PublicKey,
  dragonz2: PublicKey
): Promise<string> => {
  const bokuToken = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), BOKU_MINT.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];

  const dragonz1Token = await getATokenAddr(connection, walletKey, dragonz1);

  const dragonz1Metadata = await getMetadata(dragonz1);

  const dragonz1State = (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX)),
        dragonz1.toBuffer(),
      ],
      program.programId
    )
  )[0];

  const dragonz2Token = await getATokenAddr(connection, walletKey, dragonz2);

  const dragonz2Metadata = await getMetadata(dragonz2);

  const dragonz2State = (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX)),
        dragonz2.toBuffer(),
      ],
      program.programId
    )
  )[0];

  const breedingState = (
    await PublicKey.findProgramAddress(
      [Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX))],
      program.programId
    )
  )[0];

  const rent = await connection.getMinimumBalanceForRentExemption(
    MintLayout.span
  );


  const babyKP = Keypair.generate();
  const babyMint = babyKP.publicKey;

  const babyMetadata = await getMetadata(babyMint);
  const babyMasterEdition = await getMasterEdition(babyMint);
  const babyCreator = (
    await PublicKey.findProgramAddress(
      [Buffer.from(PREFIX), Buffer.from(PREFIX_BABY)],
      program.programId
    )
  )[0];

  const babyToken = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), babyMint.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];

  console.log("systemProgram: " + anchor.web3.SystemProgram.programId);
  console.log("tokenProgram: " + TOKEN_PROGRAM_ID);
  console.log("tokenMetadataProgram: " + TOKEN_METADATA_PROGRAM_ID);
  console.log("clock: " +  anchor.web3.SYSVAR_CLOCK_PUBKEY);
  console.log("rent: " +   anchor.web3.SYSVAR_RENT_PUBKEY);

  try {
    const tx = await program.transaction.breedBabyV3({
      accounts: {
        owner: walletKey,
        bokuMint: BOKU_MINT,
        bokuToken,
        dragonz1Mint: dragonz1,
        dragonz1Token,
        dragonz1Metadata,
        dragonz1State,
        dragonz2Mint: dragonz2,
        dragonz2Token,
        dragonz2Metadata,
        dragonz2State,
        breedingState,
        babyMint,
        babyMetadata,
        babyMasterEdition,
        babyCreator,
        dragonzMain: new PublicKey(
          "DRGnhq8axWdQqehZmj3tiLuCkuHeUvkS66dPmH7jA9QT"
        ),

        systemProgram: anchor.web3.SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      },
      signers: [babyKP],
      instructions: [
        anchor.web3.SystemProgram.createAccount({
          fromPubkey: walletKey,
          newAccountPubkey: babyMint,
          space: MintLayout.span,
          lamports: rent,
          programId: TOKEN_PROGRAM_ID,
        }),
        Token.createInitMintInstruction(
          TOKEN_PROGRAM_ID,
          babyMint,
          0,
          walletKey,
          walletKey
        ),
        createAssociatedTokenAccountInstruction(
          babyToken,
          walletKey,
          walletKey,
          babyMint
        ),
        Token.createMintToInstruction(
          TOKEN_PROGRAM_ID,
          babyMint,
          babyToken,
          walletKey,
          [],
          1
        ),
      ],
    });

tx.feePayer = program.provider.wallet.publicKey;
tx.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;

console.log(tx);

tx.partialSign(babyKP); //sign the transaction with the keypair of the babyZ mint

let signed = await program.provider.wallet.signTransaction(tx); //sign transaction with user wallet
console.log("signed " +signed.toString());

let txid = await connection.sendRawTransaction(signed.serialize()); //send transaction to the network
console.log("txid" +txid.toString);
    return babyMint.toString();
  } catch (e) {
    console.log("breeding failed");
    throw e;
  }
};

 