const legendaries = [
    {
        "mint": "Cj5dQqMgno3f3qDg2jeZaEAK4vxMSHd2WH3t37HRkkX3",
        "name": "The Anon",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/anon.gif",
        "lore": "The Anon, thriving in periods of uncertainties and disruption. The Anon has successfully coordinated attacks against the enemies of the village through decentralized syndicates. Although stealthy and ruthless, it is nothing short of a frisky dragonz that too enjoys a good load of BOKU daily."
    },
    {
        "mint": "GtnSWiLiQzdUneKahGscFd4uEVkRFG1wU6nXcjDHk2Ex",
        "name": "The Astro",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/astro.gif",
        "lore": "The Astro is an embodiment of every crypto degenerate's dream of \"going to the moon\". It could well be the only dragonz who can claim that it has gone to the moon and safely returned, proving its ability to take on tasks that seem insurmountable to the common man."
    },
    {
        "mint": "DS1QgU9XLkHPb49Mf5MAGNMzEwjMeEeC2rbbUwaoca36",
        "name": "The Mutant",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/mutant.gif",
        "lore": "The Mutant thrives in an environment of specimen collection, inclusive of bodily and non-bodily fluids. Some may come in different colors, white and green alike. One of its many desires is to bake every dragonz' fluid into the BOKU pot."
    },
    {
        "mint": "DUgriH1FahUXWVjhbcbvKHb9BrghYjWtv8SyKL7zjuDT",
        "name": "The Fren",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/fren.gif",
        "lore": "With quite possibly a mutated form of its body influenced through the immersion of BOKU between a Pepe and a Dragonz, The Fren was once lost in 42nd BC and was only found 69 years later in the nearby MaxDonaldz Fast Food chain, 4269 miles away from Boryoku Village."
    },
    {
        "mint": "GMWGUdQN5831GAsRrKduBBVq5Wxo9QEGES1hyEd9XSEm",
        "name": "The Electro",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/electro.gif",
        "lore": "The Electro acts as a core guardian of the universe, within Boryoku and beyond, safeguarding villages' wealth and health by introducing rural electrification and electrification through death. The legend has it that The Electro has the ability to trigger both the thunder and lightning across the world, anytime, anywhere."
    },
    {
        "mint": "9dHn8TwURfwvvREf9pADAxpthXtoUHhy5KdUnWbX91Rj",
        "name": "The Shinobi",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/shinobi.gif",
        "lore": "The Shinobi is an almighty dragonz that has roamed the entire Japan and reclaimed its footing in the renowned Shibuya Crossing by nuking it with a whole load of BOKU. It has been disappointed by humanity with the dirty tricks that it brings time after time. Ever since its introduction to BOKU, it has decided to forsake humanity and chose the highway by building up Boryoku Village with its 9 other legendary folks."
    },
    {
        "mint": "HCUXEW4Kit9CJUK18jKY98r3iw4CD8bzf1yKMta3PhQx",
        "name": "The Rambo",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/rambo.gif",
        "lore": "The Rambo was the first of its species to have interacted with firearms. Ever since its interaction with them, it has grown an addiction to all things massacre and was crowned as the killer of the century. To help curb his addiction, the Boryoku Masterz has tamed it by filling a grenade filled with BOKU, stuffed right into his mouth. This grenade now controls the fate of The Rambo."
    },
    {
        "mint": "AKW7rfYeMrTGYXTjytXexEjBAXU6RTfoFXHWg72Vb2D5",
        "name": "The Oracle",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/oracle.gif",
        "lore": "The Oracle embodies the intersection of cultures between a world pre-civilization and post-modernization. Originating from its tribal roots, The Oracle has traveled across the world, first birthed in the oriental countries and gradually developed an influence within and beyond the Boryoku Village."
    },
    {
        "mint": "4kAYBtqurH6rEP5YHWjZunjge3tjPAB5ikJVfC1AexAF",
        "name": "The Alchemist",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/alchemist.gif",
        "lore": "The Alchemist is the one true brewmaster in Boryoku who has been the only one responsible for the formulation and overseeing of the daily BOKU brews, utilizing dead souls and remains of perpetrators of the village. Over the years, The Alchemist has not only developed an edge over all other brewmasters across the universe but has experimented with the best mix of ingredients that have given rise to the distinctive Solana skin that it wears today."
    },
    {
        "mint": "Amhg7jgCdAycvwBfLw9ZYdbai8QidfgPWC35SRQY44wi",
        "name": "The King",
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/king.gif",
        "lore": "Casually found roaming the skies of Miami, and will be guarding the Art Basel event over the course of its duration."
    }
]

export default legendaries