import { PublicKey } from "@solana/web3.js";

export const TOKEN_METADATA_PROGRAM_ID = new PublicKey(
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s"
);

let BOKU_KEY = new PublicKey("CN7qFa5iYkHz99PTctvT4xXUHnxwjQ5MHxCuTJtPN5uS");
let SPL_KEY = new PublicKey("DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263");
if (process.env.REACT_APP_ENV === "dev") {
  BOKU_KEY = new PublicKey("B2AXk7dMgfVyTx6LksETwVqtKpTBXtjnCshFit5VxMWa");
  SPL_KEY = new PublicKey("meebAU3nZrU5PbUt3dVK6ExgbNWCUAkV7C3DaJKMZZ4");
}

export const BOKU_MINT = BOKU_KEY;
export const SPL_MINT = SPL_KEY;
