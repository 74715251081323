import { useState, useEffect } from "react";
import Countdown from "react-countdown";
import { Link, useHistory } from "react-router-dom";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import dragonz from "../dragonz/genesis_metadata_map";
import "../dragonz/Dragonz.css";
import { decodeEggState } from "../contracts/decoder";
import * as anchor from "@project-serum/anchor";
import SearchIcon from "@material-ui/icons/Search";

const Egg = () => {
  const history = useHistory();
  const searchHander = (event: any) => {
    if (event.key !== "Enter") return;

    const value = event.target.value.trim();

    if (value) {
      history.push(`/eggz/${value}`);
    }
  };
  return (
    <main>
      <section>
        <header className="header">
          <Nav />
          <h1 className="heading-main is-flex-justify-center is-uppercase u-margin-top_large mb-3">
            <img src="../title-arrow.svg" />
            Eggz
          </h1>
        </header>

        <div className="container content-wrapper">
          <div className="search-wrapper mt-5">
            <SearchIcon className="mr-2 ml-2" />
            <input
              defaultValue=""
              autoFocus
              className="search-input"
              placeholder="Eggz mint address"
              onKeyDown={searchHander}
            />
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Egg;
