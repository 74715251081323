import * as borsh from "@project-serum/borsh";
import BN from "bn.js";
import {
  PublicKey,
  AccountInfo,
  Connection,
  GetProgramAccountsConfig,
} from "@solana/web3.js";
import { BREEDING_PROGRAM_ID } from "./breeding";
import { MERCH_PROGRAM_ID } from "./merch";

const DRAGONZ_STATE_LAYOUT = borsh.struct([
  borsh.publicKey("mint"),
  borsh.i64("lastBreedTs"),
  borsh.u64("breeded"),
  borsh.u8("bump"),
]);

interface DragonzStateLayout {
  mint: Uint8Array;
  lastBreedTs: Uint8Array;
  breeded: Uint8Array;
  bump: number;
}

const EGG_STATE_LAYOUT = borsh.struct([
  borsh.publicKey("babyz"),
  borsh.publicKey("mint"),
  borsh.publicKey("dragonz1"),
  borsh.publicKey("dragonz2"),
  borsh.i64("startTs"),
  borsh.u8("bump"),
]);

interface EggStateLayout {
  babyz: Uint8Array;
  mint: Uint8Array;
  dragonz1: Uint8Array;
  dragonz2: Uint8Array;
  startTs: Uint8Array;
  bump: number;
}

const MERCH_LAYOUT = borsh.struct([
  borsh.publicKey("seller"),
  borsh.str("name"),
  borsh.str("symbol"),
  borsh.u64("maxSupply"),
  borsh.u64("sold"),
  borsh.str("uri"),
  borsh.u64("solPrice"),
  borsh.publicKey("tokenMint"),
  borsh.u64("tokenPrice"),
  borsh.bool("privateMint"),
  borsh.u8("bump"),
]);

export interface Merch {
  seller: string;
  name: string;
  symbol: string;
  maxSupply: number;
  sold: number;
  uri: string;
  solPrice: number;
  tokenMint: string;
  tokenPrice: number;
  privateMint: boolean;
}

export async function decodeDragonzState(
  connection: Connection,
  mintStr: string
) {
  const config = getProgramAccountsConfig(8, mintStr);
  const accounts = await connection.getProgramAccounts(
    BREEDING_PROGRAM_ID,
    config
  );
  const decoded = DRAGONZ_STATE_LAYOUT.decode(
    accounts[0].account.data.slice(8)
  ) as DragonzStateLayout;
  return {
    mint: decoded.mint.toString(),
    lastBreedTs: convertToNumber(decoded.lastBreedTs),
    timesBred: convertToNumber(decoded.breeded),
  };
}

export async function decodeEggState(connection: Connection, mintStr: string) {
  const config = getProgramAccountsConfig(40, mintStr);
  const accounts = await connection.getProgramAccounts(
    BREEDING_PROGRAM_ID,
    config
  );
  const decoded = EGG_STATE_LAYOUT.decode(
    accounts[0].account.data.slice(8)
  ) as EggStateLayout;
  return {
    babyz: decoded.babyz.toString(),
    mint: decoded.mint.toString(),
    dragonz1: decoded.dragonz1.toString(),
    dragonz2: decoded.dragonz2.toString(),
    startTs: convertToNumber(decoded.startTs),
  };
}

export async function decodeMerchInfo(
  connection: Connection,
  seller: PublicKey,
  merchName: string
) {
  const PREFIX = "merch";
  const merchKey = (
    await PublicKey.findProgramAddress(
      [Buffer.from(PREFIX), seller.toBuffer(), Buffer.from(merchName)],
      MERCH_PROGRAM_ID
    )
  )[0];
  console.log("merch key", merchKey.toString());
  const merchAccount = await connection.getAccountInfo(merchKey);
  const decoded = MERCH_LAYOUT.decode(merchAccount!.data.slice(8));
  return {
    seller: decoded.seller.toString(),
    name: decoded.name,
    symbol: decoded.symbol,
    maxSupply: convertToNumber(decoded.maxSupply),
    sold: convertToNumber(decoded.sold),
    uri: decoded.uri,
    solPrice: convertToNumber(decoded.solPrice) / 1000 / 1000 / 1000,
    tokenMint: decoded.tokenMint.toString(),
    tokenPrice: convertToNumber(decoded.tokenPrice) / 1000 / 1000 / 1000,
    privateMint: decoded.privateMint,
  } as Merch;
}

function getProgramAccountsConfig(offset: number, bytes: string) {
  const config: GetProgramAccountsConfig = {
    encoding: "base64",
    filters: [
      {
        memcmp: {
          offset,
          bytes,
        },
      },
    ],
  };
  return config;
}

function convertToNumber(input: Uint8Array) {
  return new BN(input, 10, "le").toNumber();
}
