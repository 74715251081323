import React, { useState, useEffect } from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import "./Breeding.css";
import useNfts, { IDragonzNft, IEggzNft, INft } from "./hooks/useNfts";
import { decodeMetadata } from "./utils/metadata.service";
import CloseIcon from "@material-ui/icons/Close";

import * as anchor from "@project-serum/anchor";

import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
} from "@solana/spl-token";

import { PublicKey } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import Nav from "./partials/Nav";
import Footer from "./partials/Footer";
import { Card } from "./partials/Card";
import { shortenAddress } from "./candy-machine";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { decodeDragonzState } from "./contracts/decoder";
import {
  BREEDING_PROGRAM_ID,
  getProgram,
  getEggState,
  breedBaby,
  singleBreed,
  BOKU_MINT,
  BONK_MINT,
} from "./contracts/breeding";
import { getMetadata } from "./contracts/utils";

import { delay } from "./utils/helpers";

const ConnectButton = styled(WalletDialogButton)`
  padding: 0.5rem 1.25rem !important;
  border-radius: 0 !important;
  background-color: var(--bd-color-primary) !important;
  border: 1px solid var(--bd-color-accent) !important;
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3) !important;
`;

const BREEDING_COSTS = 1111;

let hatchingDays = 21;
if (process.env.REACT_APP_ENV === "dev") {
  hatchingDays = 21;
}

let valentineTs = 1644753600;
let valentineDuration = 48 * 3600;
if (process.env.REACT_APP_ENV === "dev") {
  valentineTs = 1644553758;
}

export interface HomeProps {
  connection: anchor.web3.Connection;
}

const Stake = (props: HomeProps) => {
  const connection = props.connection;
  const wallet = useAnchorWallet();
  const { isLoading, nfts, setNfts } = useNfts(connection);
  const [breedingError, setBreedingError] = useState(false);
  const [notSingleSelected, setSingleError] = useState(false);
  const [bokuError, setBokuError] = useState(false);
  const [bonkError, setBonkError] = useState(false);
  const [solError, setSolError] = useState(false);

  const [isBokuBreed, setIsBokuBreed] = useState(true);

  const [bokuCost, setBokuCost] = useState(0);
  const [bonkCost, setBonkCost] = useState(69000000);

  const [isMating, setMating] = useState(false);
  const [matingSuccess, setMatingSuccess] = useState(false);

  const [isHatching, setHatching] = useState(false);
  const [hatchingSuccess, setHatchingSuccess] = useState(false);
  const [isShowingHatchingModal, setIsShowingHatchingModal] = useState(false);
  const [babyType, setBabyType] = useState("iron");
  const [babyImageUri, setBabyImageUri] = useState("");
  const [showBabyImage, setShowBabyImage] = useState(false);
  const [babySupply, setBabySupply] = useState(null);

  const PREFIX = "breeding";

  const BREEDING_PROGRAM_ID = new PublicKey(
    "brd516WNwrbXUEAukyP61zLtY869c2n4JyKE5F2ZUNY"
  );

  const [selectedDragon1, setDragon1] = useState({
    name: "",
    image: "",
    mint: "",
  });
  const [selectedDragon2, setDragon2] = useState({
    name: "",
    image: "",
    mint: "",
  });

   interface BreedingState {
    eggCnt: number;
    babyCnt: number;
  }

  const [sliderVisible, setSliderVisible] = useState(false);
  const [loading, setLoading] = useState(true);

  const toggleSlider = () => {
    setSliderVisible(!sliderVisible);
  };

  const targetDate = new Date('December 6, 2023 18:30:00 UTC').getTime();
  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());


  function calculateTimeRemaining() {
    const now = new Date().getTime();
    const difference = targetDate - now;

    if (difference <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);

    return { days, hours, minutes, seconds };
  }

  useEffect(() => {
    async function fetchTotalSupply() {
      try {
        const response = await fetch('https://binaramics.com:3000/fetch-supply');
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
        const data = await response.json();
        console.log(data);
        const totalSupply = data.totalSupply;
        setBabySupply(totalSupply);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }

    const interval = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    const initialFetchTimeout = setTimeout(() => {
      fetchTotalSupply();

      const intervalId = setInterval(fetchTotalSupply, 3 * 60 * 1000);
      return () => {
        clearInterval(intervalId);
        clearInterval(interval);
        clearTimeout(initialFetchTimeout);
      };
    }, 2500);

    return () => clearTimeout(initialFetchTimeout);

  }, []);

  const mintBaby = async () => {
    setSolError(false);
    setBokuError(false);
    setBonkError(false);
    setBreedingError(false);
    setMatingSuccess(false);
    setSingleError(false);
    let publicKey1 = null;
    let publicKey2 = null;
    
    try {
      publicKey1 = new PublicKey(selectedDragon1.mint);
    } catch (error) {
    }
    
    try {
      publicKey2 = new PublicKey(selectedDragon2.mint);
    } catch (error) {
    }
    
    if (!publicKey1 && !publicKey2) {
      console.log("No Dragons were selected");
      setMating(false);
      setBreedingError(true);
    }

    if(publicKey1 && !publicKey2) {
      console.log("Single Breed...");

      const { timesBred } = await decodeDragonzState(props.connection, publicKey1.toString());
      if(timesBred == 13) {
        setMating(false);
        setBreedingError(true);
      }
      if(!sliderVisible) {
        setMating(false);
        setSingleError(true);
      }      
      try {
        setBokuCost(2500);
        const bokuTokenKey = (
          await PublicKey.findProgramAddress(
            [
              (wallet as anchor.Wallet).publicKey.toBuffer(),
              TOKEN_PROGRAM_ID.toBuffer(),
              BOKU_MINT.toBuffer(),
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID
          )
        )[0];
  
        const bokuAmount = (await connection.getTokenAccountBalance(bokuTokenKey))
          .value.uiAmount;
  
        const solBalance = await connection.getBalance(
          (wallet as anchor.Wallet).publicKey
        );

        
        
  
        if (bokuAmount! < bokuCost) {
          setBokuError(true);
        } else if (solBalance < 0.015 * 1000 * 1000 * 1000) {
          setSolError(true);
        } else {
          try {
            setMating(true);
  
            const program = await getProgram(
              wallet as anchor.Wallet,
              BREEDING_PROGRAM_ID,
              connection
            );
            const eggMintKey = await singleBreed(
              program,
              connection,
              wallet!.publicKey,
              new PublicKey(selectedDragon1.mint),
            );
  
            setDragon1({
              name: "",
              image: "",
              mint: "",
            });
  
            setMating(false);
            setMatingSuccess(true);

            setIsShowingHatchingModal(true);
            const metadataPubkey = await getMetadata(new PublicKey(eggMintKey));
            let metadata = null;

            while (!metadata) {
              metadata = await connection.getAccountInfo(metadataPubkey);
              await delay(200);
            }

            const decoded = await decodeMetadata(metadata!.data);
            const res = await fetch(decoded!.data.uri);
            const data = await res.json();
            const imageUri = data.image;
            const babyType: string = data
              .attributes![1].value.split(" ")[0]
              .toLowerCase();
            console.log("image", imageUri, babyType);

            setHatching(false);
            setHatchingSuccess(true);
            setBabyType(babyType);
            setBabyImageUri(imageUri);

            await delay(2200);
            setShowBabyImage(true);
          } catch (e) {
            console.log("minting egg failed", e);
            setMating(false);
            setBreedingError(true);
          }
        }
      } catch {
        setBokuError(true);
      }
    }

    if(!publicKey1 && publicKey2) {
      try {
        setBokuCost(2500);
        const bokuTokenKey = (
          await PublicKey.findProgramAddress(
            [
              (wallet as anchor.Wallet).publicKey.toBuffer(),
              TOKEN_PROGRAM_ID.toBuffer(),
              BOKU_MINT.toBuffer(),
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID
          )
        )[0];
  
        const bokuAmount = (await connection.getTokenAccountBalance(bokuTokenKey))
          .value.uiAmount;
  
        const solBalance = await connection.getBalance(
          (wallet as anchor.Wallet).publicKey
        );
  
        if (bokuAmount! < bokuCost) {
          setBokuError(true);
        } else if (solBalance < 0.015 * 1000 * 1000 * 1000) {
          setSolError(true);
        } else {
          try {
            setMating(true);
  
            const program = await getProgram(
              wallet as anchor.Wallet,
              BREEDING_PROGRAM_ID,
              connection
            );
            const eggMintKey = await singleBreed(
              program,
              connection,
              wallet!.publicKey,
              new PublicKey(selectedDragon2.mint),
            );
  
            setDragon2({
              name: "",
              image: "",
              mint: "",
            });
  
            setMating(false);
            setMatingSuccess(true);

            setIsShowingHatchingModal(true);
            const metadataPubkey = await getMetadata(new PublicKey(eggMintKey));
            let metadata = null;

            while (!metadata) {
              metadata = await connection.getAccountInfo(metadataPubkey);
              await delay(200);
            }

            const decoded = await decodeMetadata(metadata!.data);
            const res = await fetch(decoded!.data.uri);
            const data = await res.json();
            const imageUri = data.image;
            const babyType: string = data
              .attributes![1].value.split(" ")[0]
              .toLowerCase();
            console.log("image", imageUri, babyType);

            setHatching(false);
            setHatchingSuccess(true);
            setBabyType(babyType);
            setBabyImageUri(imageUri);

            await delay(2200);
            setShowBabyImage(true);
          } catch (e) {
            console.log("minting egg failed", e);
            setMating(false);
            setBreedingError(true);
          }
        }
      } catch {
        setBokuError(true);
      }
    }

    if(publicKey1 && publicKey2) {
      console.log("Dual breed");
      try {
        setBokuCost(1111);
        const bokuTokenKey = (
          await PublicKey.findProgramAddress(
            [
              (wallet as anchor.Wallet).publicKey.toBuffer(),
              TOKEN_PROGRAM_ID.toBuffer(),
              BOKU_MINT.toBuffer(),
            ],
            ASSOCIATED_TOKEN_PROGRAM_ID
          )
        )[0];
  
        const bokuAmount = (await connection.getTokenAccountBalance(bokuTokenKey))
          .value.uiAmount;
  
        const solBalance = await connection.getBalance(
          (wallet as anchor.Wallet).publicKey
        );
  
        if (bokuAmount! < bokuCost) {
          setBokuError(true);
        } else if (solBalance < 0.015 * 1000 * 1000 * 1000) {
          setSolError(true);
        } else {
          try {
            setMating(true);
  
            const program = await getProgram(
              wallet as anchor.Wallet,
              BREEDING_PROGRAM_ID,
              connection
            );
            const eggMintKey = await breedBaby(
              program,
              connection,
              wallet!.publicKey,
              new PublicKey(selectedDragon1.mint),
              new PublicKey(selectedDragon2.mint)
            );
  
            setDragon1({
              name: "",
              image: "",
              mint: "",
            });
  
            setDragon2({
              name: "",
              image: "",
              mint: "",
            });
  
            console.log("eggz minted");
            
            setMating(false);
            setMatingSuccess(true);

            setIsShowingHatchingModal(true);
            const metadataPubkey = await getMetadata(new PublicKey(eggMintKey));
            let metadata = null;

            while (!metadata) {
              metadata = await connection.getAccountInfo(metadataPubkey);
              await delay(200);
            }

            const decoded = await decodeMetadata(metadata!.data);
            const res = await fetch(decoded!.data.uri);
            const data = await res.json();
            const imageUri = data.image;
            const babyType: string = data
              .attributes![1].value.split(" ")[0]
              .toLowerCase();
            console.log("image", imageUri, babyType);

            setHatching(false);
            setHatchingSuccess(true);
            setBabyType(babyType);
            setBabyImageUri(imageUri);

            await delay(2200);
            setShowBabyImage(true);
          } catch (e) {
            console.log("minting egg failed", e);
            setMating(false);
            setBreedingError(true);
          }
        }
      } catch {
        setBokuError(true);
      }
    }


  };

  const closeHatchingModal = () => {
    setIsShowingHatchingModal(false);
  };


  const isEvent = (startTs: number, duration: number) => {
    return (
      Math.floor(Date.now() / 1000) > startTs &&
      Math.floor(Date.now() / 1000) < startTs + duration
    );
  };

  return (
    <main>
      <section>
        <header className="header">
          <Nav />
          <h1 className="heading-main is-flex-justify-center is-uppercase u-margin-top_large">
            <img src="../title-arrow.svg" />
            Breeding
          </h1>

          <div className="is-flex is-flex-justify-center">
            {!wallet ? (
              <ConnectButton className="mt-3 mb-3">
                Connect Wallet
              </ConnectButton>
            ) : (
              <p className="pb-3">
                Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}
              </p>
              
            )}
          </div>

        </header>



        <div className="content-wrapper">
          {breedingError && (
            <div className="notification mt-4 has-box-shadow">
              <span className="notification--icon">!</span> Something went
              wrong... Refresh and try again
            </div>
          )}
          {bokuError && (
            <div className="notification mt-4 has-box-shadow">
              <span className="notification--icon">!</span> Not Enough BOKU...
            </div>
          )}
          {notSingleSelected && (
            <div className="notification mt-4 has-box-shadow">
              <span className="notification--icon">!</span> Please select "Single Genesis" mode to proceed...
            </div>
          )}
          {bonkError && (
            <div className="notification mt-4 has-box-shadow">
              <span className="notification--icon">!</span> Not Enough BONK...
            </div>
          )}
          {solError && (
            <div className="notification mt-4 has-box-shadow">
              <span className="notification--icon">!</span> Not Enough SOL, Need
              0.015 SOL For Tx...
            </div>
          )}
          {matingSuccess && (
            <div className="notification-success mt-4 has-box-shadow">
              <span className="notification--icon">!</span>{" "}
              <strong>
                A mysterious glowing egg appears… An ancient life force stirs
                within.
              </strong>
            </div>
          )}
          {isMating && (
            <div>
              <div
                className="modal fade show is-flex is-flex-justify-center is-flex-align-center breeding--dragon-mating"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="mating"
                aria-hidden="true"
              >
                {isEvent(valentineTs, valentineDuration) ? (
                  <img src="./breeding/valentine.gif" />
                ) : (
                  <img src="./breeding/heart_animation-transparent.gif" />
                )}
              </div>
            </div>
          )}
          {isShowingHatchingModal && (
            <div>
              <div
                className="modal fade show is-flex is-flex-justify-center is-flex-align-center breeding--dragon-mating"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="hatching"
                aria-hidden="true"
              >
                {showBabyImage && (
                  <button
                    type="button"
                    className="hatching--egg-modal__btn"
                    onClick={closeHatchingModal}
                  >
                    <strong className="is-uppercase is-flex is-flex-align-center">
                      <CloseIcon className="mr-1" />
                      Close
                    </strong>
                  </button>
                )}
                <div>
                  {isHatching && !babyImageUri && (
                    <img src="./breeding/egg.gif" />
                  )}
                  {hatchingSuccess && babyImageUri && !showBabyImage && (
                    <div className="hatching--egg-modal__hatching-img-wrapper">
                      <img src={`./breeding/hatching_${babyType}.gif`} />
                      <img src={babyImageUri} hidden />
                    </div>
                  )}
                  {showBabyImage && (
                    <div className="text-center">
                      <h3 className="mb-4" color="var(--bd-color-boku)">
                        Boryoku village welcomes a new life!
                      </h3>
                      <img src={babyImageUri} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
          {isLoading && wallet && wallet.publicKey && !nfts && (
            <div className="text-center pt-5">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {!isLoading && wallet && wallet.publicKey && (

            
            <div className="container pt-5">
              {/* <button
                type="button"
                className="button-glowing has-box-shadow mb-5"
                onClick={
                  isBokuBreed
                    ? () => setIsBokuBreed(false)
                    : () => setIsBokuBreed(true)
                }
              >
                {isBokuBreed ? (
                  <span>
                    <img src="../breeding/heart-pixel.png" /> BONK?
                  </span>
                ) : (
                  <span>
                    <img src="../breeding/heart-pixel.png" /> BREED?
                  </span>
                )}
              </button> */}


                   

            
               
            <div className="">
                  <button className="select-btn has-box-shadow-small slider-button" onClick={toggleSlider}>

                    
                      {sliderVisible ? 'DUAL GENESIS' : 'SINGLE GENESIS'}


                    </button>

                    
                    <div className="is-flex is-flex-justify-center down">
                    {babySupply === null ? (
                  <div className="loading-animation">
                  <img src="../Loading.gif" className="loading-baby-animation"></img>
                    Loading Supply...
                  </div>
                ) : (
                  <p>Baby Dragonz: {babySupply} / 5000</p>
                
                )}
                
               
          </div>
          <div className="is-flex is-flex-justify-center down">
          <p>
        Time left: {timeRemaining.days}d {timeRemaining.hours}h {timeRemaining.minutes}m {timeRemaining.seconds}s
      </p>
      </div>

                    {sliderVisible ? (
                      <div className="single-breed-box">

                        
                       <div className="col-sm-3 offset-sm-1">

                        
                      <div className="breeding--dragon-box has-box-shadow mb-4 ">

                        
                        <h2 className="is-uppercase breeding--dragon-box__heading ">
                          Dragon Alpha
                        </h2>
                        {selectedDragon1.name ? (
                          <img
                            src={selectedDragon1?.image}
                            alt="Alpha Dragon image"
                          />
                        ) : (
                          <img
                            src="placeholder-breeding.png"
                            alt="Boryoku Dragon Alpha"
                          />
                        )}
                        <div className="breeding--dragon-box__footer ">
                          {" "}
                          <button
                            type="button"
                            className="select-btn has-box-shadow-small is-uppercase single-breed-center"
                            data-toggle="modal"
                            data-target=".bd-alpha-modal-lg"
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div
                        className="modal fade bd-alpha-modal-lg"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="container pt-3 pb-3">
                              <div className="row">
                                {!nfts ||
                                (nfts! as INft).dragonz.length === 0 ? (
                                  <div className="text-center">
                                    <span>No Dragonz or in cooldown</span>
                                  </div>
                                ) : (
                                  (nfts! as INft).dragonz
                                    .filter((d) => {
                                      return d.mint != selectedDragon2.mint;
                                    })
                                    .sort(
                                      (d1, d2) =>
                                        d1.lastBreedTs - d2.lastBreedTs
                                    )
                                    .map(
                                      (dragon: IDragonzNft, index: number) => {
                                        return (
                                          <Card
                                            dragon={dragon}
                                            onClick={setDragon1}
                                          />
                                        );
                                      }
                                    )
                                )}
                              </div>
                            </div>
                          </div>

                          
                        </div>
                        
                      </div>

                      {isMating ? (
                      <div className="col-sm-4">
                        <button
                          type="button"
                          disabled
                          className="button-glowing has-box-shadow"
                          onClick={mintBaby}
                        >
                          <div className="spinner-border pl-2" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div className="">
                        <button
                          type="button"
                          className="button-glowing has-box-shadow"
                          onClick={mintBaby}
                        >
                          <span>
                            <img src="../breeding/heart-pixel.png" /> MATE
                            <p className="pt-1 boku-text ">2500 BOKU</p>
                          </span>
                        </button>
                      </div>
                    )}
                    </div>
                    
                      </div>
                    ) : (
                      <div className="row">
                     
                        <>
                    <div className="col-sm-3 offset-sm-1">
                      <div className="breeding--dragon-box has-box-shadow mb-4">
                        <h2 className="is-uppercase breeding--dragon-box__heading">
                          Dragon Alpha
                        </h2>
                        {selectedDragon1.name ? (
                          <img
                            src={selectedDragon1?.image}
                            alt="Alpha Dragon image"
                          />
                        ) : (
                          <img
                            src="placeholder-breeding.png"
                            alt="Boryoku Dragon Alpha"
                          />
                        )}
                        <div className="breeding--dragon-box__footer u-text-align_center">
                          {" "}
                          <button
                            type="button"
                            className="select-btn has-box-shadow-small is-uppercase"
                            data-toggle="modal"
                            data-target=".bd-alpha-modal-lg"
                          >
                            Select
                          </button>
                        </div>
                      </div>
                      <div
                        className="modal fade bd-alpha-modal-lg"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="container pt-3 pb-3">
                              <div className="row">
                                {!nfts ||
                                (nfts! as INft).dragonz.length === 0 ? (
                                  <div className="text-center">
                                    <span>No Dragonz or in cooldown</span>
                                  </div>
                                ) : (
                                  (nfts! as INft).dragonz
                                    .filter((d) => {
                                      return d.mint != selectedDragon2.mint;
                                    })
                                    .sort(
                                      (d1, d2) =>
                                        d1.lastBreedTs - d2.lastBreedTs
                                    )
                                    .map(
                                      (dragon: IDragonzNft, index: number) => {
                                        return (
                                          <Card
                                            dragon={dragon}
                                            onClick={setDragon1}
                                          />
                                        );
                                      }
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {isMating ? (
                      <div className="">
                        <button
                          type="button"
                          disabled
                          className="button-glowing has-box-shadow"
                          onClick={mintBaby}
                        >
                          <div className="spinner-border pl-2" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </button>
                      </div>
                    ) : (
                      <div className="col-sm-4 is-flex is-flex-justify-center is-flex-align-center mb-4 down">
                        <button
                          type="button"
                          className="button-glowing has-box-shadow"
                          onClick={mintBaby}
                        >
                          <span>
                            <img src="../breeding/heart-pixel.png" /> MATE
                            <p className="pt-1 boku-text">1111 BOKU</p>
                          </span>
                        </button>
                      </div>
                    )}

                  
                     
                    <div className="col-sm-3">
                      <div className="breeding--dragon-box has-box-shadow mb-4">
                        <h2 className="is-uppercase breeding--dragon-box__heading">
                          Dragon Beta
                        </h2>
                        {selectedDragon2.name ? (
                          <img
                            src={selectedDragon2?.image}
                            alt="Beta Dragon image"
                          />
                        ) : (
                          <img
                            src="placeholder-breeding.png"
                            alt="Boryoku Dragon Beta"
                          />
                        )}
                        <div className="breeding--dragon-box__footer u-text-align_center">
                          <div>
                            <button
                              type="button"
                              className="select-btn has-box-shadow-small is-uppercase"
                              data-toggle="modal"
                              data-target=".bd-beta-modal-lg"
                            >
                              Select
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className="modal fade bd-beta-modal-lg"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-lg">
                          <div className="modal-content">
                            <div className="container pt-3 pb-3">
                              <div className="row">
                                {!nfts ||
                                (nfts as INft).dragonz.length === 0 ? (
                                  <div className="text-center">
                                    <span>No Dragonz or in cooldown</span>
                                  </div>
                                ) : (
                                  (nfts as INft).dragonz
                                    .filter((d) => {
                                      return d.mint != selectedDragon1.mint;
                                    })
                                    .sort(
                                      (d1, d2) =>
                                        d1.lastBreedTs - d2.lastBreedTs
                                    )
                                    .map(
                                      (dragon: IDragonzNft, index: number) => {
                                        return (
                                          <Card
                                            dragon={dragon}
                                            onClick={setDragon2}
                                          />
                                        );
                                      }
                                    )
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                      </div>
                    )}
                       </div>
               
                 
                
             
            </div>
          )}
        </div>
      </section>
      <Footer />
      <div className="loader--wrapper hide">
        <div className="u-text-align_center">
          <div>Mating in progress...</div>
          <div className="loader"></div>
        </div>
      </div>
    </main>
  );
};
export default Stake;
