import "./Team.css";

const Team = () => {
  return (
    <div className="container team-container">
      <h1 className="heading-main u-margin-bottom_medium heading-indented is-uppercase">
        <img src="../title-arrow.svg" />
        More About Bōryoku
      </h1>
    </div>
  );
};

export default Team;
