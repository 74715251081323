import React from "react";
import "./Faq.css";

const Faq = () => {
  return (
    <div className="container faq-container">
      <h2 className="heading-secondary">FAQ</h2>

      <dl className="faq-list">
        <dt>What else should I know about Bōryoku Dragonz?</dt>
        <dd>
          Bōryoku Dragonz are an exclusive collection of 1,111 Dragon NFTs on
          Solana, backed by a top team of NFT collectors, designers, community
          builders, and artists. The project brings a fresh design to Solana,
          with daily token airdrops, a breeding game with token burning
          mechanics, and a multi-chain community that completely transcends a
          simple PFP offering.
        </dd>

        <dt>Where can I buy the Boryoku Dragonz?</dt>
        <dd>
          Authorized secondary marketplaces are on DigitalEyes and Magic Eden.
          Please refrain from trading on other unauthorized marketplaces to
          prevent cases of fraud, scams, and fake NFTs.
        </dd>

        <dt>Will you need 2 dragons to receive tokens?</dt>
        <dd>
          No - you will only need one, it accrues according to # of dragons you
          own.
        </dd>

        <dt>Where is the 111 NFT not minted?</dt>
        <dd>
          The 101 are reserved for team, collabs and promos. Plus auctions for
          the 10 legendaries.
        </dd>

        <dt>Will there be a gated community?</dt>
        <dd>Yes</dd>
      </dl>
    </div>
  );
};

export default Faq;
