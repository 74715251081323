import React, { useEffect } from 'react';
import styled from "styled-components";

import Nav from './partials/Nav';
import Footer from './partials/Footer';
import RarityCell from './partials/RarityCell';
import RarityDictionary from './partials/RarityDictionary';

const Title = styled.h1`
    text-align: center;
    margin-top: 30px;
`

const SubText = styled.h4`
    text-align: center;
    color: rgba(255,255,255,0.7);
    margin-top: 1vh;
`

const NavWrapper = styled.div`
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-top: 90px;

    @media (max-width: 768px) {
        margin-top: 50px;
    }
`

const NavigationTraitsGrid = styled.div`
    display: inline-grid;
    grid-template-columns: repeat(6, 220px);
    column-gap: 2rem;
    row-gap: 12px;

    @media (max-width: 1600px) {
        grid-template-columns: repeat(5,200px);
    }
    @media (max-width: 992px) {
        grid-template-columns: repeat(4,160px);
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(3,160px);
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(2,160px);
        column-gap: 12px;
    }
`

const Imgbox = styled.img`
    width: 100%;
    outline: 8px solid white;
    margin: 8px 8px 0px 8px;
`

const ButtonCaption = styled.div`
    background-color: rgba(0,0,0,0.5);
    outline: 8px solid rgba(0,0,0,0.5);
    z-index: 0;
    font-size: 28px;
    text-align: center;
    margin: 1rem 8px 0.5rem 8px;
    color: #ffffff;
    width: 100%;

    @media (max-width: 1600px) {
        font-size: 26px;
    }
    @media (max-width: 992px) {
        font-size: 22px;
    }
    @media (max-width: 768px) {
        font-size: 22px;
    }
`

const AttrTitleWrap = styled.div`
    display: inline-block;
    font-size: 42px;
`
const AttrPageBorder = styled.div`
    outline: 8px solid white;
    margin: 5rem 2rem;
    padding: 30px 120px;

    @media (max-width: 1600px) {
        padding: 30px 20px;
    }
    @media (max-width: 992px) {
        padding: 30px 40px;
    }
    @media (max-width: 768px) {
        padding: 30px 20px;
    }
    @media (max-width: 600px) {
        outline: none;
        padding: 30px 0px;
        margin: 5rem 0px;
    }
`

const AttrGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(6, 220px);
    margin-bottom: 6rem;

    @media (max-width: 1600px) {
        grid-template-columns: repeat(5, 200px);
    }
    @media (max-width: 1200px) {
        grid-template-columns: repeat(5, 170px);
    }
    @media (max-width: 992px) {
        grid-template-columns: repeat(4, 160px);
        margin-bottom: 4rem;
    }
    @media (max-width: 768px) {
        grid-template-columns: repeat(3, 150px);
    }
    @media (max-width: 600px) {
        grid-template-columns: repeat(2, 150px);
        margin-bottom: 3rem;
    }
`


export interface AttributeInfo {
    AttributeName: string;
}

const AttributeTitle = (props: AttributeInfo) => {
    return(
        <div style={{ display: "inline-block", marginBottom:"3vh" }} id={props.AttributeName}>
            <AttrTitleWrap>{props.AttributeName}</AttrTitleWrap>
            <div style={{ height: "4px", width:"40%", backgroundColor:"purple", marginTop:"5px" }}/>
        </div>
    )
}

const Rarity = () => {

    return (
        <main>
            <header className="header">
            <Nav/>
            <Title>RARITY CHART</Title>
            <SubText>click on trait to navigate</SubText>
            </header>
            <NavWrapper>
                <NavigationTraitsGrid>
                    <div style={{ marginRight:"16px"}}>
                        <a href="#Background" style={{ textDecoration:"none" }}>
                            <Imgbox src="/rarity_page_assets/background.gif"/>
                            <ButtonCaption>Background</ButtonCaption>
                        </a>
                    </div>
                    <div style={{ marginRight:"16px"}}>
                        <a href="#Type" style={{ textDecoration:"none" }}>
                            <Imgbox src="/rarity_page_assets/type.gif"/>
                            <ButtonCaption>Type</ButtonCaption>
                        </a>
                    </div>
                    <div style={{ marginRight:"16px"}}>
                        <a href="#Head" style={{ textDecoration:"none" }}>
                            <Imgbox src="/rarity_page_assets/head.gif"/>
                            <ButtonCaption>Head</ButtonCaption>
                        </a>
                    </div>
                    <div style={{ marginRight:"16px"}}>
                        <a href="#Eyes" style={{ textDecoration:"none" }}>
                            <Imgbox src="/rarity_page_assets/eyes.gif"/>
                            <ButtonCaption>Eyes</ButtonCaption>
                        </a>
                    </div>
                    <div style={{ marginRight:"16px"}}>
                        <a href="#Mouth" style={{ textDecoration:"none" }}>
                            <Imgbox src="/rarity_page_assets/mouth.gif"/>
                            <ButtonCaption>Mouth</ButtonCaption>
                        </a>
                    </div>
                    <div style={{ marginRight:"16px"}}>
                        <a href="#Neck" style={{ textDecoration:"none" }}>
                            <Imgbox src="/rarity_page_assets/neck.gif"/>
                            <ButtonCaption>Neck</ButtonCaption>
                        </a>
                    </div>
                </NavigationTraitsGrid>
            </NavWrapper>

            <AttrPageBorder>
                <div style={{ display:"table", marginLeft: "auto", marginRight:"auto" }}>

                    <AttributeTitle AttributeName="Background"/>

                    <AttrGrid>
                        {
                            RarityDictionary.background.map(attribute => (
                                <RarityCell
                                    imgsrc={"/rarity_page_assets/rarity/background/" + attribute.imgname + ".png"}
                                    AttributeName={attribute.attrname}
                                    AttributeRarity={(attribute.rarity / 11.01).toFixed(2) + "%"}
                                />
                            ))
                        }
                    </AttrGrid>


                    <AttributeTitle AttributeName="Type"/>

                    <AttrGrid>
                        {
                            RarityDictionary.type.map(attribute => (
                                <RarityCell
                                    imgsrc={"/rarity_page_assets/rarity/type/" + attribute.imgname + ".png"}
                                    AttributeName={attribute.attrname}
                                    AttributeRarity={(attribute.rarity / 11.01).toFixed(2) + "%"}
                                />
                            ))
                        }
                    </AttrGrid>


                    <AttributeTitle AttributeName="Head"/>

                    <AttrGrid>
                        {
                            RarityDictionary.head.map(attribute => (
                                <RarityCell
                                    imgsrc={"/rarity_page_assets/rarity/head_slot/" + attribute.imgname + ".png"}
                                    AttributeName={attribute.attrname}
                                    AttributeRarity={(attribute.rarity / 11.01).toFixed(2) + "%"}
                                />
                            ))
                        }
                    </AttrGrid>


                    <AttributeTitle AttributeName="Eyes"/>

                    <AttrGrid>
                        {
                            RarityDictionary.eyes.map(attribute => (
                                <RarityCell
                                    imgsrc={"/rarity_page_assets/rarity/eyes/" + attribute.imgname + ".png"}
                                    AttributeName={attribute.attrname}
                                    AttributeRarity={(attribute.rarity / 11.01).toFixed(2) + "%"}
                                />
                            ))
                        }
                    </AttrGrid>


                    <AttributeTitle AttributeName="Mouth"/>

                    <AttrGrid>
                        {
                            RarityDictionary.mouth.map(attribute => (
                                <RarityCell
                                    imgsrc={"/rarity_page_assets/rarity/mouth_slot/" + attribute.imgname + ".png"}
                                    AttributeName={attribute.attrname}
                                    AttributeRarity={(attribute.rarity / 11.01).toFixed(2) + "%"}
                                />
                            ))
                        }
                    </AttrGrid>


                    <AttributeTitle AttributeName="Neck"/>

                    <AttrGrid>
                        {
                            RarityDictionary.neck.map(attribute => (
                                <RarityCell
                                    imgsrc={"/rarity_page_assets/rarity/neck_slot/" + attribute.imgname + ".png"}
                                    AttributeName={attribute.attrname}
                                    AttributeRarity={(attribute.rarity / 11.01).toFixed(2) + "%"}
                                />
                            ))
                        }
                    </AttrGrid>

                </div>
            </AttrPageBorder>
            <Footer/>
        </main>
    );
}

export default Rarity;