const RarityDictionary = {
    background: [
        { imgname: "neon_back",         attrname: "TRON",               rarity: 117 },
        { imgname: "eeb7f4",            attrname: "YELLOW",             rarity: 106 },
        { imgname: "b9e0e6",            attrname: "TEAL",               rarity: 100 },
        { imgname: "d2eadb",            attrname: "GREY",               rarity: 98  },
        { imgname: "ced1f1",            attrname: "LAVENDER",           rarity: 94  },
        { imgname: "town_back",         attrname: "TOWN",               rarity: 94  },
        { imgname: "ebd6a9",            attrname: "PINK",               rarity: 90  },
        { imgname: "plant_back",        attrname: "NUCLEAR PLANT",      rarity: 88  },
        { imgname: "ede9ac",            attrname: "BEIGE",              rarity: 86  },
        { imgname: "d0e3b0",            attrname: "BLUE",               rarity: 79  },
        { imgname: "ebc2db",            attrname: "GREEN",              rarity: 76  },
        { imgname: "japanese_back",     attrname: "JAPANESE",           rarity: 73  }
    ],  
    type: [ 
        { imgname: "d_iron_3",          attrname: "IRON YELLOW",        rarity: 92 },
        { imgname: "d_cyber_3",         attrname: "CYBER BLUE",         rarity: 77 },
        { imgname: "d_neon_3",          attrname: "NEON BLUE",          rarity: 66 },
        { imgname: "d_diamond_2",       attrname: "DIAMOND PURPLE",     rarity:	62 },
        { imgname: "d_cosmic_3",        attrname: "COSMIC GREEN",       rarity:	61 },
        { imgname: "d_inferno_3",       attrname: "INFERNO RED",        rarity: 61 },
        { imgname: "d_zombie_3",        attrname: "ZOMBIE GOLDEN",      rarity:	57 },
        { imgname: "d_cyber_2",         attrname: "CYBER GREY",         rarity: 51 },
        { imgname: "d_diamond_1",       attrname: "DIAMOND BLUE",       rarity:	51 },
        { imgname: "d_inferno_2",       attrname: "INFERNO BLACK",      rarity:	50 },
        { imgname: "d_skeleton_3",      attrname: "SKELETON GOLDEN",    rarity:	49 },
        { imgname: "d_iron_2",          attrname: "IRON GREY",          rarity: 44 },
        { imgname: "d_skeleton_2",      attrname: "SKELETON SILVER",    rarity:	42 },
        { imgname: "d_cosmic_2",        attrname: "COSMIC PURPLE",      rarity:	40 },
        { imgname: "d_diamond_3",       attrname: "DIAMOND ORANGE",     rarity:	39 },
        { imgname: "d_zombie_2",        attrname: "ZOMBIE BLUE",        rarity: 39 },
        { imgname: "d_cyber_1",         attrname: "CYBER BLACK",        rarity: 34 },
        { imgname: "d_neon_2",          attrname: "NEON ORANGE",        rarity: 33 },
        { imgname: "d_zombie_1",        attrname: "ZOMBIE GREEN",       rarity:	32 },
        { imgname: "d_iron_1",          attrname: "IRON RED",           rarity: 30 },
        { imgname: "d_neon_1",          attrname: "NEON PURPLE",        rarity: 24 },
        { imgname: "d_inferno_1",       attrname: "INFERNO PURPLE",     rarity:	23 },
        { imgname: "d_skeleton_1",      attrname: "SKELETON BONE",      rarity:	23 },
        { imgname: "d_cosmic_1",        attrname: "COSMIC BLUE",        rarity: 21 }
    ],
    head: [
        { imgname: "none",              attrname: "NONE",               rarity: 741 }, 
        { imgname: "h_fireball",        attrname: "FIREBALL",           rarity: 101 }, 
        { imgname: "h_croco",           attrname: "CROC",               rarity: 66  },  
        { imgname: "h_eye",             attrname: "EYE",                rarity: 62  },  
        { imgname: "h_octopus",         attrname: "OCTOPUS",            rarity: 57  },  
        { imgname: "h_slug",            attrname: "SLUG",               rarity: 43  },  
        { imgname: "h_monkey",          attrname: "MONKEY",             rarity: 31  }
    ],
    eyes: [
        { imgname: "golden_shades",     attrname: "GOLDEN SHADES",      rarity: 221 },
        { imgname: "vipers",            attrname: "VIPERS",             rarity: 138 },
        { imgname: "3d_glasses",        attrname: "3D GLASSES",         rarity: 114 },
        { imgname: "synthwave_shades",  attrname: "SYNTHWAVE SHADES",   rarity: 111 },
        { imgname: "laughing_goggles",  attrname: "LAUGHING GOGGLES",   rarity: 86 },
        { imgname: "red_eyes",          attrname: "RED EYES",           rarity: 83 },
        { imgname: "white_eyes",        attrname: "WHITE EYES",         rarity: 67 },
        { imgname: "classic_eyes",      attrname: "CLASSIC EYES",       rarity: 66 },
        { imgname: "tracker",           attrname: "TRACKER",            rarity: 65 },
        { imgname: "blue_eyes",         attrname: "BLUE EYES",          rarity: 58 },
        { imgname: "laser_eyes",        attrname: "LASER EYES",         rarity: 56 },
        { imgname: "snake_eyes",        attrname: "SNAKE EYES",         rarity: 36 }
    ],
    mouth: [
        { imgname: "none",	            attrname: "NONE",               rarity: 515 },
        { imgname: "pacifier",	        attrname: "PACIFIER",           rarity: 94 },
        { imgname: "ice_breath",	    attrname: "ICE BREATH",         rarity: 93 },
        { imgname: "bubble_gum",	    attrname: "BUBBLE GUM",         rarity: 86 },
        { imgname: "acid",	            attrname: "ACID",               rarity: 78 },
        { imgname: "rainbow_puke",	    attrname: "RAINBOW PUKE",       rarity: 54 },
        { imgname: "cigar",	            attrname: "CIGAR",              rarity: 51 },
        { imgname: "electro",	        attrname: "ELECTRO",            rarity: 38 },
        { imgname: "icecream",	        attrname: "ICECREAM",           rarity: 33 },
        { imgname: "laser_ray",	        attrname: "LASER RAY",          rarity: 30 },
        { imgname: "flame",	            attrname: "FLAME",              rarity: 29 }
    ],
    neck: [
        { imgname: "none",              attrname:"NONE",                rarity: 696 },
        { imgname: "golden_chain",      attrname:"GOLDEN CHAIN",        rarity: 191 },
        { imgname: "silver_chain",      attrname:"SILVER CHAIN",        rarity: 84  },
        { imgname: "collar",            attrname:"COLLAR",              rarity: 83  },
        { imgname: "diamond_chain",     attrname:"DIAMOND CHAIN",       rarity: 47  }
    ]
}

export default RarityDictionary;