import { MERCH_PROGRAM_ID } from "../contracts/merch";
import { decodeMetadata, getMetadataAccount } from "../utils/metadata.service";

import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";

const useProofs = (connection: Connection, validCreators: string[]) => {
  const wallet = useAnchorWallet();
  const [loading, setLoading] = useState(false);
  const [proofs, setProofs] = useState<PublicKey[]>();

  async function fetchProofs() {
    const response = await connection.getParsedTokenAccountsByOwner(
      wallet!.publicKey,
      {
        programId: TOKEN_PROGRAM_ID,
      }
    );

    let proofMints: PublicKey[] = [];
    await Promise.all(
      response.value
        .filter(
          (accInfo) =>
            accInfo.account.data.parsed.info.tokenAmount.uiAmount !== 0
        )
        .map(async (accInfo) => {
          const mint = accInfo.account.data.parsed.info.mint;
          const metadataKey = new PublicKey(await getMetadataAccount(mint));
          const metadata = await connection.getAccountInfo(metadataKey);
          if (metadata) {
            const decoded = await decodeMetadata(metadata!.data);
            if (decoded!.data.creators) {
              const creator = decoded!.data.creators![0];
              if (creator.verified && validCreators.includes(creator.address)) {
                proofMints.push(new PublicKey(mint));
              }
            }
          }
        })
    );

    setProofs(proofMints);
  }

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
      ) {
        return;
      }

      setLoading(true);
      await fetchProofs();

      setLoading(false);
    })();
  }, [wallet, wallet?.publicKey]);

  return { isLoading: loading, proofs, setProofs };
};

export default useProofs;
