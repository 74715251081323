import React, { useState, useEffect } from "react";
import { BrowserRouter as Link } from "react-router-dom";
import styled from "styled-components";
import "./Merch.css";
import "../BlockList.css";
import * as anchor from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import { shortenAddress } from "../candy-machine";

import useProofs from "../hooks/useProofs";
import { mintMerch, MERCH_PROGRAM_ID } from "../contracts/merch";
import { decodeMerchInfo, Merch } from "../contracts/decoder";
import { getProgram } from "../contracts/utils";
import { PublicKey } from "@solana/web3.js";
import { HeightRounded } from "@material-ui/icons";

const ConnectButton = styled(WalletDialogButton)`
  padding: 0.5rem 1.25rem !important;
  border-radius: 0 !important;
  background-color: var(--bd-color-primary) !important;
  border: 1px solid var(--bd-color-accent) !important;
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3) !important;
`;
export interface HomeProps {
  connection: anchor.web3.Connection;
}
const validCreators = [
  "2kBDMFhxcKugjT1JNhiRD2Wxg88rx9zwh5gR7WSdKZC8",
  "NAJ1KA8tTUssP7HcVkUqS7sTaKEpgqhTDxW8GCjkVCe",
  "DRGNjvBvnXNiQz9dTppGk1tAsVxtJsvhEmojEfBU3ezf",
  "kngTPkzUSCqEdo8RSaa2v1XzRZp8gHmysSS29pPXuQ3",
];

const seller = new PublicKey("DRGNjvBvnXNiQz9dTppGk1tAsVxtJsvhEmojEfBU3ezf");

const hoodieBokuLogoName = "Boryoku Hoodiez 1st Edition";

const Merchanise = (props: HomeProps) => {
  const connection = props.connection;
  const wallet = useAnchorWallet();
  const { isLoading, proofs, setProofs } = useProofs(connection, validCreators);
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [hoodie1, setHoodie1] = useState<Merch>();
  const [isMinting, setMinting] = useState(false);

  const hasDragonz = proofs && proofs?.length > 0;

  const buyMerch = async (merchName: string) => {
    setError(false);
    setSuccess(false);
    setMinting(true);

    try {
      const program = await getProgram(
        wallet as anchor.Wallet,
        MERCH_PROGRAM_ID,
        connection
      );
      const merchMintKey = await mintMerch(
        program,
        connection,
        wallet!.publicKey,
        proofs![0],
        merchName
      );
      setHoodie1({ ...hoodie1!, sold: hoodie1!.sold + 1 });
      setSuccess(true);
      console.log("merch", merchMintKey);
    } catch (e) {
      console.error(e);
      setError(true);
    }
    setMinting(false);
  };

  useEffect(() => {
    console.log("fetching");
    async function fetchHoodie1() {
      const hoodie1Info = await decodeMerchInfo(
        connection,
        seller,
        hoodieBokuLogoName
      );
      setHoodie1(hoodie1Info);
    }

    fetchHoodie1();
  }, []);

  return (
    <main>
      <section>
        <header className="header">
          <Nav />
          <h1 className="heading-main is-flex-justify-center is-uppercase u-margin-top_large">
            <img src="../title-arrow.svg" />
            Merch
          </h1>
          <div className="is-flex is-flex-justify-center">
            {!wallet ? (
              <ConnectButton className="mt-3 mb-3">
                Connect Wallet
              </ConnectButton>
            ) : (
              <p className="pb-5">
                Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}
              </p>
            )}
          </div>
        </header>

        <div className="content-wrapper">
          {error && (
            <div className="notification mt-4 has-box-shadow">
              <span className="notification--icon">!</span> Something went
              wrong... Refresh and try again
            </div>
          )}
          {success && (
            <div className="notification-success mt-4 has-box-shadow">
              <span className="notification--icon">!</span>{" "}
              <strong>A wild Boryoku merch appeared...</strong>
            </div>
          )}
          <div className="merch-wrapper">
            <div className="merch-content has-box-shadow">
              <div className="merch-content-main">
                <p className="is-uppercase merch-title-sup">Limited Edition</p>
                <h2 className="merch-title is-uppercase">
                  {hoodieBokuLogoName}
                </h2>
                <div className="merch-content-main--img--wrapper">
                  <video width="440" height="440" autoPlay muted loop>
                    <source src="/merch/hoodie-bokulogo.mp4" type="video/mp4" />
                  </video>
                </div>
                {hoodie1 && (
                  <div>
                    <p className="merch-price">
                      <strong>{hoodie1!.tokenPrice} BOKU</strong>
                    </p>
                    <small>
                      {hoodie1!.maxSupply - hoodie1!.sold}/{hoodie1!.maxSupply}{" "}
                      available
                    </small>
                  </div>
                )}
              </div>
              <div className="merch-content-secondary">
                <p>
                  NFT can be redeemd for physical merch<sup>*</sup>
                </p>
                <small>
                  * Redemption will burn the NFT and incur 69 USDC fee
                </small>
                <br />
                <br />
                <p>
                  If not redeemed, NFT can used to exchange for Metaverse asset
                  in the future
                </p>

                <div className="u-text-align_center">
                  {!wallet ? (
                    <ConnectButton className="button is-accent-bright is-large has-box-shadow mt-5">
                      Connect Wallet
                    </ConnectButton>
                  ) : hoodie1!.maxSupply - hoodie1!.sold == 0 ? (
                    <button className="button is-accent-bright is-large has-box-shadow mt-5">
                      Soldout
                    </button>
                  ) : isLoading ? (
                    <div>
                      <button
                        disabled
                        className="button is-accent-bright is-large has-box-shadow mt-5"
                      >
                        <div className="spinner-border pl-2" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>
                      </button>
                    </div>
                  ) : !isMinting ? (
                    <div>
                      <button
                        disabled={!hasDragonz}
                        className="button is-accent-bright is-large has-box-shadow mt-5"
                        onClick={async () => await buyMerch(hoodieBokuLogoName)}
                      >
                        Mint Merch
                      </button>
                      {!hasDragonz && (
                        <div className="mt-3 has-text-accent-bright">
                          <small>No Dragonz, Eggz or Babyz found</small>
                        </div>
                      )}
                    </div>
                  ) : (
                    <div>
                      <button className="button is-accent-bright is-large has-box-shadow mt-5">
                        <div className="spinner-border pl-2" role="status">
                          <span className="sr-only">Minting...</span>
                        </div>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};
export default Merchanise;
