
interface Dragon {
    name: string,
    mint: string,
    image: string,
    traits: Array<Trait>
}

interface Trait {
    trait_type: string,
    value: string
}

interface DragonMap {
    [key: string]: Dragon
}

const dragonz: DragonMap = {
    "Cj5dQqMgno3f3qDg2jeZaEAK4vxMSHd2WH3t37HRkkX3": {
        "mint": "Cj5dQqMgno3f3qDg2jeZaEAK4vxMSHd2WH3t37HRkkX3",
        "name": "The Anon",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Anon"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/anon.gif"
    },
    "GtnSWiLiQzdUneKahGscFd4uEVkRFG1wU6nXcjDHk2Ex": {
        "mint": "GtnSWiLiQzdUneKahGscFd4uEVkRFG1wU6nXcjDHk2Ex",
        "name": "The Astro",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Astro"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/astro.gif"
    },
    "DS1QgU9XLkHPb49Mf5MAGNMzEwjMeEeC2rbbUwaoca36": {
        "mint": "DS1QgU9XLkHPb49Mf5MAGNMzEwjMeEeC2rbbUwaoca36",
        "name": "The Mutant",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Mutant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/mutant.gif"
    },
    "DUgriH1FahUXWVjhbcbvKHb9BrghYjWtv8SyKL7zjuDT": {
        "mint": "DUgriH1FahUXWVjhbcbvKHb9BrghYjWtv8SyKL7zjuDT",
        "name": "The Fren",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Fren"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/fren.gif"
    },
    "GMWGUdQN5831GAsRrKduBBVq5Wxo9QEGES1hyEd9XSEm": {
        "mint": "GMWGUdQN5831GAsRrKduBBVq5Wxo9QEGES1hyEd9XSEm",
        "name": "The Electro",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Electro"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/electro.gif"
    },
    "9dHn8TwURfwvvREf9pADAxpthXtoUHhy5KdUnWbX91Rj": {
        "mint": "9dHn8TwURfwvvREf9pADAxpthXtoUHhy5KdUnWbX91Rj",
        "name": "The Shinobi",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Shinobi"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/shinobi.gif"
    },
    "HCUXEW4Kit9CJUK18jKY98r3iw4CD8bzf1yKMta3PhQx": {
        "mint": "HCUXEW4Kit9CJUK18jKY98r3iw4CD8bzf1yKMta3PhQx",
        "name": "The Rambo",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Rambo"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/rambo.gif"
    },
    "AKW7rfYeMrTGYXTjytXexEjBAXU6RTfoFXHWg72Vb2D5": {
        "mint": "AKW7rfYeMrTGYXTjytXexEjBAXU6RTfoFXHWg72Vb2D5",
        "name": "The Oracle",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Oracle"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/oracle.gif"
    },
    "4kAYBtqurH6rEP5YHWjZunjge3tjPAB5ikJVfC1AexAF": {
        "mint": "4kAYBtqurH6rEP5YHWjZunjge3tjPAB5ikJVfC1AexAF",
        "name": "The Alchemist",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The Alchemist"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/alchemist.gif"
    },
    "Amhg7jgCdAycvwBfLw9ZYdbai8QidfgPWC35SRQY44wi": {
        "mint": "Amhg7jgCdAycvwBfLw9ZYdbai8QidfgPWC35SRQY44wi",
        "name": "The King",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Legendary",
                "value": "The King"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/king.gif"
    },
    "sV5pS8fb9xKzF2FmFE4Z1AND6tRfvSYEoZPsxNcY247": {
        "mint": "sV5pS8fb9xKzF2FmFE4Z1AND6tRfvSYEoZPsxNcY247",
        "name": "Boryoku Dragonz #1106",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1106.png"
    },
    "5FfNKmCNPPCtM6BZ6smWKBQmb2zteLu8JigUxXvE1RB7": {
        "mint": "5FfNKmCNPPCtM6BZ6smWKBQmb2zteLu8JigUxXvE1RB7",
        "name": "Boryoku Dragonz #1008",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1008.png"
    },
    "5jDCnvNc8Jv9Kbr54VJNATjBFHWqLPZQYFJXe3Enkwg1": {
        "mint": "5jDCnvNc8Jv9Kbr54VJNATjBFHWqLPZQYFJXe3Enkwg1",
        "name": "Boryoku Dragonz #1007",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1007.png"
    },
    "G9g2GxYDS49yehnPvzGvFFwBbcUTQrcycD5UPNkiFkir": {
        "mint": "G9g2GxYDS49yehnPvzGvFFwBbcUTQrcycD5UPNkiFkir",
        "name": "Boryoku Dragonz #1010",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1010.png"
    },
    "52cyA556k5GRLjhk5k2Yd8sY1oKVcTa9m1RKT8oXbBpp": {
        "mint": "52cyA556k5GRLjhk5k2Yd8sY1oKVcTa9m1RKT8oXbBpp",
        "name": "Boryoku Dragonz #1009",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1009.png"
    },
    "84xirMyShNVhQW159keWr94Ce5msTMQUCbZRDAGN6Vo4": {
        "mint": "84xirMyShNVhQW159keWr94Ce5msTMQUCbZRDAGN6Vo4",
        "name": "Boryoku Dragonz #1005",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1005.png"
    },
    "6qY69xVkxgHJ6ST6vDg6VCLZhVX1WD8fa1atKrhkxoQx": {
        "mint": "6qY69xVkxgHJ6ST6vDg6VCLZhVX1WD8fa1atKrhkxoQx",
        "name": "Boryoku Dragonz #1006",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1006.png"
    },
    "CNjzR6fEeFnoSDuGx5uJfTLh7ChBgjMVQapa14pVK88L": {
        "mint": "CNjzR6fEeFnoSDuGx5uJfTLh7ChBgjMVQapa14pVK88L",
        "name": "Boryoku Dragonz #1003",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1003.png"
    },
    "5G4796iGxqPQHsgXZuu1hcYz2cv15sN3dB3casc5BLKH": {
        "mint": "5G4796iGxqPQHsgXZuu1hcYz2cv15sN3dB3casc5BLKH",
        "name": "Boryoku Dragonz #1004",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1004.png"
    },
    "7eFiBnVNGNHmA8YjGG3LRrjHojg7XrCNA1w4bi7Ub6ji": {
        "mint": "7eFiBnVNGNHmA8YjGG3LRrjHojg7XrCNA1w4bi7Ub6ji",
        "name": "Boryoku Dragonz #1002",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1002.png"
    },
    "GM3iupvUsKpvaPLJXdnp11Gi8qzf7X3kpTLvS64dteTe": {
        "mint": "GM3iupvUsKpvaPLJXdnp11Gi8qzf7X3kpTLvS64dteTe",
        "name": "Boryoku Dragonz #1001",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1001.png"
    },
    "GXkHebKreSqFKV2A7dpy52Us8SQDNPHZDm1ZVP2YrdHE": {
        "mint": "GXkHebKreSqFKV2A7dpy52Us8SQDNPHZDm1ZVP2YrdHE",
        "name": "Boryoku Dragonz #1000",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1000.png"
    },
    "G9WQbKdFqLR4DyQhqVZ3Lys6m49SgAHek8Aw48JHAj43": {
        "mint": "G9WQbKdFqLR4DyQhqVZ3Lys6m49SgAHek8Aw48JHAj43",
        "name": "Boryoku Dragonz #998",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/998.png"
    },
    "DKedx7CArJopjoNLYnP38VuKMR8oUbEDzbfX7u58d3Ld": {
        "mint": "DKedx7CArJopjoNLYnP38VuKMR8oUbEDzbfX7u58d3Ld",
        "name": "Boryoku Dragonz #999",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/999.png"
    },
    "AYkooU9eM8sr2s2Xn5rAbGNLtFDokTEV78c6bfU7CRru": {
        "mint": "AYkooU9eM8sr2s2Xn5rAbGNLtFDokTEV78c6bfU7CRru",
        "name": "Boryoku Dragonz #997",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/997.png"
    },
    "4KemokAyagEkj4nruQBZ3AUHGBBwhyrRioJf6Hsa2ZbJ": {
        "mint": "4KemokAyagEkj4nruQBZ3AUHGBBwhyrRioJf6Hsa2ZbJ",
        "name": "Boryoku Dragonz #996",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/996.png"
    },
    "7tEfKQgg3mLcrCGxdHJVmVqRSBfitoXJCqdUcxFzLyhK": {
        "mint": "7tEfKQgg3mLcrCGxdHJVmVqRSBfitoXJCqdUcxFzLyhK",
        "name": "Boryoku Dragonz #995",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/995.png"
    },
    "81WfYp3Y2VFiGejCAPhRS7KmoRVFwTpUUTYrSZHrfFMc": {
        "mint": "81WfYp3Y2VFiGejCAPhRS7KmoRVFwTpUUTYrSZHrfFMc",
        "name": "Boryoku Dragonz #994",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/994.png"
    },
    "5fRjPRNyapxV1AWL9LMkm3cBG4c9kfN5CnX7DjjqAf4r": {
        "mint": "5fRjPRNyapxV1AWL9LMkm3cBG4c9kfN5CnX7DjjqAf4r",
        "name": "Boryoku Dragonz #993",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/993.png"
    },
    "Gs8jzsYZn1RcLAZNLzm9CzzXsQD9spUsyezqxmyD2tgn": {
        "mint": "Gs8jzsYZn1RcLAZNLzm9CzzXsQD9spUsyezqxmyD2tgn",
        "name": "Boryoku Dragonz #992",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/992.png"
    },
    "A49UmaJpGUtYp61VtV7w9iYi2Fn24BAmu6UbBQbLtSc6": {
        "mint": "A49UmaJpGUtYp61VtV7w9iYi2Fn24BAmu6UbBQbLtSc6",
        "name": "Boryoku Dragonz #990",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/990.png"
    },
    "GvGQVcSsRsDmZPQhR36W5Vmxi7nXU9vuSMpWPrH1Ah1W": {
        "mint": "GvGQVcSsRsDmZPQhR36W5Vmxi7nXU9vuSMpWPrH1Ah1W",
        "name": "Boryoku Dragonz #991",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/991.png"
    },
    "DNccusjve4jFFxntMqx64F55akWoBAxk1p7vURebcFsw": {
        "mint": "DNccusjve4jFFxntMqx64F55akWoBAxk1p7vURebcFsw",
        "name": "Boryoku Dragonz #989",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/989.png"
    },
    "5vDAbBp3UjzsxcQgnwXzkMQxZhioC7ppprXKdPTM2ovC": {
        "mint": "5vDAbBp3UjzsxcQgnwXzkMQxZhioC7ppprXKdPTM2ovC",
        "name": "Boryoku Dragonz #988",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/988.png"
    },
    "7aSUNzXtohz8333kZyCTRXsUu7d2gKXVKV4xYp1oVnBH": {
        "mint": "7aSUNzXtohz8333kZyCTRXsUu7d2gKXVKV4xYp1oVnBH",
        "name": "Boryoku Dragonz #987",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/987.png"
    },
    "CGEu7bLMW3APsDceQCJuStNoSp2dtgVf2jUMUUTP3b9K": {
        "mint": "CGEu7bLMW3APsDceQCJuStNoSp2dtgVf2jUMUUTP3b9K",
        "name": "Boryoku Dragonz #986",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/986.png"
    },
    "2JdJVoTtXv3B5ew8N49WtBk5BRCEHUAhHZaNLsrN8mPC": {
        "mint": "2JdJVoTtXv3B5ew8N49WtBk5BRCEHUAhHZaNLsrN8mPC",
        "name": "Boryoku Dragonz #985",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/985.png"
    },
    "8pCPRgThzi81Keg1NGKS9sKxw7xuwTU2cviP4UQ1kGTY": {
        "mint": "8pCPRgThzi81Keg1NGKS9sKxw7xuwTU2cviP4UQ1kGTY",
        "name": "Boryoku Dragonz #984",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/984.png"
    },
    "27TwqEofLHmqkQ1NAaSzAHfRksvt2eLk64iRKnthaCZ3": {
        "mint": "27TwqEofLHmqkQ1NAaSzAHfRksvt2eLk64iRKnthaCZ3",
        "name": "Boryoku Dragonz #983",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/983.png"
    },
    "3YcETwoQnvW3BsxSZ3pqUyzKDmyU4gyYceqQnKtEtJdp": {
        "mint": "3YcETwoQnvW3BsxSZ3pqUyzKDmyU4gyYceqQnKtEtJdp",
        "name": "Boryoku Dragonz #982",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/982.png"
    },
    "2bj3o6joG2SR5yv572rGhL7iNbBQJE9Ahvw1nHkpNgZb": {
        "mint": "2bj3o6joG2SR5yv572rGhL7iNbBQJE9Ahvw1nHkpNgZb",
        "name": "Boryoku Dragonz #981",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/981.png"
    },
    "EMAUxENMQdHi9S8tHdWG66uzLdyh63oVR5x8q9EBhds5": {
        "mint": "EMAUxENMQdHi9S8tHdWG66uzLdyh63oVR5x8q9EBhds5",
        "name": "Boryoku Dragonz #980",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/980.png"
    },
    "DM4uHhAcGW5dze2bcuxs3eRoCtHnCsudhwtjoqyC2qyR": {
        "mint": "DM4uHhAcGW5dze2bcuxs3eRoCtHnCsudhwtjoqyC2qyR",
        "name": "Boryoku Dragonz #979",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/979.png"
    },
    "52ZGmqVTb47pCQm5D7BghsLJQJL1GaASPLdjK2S9ULE": {
        "mint": "52ZGmqVTb47pCQm5D7BghsLJQJL1GaASPLdjK2S9ULE",
        "name": "Boryoku Dragonz #978",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/978.png"
    },
    "CS3PHYbRWyU2Sd2k18ABUiZXbkh8NzPRNHsedt1qMjNg": {
        "mint": "CS3PHYbRWyU2Sd2k18ABUiZXbkh8NzPRNHsedt1qMjNg",
        "name": "Boryoku Dragonz #977",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/977.png"
    },
    "84DRbh2thXrfgV1PPGucVDh297FFeJrqVtfZdsFDG7rC": {
        "mint": "84DRbh2thXrfgV1PPGucVDh297FFeJrqVtfZdsFDG7rC",
        "name": "Boryoku Dragonz #975",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/975.png"
    },
    "EjfCAR1X88fNq8HyDM5dbeEaxncDBgTZ3zQyP6srshQS": {
        "mint": "EjfCAR1X88fNq8HyDM5dbeEaxncDBgTZ3zQyP6srshQS",
        "name": "Boryoku Dragonz #976",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/976.png"
    },
    "D419wDwyrJWLEz6PgbgWjxLTE1iAsPxVE5Uwr9ffWBLW": {
        "mint": "D419wDwyrJWLEz6PgbgWjxLTE1iAsPxVE5Uwr9ffWBLW",
        "name": "Boryoku Dragonz #974",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/974.png"
    },
    "8Bs1kcXiYihDBNZ3GT2wJUFKBAKUudA37foWg9ajddin": {
        "mint": "8Bs1kcXiYihDBNZ3GT2wJUFKBAKUudA37foWg9ajddin",
        "name": "Boryoku Dragonz #971",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/971.png"
    },
    "2avTJvbNJzyptY5purssev4UKzHSFEWGCcsfcM8E1yjZ": {
        "mint": "2avTJvbNJzyptY5purssev4UKzHSFEWGCcsfcM8E1yjZ",
        "name": "Boryoku Dragonz #970",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/970.png"
    },
    "HbfE3hxfG7eUA2B9kbWSm9YDDoVfjcRcLg9Xi7LjxymB": {
        "mint": "HbfE3hxfG7eUA2B9kbWSm9YDDoVfjcRcLg9Xi7LjxymB",
        "name": "Boryoku Dragonz #973",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/973.png"
    },
    "5o6pbdutYaaySbu2zxJ5Ba9hqbtYdZvuFcaYVaFHSFVV": {
        "mint": "5o6pbdutYaaySbu2zxJ5Ba9hqbtYdZvuFcaYVaFHSFVV",
        "name": "Boryoku Dragonz #972",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/972.png"
    },
    "46ENzpRuZTaM9jA2qfvBkHH5DJgitbfQLEUw5PFAXw6r": {
        "mint": "46ENzpRuZTaM9jA2qfvBkHH5DJgitbfQLEUw5PFAXw6r",
        "name": "Boryoku Dragonz #969",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/969.png"
    },
    "8KkCYcQjZGGYLFH28hFVCSkQPynVTYaAGMAT64XTT46C": {
        "mint": "8KkCYcQjZGGYLFH28hFVCSkQPynVTYaAGMAT64XTT46C",
        "name": "Boryoku Dragonz #968",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/968.png"
    },
    "51wAMDHfp7vte962ahWs5VexifLFo8a8QtvvKguc1txY": {
        "mint": "51wAMDHfp7vte962ahWs5VexifLFo8a8QtvvKguc1txY",
        "name": "Boryoku Dragonz #967",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/967.png"
    },
    "DCHqWdTxy4Vqj4a5yJqVPS2hcgKFzKxxbQurHGGPDARe": {
        "mint": "DCHqWdTxy4Vqj4a5yJqVPS2hcgKFzKxxbQurHGGPDARe",
        "name": "Boryoku Dragonz #964",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/964.png"
    },
    "GLwCMjUkcYbu3bVTkXqUEcTEZAYitGg7DscTJcYwVFGe": {
        "mint": "GLwCMjUkcYbu3bVTkXqUEcTEZAYitGg7DscTJcYwVFGe",
        "name": "Boryoku Dragonz #965",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/965.png"
    },
    "9VjbPTZpRJEq1fkZuzkDcWZ4QTem1B8fn1wsiNzgqiew": {
        "mint": "9VjbPTZpRJEq1fkZuzkDcWZ4QTem1B8fn1wsiNzgqiew",
        "name": "Boryoku Dragonz #966",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/966.png"
    },
    "swmKrYWrbrjMvwnVyZox3qARZ17zzJnbNUWhXiT676B": {
        "mint": "swmKrYWrbrjMvwnVyZox3qARZ17zzJnbNUWhXiT676B",
        "name": "Boryoku Dragonz #962",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/962.png"
    },
    "cRsbWHoLYUC3pjbEKtNzpX7M5MB8z3dgG7gXHzYkTdQ": {
        "mint": "cRsbWHoLYUC3pjbEKtNzpX7M5MB8z3dgG7gXHzYkTdQ",
        "name": "Boryoku Dragonz #960",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/960.png"
    },
    "BRWHszSbGSrgSoeqNDzuq8AhLtYMmk2qyCcbmmr4yKx2": {
        "mint": "BRWHszSbGSrgSoeqNDzuq8AhLtYMmk2qyCcbmmr4yKx2",
        "name": "Boryoku Dragonz #959",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/959.png"
    },
    "FJJKaJTrHSmu8ub4qGPchwcjVtQ3EbtWSEGVSgbmoXja": {
        "mint": "FJJKaJTrHSmu8ub4qGPchwcjVtQ3EbtWSEGVSgbmoXja",
        "name": "Boryoku Dragonz #963",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/963.png"
    },
    "4hwPuVRughMbvrpt4uLkLLAUMyhEqWH6MfnA3gWXhFSp": {
        "mint": "4hwPuVRughMbvrpt4uLkLLAUMyhEqWH6MfnA3gWXhFSp",
        "name": "Boryoku Dragonz #958",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/958.png"
    },
    "GU5cAVQA3n37U4MWWhEGq4Rzg1yJtpSiUntBpYi2zyAG": {
        "mint": "GU5cAVQA3n37U4MWWhEGq4Rzg1yJtpSiUntBpYi2zyAG",
        "name": "Boryoku Dragonz #961",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/961.png"
    },
    "7qx7TtTpcbkz5BJYkH1gbjHknWsbtxiBZ5TgqFUka3N2": {
        "mint": "7qx7TtTpcbkz5BJYkH1gbjHknWsbtxiBZ5TgqFUka3N2",
        "name": "Boryoku Dragonz #957",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/957.png"
    },
    "9wwi7jZvDUEEE7Jft6Hrhah1ohiX5dmxfnusn29P9ScH": {
        "mint": "9wwi7jZvDUEEE7Jft6Hrhah1ohiX5dmxfnusn29P9ScH",
        "name": "Boryoku Dragonz #956",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/956.png"
    },
    "BqvydbzESFx7tr4w4KDk7oNmQjVrV1Fa8R94xXy4JnVG": {
        "mint": "BqvydbzESFx7tr4w4KDk7oNmQjVrV1Fa8R94xXy4JnVG",
        "name": "Boryoku Dragonz #955",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/955.png"
    },
    "CCjU9Xfnyum1SJHKEd2wppgwZZucrJtTdwgxx2aniiGL": {
        "mint": "CCjU9Xfnyum1SJHKEd2wppgwZZucrJtTdwgxx2aniiGL",
        "name": "Boryoku Dragonz #954",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/954.png"
    },
    "FSMXnQJ1W3p5A4p3jJPJqqDWxeznQuWwSodv17kknGPb": {
        "mint": "FSMXnQJ1W3p5A4p3jJPJqqDWxeznQuWwSodv17kknGPb",
        "name": "Boryoku Dragonz #953",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/953.png"
    },
    "35wcu6s9i9zrpeLvpqKnTC4t9iPSVhfSxZocNCo8XReK": {
        "mint": "35wcu6s9i9zrpeLvpqKnTC4t9iPSVhfSxZocNCo8XReK",
        "name": "Boryoku Dragonz #952",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/952.png"
    },
    "J4XY3MuDAi8s47YkbdZ3L8pCW45G2i1epB8iYgabAWjb": {
        "mint": "J4XY3MuDAi8s47YkbdZ3L8pCW45G2i1epB8iYgabAWjb",
        "name": "Boryoku Dragonz #951",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/951.png"
    },
    "Es7ArDAdfYa5Zp7aGBQkXh7fREHiwurGW8tGuQnSbSAQ": {
        "mint": "Es7ArDAdfYa5Zp7aGBQkXh7fREHiwurGW8tGuQnSbSAQ",
        "name": "Boryoku Dragonz #949",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/949.png"
    },
    "EjsbDdTQssyx4chKv6bBMdzgNVtK2BVtFFpLKrDPUQUS": {
        "mint": "EjsbDdTQssyx4chKv6bBMdzgNVtK2BVtFFpLKrDPUQUS",
        "name": "Boryoku Dragonz #950",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/950.png"
    },
    "CV96BBrLMGGUdMFPZkf1sWjdMtjgbZG8bijXWqUbkYmH": {
        "mint": "CV96BBrLMGGUdMFPZkf1sWjdMtjgbZG8bijXWqUbkYmH",
        "name": "Boryoku Dragonz #947",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/947.png"
    },
    "9FkBoqmfXD3eb64WvnpC8PmE5cCrTY5jXEbF5oodF9og": {
        "mint": "9FkBoqmfXD3eb64WvnpC8PmE5cCrTY5jXEbF5oodF9og",
        "name": "Boryoku Dragonz #948",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/948.png"
    },
    "829KsYYYHZ9Zdu4FcVNhzgJXzRWYBvFPKuJaueRJtDKR": {
        "mint": "829KsYYYHZ9Zdu4FcVNhzgJXzRWYBvFPKuJaueRJtDKR",
        "name": "Boryoku Dragonz #946",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/946.png"
    },
    "FeNm7KWSUYxdYEr9SQs8q7zGj7dPkKTZm7WqesXed2Gc": {
        "mint": "FeNm7KWSUYxdYEr9SQs8q7zGj7dPkKTZm7WqesXed2Gc",
        "name": "Boryoku Dragonz #944",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/944.png"
    },
    "6EHLdqhs7QTrBDtapC7DuzVzyiEpbHPViHGKaiHJa7fW": {
        "mint": "6EHLdqhs7QTrBDtapC7DuzVzyiEpbHPViHGKaiHJa7fW",
        "name": "Boryoku Dragonz #939",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/939.png"
    },
    "2j4rFmV2npMARAaBvzYtAnNMyeustfzywsvdREEMwJ95": {
        "mint": "2j4rFmV2npMARAaBvzYtAnNMyeustfzywsvdREEMwJ95",
        "name": "Boryoku Dragonz #940",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/940.png"
    },
    "HCiJrt9VCp4AC1uNqmuUiJJYCsF5HjSPpQKiBroRcgpo": {
        "mint": "HCiJrt9VCp4AC1uNqmuUiJJYCsF5HjSPpQKiBroRcgpo",
        "name": "Boryoku Dragonz #943",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/943.png"
    },
    "Hknx1QnR3u11d5ZYdCjNTUZjciDRLrkkwXcgpvKt7DFR": {
        "mint": "Hknx1QnR3u11d5ZYdCjNTUZjciDRLrkkwXcgpvKt7DFR",
        "name": "Boryoku Dragonz #941",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/941.png"
    },
    "C9DxQ9RLZ92NEARUJdgjxRmYwcSB9AKzcQHPcw3BxbYz": {
        "mint": "C9DxQ9RLZ92NEARUJdgjxRmYwcSB9AKzcQHPcw3BxbYz",
        "name": "Boryoku Dragonz #945",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/945.png"
    },
    "J59YfgFRXrVta1X9UkxM2DpVrtgbKgHtNR54cKdERW7e": {
        "mint": "J59YfgFRXrVta1X9UkxM2DpVrtgbKgHtNR54cKdERW7e",
        "name": "Boryoku Dragonz #942",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/942.png"
    },
    "BteYkS3toBiARGDXFjGtS5xeQCLMSRVZA2tY9PNhfZAt": {
        "mint": "BteYkS3toBiARGDXFjGtS5xeQCLMSRVZA2tY9PNhfZAt",
        "name": "Boryoku Dragonz #938",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/938.png"
    },
    "4n9W15kc7d8Us6zWK3qBaB2b9tJTehuz5o75UzqcJ5mv": {
        "mint": "4n9W15kc7d8Us6zWK3qBaB2b9tJTehuz5o75UzqcJ5mv",
        "name": "Boryoku Dragonz #937",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/937.png"
    },
    "CVpWmf3oRW7Tsq724xdXUozxHNRWVujxpH4TPwtM871v": {
        "mint": "CVpWmf3oRW7Tsq724xdXUozxHNRWVujxpH4TPwtM871v",
        "name": "Boryoku Dragonz #936",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/936.png"
    },
    "6JkxWz4dFwK12UXdwCkGAthKPAV39cB7TjaV2aihvzry": {
        "mint": "6JkxWz4dFwK12UXdwCkGAthKPAV39cB7TjaV2aihvzry",
        "name": "Boryoku Dragonz #935",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/935.png"
    },
    "EZYhHG46Ja4RbCssisHEB4JQUNMGeoffYDCjArivxnbm": {
        "mint": "EZYhHG46Ja4RbCssisHEB4JQUNMGeoffYDCjArivxnbm",
        "name": "Boryoku Dragonz #934",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/934.png"
    },
    "ANixm3N4gpyWsfkN9FfaymtjT4VdVSkGJvo3sRKgMctd": {
        "mint": "ANixm3N4gpyWsfkN9FfaymtjT4VdVSkGJvo3sRKgMctd",
        "name": "Boryoku Dragonz #932",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/932.png"
    },
    "GkGECMAtqnDT4ZbHpMPtao3Dsp7iE23a57uWK4zyCjRm": {
        "mint": "GkGECMAtqnDT4ZbHpMPtao3Dsp7iE23a57uWK4zyCjRm",
        "name": "Boryoku Dragonz #933",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/933.png"
    },
    "FF7Ygqn1CHco5ESSHixYk7cdvkqBG6htQ2LeJMueR3Br": {
        "mint": "FF7Ygqn1CHco5ESSHixYk7cdvkqBG6htQ2LeJMueR3Br",
        "name": "Boryoku Dragonz #931",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/931.png"
    },
    "6yQn9qJ8Z9wZkbND9TzCbdrPvvg6S5CTZRwtXtQu66Xm": {
        "mint": "6yQn9qJ8Z9wZkbND9TzCbdrPvvg6S5CTZRwtXtQu66Xm",
        "name": "Boryoku Dragonz #930",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/930.png"
    },
    "6HwxBYPQyiqXGX4ZMTponxD8NdakiJGK32mx9CrWRD14": {
        "mint": "6HwxBYPQyiqXGX4ZMTponxD8NdakiJGK32mx9CrWRD14",
        "name": "Boryoku Dragonz #929",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/929.png"
    },
    "DP6N2iwRrdv2uDLHm6VoGNVMfRahjRSVoNQzrGbc5665": {
        "mint": "DP6N2iwRrdv2uDLHm6VoGNVMfRahjRSVoNQzrGbc5665",
        "name": "Boryoku Dragonz #928",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/928.png"
    },
    "3sGFTwKsvvvZzFVWs6byJPvL8FHuqreX9ASYFqAgjTTt": {
        "mint": "3sGFTwKsvvvZzFVWs6byJPvL8FHuqreX9ASYFqAgjTTt",
        "name": "Boryoku Dragonz #927",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/927.png"
    },
    "GkU1WV7qK9XyNPVciDL5UJzKbvW8XMsHWsM4nGcEBBMt": {
        "mint": "GkU1WV7qK9XyNPVciDL5UJzKbvW8XMsHWsM4nGcEBBMt",
        "name": "Boryoku Dragonz #926",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/926.png"
    },
    "FbmavUBhibTakoSbYhSCqBnTWApTnC2oZN84UJDxLTEh": {
        "mint": "FbmavUBhibTakoSbYhSCqBnTWApTnC2oZN84UJDxLTEh",
        "name": "Boryoku Dragonz #925",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/925.png"
    },
    "Et9G4ZiMvTuy5ZaC52ojqw1qZWCrKysixuTq86GfGqE5": {
        "mint": "Et9G4ZiMvTuy5ZaC52ojqw1qZWCrKysixuTq86GfGqE5",
        "name": "Boryoku Dragonz #924",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/924.png"
    },
    "8jRWtmoaSUoEbBFD898JviemoM8Mb38tPM3HRYVrf1YF": {
        "mint": "8jRWtmoaSUoEbBFD898JviemoM8Mb38tPM3HRYVrf1YF",
        "name": "Boryoku Dragonz #923",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/923.png"
    },
    "Go4vRFpaa4aJ4g3RVdurwNVLowYYj6fXEPiBW5mG7YSD": {
        "mint": "Go4vRFpaa4aJ4g3RVdurwNVLowYYj6fXEPiBW5mG7YSD",
        "name": "Boryoku Dragonz #922",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/922.png"
    },
    "3GFxV61CLQXQonpJ64PvJ23WsZL6JgLmCbBFoJiW2QUv": {
        "mint": "3GFxV61CLQXQonpJ64PvJ23WsZL6JgLmCbBFoJiW2QUv",
        "name": "Boryoku Dragonz #920",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/920.png"
    },
    "7KohH2a8TRUAosYqTxnKXXU1448mdihg53Z8xfnYkWRh": {
        "mint": "7KohH2a8TRUAosYqTxnKXXU1448mdihg53Z8xfnYkWRh",
        "name": "Boryoku Dragonz #921",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/921.png"
    },
    "9F968UwNxdaBusaFtS8DHNUTtVv8jzYsEgYZHuJKPBH9": {
        "mint": "9F968UwNxdaBusaFtS8DHNUTtVv8jzYsEgYZHuJKPBH9",
        "name": "Boryoku Dragonz #919",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/919.png"
    },
    "7BZRYb54G13Jy5C3n8UQv4gbXTAuJw51CNj376spTsdm": {
        "mint": "7BZRYb54G13Jy5C3n8UQv4gbXTAuJw51CNj376spTsdm",
        "name": "Boryoku Dragonz #918",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/918.png"
    },
    "8GSUJ9FGKZtHB6PmwvKxJst8YpZntuAzWwC3EbjrZcqt": {
        "mint": "8GSUJ9FGKZtHB6PmwvKxJst8YpZntuAzWwC3EbjrZcqt",
        "name": "Boryoku Dragonz #917",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/917.png"
    },
    "ExTy5ApU6Lwcv3evif6R71bymt1vbTgEuLNWMWi2mZ97": {
        "mint": "ExTy5ApU6Lwcv3evif6R71bymt1vbTgEuLNWMWi2mZ97",
        "name": "Boryoku Dragonz #916",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/916.png"
    },
    "ZwVzmEMy5xkouzQwtFJTBeVboqrch5EnsrNS3S9sfH3": {
        "mint": "ZwVzmEMy5xkouzQwtFJTBeVboqrch5EnsrNS3S9sfH3",
        "name": "Boryoku Dragonz #915",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/915.png"
    },
    "Eiab53PP1a26PYDZqrn8KJUgU1gJPoDekdKsDeECuSLB": {
        "mint": "Eiab53PP1a26PYDZqrn8KJUgU1gJPoDekdKsDeECuSLB",
        "name": "Boryoku Dragonz #913",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/913.png"
    },
    "Cha7sbQ6nNjiYRktgCExydWErACd4s9Uy2vjWy6dZrvc": {
        "mint": "Cha7sbQ6nNjiYRktgCExydWErACd4s9Uy2vjWy6dZrvc",
        "name": "Boryoku Dragonz #914",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/914.png"
    },
    "2xwFgUumqheFNpc3i3Fimen4bA8wfWJ4aQA6ETtsnQDb": {
        "mint": "2xwFgUumqheFNpc3i3Fimen4bA8wfWJ4aQA6ETtsnQDb",
        "name": "Boryoku Dragonz #912",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/912.png"
    },
    "HhF5gbh432XRMPoAFNxFHDsNMb8GmExmzwGEfgkGcoV": {
        "mint": "HhF5gbh432XRMPoAFNxFHDsNMb8GmExmzwGEfgkGcoV",
        "name": "Boryoku Dragonz #911",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/911.png"
    },
    "CPCkfpGB4oRiVpWbQTDNLzDk82yAk7fma5jtHSGNULx4": {
        "mint": "CPCkfpGB4oRiVpWbQTDNLzDk82yAk7fma5jtHSGNULx4",
        "name": "Boryoku Dragonz #910",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/910.png"
    },
    "79teMSqpfuU8Z179XRcTXK7q9kwhB8pNqCfJctSiXt6V": {
        "mint": "79teMSqpfuU8Z179XRcTXK7q9kwhB8pNqCfJctSiXt6V",
        "name": "Boryoku Dragonz #909",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/909.png"
    },
    "Ff2ub5Evi12gXfW7DcdaoXuH3V6txNbJcPqL8jMSfS8H": {
        "mint": "Ff2ub5Evi12gXfW7DcdaoXuH3V6txNbJcPqL8jMSfS8H",
        "name": "Boryoku Dragonz #906",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/906.png"
    },
    "6X3mwT67Qhoswmb1GsgaWWeA4v3TNbYF5dutX1nmaJy2": {
        "mint": "6X3mwT67Qhoswmb1GsgaWWeA4v3TNbYF5dutX1nmaJy2",
        "name": "Boryoku Dragonz #905",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/905.png"
    },
    "AWZSEvGFXGvmTgCdQjZrLHJMocetvomub2HDrEMoWgy4": {
        "mint": "AWZSEvGFXGvmTgCdQjZrLHJMocetvomub2HDrEMoWgy4",
        "name": "Boryoku Dragonz #907",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/907.png"
    },
    "FTwkdd35cSq4vTr2xoqKkFMoa57XHT3pDbKdbFHyUD41": {
        "mint": "FTwkdd35cSq4vTr2xoqKkFMoa57XHT3pDbKdbFHyUD41",
        "name": "Boryoku Dragonz #908",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/908.png"
    },
    "B9znAxZUA9dYnA1TpfKWRrRoTrovwu2xDFkkPw1rgc4M": {
        "mint": "B9znAxZUA9dYnA1TpfKWRrRoTrovwu2xDFkkPw1rgc4M",
        "name": "Boryoku Dragonz #895",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/895.png"
    },
    "HWmrF4Z6RdhiAkzYHJYxDEK1AGcxgxJf2zaGSLMe2vTM": {
        "mint": "HWmrF4Z6RdhiAkzYHJYxDEK1AGcxgxJf2zaGSLMe2vTM",
        "name": "Boryoku Dragonz #896",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/896.png"
    },
    "4fVWoNtZWqwbqLXLPU9PLviQPwqf75Wh6STsfA8W1zP9": {
        "mint": "4fVWoNtZWqwbqLXLPU9PLviQPwqf75Wh6STsfA8W1zP9",
        "name": "Boryoku Dragonz #898",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/898.png"
    },
    "EJ1SBGq1pywc5Freyi1C1QQpKQ1a7ifwC5vXG8JYU9m": {
        "mint": "EJ1SBGq1pywc5Freyi1C1QQpKQ1a7ifwC5vXG8JYU9m",
        "name": "Boryoku Dragonz #904",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/904.png"
    },
    "4opp2U4agji7ATMdVFehSQ8kt4XZBz6aq9dptB2CxfFj": {
        "mint": "4opp2U4agji7ATMdVFehSQ8kt4XZBz6aq9dptB2CxfFj",
        "name": "Boryoku Dragonz #899",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/899.png"
    },
    "4piRRYuEESNw9GZCxkii4HQZckwXfPaqQSF5wJ7P45cb": {
        "mint": "4piRRYuEESNw9GZCxkii4HQZckwXfPaqQSF5wJ7P45cb",
        "name": "Boryoku Dragonz #903",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/903.png"
    },
    "8NHbvpGDkjizviDwc7yC3WHeymZmpeCdC2sqL9Zzj1HQ": {
        "mint": "8NHbvpGDkjizviDwc7yC3WHeymZmpeCdC2sqL9Zzj1HQ",
        "name": "Boryoku Dragonz #897",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/897.png"
    },
    "AeNH2ENPNyjS8GbexYrw7myduKtrwJokxifiMX4rCwdc": {
        "mint": "AeNH2ENPNyjS8GbexYrw7myduKtrwJokxifiMX4rCwdc",
        "name": "Boryoku Dragonz #902",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/902.png"
    },
    "ErrRB3eCULFdxAnpzkssK2vu5hyTwcvUR9STU2CcMJXe": {
        "mint": "ErrRB3eCULFdxAnpzkssK2vu5hyTwcvUR9STU2CcMJXe",
        "name": "Boryoku Dragonz #900",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/900.png"
    },
    "39x3ynxe6ttm74j761suVHPBpqL1mrKeK9q4jjapueex": {
        "mint": "39x3ynxe6ttm74j761suVHPBpqL1mrKeK9q4jjapueex",
        "name": "Boryoku Dragonz #901",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/901.png"
    },
    "48ArviHpTL6bE49sTX3cJsZWQgg4vw8fvZg7vgvcmUx1": {
        "mint": "48ArviHpTL6bE49sTX3cJsZWQgg4vw8fvZg7vgvcmUx1",
        "name": "Boryoku Dragonz #893",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/893.png"
    },
    "H3ASfyCKABAnEEjYZjiVD1BF6bSFFAgMaxU81Us4eS7Y": {
        "mint": "H3ASfyCKABAnEEjYZjiVD1BF6bSFFAgMaxU81Us4eS7Y",
        "name": "Boryoku Dragonz #894",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/894.png"
    },
    "Ftk7554mk2cWcPx1vjRqWmGN5K498s28B9Vq9CCrPv7b": {
        "mint": "Ftk7554mk2cWcPx1vjRqWmGN5K498s28B9Vq9CCrPv7b",
        "name": "Boryoku Dragonz #891",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/891.png"
    },
    "2kD66BUXUxhBaEAGK4uJP7PvzZzCfCgMH8pFWBe2swyq": {
        "mint": "2kD66BUXUxhBaEAGK4uJP7PvzZzCfCgMH8pFWBe2swyq",
        "name": "Boryoku Dragonz #890",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/890.png"
    },
    "8ZPE1eYSAQTsvMeU8kRvkow9xMehfvZqmiLAQvbgfYUm": {
        "mint": "8ZPE1eYSAQTsvMeU8kRvkow9xMehfvZqmiLAQvbgfYUm",
        "name": "Boryoku Dragonz #892",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/892.png"
    },
    "FfFicW7z46m3Jmd4CZibe4Dtgaf9SzSSYR8MzArK9ycG": {
        "mint": "FfFicW7z46m3Jmd4CZibe4Dtgaf9SzSSYR8MzArK9ycG",
        "name": "Boryoku Dragonz #889",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/889.png"
    },
    "BWpcjbKBKePcapHACpusdtfWxAz5FvPrK6vE8M3uprBK": {
        "mint": "BWpcjbKBKePcapHACpusdtfWxAz5FvPrK6vE8M3uprBK",
        "name": "Boryoku Dragonz #887",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/887.png"
    },
    "7uto89kKNUna5RNWwTAjxuk6C36AkSMhje3Nd93FxvJ5": {
        "mint": "7uto89kKNUna5RNWwTAjxuk6C36AkSMhje3Nd93FxvJ5",
        "name": "Boryoku Dragonz #888",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/888.png"
    },
    "EyNh92wdUP3CUsTQaBaW2y9daS6SLHucfvYGxFXB7DQp": {
        "mint": "EyNh92wdUP3CUsTQaBaW2y9daS6SLHucfvYGxFXB7DQp",
        "name": "Boryoku Dragonz #886",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/886.png"
    },
    "511UfxCMNekjBqvtuABuDRokmYyBexMYpNce54cyUHat": {
        "mint": "511UfxCMNekjBqvtuABuDRokmYyBexMYpNce54cyUHat",
        "name": "Boryoku Dragonz #885",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/885.png"
    },
    "GJL8SRC3PFMB7dQ1GCqUR1GtH92q8NLkJ9yE2AMZ7YrT": {
        "mint": "GJL8SRC3PFMB7dQ1GCqUR1GtH92q8NLkJ9yE2AMZ7YrT",
        "name": "Boryoku Dragonz #883",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/883.png"
    },
    "Db4173ug93Z3jgyRgo5zJhqZrHhTviynGmQwxqSmFN5a": {
        "mint": "Db4173ug93Z3jgyRgo5zJhqZrHhTviynGmQwxqSmFN5a",
        "name": "Boryoku Dragonz #884",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/884.png"
    },
    "2tE1hU2wTt2yotCowrfCcvCCji6VdTbRjd2eGXhno5fB": {
        "mint": "2tE1hU2wTt2yotCowrfCcvCCji6VdTbRjd2eGXhno5fB",
        "name": "Boryoku Dragonz #880",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/880.png"
    },
    "cdmepYshvwiDLwmo2o36VSFUtW5bYKDGwFUnuhV514H": {
        "mint": "cdmepYshvwiDLwmo2o36VSFUtW5bYKDGwFUnuhV514H",
        "name": "Boryoku Dragonz #882",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/882.png"
    },
    "AyF36a98G9w8emzRCXH7vAHF9fgAdotn9tPAFnerBtWt": {
        "mint": "AyF36a98G9w8emzRCXH7vAHF9fgAdotn9tPAFnerBtWt",
        "name": "Boryoku Dragonz #881",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/881.png"
    },
    "8XGzc3CqgFsyMXdotTvdYzEusWUWqQspzSNCLjMHjcMv": {
        "mint": "8XGzc3CqgFsyMXdotTvdYzEusWUWqQspzSNCLjMHjcMv",
        "name": "Boryoku Dragonz #879",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/879.png"
    },
    "BGp9usKaqpTnnS7yHwUSEJkNAshS22Y6QobyqszQcFPs": {
        "mint": "BGp9usKaqpTnnS7yHwUSEJkNAshS22Y6QobyqszQcFPs",
        "name": "Boryoku Dragonz #878",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/878.png"
    },
    "8eAVETnm16XG3XkpkApgj9gxhpedXayyBkbzsp7n2ZTL": {
        "mint": "8eAVETnm16XG3XkpkApgj9gxhpedXayyBkbzsp7n2ZTL",
        "name": "Boryoku Dragonz #871",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/871.png"
    },
    "HNtbvvBELckEiHqB4tSC2sKeeA8t3qZQ6sV3AagoeLXH": {
        "mint": "HNtbvvBELckEiHqB4tSC2sKeeA8t3qZQ6sV3AagoeLXH",
        "name": "Boryoku Dragonz #875",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/875.png"
    },
    "88r1cfFRS1iC4kSGf8s6Qs9pbo2E9SNNS1Yn1WLMmXei": {
        "mint": "88r1cfFRS1iC4kSGf8s6Qs9pbo2E9SNNS1Yn1WLMmXei",
        "name": "Boryoku Dragonz #876",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/876.png"
    },
    "GyStX5ZbR7126N2bqme1ZmT5jiqAWwxrCUqQpmVskVzU": {
        "mint": "GyStX5ZbR7126N2bqme1ZmT5jiqAWwxrCUqQpmVskVzU",
        "name": "Boryoku Dragonz #873",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/873.png"
    },
    "GUip7ZRwUHrxGLcLB1T6ug6nSbqghqWD8W73W4QXx6Xc": {
        "mint": "GUip7ZRwUHrxGLcLB1T6ug6nSbqghqWD8W73W4QXx6Xc",
        "name": "Boryoku Dragonz #870",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/870.png"
    },
    "2jsR66YrkHFU585w8am1ZWvoKwpFzpBECRZBEq41eVfS": {
        "mint": "2jsR66YrkHFU585w8am1ZWvoKwpFzpBECRZBEq41eVfS",
        "name": "Boryoku Dragonz #874",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/874.png"
    },
    "Ggy2dRQtVwqZcSE2CsTXVJFjY9ngCDq7uRf9zBJcK2TS": {
        "mint": "Ggy2dRQtVwqZcSE2CsTXVJFjY9ngCDq7uRf9zBJcK2TS",
        "name": "Boryoku Dragonz #877",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/877.png"
    },
    "7e82TRrHtENzvysri6LmK687Bm3oRaJbgUB3VrpGNS3u": {
        "mint": "7e82TRrHtENzvysri6LmK687Bm3oRaJbgUB3VrpGNS3u",
        "name": "Boryoku Dragonz #872",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/872.png"
    },
    "9yZXe7JoBBzjESkimtJMWPZ7TVMxc9m7vVC78qRLgDuR": {
        "mint": "9yZXe7JoBBzjESkimtJMWPZ7TVMxc9m7vVC78qRLgDuR",
        "name": "Boryoku Dragonz #867",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/867.png"
    },
    "HXQkJJsEmaGcZUaTVSLukbP3Y8qgsEHerucwjenirXq5": {
        "mint": "HXQkJJsEmaGcZUaTVSLukbP3Y8qgsEHerucwjenirXq5",
        "name": "Boryoku Dragonz #869",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/869.png"
    },
    "3cef7QiRBME2kvH8zhGRiR6sfShnoxdrna8CHg4TcyLc": {
        "mint": "3cef7QiRBME2kvH8zhGRiR6sfShnoxdrna8CHg4TcyLc",
        "name": "Boryoku Dragonz #863",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/863.png"
    },
    "AJLW7qFC8AZqt7TFH4TLyrcuote7AuGhdrJhGdFbxR4S": {
        "mint": "AJLW7qFC8AZqt7TFH4TLyrcuote7AuGhdrJhGdFbxR4S",
        "name": "Boryoku Dragonz #868",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/868.png"
    },
    "9F7pNaswJ75J8Bhd663NS9jBVLPWKPWPCDNEhyqNQM2G": {
        "mint": "9F7pNaswJ75J8Bhd663NS9jBVLPWKPWPCDNEhyqNQM2G",
        "name": "Boryoku Dragonz #864",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/864.png"
    },
    "38axeEeD5x9De9SvP2N49mX1bEvCYMMtYpwTQZfADqwt": {
        "mint": "38axeEeD5x9De9SvP2N49mX1bEvCYMMtYpwTQZfADqwt",
        "name": "Boryoku Dragonz #865",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/865.png"
    },
    "HhgrFjsZjRbBd76rT8APMdQX3bSZLoYjbE98Qf7ViJku": {
        "mint": "HhgrFjsZjRbBd76rT8APMdQX3bSZLoYjbE98Qf7ViJku",
        "name": "Boryoku Dragonz #866",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/866.png"
    },
    "9qAa66tcezQSL9omxayYpVPdN4uoR5xx8ErnzEdZ8TLb": {
        "mint": "9qAa66tcezQSL9omxayYpVPdN4uoR5xx8ErnzEdZ8TLb",
        "name": "Boryoku Dragonz #861",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/861.png"
    },
    "251zsZcgqyTo1MGHqiCCzi8bMN7V9C9wnAn4B1go1Pd6": {
        "mint": "251zsZcgqyTo1MGHqiCCzi8bMN7V9C9wnAn4B1go1Pd6",
        "name": "Boryoku Dragonz #862",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/862.png"
    },
    "FwJoZzP95hAaEGqo1tamGd19A8Mje3jcmY81Nj7bG1WH": {
        "mint": "FwJoZzP95hAaEGqo1tamGd19A8Mje3jcmY81Nj7bG1WH",
        "name": "Boryoku Dragonz #860",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/860.png"
    },
    "BzyhHCdWsh9qyy4w218ggs9Wqj1tJwfs5RkraEXEvEDU": {
        "mint": "BzyhHCdWsh9qyy4w218ggs9Wqj1tJwfs5RkraEXEvEDU",
        "name": "Boryoku Dragonz #859",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/859.png"
    },
    "4NsUU56YWGPeY3N2ZJbiTwyy7Vi3GBABexPpxJcAZ8da": {
        "mint": "4NsUU56YWGPeY3N2ZJbiTwyy7Vi3GBABexPpxJcAZ8da",
        "name": "Boryoku Dragonz #858",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/858.png"
    },
    "95iiwgEbDFQ6gP15xD1Zj9dqdCN5xmY94znv8aRZMVeE": {
        "mint": "95iiwgEbDFQ6gP15xD1Zj9dqdCN5xmY94znv8aRZMVeE",
        "name": "Boryoku Dragonz #857",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/857.png"
    },
    "3aajFtqPDfayw8aJDrNX66ig9qTxfFWDZbTSYJHe1PLG": {
        "mint": "3aajFtqPDfayw8aJDrNX66ig9qTxfFWDZbTSYJHe1PLG",
        "name": "Boryoku Dragonz #856",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/856.png"
    },
    "TtPodqqUzKqVWJCQrw1fkTRCWr3SgDFVYV7nh6EDaX2": {
        "mint": "TtPodqqUzKqVWJCQrw1fkTRCWr3SgDFVYV7nh6EDaX2",
        "name": "Boryoku Dragonz #855",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/855.png"
    },
    "EDeJXUE1zKF14787bKC1jbezwjJBFVoEzVUNGiuBB1mc": {
        "mint": "EDeJXUE1zKF14787bKC1jbezwjJBFVoEzVUNGiuBB1mc",
        "name": "Boryoku Dragonz #853",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/853.png"
    },
    "5zpaLdH5GqeBbcTeBtHBnbScqEP33oJdtXNKrHrrowwM": {
        "mint": "5zpaLdH5GqeBbcTeBtHBnbScqEP33oJdtXNKrHrrowwM",
        "name": "Boryoku Dragonz #854",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/854.png"
    },
    "C26mrS8LAjChBfVWmjw2xVJkqtKWX5VxsBQzh1bUDJvh": {
        "mint": "C26mrS8LAjChBfVWmjw2xVJkqtKWX5VxsBQzh1bUDJvh",
        "name": "Boryoku Dragonz #852",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/852.png"
    },
    "7jnxcvhia8jR5ZTkknYCcTGRz3CHSz7B6WVvK44rZBew": {
        "mint": "7jnxcvhia8jR5ZTkknYCcTGRz3CHSz7B6WVvK44rZBew",
        "name": "Boryoku Dragonz #851",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/851.png"
    },
    "Au3Qpm2r8AVjv4aNLt9Zkng8Q1B31EdKSmKXriiFTNo3": {
        "mint": "Au3Qpm2r8AVjv4aNLt9Zkng8Q1B31EdKSmKXriiFTNo3",
        "name": "Boryoku Dragonz #850",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/850.png"
    },
    "DxU9oHtPVTZN2dJ3ggLzNe5oDLw2vWBnX6sN6RsrfQQf": {
        "mint": "DxU9oHtPVTZN2dJ3ggLzNe5oDLw2vWBnX6sN6RsrfQQf",
        "name": "Boryoku Dragonz #849",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/849.png"
    },
    "VKEChJUSH3W33Wg6CvdEzzftEyWb7p9ZqCZdiw9oYie": {
        "mint": "VKEChJUSH3W33Wg6CvdEzzftEyWb7p9ZqCZdiw9oYie",
        "name": "Boryoku Dragonz #848",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/848.png"
    },
    "HyVsnmPaz9RqBDdctom5VcZ8uwoBUYW8YMcYp9CigFgJ": {
        "mint": "HyVsnmPaz9RqBDdctom5VcZ8uwoBUYW8YMcYp9CigFgJ",
        "name": "Boryoku Dragonz #847",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/847.png"
    },
    "3bLSMD3yxt2JKymKpThqgmzVhx5Xw1wVr4fk239zPknp": {
        "mint": "3bLSMD3yxt2JKymKpThqgmzVhx5Xw1wVr4fk239zPknp",
        "name": "Boryoku Dragonz #846",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/846.png"
    },
    "GLnPRMRgVsN9YEn6ZNtG9LknY8NZAfzdtXaPybuW3taB": {
        "mint": "GLnPRMRgVsN9YEn6ZNtG9LknY8NZAfzdtXaPybuW3taB",
        "name": "Boryoku Dragonz #845",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/845.png"
    },
    "99kTwwKNesaVsup18pR24UxkqbHpjZUWouAaMtHhppQe": {
        "mint": "99kTwwKNesaVsup18pR24UxkqbHpjZUWouAaMtHhppQe",
        "name": "Boryoku Dragonz #843",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/843.png"
    },
    "6U6hECoT6eYEkEpPLT5eBJ76L7Hf1TPUQq2KcTWJAZbc": {
        "mint": "6U6hECoT6eYEkEpPLT5eBJ76L7Hf1TPUQq2KcTWJAZbc",
        "name": "Boryoku Dragonz #844",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/844.png"
    },
    "ETRuygsBzVhLppZt8tYpJdVdwommAi549yMxZ2bQyStW": {
        "mint": "ETRuygsBzVhLppZt8tYpJdVdwommAi549yMxZ2bQyStW",
        "name": "Boryoku Dragonz #842",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/842.png"
    },
    "62t2MUHXt2TTJ6VFdg5PxMT1Tv9Xy7SpwvjVwrncbdJM": {
        "mint": "62t2MUHXt2TTJ6VFdg5PxMT1Tv9Xy7SpwvjVwrncbdJM",
        "name": "Boryoku Dragonz #840",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/840.png"
    },
    "EBPZ9Ca4C4n4vLkwdzJHmHkATWbvFdns8o5dfQm4a91r": {
        "mint": "EBPZ9Ca4C4n4vLkwdzJHmHkATWbvFdns8o5dfQm4a91r",
        "name": "Boryoku Dragonz #841",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/841.png"
    },
    "Dyqas4mUj8njEjYhTLchUYEFkq8FnjM9tUCsTrtJUwJs": {
        "mint": "Dyqas4mUj8njEjYhTLchUYEFkq8FnjM9tUCsTrtJUwJs",
        "name": "Boryoku Dragonz #839",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/839.png"
    },
    "DbMJ9C9sZNPWZ4PrMjJAddG8X1ULMmLuW1uZVuB5kbng": {
        "mint": "DbMJ9C9sZNPWZ4PrMjJAddG8X1ULMmLuW1uZVuB5kbng",
        "name": "Boryoku Dragonz #838",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/838.png"
    },
    "FrTdCbixEf1UMcnHMVc69nmeiEQz1z7m3RVUsf39Mu6y": {
        "mint": "FrTdCbixEf1UMcnHMVc69nmeiEQz1z7m3RVUsf39Mu6y",
        "name": "Boryoku Dragonz #837",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/837.png"
    },
    "Fjht54yzP2G44d3e5gyYbfQwZ4JetYdZaC2c1YtH2Juy": {
        "mint": "Fjht54yzP2G44d3e5gyYbfQwZ4JetYdZaC2c1YtH2Juy",
        "name": "Boryoku Dragonz #836",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/836.png"
    },
    "AFDREynWcy3wJKfGsH1nHVJmThUpQw7AZAt6pye2g1dV": {
        "mint": "AFDREynWcy3wJKfGsH1nHVJmThUpQw7AZAt6pye2g1dV",
        "name": "Boryoku Dragonz #834",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/834.png"
    },
    "FyAvduaZjAgCiG8K3MuytFJhojy6YMYuoz68iRyF1QP": {
        "mint": "FyAvduaZjAgCiG8K3MuytFJhojy6YMYuoz68iRyF1QP",
        "name": "Boryoku Dragonz #835",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/835.png"
    },
    "FkPnE4ju4Sn1Lu7TtW2H2JTKXa33EkAPyfnQdPkaHbzk": {
        "mint": "FkPnE4ju4Sn1Lu7TtW2H2JTKXa33EkAPyfnQdPkaHbzk",
        "name": "Boryoku Dragonz #833",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/833.png"
    },
    "CB2hDJsutuNM1tNBtSH9hCB4o3msBM5PqMaR4fRnXy5V": {
        "mint": "CB2hDJsutuNM1tNBtSH9hCB4o3msBM5PqMaR4fRnXy5V",
        "name": "Boryoku Dragonz #832",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/832.png"
    },
    "C6X4NnKtX7WsaxqMkbZRLF3H1CxYYWUwwu8Pw4PjfwUc": {
        "mint": "C6X4NnKtX7WsaxqMkbZRLF3H1CxYYWUwwu8Pw4PjfwUc",
        "name": "Boryoku Dragonz #831",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/831.png"
    },
    "agRNEKcPw7kdFzPgjY2EXbsTiqd9uumFdS5zeBHnDxC": {
        "mint": "agRNEKcPw7kdFzPgjY2EXbsTiqd9uumFdS5zeBHnDxC",
        "name": "Boryoku Dragonz #828",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/828.png"
    },
    "6rCkyP6K5P6zN4bee7VnAYAqm5zixn8maneBdBjTTWZk": {
        "mint": "6rCkyP6K5P6zN4bee7VnAYAqm5zixn8maneBdBjTTWZk",
        "name": "Boryoku Dragonz #830",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/830.png"
    },
    "DSWRuoTm3RjNu3dBAe7awYyD28EWD6ZNmxu8Pzs9fJk8": {
        "mint": "DSWRuoTm3RjNu3dBAe7awYyD28EWD6ZNmxu8Pzs9fJk8",
        "name": "Boryoku Dragonz #829",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/829.png"
    },
    "DiDvKniVYaTEpdrS8ydDHEZXYiznUGkUE2o4QcPuhNfq": {
        "mint": "DiDvKniVYaTEpdrS8ydDHEZXYiznUGkUE2o4QcPuhNfq",
        "name": "Boryoku Dragonz #827",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/827.png"
    },
    "EJKqmAnUH4hMXxm5W4NzZmTveHGSsNbDzP37M2nNbzhQ": {
        "mint": "EJKqmAnUH4hMXxm5W4NzZmTveHGSsNbDzP37M2nNbzhQ",
        "name": "Boryoku Dragonz #825",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/825.png"
    },
    "HyUV7eStVVoYxbrpvwmbeBeujFm39j7i9V4ZCVdWK3of": {
        "mint": "HyUV7eStVVoYxbrpvwmbeBeujFm39j7i9V4ZCVdWK3of",
        "name": "Boryoku Dragonz #824",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/824.png"
    },
    "4vA9ypJdLYXVAZp2dQMxYaij4f5EMiP8wcbEykvnCw4k": {
        "mint": "4vA9ypJdLYXVAZp2dQMxYaij4f5EMiP8wcbEykvnCw4k",
        "name": "Boryoku Dragonz #826",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/826.png"
    },
    "C7x2aukDGwgF3F8XN1v8aD9i2P86Qu9aGfrsiQu7WCRQ": {
        "mint": "C7x2aukDGwgF3F8XN1v8aD9i2P86Qu9aGfrsiQu7WCRQ",
        "name": "Boryoku Dragonz #823",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/823.png"
    },
    "9yQh3VniUbjJ1QNCWa2x8a1ZRkdsX7FXaj5YxWeKNTLS": {
        "mint": "9yQh3VniUbjJ1QNCWa2x8a1ZRkdsX7FXaj5YxWeKNTLS",
        "name": "Boryoku Dragonz #822",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/822.png"
    },
    "3dPYy6YZE2XFsxyuMBwwHjen5cKwCtEWB5DsK9dS5c2G": {
        "mint": "3dPYy6YZE2XFsxyuMBwwHjen5cKwCtEWB5DsK9dS5c2G",
        "name": "Boryoku Dragonz #821",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/821.png"
    },
    "6YuLTnAR8K5H4Tr4j1tNq7zmB615tYuW72uvYtoFtH2H": {
        "mint": "6YuLTnAR8K5H4Tr4j1tNq7zmB615tYuW72uvYtoFtH2H",
        "name": "Boryoku Dragonz #820",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/820.png"
    },
    "9TjFyi9YGyGeavb5fQke3rpb8QnyTadRh3oPjhHPBwTF": {
        "mint": "9TjFyi9YGyGeavb5fQke3rpb8QnyTadRh3oPjhHPBwTF",
        "name": "Boryoku Dragonz #819",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/819.png"
    },
    "CdVnSWSskx1nadexDaN6R5LjFCAEX8GXMXqWNqd7o7vh": {
        "mint": "CdVnSWSskx1nadexDaN6R5LjFCAEX8GXMXqWNqd7o7vh",
        "name": "Boryoku Dragonz #817",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/817.png"
    },
    "AA1Yfzj77yKyy1HZQfc47QhnpR25NGoV3V6z7vDKR6aj": {
        "mint": "AA1Yfzj77yKyy1HZQfc47QhnpR25NGoV3V6z7vDKR6aj",
        "name": "Boryoku Dragonz #818",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/818.png"
    },
    "5Yu52T2F7rGUbeRM5SRPTzpBrDzXCXzTyxQHosCQ2FuZ": {
        "mint": "5Yu52T2F7rGUbeRM5SRPTzpBrDzXCXzTyxQHosCQ2FuZ",
        "name": "Boryoku Dragonz #816",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/816.png"
    },
    "C9xCnnf7qK1tuWFwDS1m14ACeGwWVXCBPh4TP5e1NQDY": {
        "mint": "C9xCnnf7qK1tuWFwDS1m14ACeGwWVXCBPh4TP5e1NQDY",
        "name": "Boryoku Dragonz #815",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/815.png"
    },
    "AGDCDiaggZzxZQb6TMknx5qdKq9FgxDpGMAuoM8Ddev": {
        "mint": "AGDCDiaggZzxZQb6TMknx5qdKq9FgxDpGMAuoM8Ddev",
        "name": "Boryoku Dragonz #809",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/809.png"
    },
    "BgjRw1AMTNvTZeXnM6ExPqxeKyV3rnXbhpmQSHWT6KtT": {
        "mint": "BgjRw1AMTNvTZeXnM6ExPqxeKyV3rnXbhpmQSHWT6KtT",
        "name": "Boryoku Dragonz #811",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/811.png"
    },
    "5q9pixPFCufLRwH61hcTvgJ1ESNi5uhxrXRmzBzE6asU": {
        "mint": "5q9pixPFCufLRwH61hcTvgJ1ESNi5uhxrXRmzBzE6asU",
        "name": "Boryoku Dragonz #810",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/810.png"
    },
    "6w17ucnGRctxvMN1hcgo6dJdqgUpF8LB5aSceKLSJEWV": {
        "mint": "6w17ucnGRctxvMN1hcgo6dJdqgUpF8LB5aSceKLSJEWV",
        "name": "Boryoku Dragonz #64",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/64.png"
    },
    "9rFm8jpyyGETFhmdMVSRr4fRMhLx54jihzSzSd2J5zUm": {
        "mint": "9rFm8jpyyGETFhmdMVSRr4fRMhLx54jihzSzSd2J5zUm",
        "name": "Boryoku Dragonz #814",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/814.png"
    },
    "6NziCkFuc8qT7VZUxbNjmY31Whxwa7Ldzd51JEcCVLmW": {
        "mint": "6NziCkFuc8qT7VZUxbNjmY31Whxwa7Ldzd51JEcCVLmW",
        "name": "Boryoku Dragonz #44",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/44.png"
    },
    "FK5wbpKu7bLpqhxHvCuW7xUhqFZXDeZqdHq4g3W7HcCs": {
        "mint": "FK5wbpKu7bLpqhxHvCuW7xUhqFZXDeZqdHq4g3W7HcCs",
        "name": "Boryoku Dragonz #813",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/813.png"
    },
    "CMrnvTM2Hx3Xmh5svTsNg3iArf9ypP5eYYKXuzwwkheZ": {
        "mint": "CMrnvTM2Hx3Xmh5svTsNg3iArf9ypP5eYYKXuzwwkheZ",
        "name": "Boryoku Dragonz #38",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/38.png"
    },
    "HzG1hLmjAvg3XSLNojRfi4iGuMgF5DKo199DXm89brR4": {
        "mint": "HzG1hLmjAvg3XSLNojRfi4iGuMgF5DKo199DXm89brR4",
        "name": "Boryoku Dragonz #808",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/808.png"
    },
    "FrZBVQqor6FidjbFXkVaYY1Bi7Ez4vetsVgDKnfFBRWj": {
        "mint": "FrZBVQqor6FidjbFXkVaYY1Bi7Ez4vetsVgDKnfFBRWj",
        "name": "Boryoku Dragonz #84",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/84.png"
    },
    "5EYo2DKxYkoz9YF9bviXyvTvNT4DvihKDJt3AmthxHcE": {
        "mint": "5EYo2DKxYkoz9YF9bviXyvTvNT4DvihKDJt3AmthxHcE",
        "name": "Boryoku Dragonz #812",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/812.png"
    },
    "7cqxNqghoGBf9uE5nAbNdGK5DXr8vHZP6uDbtJk2F8e7": {
        "mint": "7cqxNqghoGBf9uE5nAbNdGK5DXr8vHZP6uDbtJk2F8e7",
        "name": "Boryoku Dragonz #50",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/50.png"
    },
    "AqkJvDVqCjwF4qsBcTFFWb3xAaSHogAWeMU3Ufk5uUvG": {
        "mint": "AqkJvDVqCjwF4qsBcTFFWb3xAaSHogAWeMU3Ufk5uUvG",
        "name": "Boryoku Dragonz #806",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/806.png"
    },
    "H7y3hAvi2y2qFWQYQXrjB9HLN2HVU1iKSviGc1LczvA3": {
        "mint": "H7y3hAvi2y2qFWQYQXrjB9HLN2HVU1iKSviGc1LczvA3",
        "name": "Boryoku Dragonz #12",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/12.png"
    },
    "HWSQgFqeD738sHEArJeMb4fohaFaRJBSMdcX84dbtdVV": {
        "mint": "HWSQgFqeD738sHEArJeMb4fohaFaRJBSMdcX84dbtdVV",
        "name": "Boryoku Dragonz #805",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/805.png"
    },
    "AfD4bdbJyk3SPqrubsz7MY7C9Y9bWMuTLbjy1AiC6smp": {
        "mint": "AfD4bdbJyk3SPqrubsz7MY7C9Y9bWMuTLbjy1AiC6smp",
        "name": "Boryoku Dragonz #807",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/807.png"
    },
    "4F6WKRHS2LaSbBd4UP4A82D4ve1soHNYA9aWqoRHQhaV": {
        "mint": "4F6WKRHS2LaSbBd4UP4A82D4ve1soHNYA9aWqoRHQhaV",
        "name": "Boryoku Dragonz #66",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/66.png"
    },
    "DD36ah7aPEx9hnecUUEPDgNaAABnmhBcHWcUT6A6u41U": {
        "mint": "DD36ah7aPEx9hnecUUEPDgNaAABnmhBcHWcUT6A6u41U",
        "name": "Boryoku Dragonz #804",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/804.png"
    },
    "EnFWi4scjU9zkQuyj3QhyRJ8zGmvh3pb7xA4G1qKxDHo": {
        "mint": "EnFWi4scjU9zkQuyj3QhyRJ8zGmvh3pb7xA4G1qKxDHo",
        "name": "Boryoku Dragonz #54",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/54.png"
    },
    "D5848N2KxNQ8b35F3MU1V5SdHhVhjYeDq43Fr56eVf58": {
        "mint": "D5848N2KxNQ8b35F3MU1V5SdHhVhjYeDq43Fr56eVf58",
        "name": "Boryoku Dragonz #803",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/803.png"
    },
    "8Fmq3X8h3ybPCb3a3Q6DEyhSeJUnjPgTVDrxR3vJeCyk": {
        "mint": "8Fmq3X8h3ybPCb3a3Q6DEyhSeJUnjPgTVDrxR3vJeCyk",
        "name": "Boryoku Dragonz #57",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/57.png"
    },
    "7cebRjA6D3CMTkEEFvbvRuUYUa9jBm1dkzNsmiaeKXPU": {
        "mint": "7cebRjA6D3CMTkEEFvbvRuUYUa9jBm1dkzNsmiaeKXPU",
        "name": "Boryoku Dragonz #802",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/802.png"
    },
    "Fjv7qrUfmLzdGEfG77JGtRDY9yhZcRKK4MLAxZuMZzeB": {
        "mint": "Fjv7qrUfmLzdGEfG77JGtRDY9yhZcRKK4MLAxZuMZzeB",
        "name": "Boryoku Dragonz #36",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/36.png"
    },
    "Gp8qRh5hZ88nUghpMKjvJ586E87ep1s4pm3cgwb7uhG3": {
        "mint": "Gp8qRh5hZ88nUghpMKjvJ586E87ep1s4pm3cgwb7uhG3",
        "name": "Boryoku Dragonz #61",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/61.png"
    },
    "5m8JMp9ijmpQ6Qugs6UqCpCnqScjiaKDXaTo6XKKhTtT": {
        "mint": "5m8JMp9ijmpQ6Qugs6UqCpCnqScjiaKDXaTo6XKKhTtT",
        "name": "Boryoku Dragonz #801",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/801.png"
    },
    "BfU8ayvciMZzCpmJccqBsULvx5nnDHCmaCoLvunExv3V": {
        "mint": "BfU8ayvciMZzCpmJccqBsULvx5nnDHCmaCoLvunExv3V",
        "name": "Boryoku Dragonz #798",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/798.png"
    },
    "84cQYzfEvN4ZTeu1cMEGGJspQGwbw5fCGKToTnSVxs8k": {
        "mint": "84cQYzfEvN4ZTeu1cMEGGJspQGwbw5fCGKToTnSVxs8k",
        "name": "Boryoku Dragonz #34",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/34.png"
    },
    "9iZrdQsSVCG6sTfWiJpZw3BswoCoFNHUJzj2nmQmRqmy": {
        "mint": "9iZrdQsSVCG6sTfWiJpZw3BswoCoFNHUJzj2nmQmRqmy",
        "name": "Boryoku Dragonz #800",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/800.png"
    },
    "727VR4mWdVx8kELBgK78B5Dt8CSNEGDk4enhZwVD49CP": {
        "mint": "727VR4mWdVx8kELBgK78B5Dt8CSNEGDk4enhZwVD49CP",
        "name": "Boryoku Dragonz #42",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/42.png"
    },
    "78nsfx49Z33JB3omMQgmKmiWW3js8qSv8jKPB2VVdrKb": {
        "mint": "78nsfx49Z33JB3omMQgmKmiWW3js8qSv8jKPB2VVdrKb",
        "name": "Boryoku Dragonz #799",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/799.png"
    },
    "DbqaLvzzcbpr3aFhNuTX6gAF1VUnYsJSBKhZGM7H2zsN": {
        "mint": "DbqaLvzzcbpr3aFhNuTX6gAF1VUnYsJSBKhZGM7H2zsN",
        "name": "Boryoku Dragonz #26",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/26.png"
    },
    "EkMDtWEHqoBead6bSB3t1n4VPsMkbAxFqpRGi347RfTd": {
        "mint": "EkMDtWEHqoBead6bSB3t1n4VPsMkbAxFqpRGi347RfTd",
        "name": "Boryoku Dragonz #794",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/794.png"
    },
    "7JtUNXqkwaMFgeFLqVuuURvmZs71YAw7Q5vym5A2itps": {
        "mint": "7JtUNXqkwaMFgeFLqVuuURvmZs71YAw7Q5vym5A2itps",
        "name": "Boryoku Dragonz #43",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/43.png"
    },
    "B1eGT5SF2AHVzyihLHenrFDQcS4PksEgXGuXfAc4WzB": {
        "mint": "B1eGT5SF2AHVzyihLHenrFDQcS4PksEgXGuXfAc4WzB",
        "name": "Boryoku Dragonz #51",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/51.png"
    },
    "GKUGSsKb1vHRCXDMxEt3pwykjyizfokwU4M9CxEjbYJ2": {
        "mint": "GKUGSsKb1vHRCXDMxEt3pwykjyizfokwU4M9CxEjbYJ2",
        "name": "Boryoku Dragonz #796",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/796.png"
    },
    "4uyLnpoLGaLd5B681ZLMVauH9k28rrEv5qe8xCja65kW": {
        "mint": "4uyLnpoLGaLd5B681ZLMVauH9k28rrEv5qe8xCja65kW",
        "name": "Boryoku Dragonz #797",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/797.png"
    },
    "BfUeVBcV2yEfVMSbNiGhSjLqm1zfwo8pcdN6BezzWm6s": {
        "mint": "BfUeVBcV2yEfVMSbNiGhSjLqm1zfwo8pcdN6BezzWm6s",
        "name": "Boryoku Dragonz #28",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/28.png"
    },
    "DKDwcn6brzRJTLquGdK7gvJ4GmwQdivxnEbrybUscyQh": {
        "mint": "DKDwcn6brzRJTLquGdK7gvJ4GmwQdivxnEbrybUscyQh",
        "name": "Boryoku Dragonz #795",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/795.png"
    },
    "FJTqEG5ZTwkxYPjhWVNudo9GXZfB9Sw916b9dkEMmyBV": {
        "mint": "FJTqEG5ZTwkxYPjhWVNudo9GXZfB9Sw916b9dkEMmyBV",
        "name": "Boryoku Dragonz #19",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/19.png"
    },
    "45uMbsck4QmU8zBpHWqJLyeJBtbzswqpubYZ6VJDRZAq": {
        "mint": "45uMbsck4QmU8zBpHWqJLyeJBtbzswqpubYZ6VJDRZAq",
        "name": "Boryoku Dragonz #22",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/22.png"
    },
    "42d26bTUsHWSQ5iGkS3ApAoGfDvwrXnwGz4uxu52C5tK": {
        "mint": "42d26bTUsHWSQ5iGkS3ApAoGfDvwrXnwGz4uxu52C5tK",
        "name": "Boryoku Dragonz #793",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/793.png"
    },
    "B3jkGnqmPeCMytGcoYkZMsCgzRFj4179kwKXPJ9Q8KVt": {
        "mint": "B3jkGnqmPeCMytGcoYkZMsCgzRFj4179kwKXPJ9Q8KVt",
        "name": "Boryoku Dragonz #59",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/59.png"
    },
    "7pEdaGqxS3xYLbYF4Ci1mNSwJDkZHxTj5hDVLc15jbtT": {
        "mint": "7pEdaGqxS3xYLbYF4Ci1mNSwJDkZHxTj5hDVLc15jbtT",
        "name": "Boryoku Dragonz #791",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/791.png"
    },
    "ApEJGdMjDXEjzXasaLKXpcqSpoUeSVaTwwW3cyeXX7U6": {
        "mint": "ApEJGdMjDXEjzXasaLKXpcqSpoUeSVaTwwW3cyeXX7U6",
        "name": "Boryoku Dragonz #17",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/17.png"
    },
    "AoCq1a7H8UVrfXSbYNBpu4NK7uBrQQpy68bhiwNM4bKv": {
        "mint": "AoCq1a7H8UVrfXSbYNBpu4NK7uBrQQpy68bhiwNM4bKv",
        "name": "Boryoku Dragonz #790",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/790.png"
    },
    "2tgPTqGWSRkQZcuj8NNmaJoU121RfKtd4XjephYYcBMp": {
        "mint": "2tgPTqGWSRkQZcuj8NNmaJoU121RfKtd4XjephYYcBMp",
        "name": "Boryoku Dragonz #33",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/33.png"
    },
    "H8Vno7WegQPC9zbrKFZuECDMxvqTpRNVyn8t3fTVwk2M": {
        "mint": "H8Vno7WegQPC9zbrKFZuECDMxvqTpRNVyn8t3fTVwk2M",
        "name": "Boryoku Dragonz #792",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/792.png"
    },
    "5RhSk7QHHbn5VKHQ8nzgA7rrnM7YoFxYAsSZRmjN6gmA": {
        "mint": "5RhSk7QHHbn5VKHQ8nzgA7rrnM7YoFxYAsSZRmjN6gmA",
        "name": "Boryoku Dragonz #787",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/787.png"
    },
    "4vLhmsWCvyGjDrVKDbKtR9aBob3usT4sjHAibU9zGEbu": {
        "mint": "4vLhmsWCvyGjDrVKDbKtR9aBob3usT4sjHAibU9zGEbu",
        "name": "Boryoku Dragonz #75",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/75.png"
    },
    "F93pw63H6eTCxXp5xsGjN2iQ9d9m7t4L1scgzp2x4x31": {
        "mint": "F93pw63H6eTCxXp5xsGjN2iQ9d9m7t4L1scgzp2x4x31",
        "name": "Boryoku Dragonz #789",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/789.png"
    },
    "2EsWWLrZPTXNny1fzLAvry77GwijZe35o1cKfd2inmTg": {
        "mint": "2EsWWLrZPTXNny1fzLAvry77GwijZe35o1cKfd2inmTg",
        "name": "Boryoku Dragonz #53",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/53.png"
    },
    "DqfuQ9i2QR3eDtb4qFpBC6zsVED1d9bMoL6Caygb1hWZ": {
        "mint": "DqfuQ9i2QR3eDtb4qFpBC6zsVED1d9bMoL6Caygb1hWZ",
        "name": "Boryoku Dragonz #788",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/788.png"
    },
    "9qTA1uL8yY4Eax967N3vnmmqkh438WRo7MKyKY3ofyyt": {
        "mint": "9qTA1uL8yY4Eax967N3vnmmqkh438WRo7MKyKY3ofyyt",
        "name": "Boryoku Dragonz #80",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/80.png"
    },
    "9X1JE1i1T9E9PmVDUdCBe4o2BD1X8Kr1AbR3Fbs2kUyU": {
        "mint": "9X1JE1i1T9E9PmVDUdCBe4o2BD1X8Kr1AbR3Fbs2kUyU",
        "name": "Boryoku Dragonz #786",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/786.png"
    },
    "Brwyb9bU87YAjrun6zamdQcpQD7eJfVeGCosL6bCxnaA": {
        "mint": "Brwyb9bU87YAjrun6zamdQcpQD7eJfVeGCosL6bCxnaA",
        "name": "Boryoku Dragonz #63",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/63.png"
    },
    "B6JSWLvZtvcn74r6DWjf1kBA9K4k16jeWdYaEMd9pQfU": {
        "mint": "B6JSWLvZtvcn74r6DWjf1kBA9K4k16jeWdYaEMd9pQfU",
        "name": "Boryoku Dragonz #785",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/785.png"
    },
    "682Bx4v3r2RcGKfXJR3hARpigz4Ptfaa5V7oojR1nKmR": {
        "mint": "682Bx4v3r2RcGKfXJR3hARpigz4Ptfaa5V7oojR1nKmR",
        "name": "Boryoku Dragonz #21",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/21.png"
    },
    "2NonPHFNNaRshdRhX6wCAyPpDruYU93Nph2TCSfi8Cr6": {
        "mint": "2NonPHFNNaRshdRhX6wCAyPpDruYU93Nph2TCSfi8Cr6",
        "name": "Boryoku Dragonz #783",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/783.png"
    },
    "AVPRpsZTiU7PkU6zMcrXLFKA2Kp7ixh4D3QiG2Sk2LxL": {
        "mint": "AVPRpsZTiU7PkU6zMcrXLFKA2Kp7ixh4D3QiG2Sk2LxL",
        "name": "Boryoku Dragonz #16",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/16.png"
    },
    "GV9fUKacRRmSm1LhbXNMoBv5dx2Un8RyKEiRvmPy7gxS": {
        "mint": "GV9fUKacRRmSm1LhbXNMoBv5dx2Un8RyKEiRvmPy7gxS",
        "name": "Boryoku Dragonz #782",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/782.png"
    },
    "7dFPxpmRTuHbsesCXHeZ3mtTzbabJGUhsTatfGdJ8DNb": {
        "mint": "7dFPxpmRTuHbsesCXHeZ3mtTzbabJGUhsTatfGdJ8DNb",
        "name": "Boryoku Dragonz #30",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/30.png"
    },
    "954KNJuSE3MpJEUctsMnLU4YnDcPiF9rwZLMhPKu3Vbp": {
        "mint": "954KNJuSE3MpJEUctsMnLU4YnDcPiF9rwZLMhPKu3Vbp",
        "name": "Boryoku Dragonz #781",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/781.png"
    },
    "7aFQaRJbPkHZMPJiE2i9Sr72QtcR1dxHU7L9z6qWF9tf": {
        "mint": "7aFQaRJbPkHZMPJiE2i9Sr72QtcR1dxHU7L9z6qWF9tf",
        "name": "Boryoku Dragonz #35",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/35.png"
    },
    "CZvJiNbFkE4hKMWD4CvBrpSZU1xCGoALXd2v5gqi89pX": {
        "mint": "CZvJiNbFkE4hKMWD4CvBrpSZU1xCGoALXd2v5gqi89pX",
        "name": "Boryoku Dragonz #784",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/784.png"
    },
    "39i2hJDooKhu1vx7jpdYHo58XAwdpqyw6t3o2B3anjru": {
        "mint": "39i2hJDooKhu1vx7jpdYHo58XAwdpqyw6t3o2B3anjru",
        "name": "Boryoku Dragonz #24",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/24.png"
    },
    "CA6TfqMc1fhLKr9WCQjRRUy1TTBQtJmukAuMWYSs52ph": {
        "mint": "CA6TfqMc1fhLKr9WCQjRRUy1TTBQtJmukAuMWYSs52ph",
        "name": "Boryoku Dragonz #780",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/780.png"
    },
    "E5ZhQAEiCDnJCYHr866H8LheQ5FTYa8TkWdSZmSYRGws": {
        "mint": "E5ZhQAEiCDnJCYHr866H8LheQ5FTYa8TkWdSZmSYRGws",
        "name": "Boryoku Dragonz #83",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/83.png"
    },
    "HBjaUuC3zZAA5rLCCRa56HzXp59VJnxXs4AD6hB7frsx": {
        "mint": "HBjaUuC3zZAA5rLCCRa56HzXp59VJnxXs4AD6hB7frsx",
        "name": "Boryoku Dragonz #778",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/778.png"
    },
    "CR3hmfk9Zu6k6E7oCva48wF3XU4zt2xzr6QfmZGuXmH": {
        "mint": "CR3hmfk9Zu6k6E7oCva48wF3XU4zt2xzr6QfmZGuXmH",
        "name": "Boryoku Dragonz #18",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/18.png"
    },
    "BHVuHJeepTUnTiSzQQRsaCcBgD6Zao27kBdbXbKyF6Gt": {
        "mint": "BHVuHJeepTUnTiSzQQRsaCcBgD6Zao27kBdbXbKyF6Gt",
        "name": "Boryoku Dragonz #777",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/777.png"
    },
    "aR46iB1HZX5PgSHJ53rhqbo7uTmVfDPBQ7TtrKExQnk": {
        "mint": "aR46iB1HZX5PgSHJ53rhqbo7uTmVfDPBQ7TtrKExQnk",
        "name": "Boryoku Dragonz #11",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/11.png"
    },
    "GSLoDi1Rf3FdKBCxzbtivVoWa7T8EGSQ3kr2wYiuCfMs": {
        "mint": "GSLoDi1Rf3FdKBCxzbtivVoWa7T8EGSQ3kr2wYiuCfMs",
        "name": "Boryoku Dragonz #779",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/779.png"
    },
    "29niZ9UazQ1NGb5KT2gdhhoGwzg1kuJsqAuRAMLY39iJ": {
        "mint": "29niZ9UazQ1NGb5KT2gdhhoGwzg1kuJsqAuRAMLY39iJ",
        "name": "Boryoku Dragonz #56",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/56.png"
    },
    "Dhmp3yCQQfQJ7ybDZ3ky1Xkf9FMFqD2Z3TzpAVWkmSnb": {
        "mint": "Dhmp3yCQQfQJ7ybDZ3ky1Xkf9FMFqD2Z3TzpAVWkmSnb",
        "name": "Boryoku Dragonz #776",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/776.png"
    },
    "AUiX3tcLVRoVzjwhQKVoNn92tegVtBLSxMpmGremhR8P": {
        "mint": "AUiX3tcLVRoVzjwhQKVoNn92tegVtBLSxMpmGremhR8P",
        "name": "Boryoku Dragonz #76",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/76.png"
    },
    "7K1jL1jPhXBm8dLd98oP3hu6HXYbQWXQVtnqxjJZscDk": {
        "mint": "7K1jL1jPhXBm8dLd98oP3hu6HXYbQWXQVtnqxjJZscDk",
        "name": "Boryoku Dragonz #774",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/774.png"
    },
    "DvD247hPMZEanMGdMSaJXDHAZz3Toz2CcAnRx96hx5d6": {
        "mint": "DvD247hPMZEanMGdMSaJXDHAZz3Toz2CcAnRx96hx5d6",
        "name": "Boryoku Dragonz #29",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/29.png"
    },
    "9gEepEbqJt28dFoCtLDUiatts75y2PJCLf3teCzZUjR": {
        "mint": "9gEepEbqJt28dFoCtLDUiatts75y2PJCLf3teCzZUjR",
        "name": "Boryoku Dragonz #775",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/775.png"
    },
    "5bjPNSgWGiP2raZKMt86BR9Sbpab2trxWQHDggGaZ4Xq": {
        "mint": "5bjPNSgWGiP2raZKMt86BR9Sbpab2trxWQHDggGaZ4Xq",
        "name": "Boryoku Dragonz #13",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/13.png"
    },
    "Hzrs1CJkkhK4a5HpJu5Ji8mPcdf5BsrF5zNnx6sLENfb": {
        "mint": "Hzrs1CJkkhK4a5HpJu5Ji8mPcdf5BsrF5zNnx6sLENfb",
        "name": "Boryoku Dragonz #772",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/772.png"
    },
    "8VdTdu9uiwugYfr79vVCS3Tg3sXwPW1eN7WF9R6DqhHL": {
        "mint": "8VdTdu9uiwugYfr79vVCS3Tg3sXwPW1eN7WF9R6DqhHL",
        "name": "Boryoku Dragonz #45",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/45.png"
    },
    "7Zs62GiuxqMK71rDmtUPCD9yJpKYxJpSpFvqCsf6gmym": {
        "mint": "7Zs62GiuxqMK71rDmtUPCD9yJpKYxJpSpFvqCsf6gmym",
        "name": "Boryoku Dragonz #773",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/773.png"
    },
    "7uBqsdE36bDofP4ugqg7H4MZqnsgNLJ9YRqcrsHJiZNi": {
        "mint": "7uBqsdE36bDofP4ugqg7H4MZqnsgNLJ9YRqcrsHJiZNi",
        "name": "Boryoku Dragonz #79",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/79.png"
    },
    "97qkxeshBcwpLExT57n48dLtK9WGG4YNMCJJepYTBTMi": {
        "mint": "97qkxeshBcwpLExT57n48dLtK9WGG4YNMCJJepYTBTMi",
        "name": "Boryoku Dragonz #771",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/771.png"
    },
    "8eoSbfnvdEehjGk3RGVLxFiog32dAYk5pvc9WbGGfV8r": {
        "mint": "8eoSbfnvdEehjGk3RGVLxFiog32dAYk5pvc9WbGGfV8r",
        "name": "Boryoku Dragonz #78",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/78.png"
    },
    "D26QyBhidPc75GUUN2PpiHAnF2uJ9s8aXSuS7cFrhT6K": {
        "mint": "D26QyBhidPc75GUUN2PpiHAnF2uJ9s8aXSuS7cFrhT6K",
        "name": "Boryoku Dragonz #769",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/769.png"
    },
    "Drzc7xSe4j68HUC76CgBk5vrbyMdnzbJsMLMLU1iSAaD": {
        "mint": "Drzc7xSe4j68HUC76CgBk5vrbyMdnzbJsMLMLU1iSAaD",
        "name": "Boryoku Dragonz #770",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/770.png"
    },
    "Daq2pbAFvFevVe2zXL1iL5tkHTA7mGXhTSy895w2dgJU": {
        "mint": "Daq2pbAFvFevVe2zXL1iL5tkHTA7mGXhTSy895w2dgJU",
        "name": "Boryoku Dragonz #58",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/58.png"
    },
    "C8U9EKykqvamp27VBP8secyFMskg5Y2BkvvdDkrFr1an": {
        "mint": "C8U9EKykqvamp27VBP8secyFMskg5Y2BkvvdDkrFr1an",
        "name": "Boryoku Dragonz #73",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/73.png"
    },
    "3vPMQ2BvZ1R6zQJgqqGn8UjRq5WYekRWa6osEkKeDtyC": {
        "mint": "3vPMQ2BvZ1R6zQJgqqGn8UjRq5WYekRWa6osEkKeDtyC",
        "name": "Boryoku Dragonz #768",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/768.png"
    },
    "7dpdzaBKMUGSgtp3hhQmPScqDDAGazPmBDgYTTQ6mj1q": {
        "mint": "7dpdzaBKMUGSgtp3hhQmPScqDDAGazPmBDgYTTQ6mj1q",
        "name": "Boryoku Dragonz #15",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/15.png"
    },
    "4P2REkH8NtQVTWkRsqfAJ6UdmFAdiSaJygbT4Ytxb4bK": {
        "mint": "4P2REkH8NtQVTWkRsqfAJ6UdmFAdiSaJygbT4Ytxb4bK",
        "name": "Boryoku Dragonz #767",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/767.png"
    },
    "2cHoEJg41mufLMZf8Wk7bJUY7acZkWDhFRh5yBnFtTKT": {
        "mint": "2cHoEJg41mufLMZf8Wk7bJUY7acZkWDhFRh5yBnFtTKT",
        "name": "Boryoku Dragonz #69",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/69.png"
    },
    "9pFbnxsageLNAYuxGZnB3E11LqAPMD8kR6YouqgE6C6m": {
        "mint": "9pFbnxsageLNAYuxGZnB3E11LqAPMD8kR6YouqgE6C6m",
        "name": "Boryoku Dragonz #766",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/766.png"
    },
    "DkcNMFfZi587rLFXrbhXWYLKx3V34BLs2qJKS5Fddg7B": {
        "mint": "DkcNMFfZi587rLFXrbhXWYLKx3V34BLs2qJKS5Fddg7B",
        "name": "Boryoku Dragonz #32",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/32.png"
    },
    "EjzvWuFDKWyU2TkoPHj259svLPPQh3gbuJHR1grhMNkd": {
        "mint": "EjzvWuFDKWyU2TkoPHj259svLPPQh3gbuJHR1grhMNkd",
        "name": "Boryoku Dragonz #765",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/765.png"
    },
    "J3cngyL2dGuFJowCgBRjUxudhJ2BrVULx9JUa8k2SwG": {
        "mint": "J3cngyL2dGuFJowCgBRjUxudhJ2BrVULx9JUa8k2SwG",
        "name": "Boryoku Dragonz #20",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/20.png"
    },
    "3fNwDDmj9FcYasitFvmpQW9Ba3EmSvGLb3dxZN85tHCc": {
        "mint": "3fNwDDmj9FcYasitFvmpQW9Ba3EmSvGLb3dxZN85tHCc",
        "name": "Boryoku Dragonz #72",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/72.png"
    },
    "2DTWPeExeY9REQw8U3a3zf9HUZWosVnK9VV7GdEBWHVH": {
        "mint": "2DTWPeExeY9REQw8U3a3zf9HUZWosVnK9VV7GdEBWHVH",
        "name": "Boryoku Dragonz #764",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/764.png"
    },
    "7cHVzV9D72bKKr73okBHTQjn7JsWguHPASmKo5keUyDy": {
        "mint": "7cHVzV9D72bKKr73okBHTQjn7JsWguHPASmKo5keUyDy",
        "name": "Boryoku Dragonz #77",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/77.png"
    },
    "DiJhrE6LqAwqCVnKXZYHa64qPdQzHxPiW7admaWQkGTF": {
        "mint": "DiJhrE6LqAwqCVnKXZYHa64qPdQzHxPiW7admaWQkGTF",
        "name": "Boryoku Dragonz #760",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/760.png"
    },
    "26ZaV21kTQw6snsHN97MLbEKMnY6deFoUCrkP3k49M1P": {
        "mint": "26ZaV21kTQw6snsHN97MLbEKMnY6deFoUCrkP3k49M1P",
        "name": "Boryoku Dragonz #52",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/52.png"
    },
    "Cym3ygRwtBsBCMXhggKfey3WFiCn8e1pPYWAGB3GkcCQ": {
        "mint": "Cym3ygRwtBsBCMXhggKfey3WFiCn8e1pPYWAGB3GkcCQ",
        "name": "Boryoku Dragonz #761",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/761.png"
    },
    "2ZZaBb3X1D2GepkFRk3Dc2CEyj1FP3Z1nAbPNkSQy7b8": {
        "mint": "2ZZaBb3X1D2GepkFRk3Dc2CEyj1FP3Z1nAbPNkSQy7b8",
        "name": "Boryoku Dragonz #762",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/762.png"
    },
    "Eo4f9niAQJm7etMMcVzPvMWZxGmfZG14aoR1aLmj7im2": {
        "mint": "Eo4f9niAQJm7etMMcVzPvMWZxGmfZG14aoR1aLmj7im2",
        "name": "Boryoku Dragonz #82",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/82.png"
    },
    "HoqGSjLwJ8QRGP6cNiiPt9kMZzW6qZjndk89tkEmT1n6": {
        "mint": "HoqGSjLwJ8QRGP6cNiiPt9kMZzW6qZjndk89tkEmT1n6",
        "name": "Boryoku Dragonz #14",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/14.png"
    },
    "CwrecHYJxkhcaApYpadH982GcotZ6VrR4c44TBbZXrxQ": {
        "mint": "CwrecHYJxkhcaApYpadH982GcotZ6VrR4c44TBbZXrxQ",
        "name": "Boryoku Dragonz #763",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/763.png"
    },
    "6pSgDrvqXMR4ikFASM8ekzsvqkDPXY1StqSrpLLhw8Cy": {
        "mint": "6pSgDrvqXMR4ikFASM8ekzsvqkDPXY1StqSrpLLhw8Cy",
        "name": "Boryoku Dragonz #70",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/70.png"
    },
    "69EqF2gpENK5WGCfzzbqyQSWpv7aGAjWynqdg8bNEscb": {
        "mint": "69EqF2gpENK5WGCfzzbqyQSWpv7aGAjWynqdg8bNEscb",
        "name": "Boryoku Dragonz #758",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/758.png"
    },
    "6JGm5Uknjz6miE8DkkNoNHw5KTA6vGRMWogKkD8EKbu": {
        "mint": "6JGm5Uknjz6miE8DkkNoNHw5KTA6vGRMWogKkD8EKbu",
        "name": "Boryoku Dragonz #65",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/65.png"
    },
    "2vDVfdZyaT33mZBZ8nUiMZW5qekypyQcE23WxKwGW1HZ": {
        "mint": "2vDVfdZyaT33mZBZ8nUiMZW5qekypyQcE23WxKwGW1HZ",
        "name": "Boryoku Dragonz #759",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/759.png"
    },
    "3fztGFTKJ4zcYFZA9xL6SY7QVcNupdM7x5rGkeoxToWi": {
        "mint": "3fztGFTKJ4zcYFZA9xL6SY7QVcNupdM7x5rGkeoxToWi",
        "name": "Boryoku Dragonz #74",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/74.png"
    },
    "G13CWaVFtqGz8bRDHDuCZGSRRrPTyLHqxYzvcSgchuJp": {
        "mint": "G13CWaVFtqGz8bRDHDuCZGSRRrPTyLHqxYzvcSgchuJp",
        "name": "Boryoku Dragonz #757",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/757.png"
    },
    "4oRENUKhFGH3qh27zZTSa1YzyzUF71pMCoZfPyHzPvyM": {
        "mint": "4oRENUKhFGH3qh27zZTSa1YzyzUF71pMCoZfPyHzPvyM",
        "name": "Boryoku Dragonz #55",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/55.png"
    },
    "C3CroNEzkGSRkMST9JQdaJ4ikwaWtiMek32mstGBa86a": {
        "mint": "C3CroNEzkGSRkMST9JQdaJ4ikwaWtiMek32mstGBa86a",
        "name": "Boryoku Dragonz #756",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/756.png"
    },
    "DNG2M1nnQaZg32wCGquEXsQhsh5RggoLDqLhGox6snbk": {
        "mint": "DNG2M1nnQaZg32wCGquEXsQhsh5RggoLDqLhGox6snbk",
        "name": "Boryoku Dragonz #49",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/49.png"
    },
    "EoMVRMUFJ3VrtUFMUCHsNBNVZxWzXdPcpauHjtU2PABE": {
        "mint": "EoMVRMUFJ3VrtUFMUCHsNBNVZxWzXdPcpauHjtU2PABE",
        "name": "Boryoku Dragonz #48",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/48.png"
    },
    "5FUbpwfzh19Gdt14BLjKDTX5g2UGM714gTnoMNxxFt1P": {
        "mint": "5FUbpwfzh19Gdt14BLjKDTX5g2UGM714gTnoMNxxFt1P",
        "name": "Boryoku Dragonz #755",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/755.png"
    },
    "AEJ5nWoepNbGqyHQQYbxF3NNr8QW1FCmZ163NyoVQa6w": {
        "mint": "AEJ5nWoepNbGqyHQQYbxF3NNr8QW1FCmZ163NyoVQa6w",
        "name": "Boryoku Dragonz #41",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/41.png"
    },
    "B7aPHtxTmT2izDvtdbc9kJ6SBfNZaqWz7AdrAUvii1Nm": {
        "mint": "B7aPHtxTmT2izDvtdbc9kJ6SBfNZaqWz7AdrAUvii1Nm",
        "name": "Boryoku Dragonz #85",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/85.png"
    },
    "6YisLDcBGMbTnFmGH9t3VivybysX8KcGYFmrpfYm5VCQ": {
        "mint": "6YisLDcBGMbTnFmGH9t3VivybysX8KcGYFmrpfYm5VCQ",
        "name": "Boryoku Dragonz #753",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/753.png"
    },
    "EK6CQGora4xSdw7kZNfzhs7CyfXTJeHYbnnjr1wUAKon": {
        "mint": "EK6CQGora4xSdw7kZNfzhs7CyfXTJeHYbnnjr1wUAKon",
        "name": "Boryoku Dragonz #31",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/31.png"
    },
    "EDdyg2SzpGvzgyTkWFPdojRGikYSWbiD7wFvarfKEC2N": {
        "mint": "EDdyg2SzpGvzgyTkWFPdojRGikYSWbiD7wFvarfKEC2N",
        "name": "Boryoku Dragonz #751",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/751.png"
    },
    "9GA2KVq2idcoMFNbLr9wu6fozgfZgNr332AmGrmXNZH8": {
        "mint": "9GA2KVq2idcoMFNbLr9wu6fozgfZgNr332AmGrmXNZH8",
        "name": "Boryoku Dragonz #47",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/47.png"
    },
    "4FnApXAk5uTBjNpDpT57dBoVjE6eXbuNovPLk2ZvtsVs": {
        "mint": "4FnApXAk5uTBjNpDpT57dBoVjE6eXbuNovPLk2ZvtsVs",
        "name": "Boryoku Dragonz #67",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/67.png"
    },
    "B7bT7T8NLRV7uSQUWnJGscfk3eK7kdNnb7pqyg9HSqd5": {
        "mint": "B7bT7T8NLRV7uSQUWnJGscfk3eK7kdNnb7pqyg9HSqd5",
        "name": "Boryoku Dragonz #752",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/752.png"
    },
    "7pCciFeJJjJanjdd8j1MtG7fXVRwjPEzoNpFqu3KYQcC": {
        "mint": "7pCciFeJJjJanjdd8j1MtG7fXVRwjPEzoNpFqu3KYQcC",
        "name": "Boryoku Dragonz #46",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/46.png"
    },
    "FpzaZm1qjS7eacR7hUfEeYsLmroDQYPrd2SuCwSZbi2T": {
        "mint": "FpzaZm1qjS7eacR7hUfEeYsLmroDQYPrd2SuCwSZbi2T",
        "name": "Boryoku Dragonz #748",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/748.png"
    },
    "5tyCqb2wGeDj6RojMKxoBP4qAVshjWSzB5WE43YfHh7Y": {
        "mint": "5tyCqb2wGeDj6RojMKxoBP4qAVshjWSzB5WE43YfHh7Y",
        "name": "Boryoku Dragonz #39",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/39.png"
    },
    "BjTLtJSvoJr6eWN6yVVo1jemnAfJNE818hF5EiNMhw5r": {
        "mint": "BjTLtJSvoJr6eWN6yVVo1jemnAfJNE818hF5EiNMhw5r",
        "name": "Boryoku Dragonz #750",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/750.png"
    },
    "Dy7f7HbCip1KwZtFdGnHyh7wcM1Tng3M3UzfYpEbWrWR": {
        "mint": "Dy7f7HbCip1KwZtFdGnHyh7wcM1Tng3M3UzfYpEbWrWR",
        "name": "Boryoku Dragonz #68",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/68.png"
    },
    "ADCg93QLEpUeahDQGxjHa5CpWUW6cz7Ya61LmtKDQSXH": {
        "mint": "ADCg93QLEpUeahDQGxjHa5CpWUW6cz7Ya61LmtKDQSXH",
        "name": "Boryoku Dragonz #749",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/749.png"
    },
    "328hW7NY8duJRCLifp1YphEM4sPHfFvbpLXgApUMH3AE": {
        "mint": "328hW7NY8duJRCLifp1YphEM4sPHfFvbpLXgApUMH3AE",
        "name": "Boryoku Dragonz #25",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/25.png"
    },
    "DwVbb2LhyrZd5wKKtZsq8SKFsrDcVNnradgTDBvQtK8G": {
        "mint": "DwVbb2LhyrZd5wKKtZsq8SKFsrDcVNnradgTDBvQtK8G",
        "name": "Boryoku Dragonz #746",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/746.png"
    },
    "EaxRiHUVyoCPbj1dsMp5uKySgsy2X1YjvvRzhJyjhHi7": {
        "mint": "EaxRiHUVyoCPbj1dsMp5uKySgsy2X1YjvvRzhJyjhHi7",
        "name": "Boryoku Dragonz #40",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/40.png"
    },
    "EHmnTR7WgTaiMpxh3jJdqtFUCFUT72zuZhobcWnaHc6t": {
        "mint": "EHmnTR7WgTaiMpxh3jJdqtFUCFUT72zuZhobcWnaHc6t",
        "name": "Boryoku Dragonz #81",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/81.png"
    },
    "BmXFDywtCYaf87vgQ87dVZFJwqR7bBCnEvCWzwdpaznA": {
        "mint": "BmXFDywtCYaf87vgQ87dVZFJwqR7bBCnEvCWzwdpaznA",
        "name": "Boryoku Dragonz #745",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/745.png"
    },
    "BiaLzHjbzeiet2FBsejmxLpemHxqzqRVJuYePFxgm9PK": {
        "mint": "BiaLzHjbzeiet2FBsejmxLpemHxqzqRVJuYePFxgm9PK",
        "name": "Boryoku Dragonz #747",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/747.png"
    },
    "5kz74b7qAyMsdPrrmbTLjMLMuck73B7qViw8x4TzvhtP": {
        "mint": "5kz74b7qAyMsdPrrmbTLjMLMuck73B7qViw8x4TzvhtP",
        "name": "Boryoku Dragonz #23",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/23.png"
    },
    "APJW2m9Wbs2FHnTFBWe3iR7y2R51qeQFbQv2QJmqNV76": {
        "mint": "APJW2m9Wbs2FHnTFBWe3iR7y2R51qeQFbQv2QJmqNV76",
        "name": "Boryoku Dragonz #744",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/744.png"
    },
    "8T1PtwETBj5uhAiCh3v5Ys9vaHXfTKm5GDeDbynbJeJf": {
        "mint": "8T1PtwETBj5uhAiCh3v5Ys9vaHXfTKm5GDeDbynbJeJf",
        "name": "Boryoku Dragonz #27",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/27.png"
    },
    "FkjkBCKuKHFXPYE51AtSU6D3EwvEgrNQzJnRB6HhWP6H": {
        "mint": "FkjkBCKuKHFXPYE51AtSU6D3EwvEgrNQzJnRB6HhWP6H",
        "name": "Boryoku Dragonz #742",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/742.png"
    },
    "CwQ56LrpWtxKRVMzfcFT1tdRYzbZxTDHpAN6zWmr8fd4": {
        "mint": "CwQ56LrpWtxKRVMzfcFT1tdRYzbZxTDHpAN6zWmr8fd4",
        "name": "Boryoku Dragonz #71",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/71.png"
    },
    "HtEaio8p69qE831mSS57XwSsp9xTymKqQJDvcxrXiH1b": {
        "mint": "HtEaio8p69qE831mSS57XwSsp9xTymKqQJDvcxrXiH1b",
        "name": "Boryoku Dragonz #741",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/741.png"
    },
    "2x3d7aByt757a9ZMp5LmPXZRhMVs33b2jRcWPbJFYBb4": {
        "mint": "2x3d7aByt757a9ZMp5LmPXZRhMVs33b2jRcWPbJFYBb4",
        "name": "Boryoku Dragonz #62",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/62.png"
    },
    "AhNaYhJgjV1sEGKGSH45AstUtzWUJybP4St38EmTyKX3": {
        "mint": "AhNaYhJgjV1sEGKGSH45AstUtzWUJybP4St38EmTyKX3",
        "name": "Boryoku Dragonz #743",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/743.png"
    },
    "DrTEKqgmGtA7BykZP3AecRYH3G8QK6UtpHEwM5or7yh1": {
        "mint": "DrTEKqgmGtA7BykZP3AecRYH3G8QK6UtpHEwM5or7yh1",
        "name": "Boryoku Dragonz #37",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/37.png"
    },
    "GE76D1gHyucb8D7PGF2BfqQGzzG6zqRjFjTqb17GuRyV": {
        "mint": "GE76D1gHyucb8D7PGF2BfqQGzzG6zqRjFjTqb17GuRyV",
        "name": "Boryoku Dragonz #60",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/60.png"
    },
    "5fFDPYaG5hqMqnWfrm8xU1q7XSp2f7EkwJcbm7PCuosw": {
        "mint": "5fFDPYaG5hqMqnWfrm8xU1q7XSp2f7EkwJcbm7PCuosw",
        "name": "Boryoku Dragonz #740",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/740.png"
    },
    "DvUxxXpgDLTNRoez1YTnjEMGDcpyFZjvLfXeqpGzYZhg": {
        "mint": "DvUxxXpgDLTNRoez1YTnjEMGDcpyFZjvLfXeqpGzYZhg",
        "name": "Boryoku Dragonz #129",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/129.png"
    },
    "Hz1q8EnF3fm34KbtAYQbQhp3xcmUD8vUmDUMtbvxiJeq": {
        "mint": "Hz1q8EnF3fm34KbtAYQbQhp3xcmUD8vUmDUMtbvxiJeq",
        "name": "Boryoku Dragonz #739",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/739.png"
    },
    "A7FWEThupTjHHy7ZAXVpDBGnJtdxiksPF1qRXWSomXJC": {
        "mint": "A7FWEThupTjHHy7ZAXVpDBGnJtdxiksPF1qRXWSomXJC",
        "name": "Boryoku Dragonz #131",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/131.png"
    },
    "DtMNur57DbmisWVftKoSECewzLa8aUx1mJu7HryFwShw": {
        "mint": "DtMNur57DbmisWVftKoSECewzLa8aUx1mJu7HryFwShw",
        "name": "Boryoku Dragonz #738",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/738.png"
    },
    "7wKEFuYfUUWMAWfh6YabLi69CrgsSJXayYF3i9J7ujGF": {
        "mint": "7wKEFuYfUUWMAWfh6YabLi69CrgsSJXayYF3i9J7ujGF",
        "name": "Boryoku Dragonz #110",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/110.png"
    },
    "7Zvfb4DHS5nQxXC5dHRHmVYv3z6936ZAC2kEUjwJSr2w": {
        "mint": "7Zvfb4DHS5nQxXC5dHRHmVYv3z6936ZAC2kEUjwJSr2w",
        "name": "Boryoku Dragonz #736",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/736.png"
    },
    "DGqeuUsP1TGQMsrF67AwkkMJj2Gm4BhexrJTdzpe7eVj": {
        "mint": "DGqeuUsP1TGQMsrF67AwkkMJj2Gm4BhexrJTdzpe7eVj",
        "name": "Boryoku Dragonz #91",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/91.png"
    },
    "7Cfsko96i7LyUWknQQfry9HegDNZBFkV3RyjgcRSynVG": {
        "mint": "7Cfsko96i7LyUWknQQfry9HegDNZBFkV3RyjgcRSynVG",
        "name": "Boryoku Dragonz #120",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/120.png"
    },
    "5mvLkNZj1Vx2WZnaN6piDLw7kyfKYioXj4Z3ahEXjkQU": {
        "mint": "5mvLkNZj1Vx2WZnaN6piDLw7kyfKYioXj4Z3ahEXjkQU",
        "name": "Boryoku Dragonz #737",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/737.png"
    },
    "Eux3JgGrRVBeC58xvDU5DhkZcaMEj2rkNtqTEx8vg8ki": {
        "mint": "Eux3JgGrRVBeC58xvDU5DhkZcaMEj2rkNtqTEx8vg8ki",
        "name": "Boryoku Dragonz #106",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/106.png"
    },
    "BmQUsvKuJcLsYwwjv23Esc1hD19i62YviUcNZYP3bBfg": {
        "mint": "BmQUsvKuJcLsYwwjv23Esc1hD19i62YviUcNZYP3bBfg",
        "name": "Boryoku Dragonz #735",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/735.png"
    },
    "G8ZRiPpa9Q6uko2iU5pwx7TeYKfU8tJo2HBNzJUXWBYx": {
        "mint": "G8ZRiPpa9Q6uko2iU5pwx7TeYKfU8tJo2HBNzJUXWBYx",
        "name": "Boryoku Dragonz #96",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/96.png"
    },
    "EFXEBLFBgMzHmzfVvkSuzxgVN9TsgKmWaE2481kyViZo": {
        "mint": "EFXEBLFBgMzHmzfVvkSuzxgVN9TsgKmWaE2481kyViZo",
        "name": "Boryoku Dragonz #734",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/734.png"
    },
    "BkYBAbJFhVXUzGBxzafw7NtgBz2cPaAfzqJrTeEwhBsz": {
        "mint": "BkYBAbJFhVXUzGBxzafw7NtgBz2cPaAfzqJrTeEwhBsz",
        "name": "Boryoku Dragonz #732",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/732.png"
    },
    "8SeFZcrUhHKF9Xitk8xHWqsG776Dki8kwRm4d1NvSPpg": {
        "mint": "8SeFZcrUhHKF9Xitk8xHWqsG776Dki8kwRm4d1NvSPpg",
        "name": "Boryoku Dragonz #123",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/123.png"
    },
    "61VzM2CbGtBCzwgYHDL5zmAAi6u4nfKkadu1Gn8VCNza": {
        "mint": "61VzM2CbGtBCzwgYHDL5zmAAi6u4nfKkadu1Gn8VCNza",
        "name": "Boryoku Dragonz #88",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/88.png"
    },
    "2xrUNNR1qo4fim4McNPvn6hvYYMrzwDCBWssgBaoR8Uh": {
        "mint": "2xrUNNR1qo4fim4McNPvn6hvYYMrzwDCBWssgBaoR8Uh",
        "name": "Boryoku Dragonz #733",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/733.png"
    },
    "CL4c134yLDsp9GfebRA8945YyyJgPViEDySi9yZaWNBX": {
        "mint": "CL4c134yLDsp9GfebRA8945YyyJgPViEDySi9yZaWNBX",
        "name": "Boryoku Dragonz #731",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/731.png"
    },
    "J8QGNXo6kNChShxaGuha8Kvg57GbAFtEbMgS7geu9Lvw": {
        "mint": "J8QGNXo6kNChShxaGuha8Kvg57GbAFtEbMgS7geu9Lvw",
        "name": "Boryoku Dragonz #119",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/119.png"
    },
    "6j6m57gVqp4EbGFbzzJz9bfK82ntgygdButJU2fyzatG": {
        "mint": "6j6m57gVqp4EbGFbzzJz9bfK82ntgygdButJU2fyzatG",
        "name": "Boryoku Dragonz #730",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/730.png"
    },
    "DeVHfC57PTFSvWbLxe4YHRkZKYtjzsvtKr5spEYpaqWf": {
        "mint": "DeVHfC57PTFSvWbLxe4YHRkZKYtjzsvtKr5spEYpaqWf",
        "name": "Boryoku Dragonz #117",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/117.png"
    },
    "CNbDzv7FjCcRncQruWuryNJER1iPm43kqSoG5zh71WKb": {
        "mint": "CNbDzv7FjCcRncQruWuryNJER1iPm43kqSoG5zh71WKb",
        "name": "Boryoku Dragonz #729",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/729.png"
    },
    "5hkqUACzHMrpTmzthRHg4LcZEaG2uGpF5gzFqpC7f9ih": {
        "mint": "5hkqUACzHMrpTmzthRHg4LcZEaG2uGpF5gzFqpC7f9ih",
        "name": "Boryoku Dragonz #122",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/122.png"
    },
    "8eu8VDxJrzJvawsjv632r7oJKNYSvetbyNGMRB4ZJdHu": {
        "mint": "8eu8VDxJrzJvawsjv632r7oJKNYSvetbyNGMRB4ZJdHu",
        "name": "Boryoku Dragonz #727",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/727.png"
    },
    "FCY8jq2STzkjR7tCw4q1F7UkTx9s2rrTwKeX4L7zFWsr": {
        "mint": "FCY8jq2STzkjR7tCw4q1F7UkTx9s2rrTwKeX4L7zFWsr",
        "name": "Boryoku Dragonz #125",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/125.png"
    },
    "5iNtEkAdN2rZvTHNbBmDnTYxJ5c6nX8bgGkXTjnJqDsM": {
        "mint": "5iNtEkAdN2rZvTHNbBmDnTYxJ5c6nX8bgGkXTjnJqDsM",
        "name": "Boryoku Dragonz #728",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/728.png"
    },
    "2XBaPtqqU5ryEFLkh4xPfRhGD6riEQq6cBxB6TTATK6g": {
        "mint": "2XBaPtqqU5ryEFLkh4xPfRhGD6riEQq6cBxB6TTATK6g",
        "name": "Boryoku Dragonz #104",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/104.png"
    },
    "2LQPhg8hMdVmF8T2e5sCX9Xiivbh1Ds88C94m4b8J77q": {
        "mint": "2LQPhg8hMdVmF8T2e5sCX9Xiivbh1Ds88C94m4b8J77q",
        "name": "Boryoku Dragonz #108",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/108.png"
    },
    "EMf729L8kyVRA1GsaAdm76uQKgZkUFbhsTdbpX3fFuEW": {
        "mint": "EMf729L8kyVRA1GsaAdm76uQKgZkUFbhsTdbpX3fFuEW",
        "name": "Boryoku Dragonz #723",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/723.png"
    },
    "D9Newj82ReRStaFfC5YgXZVrNB4L9xyfoJX6BX523Rj3": {
        "mint": "D9Newj82ReRStaFfC5YgXZVrNB4L9xyfoJX6BX523Rj3",
        "name": "Boryoku Dragonz #118",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/118.png"
    },
    "4R3sVPGucM6yr1pV27iCHXYzYh1LLxSCKsQsiDhQt6wL": {
        "mint": "4R3sVPGucM6yr1pV27iCHXYzYh1LLxSCKsQsiDhQt6wL",
        "name": "Boryoku Dragonz #726",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/726.png"
    },
    "5RqVbym5yDKx4KgFqVk9inxi8PjJLQKhizekBSR8Yn5n": {
        "mint": "5RqVbym5yDKx4KgFqVk9inxi8PjJLQKhizekBSR8Yn5n",
        "name": "Boryoku Dragonz #112",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/112.png"
    },
    "3qTtepEgyQS9MNMj9ZqCwSX7q1ejkhrvqb9SvUQErreH": {
        "mint": "3qTtepEgyQS9MNMj9ZqCwSX7q1ejkhrvqb9SvUQErreH",
        "name": "Boryoku Dragonz #724",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/724.png"
    },
    "DJbjiQe6dfv4mvN92tUxi9M1JQpebAG6z6LCs9NxQv7Y": {
        "mint": "DJbjiQe6dfv4mvN92tUxi9M1JQpebAG6z6LCs9NxQv7Y",
        "name": "Boryoku Dragonz #107",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/107.png"
    },
    "HbtexjJ9t4gZUjh5rqM6kw3UtXC8PsV7FPjiifXxUc26": {
        "mint": "HbtexjJ9t4gZUjh5rqM6kw3UtXC8PsV7FPjiifXxUc26",
        "name": "Boryoku Dragonz #725",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/725.png"
    },
    "CzkGQJ4DfPJgVZb8H4E94dhGxTzNJGXswc7qXn9wnG8h": {
        "mint": "CzkGQJ4DfPJgVZb8H4E94dhGxTzNJGXswc7qXn9wnG8h",
        "name": "Boryoku Dragonz #722",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/722.png"
    },
    "2qAnx1adzgSYVvuNdGEWUpir6iJFzeKLSxnYat86FEin": {
        "mint": "2qAnx1adzgSYVvuNdGEWUpir6iJFzeKLSxnYat86FEin",
        "name": "Boryoku Dragonz #92",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/92.png"
    },
    "9DDQ6Wt6H13Mb6E3VAh5AHBk4DhWXJtxqrzC6SGHxao5": {
        "mint": "9DDQ6Wt6H13Mb6E3VAh5AHBk4DhWXJtxqrzC6SGHxao5",
        "name": "Boryoku Dragonz #720",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/720.png"
    },
    "BWrRa7nhue2GcRfjmffHUKGE7o69L1yLxzzYVK3fAoDv": {
        "mint": "BWrRa7nhue2GcRfjmffHUKGE7o69L1yLxzzYVK3fAoDv",
        "name": "Boryoku Dragonz #94",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/94.png"
    },
    "7cxjvJQFLc5SrPxtk7Ux11LMk8ouSnUNdtqDiCfvMQrA": {
        "mint": "7cxjvJQFLc5SrPxtk7Ux11LMk8ouSnUNdtqDiCfvMQrA",
        "name": "Boryoku Dragonz #719",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/719.png"
    },
    "9frVQbNtj2U6JMki3VfY1VQQdHWKyc6TFeY3nLMqbgFZ": {
        "mint": "9frVQbNtj2U6JMki3VfY1VQQdHWKyc6TFeY3nLMqbgFZ",
        "name": "Boryoku Dragonz #93",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/93.png"
    },
    "A6HCWso8mjgkbat9sVoHT49oHatDbMwcv45BfFe9oMwt": {
        "mint": "A6HCWso8mjgkbat9sVoHT49oHatDbMwcv45BfFe9oMwt",
        "name": "Boryoku Dragonz #718",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/718.png"
    },
    "uMrxjYovV2mzYSsqfKUmKXARvXcvQNjusFSkWZdXSEw": {
        "mint": "uMrxjYovV2mzYSsqfKUmKXARvXcvQNjusFSkWZdXSEw",
        "name": "Boryoku Dragonz #121",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/121.png"
    },
    "J25pQTYDerVcV1ckjjXnY31rgy4NnhduKGWT5c4R9Nrp": {
        "mint": "J25pQTYDerVcV1ckjjXnY31rgy4NnhduKGWT5c4R9Nrp",
        "name": "Boryoku Dragonz #717",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/717.png"
    },
    "4eZNn3pWvZKL2AuzDL2oNJL9WDT6uXVT5eWbEjE27muL": {
        "mint": "4eZNn3pWvZKL2AuzDL2oNJL9WDT6uXVT5eWbEjE27muL",
        "name": "Boryoku Dragonz #116",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/116.png"
    },
    "Cg3vs9YNkbZZyVrRNtJAnqUty7KDKd1TsJXhMf2bFCUi": {
        "mint": "Cg3vs9YNkbZZyVrRNtJAnqUty7KDKd1TsJXhMf2bFCUi",
        "name": "Boryoku Dragonz #721",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/721.png"
    },
    "67g7BzGcmMZ6584uHYXCa6DUZq6a2FUWb8vnJRwNqbF7": {
        "mint": "67g7BzGcmMZ6584uHYXCa6DUZq6a2FUWb8vnJRwNqbF7",
        "name": "Boryoku Dragonz #128",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/128.png"
    },
    "hizyUj7XmUG2yjMdkPP9B8j8wdXZyEL8EoJNwf8XMnr": {
        "mint": "hizyUj7XmUG2yjMdkPP9B8j8wdXZyEL8EoJNwf8XMnr",
        "name": "Boryoku Dragonz #716",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/716.png"
    },
    "AJea3Ld5nXD9aGuAXtfGkVivAcKWVvkivc4fnFzzPtCU": {
        "mint": "AJea3Ld5nXD9aGuAXtfGkVivAcKWVvkivc4fnFzzPtCU",
        "name": "Boryoku Dragonz #86",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/86.png"
    },
    "8TNDp8XvLPeHGzRSksdyNbYWbiW8gqUBojjDrPwjnpMx": {
        "mint": "8TNDp8XvLPeHGzRSksdyNbYWbiW8gqUBojjDrPwjnpMx",
        "name": "Boryoku Dragonz #715",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/715.png"
    },
    "2Zb9VxrmpCSc6gcTwFhHVWrpfoSTwY3NGmmh3T52rn4n": {
        "mint": "2Zb9VxrmpCSc6gcTwFhHVWrpfoSTwY3NGmmh3T52rn4n",
        "name": "Boryoku Dragonz #111",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/111.png"
    },
    "6pTFXizxFjmPpzB6UKPrWtC4yEAZ7b29tQf6h7tmKWxe": {
        "mint": "6pTFXizxFjmPpzB6UKPrWtC4yEAZ7b29tQf6h7tmKWxe",
        "name": "Boryoku Dragonz #713",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/713.png"
    },
    "5fnsGoWu19Btqxzw49gHVA1c9ERz8nvGEjdNJi7oQGRr": {
        "mint": "5fnsGoWu19Btqxzw49gHVA1c9ERz8nvGEjdNJi7oQGRr",
        "name": "Boryoku Dragonz #87",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/87.png"
    },
    "Drs59nZjYRNUDkAQyeQjHFdCottsnNfMKZ6quyDHBNtM": {
        "mint": "Drs59nZjYRNUDkAQyeQjHFdCottsnNfMKZ6quyDHBNtM",
        "name": "Boryoku Dragonz #712",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/712.png"
    },
    "9X6n2BsroN7ECjW4hSysYJwpKXn44zDGky2C66kkALPE": {
        "mint": "9X6n2BsroN7ECjW4hSysYJwpKXn44zDGky2C66kkALPE",
        "name": "Boryoku Dragonz #100",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/100.png"
    },
    "Hgu5WLZDBrXz4XhvrZpegarDqGkUbyipD7jrj5EyeddP": {
        "mint": "Hgu5WLZDBrXz4XhvrZpegarDqGkUbyipD7jrj5EyeddP",
        "name": "Boryoku Dragonz #714",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/714.png"
    },
    "BnSPrZGQTQWcNNcYHoBufDynap6mrwdDnzyqsCNsqnj5": {
        "mint": "BnSPrZGQTQWcNNcYHoBufDynap6mrwdDnzyqsCNsqnj5",
        "name": "Boryoku Dragonz #98",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/98.png"
    },
    "CA6FtkCrYshR8ae8CdS3GDp5QQQxzNJuUPf3zLaahyV6": {
        "mint": "CA6FtkCrYshR8ae8CdS3GDp5QQQxzNJuUPf3zLaahyV6",
        "name": "Boryoku Dragonz #101",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/101.png"
    },
    "Gidm4uZDrmjzWG78wtVBM3FQZMPRt2MY9VZanDsXcGrf": {
        "mint": "Gidm4uZDrmjzWG78wtVBM3FQZMPRt2MY9VZanDsXcGrf",
        "name": "Boryoku Dragonz #710",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/710.png"
    },
    "5qsVmtW1rgvp1diGJbvndmpGTstkmBNWFSqYsKaqWRPk": {
        "mint": "5qsVmtW1rgvp1diGJbvndmpGTstkmBNWFSqYsKaqWRPk",
        "name": "Boryoku Dragonz #126",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/126.png"
    },
    "J6sm4sT1jZ45kkd2Ze8DXA3nGG44rmSi7FSvVQtVmW6t": {
        "mint": "J6sm4sT1jZ45kkd2Ze8DXA3nGG44rmSi7FSvVQtVmW6t",
        "name": "Boryoku Dragonz #711",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/711.png"
    },
    "GmwNMfrdjhoGaBhWBJgs8QFjS2wbjznwjBKRtnT2LPyj": {
        "mint": "GmwNMfrdjhoGaBhWBJgs8QFjS2wbjznwjBKRtnT2LPyj",
        "name": "Boryoku Dragonz #114",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/114.png"
    },
    "5gXFBZ7GrYGUh4ox1eCdFpyAb148c23Z6DUKx3FS7tJm": {
        "mint": "5gXFBZ7GrYGUh4ox1eCdFpyAb148c23Z6DUKx3FS7tJm",
        "name": "Boryoku Dragonz #706",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/706.png"
    },
    "9GBzjfDkPzA2bofELUNHqwtJ2Rs5cpWJoLkD3oa6353Q": {
        "mint": "9GBzjfDkPzA2bofELUNHqwtJ2Rs5cpWJoLkD3oa6353Q",
        "name": "Boryoku Dragonz #97",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/97.png"
    },
    "4FLkoXS1XBN2nNp51MKdgnFYh3YLgTWS32RoTePxtMTQ": {
        "mint": "4FLkoXS1XBN2nNp51MKdgnFYh3YLgTWS32RoTePxtMTQ",
        "name": "Boryoku Dragonz #707",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/707.png"
    },
    "4JHrJMitBgRbNCU2ZGfSV4wZZRh8GVa6L2Zcs1khLWiR": {
        "mint": "4JHrJMitBgRbNCU2ZGfSV4wZZRh8GVa6L2Zcs1khLWiR",
        "name": "Boryoku Dragonz #89",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/89.png"
    },
    "7PbfLRTMRoYjr7jvcp3b6dUE8PssLh5J9ibTm9Cvh7nR": {
        "mint": "7PbfLRTMRoYjr7jvcp3b6dUE8PssLh5J9ibTm9Cvh7nR",
        "name": "Boryoku Dragonz #708",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/708.png"
    },
    "13gVMPYybwGVayetPttzy6U9dmbjgmdAUKzSMNWs7f7v": {
        "mint": "13gVMPYybwGVayetPttzy6U9dmbjgmdAUKzSMNWs7f7v",
        "name": "Boryoku Dragonz #115",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/115.png"
    },
    "9C4FCfpHBMB2BhKrhnGj8VpBxefc4DmFP6LW4UWMtjnL": {
        "mint": "9C4FCfpHBMB2BhKrhnGj8VpBxefc4DmFP6LW4UWMtjnL",
        "name": "Boryoku Dragonz #709",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/709.png"
    },
    "8nYQK7jDGKLgfxKqmZn4ELsPEbxmEnpC4LHK7o2p4GxM": {
        "mint": "8nYQK7jDGKLgfxKqmZn4ELsPEbxmEnpC4LHK7o2p4GxM",
        "name": "Boryoku Dragonz #95",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/95.png"
    },
    "2BPqzyP61dARZPxG14aZ2jiWaUNXdVpifGczZJJYYny3": {
        "mint": "2BPqzyP61dARZPxG14aZ2jiWaUNXdVpifGczZJJYYny3",
        "name": "Boryoku Dragonz #705",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/705.png"
    },
    "DpyQTHbmksCpKRi3uanKvrkN9wpbDrCdHnL2zA4jQrZc": {
        "mint": "DpyQTHbmksCpKRi3uanKvrkN9wpbDrCdHnL2zA4jQrZc",
        "name": "Boryoku Dragonz #702",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/702.png"
    },
    "2KYBLmQ8Wb1WEZuphga2mjGAXYZDNGGzhYtQaHqvDH9D": {
        "mint": "2KYBLmQ8Wb1WEZuphga2mjGAXYZDNGGzhYtQaHqvDH9D",
        "name": "Boryoku Dragonz #127",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/127.png"
    },
    "3pLxZ6EFGffj9P5KXuBpcbmLPT8bMCe6C2BD316nU3kh": {
        "mint": "3pLxZ6EFGffj9P5KXuBpcbmLPT8bMCe6C2BD316nU3kh",
        "name": "Boryoku Dragonz #704",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/704.png"
    },
    "Hb5djWdhtuSKo6yykzfLNMNTKVDe7nKzgVS1vUrrhq59": {
        "mint": "Hb5djWdhtuSKo6yykzfLNMNTKVDe7nKzgVS1vUrrhq59",
        "name": "Boryoku Dragonz #103",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/103.png"
    },
    "HL3NCeaqtFvYQ1boVhiQxt2S9p5eyJ1edkB6FHXe1Az1": {
        "mint": "HL3NCeaqtFvYQ1boVhiQxt2S9p5eyJ1edkB6FHXe1Az1",
        "name": "Boryoku Dragonz #703",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/703.png"
    },
    "95xXSwhBRNggG9nkD391JWUeuRCmYwHrRzPExXfYivMr": {
        "mint": "95xXSwhBRNggG9nkD391JWUeuRCmYwHrRzPExXfYivMr",
        "name": "Boryoku Dragonz #130",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/130.png"
    },
    "G6YjqKPzHnrB9aSNBY7fQj4zbRsrbFucZP19JcTA4d9e": {
        "mint": "G6YjqKPzHnrB9aSNBY7fQj4zbRsrbFucZP19JcTA4d9e",
        "name": "Boryoku Dragonz #701",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/701.png"
    },
    "HLpr59T1ixK4DAn7bJLwqsD3WF6LBVZC91TfT8idUQQ7": {
        "mint": "HLpr59T1ixK4DAn7bJLwqsD3WF6LBVZC91TfT8idUQQ7",
        "name": "Boryoku Dragonz #124",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/124.png"
    },
    "B7GAAUppiY76H2qpaa1PJyzsKdYA8Cg1yvC2EVKiV7kN": {
        "mint": "B7GAAUppiY76H2qpaa1PJyzsKdYA8Cg1yvC2EVKiV7kN",
        "name": "Boryoku Dragonz #700",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/700.png"
    },
    "GiZmDxwiFhp1JkvQSnM6an4Q4VyMiMsC8cf9eyFvnhs": {
        "mint": "GiZmDxwiFhp1JkvQSnM6an4Q4VyMiMsC8cf9eyFvnhs",
        "name": "Boryoku Dragonz #698",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/698.png"
    },
    "5JjNWPq2iz3E6QHfeasLFAa7tNud8sPvWZFq89Gkjqwb": {
        "mint": "5JjNWPq2iz3E6QHfeasLFAa7tNud8sPvWZFq89Gkjqwb",
        "name": "Boryoku Dragonz #99",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/99.png"
    },
    "8D3Vx4daNHAD9AzB62vSDQsffoh6S1cxZHrtMY8shKyY": {
        "mint": "8D3Vx4daNHAD9AzB62vSDQsffoh6S1cxZHrtMY8shKyY",
        "name": "Boryoku Dragonz #699",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/699.png"
    },
    "4gHvVtJ12sUwFGC7Qpjzo6Say7NTNYPMxYL5yVNu2zX7": {
        "mint": "4gHvVtJ12sUwFGC7Qpjzo6Say7NTNYPMxYL5yVNu2zX7",
        "name": "Boryoku Dragonz #132",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/132.png"
    },
    "ADtcWcuQtQ2cf8FttTAGEveBkdwHb84convf5LyGRyCa": {
        "mint": "ADtcWcuQtQ2cf8FttTAGEveBkdwHb84convf5LyGRyCa",
        "name": "Boryoku Dragonz #696",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/696.png"
    },
    "4im7hoFBvsNVtTywpNAyC7h2Z4SyxcUXwY8frqBdNvTU": {
        "mint": "4im7hoFBvsNVtTywpNAyC7h2Z4SyxcUXwY8frqBdNvTU",
        "name": "Boryoku Dragonz #105",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/105.png"
    },
    "BKFNGdHEytLcjeXhQm8mcXB2wCyUnyipkXDz73hdWMpN": {
        "mint": "BKFNGdHEytLcjeXhQm8mcXB2wCyUnyipkXDz73hdWMpN",
        "name": "Boryoku Dragonz #697",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/697.png"
    },
    "FThbQzBGRbVGmF7rQKtkPcwyFgQJVSoc3xnNfdzWHufp": {
        "mint": "FThbQzBGRbVGmF7rQKtkPcwyFgQJVSoc3xnNfdzWHufp",
        "name": "Boryoku Dragonz #90",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/90.png"
    },
    "9WtMfiAs45pWQUizQkvoFZiF3mWRmpDdWXQ2MrB4Qv2M": {
        "mint": "9WtMfiAs45pWQUizQkvoFZiF3mWRmpDdWXQ2MrB4Qv2M",
        "name": "Boryoku Dragonz #695",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/695.png"
    },
    "8gxceYKcH2JQabQjQcYZoTTRoKQhdtD8XyJsW1t3Czyj": {
        "mint": "8gxceYKcH2JQabQjQcYZoTTRoKQhdtD8XyJsW1t3Czyj",
        "name": "Boryoku Dragonz #133",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/133.png"
    },
    "5KGU5zwwSeXLRQjENAk5wYAkYUsrTinxBw67pkh6sipW": {
        "mint": "5KGU5zwwSeXLRQjENAk5wYAkYUsrTinxBw67pkh6sipW",
        "name": "Boryoku Dragonz #693",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/693.png"
    },
    "4ZBgnTFvvZRUXFhXBdKLFUQDbZhbJ9g3yRpPuQv27sWF": {
        "mint": "4ZBgnTFvvZRUXFhXBdKLFUQDbZhbJ9g3yRpPuQv27sWF",
        "name": "Boryoku Dragonz #102",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/102.png"
    },
    "AJUm6vWs7iXvZGpkgZppj6K1t8BvNX7g7ncURsA5FP9o": {
        "mint": "AJUm6vWs7iXvZGpkgZppj6K1t8BvNX7g7ncURsA5FP9o",
        "name": "Boryoku Dragonz #694",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/694.png"
    },
    "GG83sTPhCWtEi6M6QzZYgLGmpcPYKxRvLhoWdh7THQje": {
        "mint": "GG83sTPhCWtEi6M6QzZYgLGmpcPYKxRvLhoWdh7THQje",
        "name": "Boryoku Dragonz #134",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/134.png"
    },
    "Rh7k97sMbDiXQ4t71VtidXj1gysbeggykXgTvQEW5Bk": {
        "mint": "Rh7k97sMbDiXQ4t71VtidXj1gysbeggykXgTvQEW5Bk",
        "name": "Boryoku Dragonz #109",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/109.png"
    },
    "vP6XAFJavqMmgyo7R2TfQzuHBFmGmq9Xn4LeX9Xfk6q": {
        "mint": "vP6XAFJavqMmgyo7R2TfQzuHBFmGmq9Xn4LeX9Xfk6q",
        "name": "Boryoku Dragonz #692",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/692.png"
    },
    "2opH4ATBoypfKwGbj2JYGzHCzE77odDMSLhrTtoWo3jP": {
        "mint": "2opH4ATBoypfKwGbj2JYGzHCzE77odDMSLhrTtoWo3jP",
        "name": "Boryoku Dragonz #113",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/113.png"
    },
    "8JnBUN9b9hf1hVnpM9qLQMva2cM21N4cXdpfiKhvCWSQ": {
        "mint": "8JnBUN9b9hf1hVnpM9qLQMva2cM21N4cXdpfiKhvCWSQ",
        "name": "Boryoku Dragonz #689",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/689.png"
    },
    "FU5e61qgQCh7L689V7kpvDemSkHuHa2U4Q4z1AVsoDbM": {
        "mint": "FU5e61qgQCh7L689V7kpvDemSkHuHa2U4Q4z1AVsoDbM",
        "name": "Boryoku Dragonz #147",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/147.png"
    },
    "4C2rUxZs2gbC1gEkQapabaRMeCx8hAt4x9FkemRW7DxT": {
        "mint": "4C2rUxZs2gbC1gEkQapabaRMeCx8hAt4x9FkemRW7DxT",
        "name": "Boryoku Dragonz #690",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/690.png"
    },
    "ELTjgyyzGjL6pqfaBqPGQNKxNsdqvmNtuduk3Pz8qsfy": {
        "mint": "ELTjgyyzGjL6pqfaBqPGQNKxNsdqvmNtuduk3Pz8qsfy",
        "name": "Boryoku Dragonz #142",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/142.png"
    },
    "9n5jdbnvdfCBkdbjzv56WDfewuUSbmSXnMcUULtk4jm8": {
        "mint": "9n5jdbnvdfCBkdbjzv56WDfewuUSbmSXnMcUULtk4jm8",
        "name": "Boryoku Dragonz #687",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/687.png"
    },
    "FH9nijTsf1K6rRh1E8ocyi6GKkKeusFkqcptibNwA8h3": {
        "mint": "FH9nijTsf1K6rRh1E8ocyi6GKkKeusFkqcptibNwA8h3",
        "name": "Boryoku Dragonz #153",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/153.png"
    },
    "FJfUEYbfqpqTepgr3jx3fr29AKMSmh2VbwxsQN5qTuSX": {
        "mint": "FJfUEYbfqpqTepgr3jx3fr29AKMSmh2VbwxsQN5qTuSX",
        "name": "Boryoku Dragonz #144",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/144.png"
    },
    "8HMLDE6p2pmQQcCG7TbXihAisNxgrBJ5UTwR79PMUCnX": {
        "mint": "8HMLDE6p2pmQQcCG7TbXihAisNxgrBJ5UTwR79PMUCnX",
        "name": "Boryoku Dragonz #688",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/688.png"
    },
    "DsDBmTJJ25ABfwet8L4FLdGiYbnqEobD2vuVi1PY65Ra": {
        "mint": "DsDBmTJJ25ABfwet8L4FLdGiYbnqEobD2vuVi1PY65Ra",
        "name": "Boryoku Dragonz #164",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/164.png"
    },
    "GAVNXa1xcHkqYTQi1vrcjhy5UccCT4xLsztPZZZvKiMr": {
        "mint": "GAVNXa1xcHkqYTQi1vrcjhy5UccCT4xLsztPZZZvKiMr",
        "name": "Boryoku Dragonz #686",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/686.png"
    },
    "28akyGUiG1LtcsR9h3e8gTB8Jt66uPQXvxVSuJjkkbsD": {
        "mint": "28akyGUiG1LtcsR9h3e8gTB8Jt66uPQXvxVSuJjkkbsD",
        "name": "Boryoku Dragonz #143",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/143.png"
    },
    "DhodoW4GnVNBQphEwpV18rwxLrRKcn9qNf2JEqmXMz1Q": {
        "mint": "DhodoW4GnVNBQphEwpV18rwxLrRKcn9qNf2JEqmXMz1Q",
        "name": "Boryoku Dragonz #691",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/691.png"
    },
    "DJLsbiy8GSYhhqcKgHqKK2dwHL9uanUPFnHKKgrUxHsc": {
        "mint": "DJLsbiy8GSYhhqcKgHqKK2dwHL9uanUPFnHKKgrUxHsc",
        "name": "Boryoku Dragonz #136",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/136.png"
    },
    "762yjVuqr7DrqViKXqHkqqHGZiu6TAgrj4onmffPKTJ7": {
        "mint": "762yjVuqr7DrqViKXqHkqqHGZiu6TAgrj4onmffPKTJ7",
        "name": "Boryoku Dragonz #684",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/684.png"
    },
    "21WCqWJaUF94hXh9h22CLySJnYTpZm4kbZKCJe5yCitE": {
        "mint": "21WCqWJaUF94hXh9h22CLySJnYTpZm4kbZKCJe5yCitE",
        "name": "Boryoku Dragonz #685",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/685.png"
    },
    "3hnf6nCsC9MJA2LoPFWzAGs6rNFFzAqW3HJmFFNdwx9z": {
        "mint": "3hnf6nCsC9MJA2LoPFWzAGs6rNFFzAqW3HJmFFNdwx9z",
        "name": "Boryoku Dragonz #138",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/138.png"
    },
    "GaGrfKTNquUFmWbyAZQpvuRG4VVKLGeDb1KKdGMaW4Bp": {
        "mint": "GaGrfKTNquUFmWbyAZQpvuRG4VVKLGeDb1KKdGMaW4Bp",
        "name": "Boryoku Dragonz #683",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/683.png"
    },
    "HYSRydoaxPrKNZqnHEsf8P25VnLnU4dpacCmdTxSNHLA": {
        "mint": "HYSRydoaxPrKNZqnHEsf8P25VnLnU4dpacCmdTxSNHLA",
        "name": "Boryoku Dragonz #169",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/169.png"
    },
    "KNPwcB749xisWoobRkzdpbN1zZoZuvSgr2TUDscxExT": {
        "mint": "KNPwcB749xisWoobRkzdpbN1zZoZuvSgr2TUDscxExT",
        "name": "Boryoku Dragonz #681",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/681.png"
    },
    "9qJGcyzgFahtBNojpnZagnhbSMQkQ6bF5643HHnEePZd": {
        "mint": "9qJGcyzgFahtBNojpnZagnhbSMQkQ6bF5643HHnEePZd",
        "name": "Boryoku Dragonz #146",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/146.png"
    },
    "7MNCdbSSG9vaLoDRvv2XKahvuXHHLJ67o1QBHHUtCKjQ": {
        "mint": "7MNCdbSSG9vaLoDRvv2XKahvuXHHLJ67o1QBHHUtCKjQ",
        "name": "Boryoku Dragonz #167",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/167.png"
    },
    "6DTHtuFpcN8w1zHL75mC4Sm1k2cBReQPEyW2EBzwNUJt": {
        "mint": "6DTHtuFpcN8w1zHL75mC4Sm1k2cBReQPEyW2EBzwNUJt",
        "name": "Boryoku Dragonz #682",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/682.png"
    },
    "Euuo9VGqaZKxsTaxRupzum9BK4LbCyxrmv1QN1Tcydgr": {
        "mint": "Euuo9VGqaZKxsTaxRupzum9BK4LbCyxrmv1QN1Tcydgr",
        "name": "Boryoku Dragonz #163",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/163.png"
    },
    "382AmXfzaa5zzPS7LyBeVQUhSo1vF8i9Yx48CeQdAd51": {
        "mint": "382AmXfzaa5zzPS7LyBeVQUhSo1vF8i9Yx48CeQdAd51",
        "name": "Boryoku Dragonz #680",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/680.png"
    },
    "HEkRDHadFdzLomcu7Hi6heca1WJ5xvaVBszZjt895XUV": {
        "mint": "HEkRDHadFdzLomcu7Hi6heca1WJ5xvaVBszZjt895XUV",
        "name": "Boryoku Dragonz #175",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/175.png"
    },
    "9SwcsWcaeQ1rGGXZmGpxeNbmHrMSi6S3fHvxEMTcCtZ5": {
        "mint": "9SwcsWcaeQ1rGGXZmGpxeNbmHrMSi6S3fHvxEMTcCtZ5",
        "name": "Boryoku Dragonz #677",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/677.png"
    },
    "7X22mcqJtYjGgpsoNJffvBkwKzjxrrbjY5Sk1dSNxxEc": {
        "mint": "7X22mcqJtYjGgpsoNJffvBkwKzjxrrbjY5Sk1dSNxxEc",
        "name": "Boryoku Dragonz #176",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/176.png"
    },
    "7psKqBL1obDt9bkorKVzrBwFFP373uEmnSU5sEhPjgGx": {
        "mint": "7psKqBL1obDt9bkorKVzrBwFFP373uEmnSU5sEhPjgGx",
        "name": "Boryoku Dragonz #679",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/679.png"
    },
    "7sm8sMQQcH95knKPhGXtWjjFKk2xX8L4D4bQPkFgC15B": {
        "mint": "7sm8sMQQcH95knKPhGXtWjjFKk2xX8L4D4bQPkFgC15B",
        "name": "Boryoku Dragonz #150",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/150.png"
    },
    "3LpkQaiP88xNpvyrH3BrWuNDfuedHUHD4cuNB2D1zWci": {
        "mint": "3LpkQaiP88xNpvyrH3BrWuNDfuedHUHD4cuNB2D1zWci",
        "name": "Boryoku Dragonz #678",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/678.png"
    },
    "GpxiQznnCKzKg4aKYEFchWFNGg3zRMjzriDNbnhE8yoM": {
        "mint": "GpxiQznnCKzKg4aKYEFchWFNGg3zRMjzriDNbnhE8yoM",
        "name": "Boryoku Dragonz #139",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/139.png"
    },
    "3LpKRUKAakoRbkeW79PygTbcx5KPjchAXbpQm4QE5Xfv": {
        "mint": "3LpKRUKAakoRbkeW79PygTbcx5KPjchAXbpQm4QE5Xfv",
        "name": "Boryoku Dragonz #676",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/676.png"
    },
    "H31fRxxSUKt4WiBBTLTBuoBhUcWkFmTKCY9xBbCcEUiH": {
        "mint": "H31fRxxSUKt4WiBBTLTBuoBhUcWkFmTKCY9xBbCcEUiH",
        "name": "Boryoku Dragonz #140",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/140.png"
    },
    "Ak79ChFPvbyMimwnLWQ9utUxLrqTtPmMTUhFdMUpfCTT": {
        "mint": "Ak79ChFPvbyMimwnLWQ9utUxLrqTtPmMTUhFdMUpfCTT",
        "name": "Boryoku Dragonz #674",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/674.png"
    },
    "C7o9pADX1FUXRrgLahNx1B2XqSndLL63L2xjiYDVRBTW": {
        "mint": "C7o9pADX1FUXRrgLahNx1B2XqSndLL63L2xjiYDVRBTW",
        "name": "Boryoku Dragonz #675",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/675.png"
    },
    "AUFcgkrT8EeSB7EER5VhnDkVCWg6jtSHh4mvVxEZcLgS": {
        "mint": "AUFcgkrT8EeSB7EER5VhnDkVCWg6jtSHh4mvVxEZcLgS",
        "name": "Boryoku Dragonz #174",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/174.png"
    },
    "zKZug8FuzGg4W4t8armASzt3BCXQqSa69Geaenvwhxm": {
        "mint": "zKZug8FuzGg4W4t8armASzt3BCXQqSa69Geaenvwhxm",
        "name": "Boryoku Dragonz #166",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/166.png"
    },
    "HT7azpfqUeyBbu2pFR6f2w1jjYbRuacNQksCGyoqcSXk": {
        "mint": "HT7azpfqUeyBbu2pFR6f2w1jjYbRuacNQksCGyoqcSXk",
        "name": "Boryoku Dragonz #672",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/672.png"
    },
    "9LeHbyYJCoSMvcV85Btf5zL638Qe2DeGXn9gDypvBnpQ": {
        "mint": "9LeHbyYJCoSMvcV85Btf5zL638Qe2DeGXn9gDypvBnpQ",
        "name": "Boryoku Dragonz #160",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/160.png"
    },
    "FcxR4RSixKoi2s82jHU6avtKWZHCq879EVkqRAu8J854": {
        "mint": "FcxR4RSixKoi2s82jHU6avtKWZHCq879EVkqRAu8J854",
        "name": "Boryoku Dragonz #673",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/673.png"
    },
    "HmFB8ubz4b31dytXKdDDa9d5myPh4UuwCsvsbKCpEp9W": {
        "mint": "HmFB8ubz4b31dytXKdDDa9d5myPh4UuwCsvsbKCpEp9W",
        "name": "Boryoku Dragonz #135",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/135.png"
    },
    "3zqS3yR3q8B8MhZQ4deZrWBSZcmsDYFehbkWCC2Swjnc": {
        "mint": "3zqS3yR3q8B8MhZQ4deZrWBSZcmsDYFehbkWCC2Swjnc",
        "name": "Boryoku Dragonz #668",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/668.png"
    },
    "6vx5NwQzKDeXTgr61D4b5oSHL9yLvF9eCAH6mw6JPXEj": {
        "mint": "6vx5NwQzKDeXTgr61D4b5oSHL9yLvF9eCAH6mw6JPXEj",
        "name": "Boryoku Dragonz #671",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/671.png"
    },
    "FNaCduYfyo4BMYCBmY22Tz2Tnvmdraux2tnYAthKX58": {
        "mint": "FNaCduYfyo4BMYCBmY22Tz2Tnvmdraux2tnYAthKX58",
        "name": "Boryoku Dragonz #157",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/157.png"
    },
    "6VMZEWeCt8LXoRBwPN1YmRewo3rWyGAYx64At92qW4ge": {
        "mint": "6VMZEWeCt8LXoRBwPN1YmRewo3rWyGAYx64At92qW4ge",
        "name": "Boryoku Dragonz #670",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/670.png"
    },
    "7YnL26xuxkTk1e4hMVSKr1FnAUnnGyDQdWmrhrkY3FDZ": {
        "mint": "7YnL26xuxkTk1e4hMVSKr1FnAUnnGyDQdWmrhrkY3FDZ",
        "name": "Boryoku Dragonz #151",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/151.png"
    },
    "Gfqk2Synom6oV2fZCT3wFaRK9KRS7TJTpmcKXF3F9sQ3": {
        "mint": "Gfqk2Synom6oV2fZCT3wFaRK9KRS7TJTpmcKXF3F9sQ3",
        "name": "Boryoku Dragonz #669",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/669.png"
    },
    "AsCZk2dvoHWngYAfT2Ksg3G9YNy48LQiuVci1CUQR9a9": {
        "mint": "AsCZk2dvoHWngYAfT2Ksg3G9YNy48LQiuVci1CUQR9a9",
        "name": "Boryoku Dragonz #171",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/171.png"
    },
    "HGBykPp2f27UhL9Bqm6S8e4rWwfXRenJ7p697fMYgtsH": {
        "mint": "HGBykPp2f27UhL9Bqm6S8e4rWwfXRenJ7p697fMYgtsH",
        "name": "Boryoku Dragonz #161",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/161.png"
    },
    "5q8bTbvBYVgA4Lmp1Z86mAA1urg1bmBL66PTgk8j41tN": {
        "mint": "5q8bTbvBYVgA4Lmp1Z86mAA1urg1bmBL66PTgk8j41tN",
        "name": "Boryoku Dragonz #667",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/667.png"
    },
    "GU8MwRPPtuS6askzXcCgDvWyVwXMYSTSoXqi1CJjDFHi": {
        "mint": "GU8MwRPPtuS6askzXcCgDvWyVwXMYSTSoXqi1CJjDFHi",
        "name": "Boryoku Dragonz #665",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/665.png"
    },
    "4MT67NP8H4fJKkvPrXcQLsYEAxPxahASjXykNxFej4GG": {
        "mint": "4MT67NP8H4fJKkvPrXcQLsYEAxPxahASjXykNxFej4GG",
        "name": "Boryoku Dragonz #168",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/168.png"
    },
    "DrWzhS5Z12ZCHJA4EBNebYSLib8NCv43bM2qrs3Txwyg": {
        "mint": "DrWzhS5Z12ZCHJA4EBNebYSLib8NCv43bM2qrs3Txwyg",
        "name": "Boryoku Dragonz #666",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/666.png"
    },
    "8dNbdJV1hqwA1r4uffoxBKzmFJ7Qua76sxVofSr1iB9p": {
        "mint": "8dNbdJV1hqwA1r4uffoxBKzmFJ7Qua76sxVofSr1iB9p",
        "name": "Boryoku Dragonz #170",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/170.png"
    },
    "Exb87KDZfz7zPm6Y35mBbAozGra8uyWfVepMWRLcG1NN": {
        "mint": "Exb87KDZfz7zPm6Y35mBbAozGra8uyWfVepMWRLcG1NN",
        "name": "Boryoku Dragonz #664",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/664.png"
    },
    "3ryin9KHEtcXLAYPrhoXqgKNevwmz7NzNTHTuFMmWBFe": {
        "mint": "3ryin9KHEtcXLAYPrhoXqgKNevwmz7NzNTHTuFMmWBFe",
        "name": "Boryoku Dragonz #165",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/165.png"
    },
    "C8AeQ2QvtKN3nH7BZMmWYUz2nYb88DzEi1qrq4Qo7aZX": {
        "mint": "C8AeQ2QvtKN3nH7BZMmWYUz2nYb88DzEi1qrq4Qo7aZX",
        "name": "Boryoku Dragonz #663",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/663.png"
    },
    "GAkfmSQKLR1WvngzD7aQRWkrZ5o79ennDdpBLjHJujr8": {
        "mint": "GAkfmSQKLR1WvngzD7aQRWkrZ5o79ennDdpBLjHJujr8",
        "name": "Boryoku Dragonz #662",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/662.png"
    },
    "4B5MXK2Uirr26To6Ha8VKpu7t1iuiLWvSJ4UKxn7jFV6": {
        "mint": "4B5MXK2Uirr26To6Ha8VKpu7t1iuiLWvSJ4UKxn7jFV6",
        "name": "Boryoku Dragonz #156",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/156.png"
    },
    "C6MoveU3HHnhqeGZGzj9fwxv4YNTSvfSkKdnPDkvHnSV": {
        "mint": "C6MoveU3HHnhqeGZGzj9fwxv4YNTSvfSkKdnPDkvHnSV",
        "name": "Boryoku Dragonz #661",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/661.png"
    },
    "6sb9pYzoMo1UxERcspUsc1etUwWR2s9zBSaGb5McEhdA": {
        "mint": "6sb9pYzoMo1UxERcspUsc1etUwWR2s9zBSaGb5McEhdA",
        "name": "Boryoku Dragonz #141",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/141.png"
    },
    "7qRdRvYyJPBtVXgnAKbXNnQPG1GFmoVDrs57gPR9KLjb": {
        "mint": "7qRdRvYyJPBtVXgnAKbXNnQPG1GFmoVDrs57gPR9KLjb",
        "name": "Boryoku Dragonz #660",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/660.png"
    },
    "CzVHDtcjDDGJNbWhUdmyo98eV9FmrUviRnquoPZy6PAo": {
        "mint": "CzVHDtcjDDGJNbWhUdmyo98eV9FmrUviRnquoPZy6PAo",
        "name": "Boryoku Dragonz #172",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/172.png"
    },
    "JTwJz7jaL5rHuAw2d9xWpk5px9rLGiLArJuKpCCh2rp": {
        "mint": "JTwJz7jaL5rHuAw2d9xWpk5px9rLGiLArJuKpCCh2rp",
        "name": "Boryoku Dragonz #149",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/149.png"
    },
    "sSMC6V6okr13ibQkzfBzTWXMZt6BjpggMTfuWcCF8Ua": {
        "mint": "sSMC6V6okr13ibQkzfBzTWXMZt6BjpggMTfuWcCF8Ua",
        "name": "Boryoku Dragonz #659",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/659.png"
    },
    "9kDZrHLJ1ZUpmNvGzW9jDF5Fmqu5NkPiY53rYMP61ef9": {
        "mint": "9kDZrHLJ1ZUpmNvGzW9jDF5Fmqu5NkPiY53rYMP61ef9",
        "name": "Boryoku Dragonz #145",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/145.png"
    },
    "BXmC2ezmnnpwwCwkfVvePNQPx5fbnB58BTcYBTg5WM1": {
        "mint": "BXmC2ezmnnpwwCwkfVvePNQPx5fbnB58BTcYBTg5WM1",
        "name": "Boryoku Dragonz #656",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/656.png"
    },
    "A5f6A9TjVoc61gB7yPoHzUoycRoNFSgrWVmj9Vw2aoNS": {
        "mint": "A5f6A9TjVoc61gB7yPoHzUoycRoNFSgrWVmj9Vw2aoNS",
        "name": "Boryoku Dragonz #173",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/173.png"
    },
    "DAHMqc2RiUXzpoAAVdx56ETg98R2XiNcxvBtM9zKZXkc": {
        "mint": "DAHMqc2RiUXzpoAAVdx56ETg98R2XiNcxvBtM9zKZXkc",
        "name": "Boryoku Dragonz #658",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/658.png"
    },
    "BKPaKDxFnpKWPWQxeK75EPgs47Cr7HehaQggtvNLZvqm": {
        "mint": "BKPaKDxFnpKWPWQxeK75EPgs47Cr7HehaQggtvNLZvqm",
        "name": "Boryoku Dragonz #159",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/159.png"
    },
    "Dz4m4kHFhN9e1esQ1ReHvHJ6e7aZApNMpZCGmcwbxGoB": {
        "mint": "Dz4m4kHFhN9e1esQ1ReHvHJ6e7aZApNMpZCGmcwbxGoB",
        "name": "Boryoku Dragonz #657",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/657.png"
    },
    "HuD7vgbEpPGQEJ3L8kacALU5ow5g1xesG8cTzHHNXS9K": {
        "mint": "HuD7vgbEpPGQEJ3L8kacALU5ow5g1xesG8cTzHHNXS9K",
        "name": "Boryoku Dragonz #154",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/154.png"
    },
    "AW5cWzhM2cjHj3LAxArobxHnxMQaLNacPGnseaXA47iD": {
        "mint": "AW5cWzhM2cjHj3LAxArobxHnxMQaLNacPGnseaXA47iD",
        "name": "Boryoku Dragonz #655",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/655.png"
    },
    "7gVoKJPG2yX5k2ikLB64mah6NQBok7qiqgkHdZcvhwLv": {
        "mint": "7gVoKJPG2yX5k2ikLB64mah6NQBok7qiqgkHdZcvhwLv",
        "name": "Boryoku Dragonz #137",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/137.png"
    },
    "9tna2VRqGENcWJfVtp9Y5rsa9ZHAC3aJ3G1KU5JaDpeA": {
        "mint": "9tna2VRqGENcWJfVtp9Y5rsa9ZHAC3aJ3G1KU5JaDpeA",
        "name": "Boryoku Dragonz #148",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/148.png"
    },
    "3WYHMZrJEesERrxkRGXCBUybFJYV9oRbW5q1fk7UovW4": {
        "mint": "3WYHMZrJEesERrxkRGXCBUybFJYV9oRbW5q1fk7UovW4",
        "name": "Boryoku Dragonz #651",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/651.png"
    },
    "CNgFPqQhN1EYzojGXi2SkQ6srtJnrw5qhuDvqZ9KDEMY": {
        "mint": "CNgFPqQhN1EYzojGXi2SkQ6srtJnrw5qhuDvqZ9KDEMY",
        "name": "Boryoku Dragonz #654",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/654.png"
    },
    "2xPSiVVMxJtRCegbgF9HSYqF3JqSHWRnEK2muYvF5Vxt": {
        "mint": "2xPSiVVMxJtRCegbgF9HSYqF3JqSHWRnEK2muYvF5Vxt",
        "name": "Boryoku Dragonz #162",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/162.png"
    },
    "J2TTW4uARbwM6vES2bCS1Ebc3CYVVMD2PQPtNj6CuWmd": {
        "mint": "J2TTW4uARbwM6vES2bCS1Ebc3CYVVMD2PQPtNj6CuWmd",
        "name": "Boryoku Dragonz #652",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/652.png"
    },
    "CgjC4bKmZjJvigncy9k1s9arat7VzfATHehfMEexD8DF": {
        "mint": "CgjC4bKmZjJvigncy9k1s9arat7VzfATHehfMEexD8DF",
        "name": "Boryoku Dragonz #158",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/158.png"
    },
    "BMJnGvwhTt2YnE3sX5Nd18NZV7yKBRFsvELyYfNM9eQY": {
        "mint": "BMJnGvwhTt2YnE3sX5Nd18NZV7yKBRFsvELyYfNM9eQY",
        "name": "Boryoku Dragonz #653",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/653.png"
    },
    "5p8htu7DNzWF1qYjY1g4rGSYjdtybnR5byattS3TDXwf": {
        "mint": "5p8htu7DNzWF1qYjY1g4rGSYjdtybnR5byattS3TDXwf",
        "name": "Boryoku Dragonz #155",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/155.png"
    },
    "DpHVarYsbLPoH7ePE5tbKwTzduQYdKSu68SZEQGR6zH5": {
        "mint": "DpHVarYsbLPoH7ePE5tbKwTzduQYdKSu68SZEQGR6zH5",
        "name": "Boryoku Dragonz #650",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/650.png"
    },
    "8cau5W9gHzBo8LieVBL9w4AzM7kWf7qMZGyfgty8hER7": {
        "mint": "8cau5W9gHzBo8LieVBL9w4AzM7kWf7qMZGyfgty8hER7",
        "name": "Boryoku Dragonz #152",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/152.png"
    },
    "DWSG3S7BT2P6NRUZR5n7Ayj9ua9LZ7TZuqEnRpNU33vH": {
        "mint": "DWSG3S7BT2P6NRUZR5n7Ayj9ua9LZ7TZuqEnRpNU33vH",
        "name": "Boryoku Dragonz #649",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/649.png"
    },
    "FmZax5srbn4s4vd7TYYLHDZ9SqR8ZDzDdVwmcwBL7TB4": {
        "mint": "FmZax5srbn4s4vd7TYYLHDZ9SqR8ZDzDdVwmcwBL7TB4",
        "name": "Boryoku Dragonz #178",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/178.png"
    },
    "3cSj9sLTMdToqZBC8Ay2nxwHJtgvrfnnd4YgiGjPcUGU": {
        "mint": "3cSj9sLTMdToqZBC8Ay2nxwHJtgvrfnnd4YgiGjPcUGU",
        "name": "Boryoku Dragonz #183",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/183.png"
    },
    "DiSvxB7DzaMr4sGtzjx9UJ24RzGZQ7VBdtccbmhsRW6J": {
        "mint": "DiSvxB7DzaMr4sGtzjx9UJ24RzGZQ7VBdtccbmhsRW6J",
        "name": "Boryoku Dragonz #647",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/647.png"
    },
    "7T1jf8xnnj8mmAegA9VRzPbne9G6Kr64m3a5trkvP5dK": {
        "mint": "7T1jf8xnnj8mmAegA9VRzPbne9G6Kr64m3a5trkvP5dK",
        "name": "Boryoku Dragonz #179",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/179.png"
    },
    "3rN6kxjZ6QDmic621gyaDZ8ZksmN4Nx4davwW2WTLJJE": {
        "mint": "3rN6kxjZ6QDmic621gyaDZ8ZksmN4Nx4davwW2WTLJJE",
        "name": "Boryoku Dragonz #648",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/648.png"
    },
    "6ivqs3RwCHhFq9rPmJbH2JiQiHLw8JU2tff6nLnes6Bn": {
        "mint": "6ivqs3RwCHhFq9rPmJbH2JiQiHLw8JU2tff6nLnes6Bn",
        "name": "Boryoku Dragonz #645",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/645.png"
    },
    "2Ck4SiACmGrir9c1d9JAjkzc6Jk1QGGxo2JHqGCrrCMx": {
        "mint": "2Ck4SiACmGrir9c1d9JAjkzc6Jk1QGGxo2JHqGCrrCMx",
        "name": "Boryoku Dragonz #190",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/190.png"
    },
    "7ieU8E8xvSDE8LPRKdySvx5W38mUnsNa72TzmQof6C5K": {
        "mint": "7ieU8E8xvSDE8LPRKdySvx5W38mUnsNa72TzmQof6C5K",
        "name": "Boryoku Dragonz #646",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/646.png"
    },
    "GYiW9C4HpM2rBu9WbR8acWZkWKC4XYb7zgntA7txAFJs": {
        "mint": "GYiW9C4HpM2rBu9WbR8acWZkWKC4XYb7zgntA7txAFJs",
        "name": "Boryoku Dragonz #180",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/180.png"
    },
    "Hz2Lvs6CLPku2ytg6L9Rszy67YihzCupy3xDpJJFmFX8": {
        "mint": "Hz2Lvs6CLPku2ytg6L9Rszy67YihzCupy3xDpJJFmFX8",
        "name": "Boryoku Dragonz #644",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/644.png"
    },
    "6m4uGVcDTUFHqsP7oyVz18XX5DzSa7RedwWuBFJ64skw": {
        "mint": "6m4uGVcDTUFHqsP7oyVz18XX5DzSa7RedwWuBFJ64skw",
        "name": "Boryoku Dragonz #185",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/185.png"
    },
    "yR11WVbpimc5hga8NoidQfF7diwk82LNgftiKaynVdZ": {
        "mint": "yR11WVbpimc5hga8NoidQfF7diwk82LNgftiKaynVdZ",
        "name": "Boryoku Dragonz #643",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/643.png"
    },
    "79VUVTpNvTmMFB2TqJhHGf63gXtHmZmZ5ec7sqUrdRbq": {
        "mint": "79VUVTpNvTmMFB2TqJhHGf63gXtHmZmZ5ec7sqUrdRbq",
        "name": "Boryoku Dragonz #177",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/177.png"
    },
    "3hPdFh3TGee5qXBUTgVGczazSLuDPXFFu1LDGcVz9HJK": {
        "mint": "3hPdFh3TGee5qXBUTgVGczazSLuDPXFFu1LDGcVz9HJK",
        "name": "Boryoku Dragonz #642",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/642.png"
    },
    "57Ro2AJs66bxezbivuercMAMJCwX8MULCtKpqKxUSoXX": {
        "mint": "57Ro2AJs66bxezbivuercMAMJCwX8MULCtKpqKxUSoXX",
        "name": "Boryoku Dragonz #184",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/184.png"
    },
    "CU134jttAcXSJVrGynmkWtPE9BCzV5Fn6xn8jXBpigjT": {
        "mint": "CU134jttAcXSJVrGynmkWtPE9BCzV5Fn6xn8jXBpigjT",
        "name": "Boryoku Dragonz #189",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/189.png"
    },
    "GqxXgssH7JZYzsdGyKqBZuiSWeoxm3KdsQwzhPm4Q8BC": {
        "mint": "GqxXgssH7JZYzsdGyKqBZuiSWeoxm3KdsQwzhPm4Q8BC",
        "name": "Boryoku Dragonz #640",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/640.png"
    },
    "DR892agW5vXqMV4C3nSHd5v5FXs5dFLFBFRe8hqAGgBR": {
        "mint": "DR892agW5vXqMV4C3nSHd5v5FXs5dFLFBFRe8hqAGgBR",
        "name": "Boryoku Dragonz #188",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/188.png"
    },
    "Q2hkCjgTgKRyhHYFDFrsH5DUW4X2Jtz6q2Wqz6K6Dd1": {
        "mint": "Q2hkCjgTgKRyhHYFDFrsH5DUW4X2Jtz6q2Wqz6K6Dd1",
        "name": "Boryoku Dragonz #186",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/186.png"
    },
    "BdMiuExttPB52hPpZndDBrokp6sxP8EtsRK3bahgDYUb": {
        "mint": "BdMiuExttPB52hPpZndDBrokp6sxP8EtsRK3bahgDYUb",
        "name": "Boryoku Dragonz #641",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/641.png"
    },
    "AqJM1riT1G9yeQXkfsXEBGNKuu3Re8KMbRMHCXbZoCv7": {
        "mint": "AqJM1riT1G9yeQXkfsXEBGNKuu3Re8KMbRMHCXbZoCv7",
        "name": "Boryoku Dragonz #639",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/639.png"
    },
    "6fPn9abdR487hTsDj9ReJkdxTAUTeUfNnQZA7heEYS6C": {
        "mint": "6fPn9abdR487hTsDj9ReJkdxTAUTeUfNnQZA7heEYS6C",
        "name": "Boryoku Dragonz #182",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/182.png"
    },
    "AZZ4yG8yBrkaUs6qweLAHgvjp1GdkyUtHPmSZy45g469": {
        "mint": "AZZ4yG8yBrkaUs6qweLAHgvjp1GdkyUtHPmSZy45g469",
        "name": "Boryoku Dragonz #638",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/638.png"
    },
    "GpeQCmsUhc2qSbLtwqNwhwKE2jhhbEjkoFshTq7zfXgT": {
        "mint": "GpeQCmsUhc2qSbLtwqNwhwKE2jhhbEjkoFshTq7zfXgT",
        "name": "Boryoku Dragonz #187",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/187.png"
    },
    "CLVP7Jps5ZW9N3VfohZDhxW85wX8MbnHEpXZfmShn4Ra": {
        "mint": "CLVP7Jps5ZW9N3VfohZDhxW85wX8MbnHEpXZfmShn4Ra",
        "name": "Boryoku Dragonz #635",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/635.png"
    },
    "E7x5RfH1HHDGVj1im2SZXmSgoTicT8FPnvbajta3zdYt": {
        "mint": "E7x5RfH1HHDGVj1im2SZXmSgoTicT8FPnvbajta3zdYt",
        "name": "Boryoku Dragonz #181",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/181.png"
    },
    "DCeihPZvLQWfkKJknUryDJ5B3Yx67JzfqjpTviKeM8wH": {
        "mint": "DCeihPZvLQWfkKJknUryDJ5B3Yx67JzfqjpTviKeM8wH",
        "name": "Boryoku Dragonz #637",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/637.png"
    },
    "6RdaWGAd4XjecvRnnZuNSvCEBaEnffWzGhaaSBEDjdtm": {
        "mint": "6RdaWGAd4XjecvRnnZuNSvCEBaEnffWzGhaaSBEDjdtm",
        "name": "Boryoku Dragonz #191",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/191.png"
    },
    "8GuXLRkh5wL35UiAR2Ah9jhKGoDz2w3BVKzmPPFNaD27": {
        "mint": "8GuXLRkh5wL35UiAR2Ah9jhKGoDz2w3BVKzmPPFNaD27",
        "name": "Boryoku Dragonz #636",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/636.png"
    },
    "Ait5UmLBBUnys2AKxSftWANzpV89wDzxDXzA4iu3bYrZ": {
        "mint": "Ait5UmLBBUnys2AKxSftWANzpV89wDzxDXzA4iu3bYrZ",
        "name": "Boryoku Dragonz #192",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/192.png"
    },
    "86hq3PBxQbdHJPU78PpS22nmeDqkfWyeaMgxuVPGYeTq": {
        "mint": "86hq3PBxQbdHJPU78PpS22nmeDqkfWyeaMgxuVPGYeTq",
        "name": "Boryoku Dragonz #633",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/633.png"
    },
    "As5fAr5u5E7Wna2viHzYGbFsgosHQQfGjoyhkia9QvjB": {
        "mint": "As5fAr5u5E7Wna2viHzYGbFsgosHQQfGjoyhkia9QvjB",
        "name": "Boryoku Dragonz #197",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/197.png"
    },
    "Fy5mH1ruc6aywnRyd169HQdMHogTA65Suvz779o6jhDi": {
        "mint": "Fy5mH1ruc6aywnRyd169HQdMHogTA65Suvz779o6jhDi",
        "name": "Boryoku Dragonz #199",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/199.png"
    },
    "5tdcCMNUknpgYyxDmtMgbvhgiukth9JQk4sFeYQa7P5D": {
        "mint": "5tdcCMNUknpgYyxDmtMgbvhgiukth9JQk4sFeYQa7P5D",
        "name": "Boryoku Dragonz #634",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/634.png"
    },
    "2uefoiU8mHLtL32kr3sW33H2adqczHjeLv5DYFdmvz9a": {
        "mint": "2uefoiU8mHLtL32kr3sW33H2adqczHjeLv5DYFdmvz9a",
        "name": "Boryoku Dragonz #631",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/631.png"
    },
    "Gt5B6h8UoqGqEY1oYpz9DJADYaAiqGRDN8myPBvV7LAR": {
        "mint": "Gt5B6h8UoqGqEY1oYpz9DJADYaAiqGRDN8myPBvV7LAR",
        "name": "Boryoku Dragonz #193",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/193.png"
    },
    "HtJS8G3jGmXCCqSFKKuUtN7RAE4NRy1hsSvUjbM7PZRU": {
        "mint": "HtJS8G3jGmXCCqSFKKuUtN7RAE4NRy1hsSvUjbM7PZRU",
        "name": "Boryoku Dragonz #632",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/632.png"
    },
    "FLX3KrcFSZGpDmFKeagkZeAHC3AR4MJvN76x5zjG2mfZ": {
        "mint": "FLX3KrcFSZGpDmFKeagkZeAHC3AR4MJvN76x5zjG2mfZ",
        "name": "Boryoku Dragonz #201",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/201.png"
    },
    "374wpmxdMX1LRL9ZcPXuqEDVjC9KgvXnF7MHfKdnd7zL": {
        "mint": "374wpmxdMX1LRL9ZcPXuqEDVjC9KgvXnF7MHfKdnd7zL",
        "name": "Boryoku Dragonz #629",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/629.png"
    },
    "2BQPqs3VVoS51cDnry6hk8DrtewjJr2G5RQNnGf1txa3": {
        "mint": "2BQPqs3VVoS51cDnry6hk8DrtewjJr2G5RQNnGf1txa3",
        "name": "Boryoku Dragonz #630",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/630.png"
    },
    "DWvmKB19XQTa1n9gU1c64cPNbRzSVsZZy8dod7AhYARh": {
        "mint": "DWvmKB19XQTa1n9gU1c64cPNbRzSVsZZy8dod7AhYARh",
        "name": "Boryoku Dragonz #195",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/195.png"
    },
    "JBuUVT7idTuvWhLnviyBE61bGJC9grRuDh8afZHXJJ4": {
        "mint": "JBuUVT7idTuvWhLnviyBE61bGJC9grRuDh8afZHXJJ4",
        "name": "Boryoku Dragonz #627",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/627.png"
    },
    "6n3YTfWoK4YjTedTE6xiV8xGYNSLza79SiibbQD2omea": {
        "mint": "6n3YTfWoK4YjTedTE6xiV8xGYNSLza79SiibbQD2omea",
        "name": "Boryoku Dragonz #194",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/194.png"
    },
    "4arMsQJ1ZDbjbofmz8bvhLNhY6UptcYH2bc85NxGeNef": {
        "mint": "4arMsQJ1ZDbjbofmz8bvhLNhY6UptcYH2bc85NxGeNef",
        "name": "Boryoku Dragonz #628",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/628.png"
    },
    "8erixuzuLerZVDbViseUpzYe37QhV4CfgTz8FDNGucwf": {
        "mint": "8erixuzuLerZVDbViseUpzYe37QhV4CfgTz8FDNGucwf",
        "name": "Boryoku Dragonz #198",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/198.png"
    },
    "HZCPnyoPoaQMLTtGo7wP1GNENodVVtFfo2ZL7BN3KwPa": {
        "mint": "HZCPnyoPoaQMLTtGo7wP1GNENodVVtFfo2ZL7BN3KwPa",
        "name": "Boryoku Dragonz #626",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/626.png"
    },
    "6HGfhDjJ6XH3X9wAh1bfx43M7mo4YTgUJo4KA9ihaj58": {
        "mint": "6HGfhDjJ6XH3X9wAh1bfx43M7mo4YTgUJo4KA9ihaj58",
        "name": "Boryoku Dragonz #200",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/200.png"
    },
    "CnDhxpvBR8resA9LongkzjJWB81gKG7MLBz5umcGZNRj": {
        "mint": "CnDhxpvBR8resA9LongkzjJWB81gKG7MLBz5umcGZNRj",
        "name": "Boryoku Dragonz #624",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/624.png"
    },
    "DvxMse6u8tnoTjxFam8pC3eNx5tUSiuefTj9K4BTCT7o": {
        "mint": "DvxMse6u8tnoTjxFam8pC3eNx5tUSiuefTj9K4BTCT7o",
        "name": "Boryoku Dragonz #196",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/196.png"
    },
    "DD3k9h9ES6BfqvM37judNoVun9AxPTeacqiBwB6PjPnQ": {
        "mint": "DD3k9h9ES6BfqvM37judNoVun9AxPTeacqiBwB6PjPnQ",
        "name": "Boryoku Dragonz #625",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/625.png"
    },
    "CTSLpSaMLXBw9D9bFjpx79cm6qL3nuugib72HHmeJBjh": {
        "mint": "CTSLpSaMLXBw9D9bFjpx79cm6qL3nuugib72HHmeJBjh",
        "name": "Boryoku Dragonz #224",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/224.png"
    },
    "2BB7V9DPmCVXynsPyVAyKjrRTYMS28AATxHWJbBeqSMz": {
        "mint": "2BB7V9DPmCVXynsPyVAyKjrRTYMS28AATxHWJbBeqSMz",
        "name": "Boryoku Dragonz #622",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/622.png"
    },
    "FXJQQwQHZAqRWt67TVDVdpMvzAXAhRgJBN9JfgTjonfY": {
        "mint": "FXJQQwQHZAqRWt67TVDVdpMvzAXAhRgJBN9JfgTjonfY",
        "name": "Boryoku Dragonz #227",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/227.png"
    },
    "Ema3WkrJJU3anNz1eQgY91hAKB5QehwCF6CiWj3s6eGF": {
        "mint": "Ema3WkrJJU3anNz1eQgY91hAKB5QehwCF6CiWj3s6eGF",
        "name": "Boryoku Dragonz #230",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/230.png"
    },
    "8RqktrQq8dA147wfygzmgsh2FyXnFtMyKB65ESwwxMY7": {
        "mint": "8RqktrQq8dA147wfygzmgsh2FyXnFtMyKB65ESwwxMY7",
        "name": "Boryoku Dragonz #621",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/621.png"
    },
    "H24hKu3ApgLhkUsb7K9Twpm5SWZn7d81NPTQJodfP2V2": {
        "mint": "H24hKu3ApgLhkUsb7K9Twpm5SWZn7d81NPTQJodfP2V2",
        "name": "Boryoku Dragonz #623",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/623.png"
    },
    "2tCCLZkGyPtvZHyiBs5TZ32SvixhLHmc5TE4QuXJX4DA": {
        "mint": "2tCCLZkGyPtvZHyiBs5TZ32SvixhLHmc5TE4QuXJX4DA",
        "name": "Boryoku Dragonz #208",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/208.png"
    },
    "8DDwFRJ1ugBuFbiAvvaBjoTEuJqoENUTrNSZJWABZUzM": {
        "mint": "8DDwFRJ1ugBuFbiAvvaBjoTEuJqoENUTrNSZJWABZUzM",
        "name": "Boryoku Dragonz #619",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/619.png"
    },
    "ArKkAGh2Xmdco5p8NPyMfnDFBtfyEVdotVZtrMvgdJpU": {
        "mint": "ArKkAGh2Xmdco5p8NPyMfnDFBtfyEVdotVZtrMvgdJpU",
        "name": "Boryoku Dragonz #236",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/236.png"
    },
    "3dqXGqw3iASJu7FbwTakcyVZUFHSE971YXkMqJDmKfoN": {
        "mint": "3dqXGqw3iASJu7FbwTakcyVZUFHSE971YXkMqJDmKfoN",
        "name": "Boryoku Dragonz #620",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/620.png"
    },
    "HYGCr9M5BxDGnEs8n63mjmEQExUVZhr9A3b8XpqsWQsp": {
        "mint": "HYGCr9M5BxDGnEs8n63mjmEQExUVZhr9A3b8XpqsWQsp",
        "name": "Boryoku Dragonz #229",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/229.png"
    },
    "EqdYhpYvfbbx2Noh2SvkvFkqpUUzq2icVRZvEcWSSEhX": {
        "mint": "EqdYhpYvfbbx2Noh2SvkvFkqpUUzq2icVRZvEcWSSEhX",
        "name": "Boryoku Dragonz #618",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/618.png"
    },
    "FMf57vUtDXiYkZ9RMwMFmcLyXH5Hdb4hTKsJWW9usUKF": {
        "mint": "FMf57vUtDXiYkZ9RMwMFmcLyXH5Hdb4hTKsJWW9usUKF",
        "name": "Boryoku Dragonz #615",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/615.png"
    },
    "7gpWx9JgiHdYNWbPkXp58H94DcgJZuJdg5fd8Go5sxCg": {
        "mint": "7gpWx9JgiHdYNWbPkXp58H94DcgJZuJdg5fd8Go5sxCg",
        "name": "Boryoku Dragonz #213",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/213.png"
    },
    "4MZWkDuSTnf9zsytyEaeKuJTAZ6zTU6jAXdqqrwi6fn1": {
        "mint": "4MZWkDuSTnf9zsytyEaeKuJTAZ6zTU6jAXdqqrwi6fn1",
        "name": "Boryoku Dragonz #614",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/614.png"
    },
    "9H39ybbHXmyveLQ2brLWFYrTLjHqqEg2LuAcXnxFs6Py": {
        "mint": "9H39ybbHXmyveLQ2brLWFYrTLjHqqEg2LuAcXnxFs6Py",
        "name": "Boryoku Dragonz #220",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/220.png"
    },
    "9FXxJhuFF8ui34EHQtQJeyshXgzoamHtqPvrRVSrWdhT": {
        "mint": "9FXxJhuFF8ui34EHQtQJeyshXgzoamHtqPvrRVSrWdhT",
        "name": "Boryoku Dragonz #616",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/616.png"
    },
    "FjvdghXh3otJHtvXS3kVxHVN2jvyFTTMZXdzJHHTJ1Yi": {
        "mint": "FjvdghXh3otJHtvXS3kVxHVN2jvyFTTMZXdzJHHTJ1Yi",
        "name": "Boryoku Dragonz #225",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/225.png"
    },
    "FZE1EHH6MYgmRbZGvFLhczDYV8oF2pBcfHNCvwVBn5ri": {
        "mint": "FZE1EHH6MYgmRbZGvFLhczDYV8oF2pBcfHNCvwVBn5ri",
        "name": "Boryoku Dragonz #617",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/617.png"
    },
    "3zcgdAKxXzepNdCzPDtEAgyERUPhY3RBhjcQhGAf1AQ2": {
        "mint": "3zcgdAKxXzepNdCzPDtEAgyERUPhY3RBhjcQhGAf1AQ2",
        "name": "Boryoku Dragonz #610",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/610.png"
    },
    "9cbaffbqCpq4cShQ4oASfUcmpzpuwyFDxuiAuWqdfCTk": {
        "mint": "9cbaffbqCpq4cShQ4oASfUcmpzpuwyFDxuiAuWqdfCTk",
        "name": "Boryoku Dragonz #231",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/231.png"
    },
    "6cw3HQ9WKuhAEBBtAC7VuumzUg4g9oD7ihZg7BTPgVK9": {
        "mint": "6cw3HQ9WKuhAEBBtAC7VuumzUg4g9oD7ihZg7BTPgVK9",
        "name": "Boryoku Dragonz #609",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/609.png"
    },
    "HkkUzG7xmJXm6ob4Xy2JLWqEm6Lg241m9a8Sj12cAwHs": {
        "mint": "HkkUzG7xmJXm6ob4Xy2JLWqEm6Lg241m9a8Sj12cAwHs",
        "name": "Boryoku Dragonz #206",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/206.png"
    },
    "GecP7nmimXqDvkJYLFa9hvxTxHvSxsXPzzcS8z5v37Cg": {
        "mint": "GecP7nmimXqDvkJYLFa9hvxTxHvSxsXPzzcS8z5v37Cg",
        "name": "Boryoku Dragonz #608",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/608.png"
    },
    "A8j8RpBnLxU9EDJeCTwy51knhiCwnKruXVAkjoeLaJPo": {
        "mint": "A8j8RpBnLxU9EDJeCTwy51knhiCwnKruXVAkjoeLaJPo",
        "name": "Boryoku Dragonz #211",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/211.png"
    },
    "4QZRZiGoF9q25egYbwMGL6iWHU3i6DUdcMZvGX3egByH": {
        "mint": "4QZRZiGoF9q25egYbwMGL6iWHU3i6DUdcMZvGX3egByH",
        "name": "Boryoku Dragonz #612",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/612.png"
    },
    "6S6QwdhVwNtRCx53wVqCN9esZbkvWarjwTXwBFugCyav": {
        "mint": "6S6QwdhVwNtRCx53wVqCN9esZbkvWarjwTXwBFugCyav",
        "name": "Boryoku Dragonz #216",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/216.png"
    },
    "EFvVzA5wVLG2V3P7JjCWLyW6L1p6mMnyBCA6AZLJHif1": {
        "mint": "EFvVzA5wVLG2V3P7JjCWLyW6L1p6mMnyBCA6AZLJHif1",
        "name": "Boryoku Dragonz #607",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/607.png"
    },
    "14X26aANfpub4LuHJrtoQySWZaFs37qNj9AeJWcJacNu": {
        "mint": "14X26aANfpub4LuHJrtoQySWZaFs37qNj9AeJWcJacNu",
        "name": "Boryoku Dragonz #207",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/207.png"
    },
    "2uoquwtoXdvxTJJuE8jsKXuXuVqVpVcaJViTTUMaTeDG": {
        "mint": "2uoquwtoXdvxTJJuE8jsKXuXuVqVpVcaJViTTUMaTeDG",
        "name": "Boryoku Dragonz #606",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/606.png"
    },
    "AZff5YFHirRgHfUsufYNEh8exbo2KKsxgP63avgSS7bM": {
        "mint": "AZff5YFHirRgHfUsufYNEh8exbo2KKsxgP63avgSS7bM",
        "name": "Boryoku Dragonz #237",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/237.png"
    },
    "CsyPR8eMGZ4MWxctGCBpxHWT8NdKdV8JFGq1wVutLgbw": {
        "mint": "CsyPR8eMGZ4MWxctGCBpxHWT8NdKdV8JFGq1wVutLgbw",
        "name": "Boryoku Dragonz #611",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/611.png"
    },
    "CvM2hvi8pZ8ySxZSrUZj7PTRukvX1k1i398VQCZjVSyF": {
        "mint": "CvM2hvi8pZ8ySxZSrUZj7PTRukvX1k1i398VQCZjVSyF",
        "name": "Boryoku Dragonz #239",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/239.png"
    },
    "GdYpxUDNoMR45D4fgCsHofGM89XWgpauRWg6g4MvEZsh": {
        "mint": "GdYpxUDNoMR45D4fgCsHofGM89XWgpauRWg6g4MvEZsh",
        "name": "Boryoku Dragonz #613",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/613.png"
    },
    "BFHTNmuE4YErRz75gqFTZFfN6zJtjPMgRNKXSrLAmjah": {
        "mint": "BFHTNmuE4YErRz75gqFTZFfN6zJtjPMgRNKXSrLAmjah",
        "name": "Boryoku Dragonz #240",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/240.png"
    },
    "7DJvA8fMfHM37dDm6HUESiPGqMPzL1ooqdUoxwjWxfuU": {
        "mint": "7DJvA8fMfHM37dDm6HUESiPGqMPzL1ooqdUoxwjWxfuU",
        "name": "Boryoku Dragonz #604",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/604.png"
    },
    "FcoMsFGZEgJPtkcVhRky5Ux9DWNSWz6mRg3RoTFYB9VN": {
        "mint": "FcoMsFGZEgJPtkcVhRky5Ux9DWNSWz6mRg3RoTFYB9VN",
        "name": "Boryoku Dragonz #234",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/234.png"
    },
    "3wiSukEcx6FWEDLyd6GZaoxYwJngCqQipGoP59h8aukj": {
        "mint": "3wiSukEcx6FWEDLyd6GZaoxYwJngCqQipGoP59h8aukj",
        "name": "Boryoku Dragonz #605",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/605.png"
    },
    "CBCt36MWacSaEdK9McvyahhYP23mNmV63NoBWNTuqSr2": {
        "mint": "CBCt36MWacSaEdK9McvyahhYP23mNmV63NoBWNTuqSr2",
        "name": "Boryoku Dragonz #238",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/238.png"
    },
    "ApRAbG8j7Adyumywr5982FnYQ4REbYPWGLGTShfE9yk": {
        "mint": "ApRAbG8j7Adyumywr5982FnYQ4REbYPWGLGTShfE9yk",
        "name": "Boryoku Dragonz #601",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/601.png"
    },
    "H19mnejvGK9DJKAdUU5zUp9KNbDWfUuMMguiprSJzBdR": {
        "mint": "H19mnejvGK9DJKAdUU5zUp9KNbDWfUuMMguiprSJzBdR",
        "name": "Boryoku Dragonz #602",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/602.png"
    },
    "6ktscBv2BSzRzjsQgahzYmZCAtP7uNgPBiDFckegU5ZX": {
        "mint": "6ktscBv2BSzRzjsQgahzYmZCAtP7uNgPBiDFckegU5ZX",
        "name": "Boryoku Dragonz #222",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/222.png"
    },
    "Hnz5iKNfJuwvSrPpGX8WccBcaMdfpncXzsfG9XR7vZmC": {
        "mint": "Hnz5iKNfJuwvSrPpGX8WccBcaMdfpncXzsfG9XR7vZmC",
        "name": "Boryoku Dragonz #603",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/603.png"
    },
    "BKBrRzEVxR5axhN4kpeBm4krrPNUpQjvH831WQqiKFuq": {
        "mint": "BKBrRzEVxR5axhN4kpeBm4krrPNUpQjvH831WQqiKFuq",
        "name": "Boryoku Dragonz #228",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/228.png"
    },
    "39S727akskMtJyg46mpD5oufe3PWKDvvLGMjSece7GPG": {
        "mint": "39S727akskMtJyg46mpD5oufe3PWKDvvLGMjSece7GPG",
        "name": "Boryoku Dragonz #600",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/600.png"
    },
    "EWFANuQ4snfTFu8gfqFPgAQ2a1JFDcnELEG4EEdyqwU6": {
        "mint": "EWFANuQ4snfTFu8gfqFPgAQ2a1JFDcnELEG4EEdyqwU6",
        "name": "Boryoku Dragonz #212",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/212.png"
    },
    "HgvcStpxpdWJPzU67f9CyFBUePxCjKNUcME57rvMpSQ6": {
        "mint": "HgvcStpxpdWJPzU67f9CyFBUePxCjKNUcME57rvMpSQ6",
        "name": "Boryoku Dragonz #597",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/597.png"
    },
    "83yzW5Fido73cGpk8USP4oPos9STCqXzeV9b1H3KE7Gw": {
        "mint": "83yzW5Fido73cGpk8USP4oPos9STCqXzeV9b1H3KE7Gw",
        "name": "Boryoku Dragonz #223",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/223.png"
    },
    "5UsWskoqghU6PbqurGGcgroLNmp35p25nWjCCkXzdHCv": {
        "mint": "5UsWskoqghU6PbqurGGcgroLNmp35p25nWjCCkXzdHCv",
        "name": "Boryoku Dragonz #598",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/598.png"
    },
    "DNYeuC8qEvvLPmfQstD6bT7U7VpTy2vfppEFDfQSAo3R": {
        "mint": "DNYeuC8qEvvLPmfQstD6bT7U7VpTy2vfppEFDfQSAo3R",
        "name": "Boryoku Dragonz #215",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/215.png"
    },
    "2C7qvmht5gGMAQc5Qic5YH8cXhSn34KrDRWpjt9wu57z": {
        "mint": "2C7qvmht5gGMAQc5Qic5YH8cXhSn34KrDRWpjt9wu57z",
        "name": "Boryoku Dragonz #599",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/599.png"
    },
    "AKJ2BTfxVWcVnCm1MK3Bs9vAjvhe1HDqqGyCyW1k71K2": {
        "mint": "AKJ2BTfxVWcVnCm1MK3Bs9vAjvhe1HDqqGyCyW1k71K2",
        "name": "Boryoku Dragonz #221",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/221.png"
    },
    "Fwxe4oEwyShvtHcJ5v54mkQMqjy7rDML96EiuAogzTED": {
        "mint": "Fwxe4oEwyShvtHcJ5v54mkQMqjy7rDML96EiuAogzTED",
        "name": "Boryoku Dragonz #596",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/596.png"
    },
    "FUeFvMSJ4vGnuRSdUogTY58gC5eYH9yMqEUDJqpHH8AD": {
        "mint": "FUeFvMSJ4vGnuRSdUogTY58gC5eYH9yMqEUDJqpHH8AD",
        "name": "Boryoku Dragonz #595",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/595.png"
    },
    "E7LBumP7v8pZyF29zevYHkhTiPhHS1cLDLq9TBeRMAyf": {
        "mint": "E7LBumP7v8pZyF29zevYHkhTiPhHS1cLDLq9TBeRMAyf",
        "name": "Boryoku Dragonz #202",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/202.png"
    },
    "EfXUVdN6afeuurRvNozmBQWSn9iQyonSinr5bw5Dtn4q": {
        "mint": "EfXUVdN6afeuurRvNozmBQWSn9iQyonSinr5bw5Dtn4q",
        "name": "Boryoku Dragonz #594",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/594.png"
    },
    "6RggGuCeVgZQKHsQpxqi4kdc1DW4jE5cn2UqZmUfafxm": {
        "mint": "6RggGuCeVgZQKHsQpxqi4kdc1DW4jE5cn2UqZmUfafxm",
        "name": "Boryoku Dragonz #205",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/205.png"
    },
    "BVRP5vfowSDa2KRAcx126CSXxV2ubkm58QUb62WWG4QR": {
        "mint": "BVRP5vfowSDa2KRAcx126CSXxV2ubkm58QUb62WWG4QR",
        "name": "Boryoku Dragonz #593",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/593.png"
    },
    "BPjP7Es9tsD5KswieuTmHoKXoRBP6XtZjnNgUDifdieK": {
        "mint": "BPjP7Es9tsD5KswieuTmHoKXoRBP6XtZjnNgUDifdieK",
        "name": "Boryoku Dragonz #241",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/241.png"
    },
    "3KsaxzdGxBEjDVfEbxJMgdTsBFcXBbPZocdS3YZcURY9": {
        "mint": "3KsaxzdGxBEjDVfEbxJMgdTsBFcXBbPZocdS3YZcURY9",
        "name": "Boryoku Dragonz #589",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/589.png"
    },
    "CXuL2kiQecC8nUme3wP63UYrzCUcuAUyh8tCQezwTujW": {
        "mint": "CXuL2kiQecC8nUme3wP63UYrzCUcuAUyh8tCQezwTujW",
        "name": "Boryoku Dragonz #209",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/209.png"
    },
    "3jXmhoNM9VRsfR4LX81PQ3iScKAKGfuSyzmnu7tG7DSz": {
        "mint": "3jXmhoNM9VRsfR4LX81PQ3iScKAKGfuSyzmnu7tG7DSz",
        "name": "Boryoku Dragonz #590",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/590.png"
    },
    "4TvjCjXLZpnwh36BzNva6LYFhHiQBe22mduAt7DHMWYo": {
        "mint": "4TvjCjXLZpnwh36BzNva6LYFhHiQBe22mduAt7DHMWYo",
        "name": "Boryoku Dragonz #592",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/592.png"
    },
    "GKLP5Nws73KucdsjgMWvRXEq6GwydKJdhWuedAnV1yMW": {
        "mint": "GKLP5Nws73KucdsjgMWvRXEq6GwydKJdhWuedAnV1yMW",
        "name": "Boryoku Dragonz #219",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/219.png"
    },
    "CsmQzzBA1jnPru356j3XkErZhFq2jpHV8GWAtxURibmM": {
        "mint": "CsmQzzBA1jnPru356j3XkErZhFq2jpHV8GWAtxURibmM",
        "name": "Boryoku Dragonz #588",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/588.png"
    },
    "CDKMVqL7EU1Z3MkZRSVBmCJRjVvvib5zA5TcvTJirePi": {
        "mint": "CDKMVqL7EU1Z3MkZRSVBmCJRjVvvib5zA5TcvTJirePi",
        "name": "Boryoku Dragonz #233",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/233.png"
    },
    "gFZmBjSN97mcnzvFoq3TjUJnH8qNpqewVPd3iihzTuN": {
        "mint": "gFZmBjSN97mcnzvFoq3TjUJnH8qNpqewVPd3iihzTuN",
        "name": "Boryoku Dragonz #591",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/591.png"
    },
    "3pZUTu8Nj3ZyGTKbZhxaRXs4BsAB6e4HXwEjsJJfVgSt": {
        "mint": "3pZUTu8Nj3ZyGTKbZhxaRXs4BsAB6e4HXwEjsJJfVgSt",
        "name": "Boryoku Dragonz #203",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/203.png"
    },
    "FLMbvWg3e7Ef2hpriPCMarW3iR1W8papWoG8fSX2qwok": {
        "mint": "FLMbvWg3e7Ef2hpriPCMarW3iR1W8papWoG8fSX2qwok",
        "name": "Boryoku Dragonz #587",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/587.png"
    },
    "8oFj69A1D7uU2wz8QtBLm1HfYSDhdRY8d4orptpyaNuf": {
        "mint": "8oFj69A1D7uU2wz8QtBLm1HfYSDhdRY8d4orptpyaNuf",
        "name": "Boryoku Dragonz #585",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/585.png"
    },
    "34C2CFztr8RtLqFfGmx4Sh6uhomJHg6DLbzG15mxfukH": {
        "mint": "34C2CFztr8RtLqFfGmx4Sh6uhomJHg6DLbzG15mxfukH",
        "name": "Boryoku Dragonz #235",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/235.png"
    },
    "9LH9KWiYsNEb3qETb6SUHuiuPLMEaW6nEuMMEEoeDo4U": {
        "mint": "9LH9KWiYsNEb3qETb6SUHuiuPLMEaW6nEuMMEEoeDo4U",
        "name": "Boryoku Dragonz #582",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/582.png"
    },
    "PNRgPyKoWQQsBtfAuGH39VC8DCopytYVNYNdSvHLEsj": {
        "mint": "PNRgPyKoWQQsBtfAuGH39VC8DCopytYVNYNdSvHLEsj",
        "name": "Boryoku Dragonz #210",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/210.png"
    },
    "2GERNk1C4RNg3SE2jui8dXmNbBeDyubb544VomatAyYF": {
        "mint": "2GERNk1C4RNg3SE2jui8dXmNbBeDyubb544VomatAyYF",
        "name": "Boryoku Dragonz #586",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/586.png"
    },
    "FHRXAbH4HN27XT66X2AuvBNnGsxgWf5VBH4K29AwwamU": {
        "mint": "FHRXAbH4HN27XT66X2AuvBNnGsxgWf5VBH4K29AwwamU",
        "name": "Boryoku Dragonz #226",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/226.png"
    },
    "Gcik6j6Qx9CysUyn56utSaH3hsZneQi5qDBVGSCkp1Fn": {
        "mint": "Gcik6j6Qx9CysUyn56utSaH3hsZneQi5qDBVGSCkp1Fn",
        "name": "Boryoku Dragonz #580",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/580.png"
    },
    "GqjcwWetdMBQ1gBB35WAhRtJrxd9VpDbyynjVWvczggp": {
        "mint": "GqjcwWetdMBQ1gBB35WAhRtJrxd9VpDbyynjVWvczggp",
        "name": "Boryoku Dragonz #204",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/204.png"
    },
    "DDWXn7Gx3AYRbWK2ESFq9oBN8iY6zJLzj7xtWgSSGwNF": {
        "mint": "DDWXn7Gx3AYRbWK2ESFq9oBN8iY6zJLzj7xtWgSSGwNF",
        "name": "Boryoku Dragonz #584",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/584.png"
    },
    "4txKWavVstDRYuMeEaKhCZW9GcTNLVdFnKU75PDnAA28": {
        "mint": "4txKWavVstDRYuMeEaKhCZW9GcTNLVdFnKU75PDnAA28",
        "name": "Boryoku Dragonz #218",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/218.png"
    },
    "FddmbPoDmeHRCdH5wAF32qYwbyoUsqJmvcCJKjYVTt2D": {
        "mint": "FddmbPoDmeHRCdH5wAF32qYwbyoUsqJmvcCJKjYVTt2D",
        "name": "Boryoku Dragonz #579",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/579.png"
    },
    "DWYsb798EgHroEqP81tjutPvSGvRmWq4hiEmQK8DCSSv": {
        "mint": "DWYsb798EgHroEqP81tjutPvSGvRmWq4hiEmQK8DCSSv",
        "name": "Boryoku Dragonz #583",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/583.png"
    },
    "EfbMZabMRdKa6SGVFZHLLUdGZBvC8bJDuNJPG4Ktnn8p": {
        "mint": "EfbMZabMRdKa6SGVFZHLLUdGZBvC8bJDuNJPG4Ktnn8p",
        "name": "Boryoku Dragonz #217",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/217.png"
    },
    "BFx4mLbKuRTYbQ4dFJ7nMXHfXkt5kg6N8YcAhCDWZ8iH": {
        "mint": "BFx4mLbKuRTYbQ4dFJ7nMXHfXkt5kg6N8YcAhCDWZ8iH",
        "name": "Boryoku Dragonz #581",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/581.png"
    },
    "ndMH5xC9KCjXqdNEhRxGHdYpzvQGvVKGqcKHEfydNh3": {
        "mint": "ndMH5xC9KCjXqdNEhRxGHdYpzvQGvVKGqcKHEfydNh3",
        "name": "Boryoku Dragonz #214",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/214.png"
    },
    "9BjZU2MPFbbTMWenD9TY95xazeZtQiHbxjn1XKDmcQC6": {
        "mint": "9BjZU2MPFbbTMWenD9TY95xazeZtQiHbxjn1XKDmcQC6",
        "name": "Boryoku Dragonz #578",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/578.png"
    },
    "73vLHn9we1JDHx5AsYdeaF9HzwMZjgysmURJTGmuaNbC": {
        "mint": "73vLHn9we1JDHx5AsYdeaF9HzwMZjgysmURJTGmuaNbC",
        "name": "Boryoku Dragonz #232",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/232.png"
    },
    "Grmfwp4BEH6R58r4kFAzi2NiJpAj7MEyq57GuJ1NUYPa": {
        "mint": "Grmfwp4BEH6R58r4kFAzi2NiJpAj7MEyq57GuJ1NUYPa",
        "name": "Boryoku Dragonz #574",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/574.png"
    },
    "Eu4CbCkrBYs9VyZUT6i5cSX5z1wSMU4BXTt7Z4Wktw91": {
        "mint": "Eu4CbCkrBYs9VyZUT6i5cSX5z1wSMU4BXTt7Z4Wktw91",
        "name": "Boryoku Dragonz #577",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/577.png"
    },
    "4cftxQtQ9S86m6thRkmtBXsx5KC5Zr3fCnu1JGyY6Y8G": {
        "mint": "4cftxQtQ9S86m6thRkmtBXsx5KC5Zr3fCnu1JGyY6Y8G",
        "name": "Boryoku Dragonz #246",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/246.png"
    },
    "3mQTj6mXRxjuQhWqerUPfGzSvNzpJYRkr4evAKk3T8ho": {
        "mint": "3mQTj6mXRxjuQhWqerUPfGzSvNzpJYRkr4evAKk3T8ho",
        "name": "Boryoku Dragonz #258",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/258.png"
    },
    "EDp6JLrVWrZbikCWrZhMqFYoN1JfxMuETHWCGPNnw33U": {
        "mint": "EDp6JLrVWrZbikCWrZhMqFYoN1JfxMuETHWCGPNnw33U",
        "name": "Boryoku Dragonz #573",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/573.png"
    },
    "EtL9Wr1pdvrAeNFaPjHD6E43b6kwqj6Z8xFPtqvJCsov": {
        "mint": "EtL9Wr1pdvrAeNFaPjHD6E43b6kwqj6Z8xFPtqvJCsov",
        "name": "Boryoku Dragonz #252",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/252.png"
    },
    "ULhA2xfjyZRXJt54QxtDnieuDxyppNPgi48YTk2KUej": {
        "mint": "ULhA2xfjyZRXJt54QxtDnieuDxyppNPgi48YTk2KUej",
        "name": "Boryoku Dragonz #572",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/572.png"
    },
    "8RobU8K9psFQ7fmuHYNfjeYka5jspbjQUd5oYHL228uq": {
        "mint": "8RobU8K9psFQ7fmuHYNfjeYka5jspbjQUd5oYHL228uq",
        "name": "Boryoku Dragonz #575",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/575.png"
    },
    "B4pxAnWYh6JDa1zJu8C2XVziEPLV3H93prAz8uMoxp2p": {
        "mint": "B4pxAnWYh6JDa1zJu8C2XVziEPLV3H93prAz8uMoxp2p",
        "name": "Boryoku Dragonz #269",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/269.png"
    },
    "7HWSTa3hjLtuqzTBAyy5j9VjyHWScV3ZYAR7QSREZe91": {
        "mint": "7HWSTa3hjLtuqzTBAyy5j9VjyHWScV3ZYAR7QSREZe91",
        "name": "Boryoku Dragonz #571",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/571.png"
    },
    "35CNHFpNWubgmSebQqUzh5y4kxruxjCga4AQrHVbodLm": {
        "mint": "35CNHFpNWubgmSebQqUzh5y4kxruxjCga4AQrHVbodLm",
        "name": "Boryoku Dragonz #262",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/262.png"
    },
    "AtkscKhFKC31UANN959MZnv9kXbnkcMNvggwjpucPJ7z": {
        "mint": "AtkscKhFKC31UANN959MZnv9kXbnkcMNvggwjpucPJ7z",
        "name": "Boryoku Dragonz #576",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/576.png"
    },
    "CtWBuW1toxS5Miocg8HGkCbbFKbibyZwTSxBdRp1xfnp": {
        "mint": "CtWBuW1toxS5Miocg8HGkCbbFKbibyZwTSxBdRp1xfnp",
        "name": "Boryoku Dragonz #265",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/265.png"
    },
    "DnHoLqHgXjznYB3YQSviCiDMVzY87vh3vqFSskHduVam": {
        "mint": "DnHoLqHgXjznYB3YQSviCiDMVzY87vh3vqFSskHduVam",
        "name": "Boryoku Dragonz #569",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/569.png"
    },
    "EQTv7d3UQ4jFSJ2yDyvXTVczyWBXC4qxzzndTrtQqmsq": {
        "mint": "EQTv7d3UQ4jFSJ2yDyvXTVczyWBXC4qxzzndTrtQqmsq",
        "name": "Boryoku Dragonz #242",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/242.png"
    },
    "ChbqaoyyXv3zob9KNUDepCLEtQbfYvm6eEZYhPCkZpwR": {
        "mint": "ChbqaoyyXv3zob9KNUDepCLEtQbfYvm6eEZYhPCkZpwR",
        "name": "Boryoku Dragonz #570",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/570.png"
    },
    "CUxg3KQXSCTP2ojTKoiAmSoruLWrAogNRbxVY7wPCijj": {
        "mint": "CUxg3KQXSCTP2ojTKoiAmSoruLWrAogNRbxVY7wPCijj",
        "name": "Boryoku Dragonz #263",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/263.png"
    },
    "6t6CJuBMnZQxWjsAei3DPYdqVyjeNhodK4g1ZHDGGEbq": {
        "mint": "6t6CJuBMnZQxWjsAei3DPYdqVyjeNhodK4g1ZHDGGEbq",
        "name": "Boryoku Dragonz #568",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/568.png"
    },
    "4NhaZpUbG4tbS4zyjh6AHzQechX6qA6cuMRihkmcu4SD": {
        "mint": "4NhaZpUbG4tbS4zyjh6AHzQechX6qA6cuMRihkmcu4SD",
        "name": "Boryoku Dragonz #567",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/567.png"
    },
    "8XbwdargC5Vb4BE3LeC744HgSBLFC112f7P2wM53j4e3": {
        "mint": "8XbwdargC5Vb4BE3LeC744HgSBLFC112f7P2wM53j4e3",
        "name": "Boryoku Dragonz #251",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/251.png"
    },
    "2JicLmSjJDt5K4rFCEq6bk3ddt83719LScTwr4zgkLXs": {
        "mint": "2JicLmSjJDt5K4rFCEq6bk3ddt83719LScTwr4zgkLXs",
        "name": "Boryoku Dragonz #565",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/565.png"
    },
    "FCEPydeaZJN65yrjrh9GxRR2zY6YUBedeVaHcLH4sBS7": {
        "mint": "FCEPydeaZJN65yrjrh9GxRR2zY6YUBedeVaHcLH4sBS7",
        "name": "Boryoku Dragonz #260",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/260.png"
    },
    "CgxNMRmftYdwRbdiKqMnz25nvPjj5QpvrPifFQS52xxD": {
        "mint": "CgxNMRmftYdwRbdiKqMnz25nvPjj5QpvrPifFQS52xxD",
        "name": "Boryoku Dragonz #564",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/564.png"
    },
    "CRyMkj2Jc3xdL61t8Sb46U6RybTVABTtMF5sitLB5fS5": {
        "mint": "CRyMkj2Jc3xdL61t8Sb46U6RybTVABTtMF5sitLB5fS5",
        "name": "Boryoku Dragonz #254",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/254.png"
    },
    "AKbqiAHUQhSzK9TB9dvexTJT69UA9uPdPQaJJQMH25KG": {
        "mint": "AKbqiAHUQhSzK9TB9dvexTJT69UA9uPdPQaJJQMH25KG",
        "name": "Boryoku Dragonz #566",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/566.png"
    },
    "7RLG3XpwGHnagSXgf4egkvr93t3wNvJ2dcFATfWcE5gE": {
        "mint": "7RLG3XpwGHnagSXgf4egkvr93t3wNvJ2dcFATfWcE5gE",
        "name": "Boryoku Dragonz #245",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/245.png"
    },
    "FV8g9mrXV3B9xoCUs3BQ3cqVxGMedKyNQF8MvVwUuUdZ": {
        "mint": "FV8g9mrXV3B9xoCUs3BQ3cqVxGMedKyNQF8MvVwUuUdZ",
        "name": "Boryoku Dragonz #563",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/563.png"
    },
    "66cjvhcUmutiw5cb9EUmwVUZ4Q4mNCP7NGCapfWGzSov": {
        "mint": "66cjvhcUmutiw5cb9EUmwVUZ4Q4mNCP7NGCapfWGzSov",
        "name": "Boryoku Dragonz #244",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/244.png"
    },
    "737cWqP51P3s5kZTxwnu3QuxwUr2yZ44NrpPVqoihZ9N": {
        "mint": "737cWqP51P3s5kZTxwnu3QuxwUr2yZ44NrpPVqoihZ9N",
        "name": "Boryoku Dragonz #562",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/562.png"
    },
    "8Kn6gQuwztXPMpDG7asGbgHnM94uoceS845Hdm3oLgE": {
        "mint": "8Kn6gQuwztXPMpDG7asGbgHnM94uoceS845Hdm3oLgE",
        "name": "Boryoku Dragonz #270",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/270.png"
    },
    "9x9g27rZ96JcodLJGmrehyKZrkhkEvKk1iL2j14Q6Xvu": {
        "mint": "9x9g27rZ96JcodLJGmrehyKZrkhkEvKk1iL2j14Q6Xvu",
        "name": "Boryoku Dragonz #561",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/561.png"
    },
    "8437rD3WsQu3GCExxx4rip9qCy94VnbfjKCkqBfAb4pi": {
        "mint": "8437rD3WsQu3GCExxx4rip9qCy94VnbfjKCkqBfAb4pi",
        "name": "Boryoku Dragonz #264",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/264.png"
    },
    "3fVz9mJHuBqPWa7W7CBKL2v3yktqs8NnRoyA6sdze3m4": {
        "mint": "3fVz9mJHuBqPWa7W7CBKL2v3yktqs8NnRoyA6sdze3m4",
        "name": "Boryoku Dragonz #560",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/560.png"
    },
    "B9Qb3S1ACqzPmZBRnzq9PVxhkZrfFh4tre3QJiqcfX2H": {
        "mint": "B9Qb3S1ACqzPmZBRnzq9PVxhkZrfFh4tre3QJiqcfX2H",
        "name": "Boryoku Dragonz #248",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/248.png"
    },
    "EnBMDuMRdCCdhzRd58TTCvXr9AGS2HC6hBEkryzgmjgG": {
        "mint": "EnBMDuMRdCCdhzRd58TTCvXr9AGS2HC6hBEkryzgmjgG",
        "name": "Boryoku Dragonz #559",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/559.png"
    },
    "3za3eS8TgavSVdEySUwM7rp3JUkpvKzsWhakPfCd2XAv": {
        "mint": "3za3eS8TgavSVdEySUwM7rp3JUkpvKzsWhakPfCd2XAv",
        "name": "Boryoku Dragonz #249",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/249.png"
    },
    "A1YJezMR2YFKWwpnsNnLV9NJmnMF9tuzUAEt8ok2cvpy": {
        "mint": "A1YJezMR2YFKWwpnsNnLV9NJmnMF9tuzUAEt8ok2cvpy",
        "name": "Boryoku Dragonz #556",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/556.png"
    },
    "6otWjggngF7LSzaeWzLhhrtVmfQrKik7pE9yBpeq2o6o": {
        "mint": "6otWjggngF7LSzaeWzLhhrtVmfQrKik7pE9yBpeq2o6o",
        "name": "Boryoku Dragonz #268",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/268.png"
    },
    "6vaZvCuTeNvFvEc2wmRnYCh4q4vMhtECSEQE3fWUYC9R": {
        "mint": "6vaZvCuTeNvFvEc2wmRnYCh4q4vMhtECSEQE3fWUYC9R",
        "name": "Boryoku Dragonz #558",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/558.png"
    },
    "5mJxgRk8eVxFUSpSdjD5gGyrNkWUA6cg2uc2nAErCjCe": {
        "mint": "5mJxgRk8eVxFUSpSdjD5gGyrNkWUA6cg2uc2nAErCjCe",
        "name": "Boryoku Dragonz #557",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/557.png"
    },
    "FBTbSYhvf3n3mwwwXvmDyVSEgQrGNZQKAbctA3hW6dF1": {
        "mint": "FBTbSYhvf3n3mwwwXvmDyVSEgQrGNZQKAbctA3hW6dF1",
        "name": "Boryoku Dragonz #250",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/250.png"
    },
    "42scMu6XiXepsNCrhoe1tNkY4UdyoaJRrf778aqZwLGq": {
        "mint": "42scMu6XiXepsNCrhoe1tNkY4UdyoaJRrf778aqZwLGq",
        "name": "Boryoku Dragonz #554",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/554.png"
    },
    "3nYgGJKjJu1LBXV4Z6Cfx4TGrBZ7cJ9DCfxuB7NRJVwH": {
        "mint": "3nYgGJKjJu1LBXV4Z6Cfx4TGrBZ7cJ9DCfxuB7NRJVwH",
        "name": "Boryoku Dragonz #266",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/266.png"
    },
    "6ouEwtKb8kHthcguLDpNhK3Q68iHAqrPZyCdzXChse5x": {
        "mint": "6ouEwtKb8kHthcguLDpNhK3Q68iHAqrPZyCdzXChse5x",
        "name": "Boryoku Dragonz #555",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/555.png"
    },
    "2Dwemx5pr21ffYLhPpewNpQuDYfR3PQJttij9ShpWGuS": {
        "mint": "2Dwemx5pr21ffYLhPpewNpQuDYfR3PQJttij9ShpWGuS",
        "name": "Boryoku Dragonz #259",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/259.png"
    },
    "DMT74mSizBRB3cPfYeZprxRTDMkrQNbNc3HE6Wgagqsx": {
        "mint": "DMT74mSizBRB3cPfYeZprxRTDMkrQNbNc3HE6Wgagqsx",
        "name": "Boryoku Dragonz #256",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/256.png"
    },
    "8WiiQUF2MiETWRREb9G1eC63MQW1mAhoGkrBkTFX8XiC": {
        "mint": "8WiiQUF2MiETWRREb9G1eC63MQW1mAhoGkrBkTFX8XiC",
        "name": "Boryoku Dragonz #553",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/553.png"
    },
    "DLVoAo6rTuar14WnacpreLxYYe3ByB83tRGB6ccARnK2": {
        "mint": "DLVoAo6rTuar14WnacpreLxYYe3ByB83tRGB6ccARnK2",
        "name": "Boryoku Dragonz #261",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/261.png"
    },
    "BLasJvRY421aD87mdyNni9T4zZgvXvkMyv9C9xQp9Twz": {
        "mint": "BLasJvRY421aD87mdyNni9T4zZgvXvkMyv9C9xQp9Twz",
        "name": "Boryoku Dragonz #551",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/551.png"
    },
    "FYNCRVJ2rmcV9SNuJB5YhfpVcxcQJg6u92t1MDdC714E": {
        "mint": "FYNCRVJ2rmcV9SNuJB5YhfpVcxcQJg6u92t1MDdC714E",
        "name": "Boryoku Dragonz #247",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/247.png"
    },
    "3d5Q2RLeKDWMUcLXV11rBG4fxPVsW9JxVppzussq1Res": {
        "mint": "3d5Q2RLeKDWMUcLXV11rBG4fxPVsW9JxVppzussq1Res",
        "name": "Boryoku Dragonz #549",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/549.png"
    },
    "HTzhWgREDfkS6ybU25sXw5SZZKi7izSEZgJNjqYwsHwL": {
        "mint": "HTzhWgREDfkS6ybU25sXw5SZZKi7izSEZgJNjqYwsHwL",
        "name": "Boryoku Dragonz #257",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/257.png"
    },
    "55MSf9bMED12j3cLfFv7ppUaGMASUsDkqzMzTPmctnkB": {
        "mint": "55MSf9bMED12j3cLfFv7ppUaGMASUsDkqzMzTPmctnkB",
        "name": "Boryoku Dragonz #550",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/550.png"
    },
    "DCNdSFwKLnoibCgJ8LU5Kdvpzjq8kcXYnRvU5a4R4E53": {
        "mint": "DCNdSFwKLnoibCgJ8LU5Kdvpzjq8kcXYnRvU5a4R4E53",
        "name": "Boryoku Dragonz #253",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/253.png"
    },
    "93tx4HuQjk2So2pLnKZQrRMNo2ZxatSUmRJerB3hoXQS": {
        "mint": "93tx4HuQjk2So2pLnKZQrRMNo2ZxatSUmRJerB3hoXQS",
        "name": "Boryoku Dragonz #552",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/552.png"
    },
    "4EBKvyzLZ6SVgREPL27oySSPTCPZ7NkFX32fqrSvcWaC": {
        "mint": "4EBKvyzLZ6SVgREPL27oySSPTCPZ7NkFX32fqrSvcWaC",
        "name": "Boryoku Dragonz #255",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/255.png"
    },
    "XGyPM78YSYkJ7J53VuQn3yFsC1KaW7nwDWiwoej8BUT": {
        "mint": "XGyPM78YSYkJ7J53VuQn3yFsC1KaW7nwDWiwoej8BUT",
        "name": "Boryoku Dragonz #548",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/548.png"
    },
    "6pdqHV66Bp5dPNwD6XvZpc4LQTSCri4vA5yMX9SDMyq4": {
        "mint": "6pdqHV66Bp5dPNwD6XvZpc4LQTSCri4vA5yMX9SDMyq4",
        "name": "Boryoku Dragonz #267",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/267.png"
    },
    "B9JitkrQjfaTiH4U7kiYt6zQzSjwYBVhGA9Cd8Yu5M7B": {
        "mint": "B9JitkrQjfaTiH4U7kiYt6zQzSjwYBVhGA9Cd8Yu5M7B",
        "name": "Boryoku Dragonz #545",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/545.png"
    },
    "9qNgM5RA2878X7ndddsKzuh9pj8DJnSgNPZXEHVQwE9M": {
        "mint": "9qNgM5RA2878X7ndddsKzuh9pj8DJnSgNPZXEHVQwE9M",
        "name": "Boryoku Dragonz #243",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/243.png"
    },
    "C8FscNN86X3ZtSRJHu4qfbQhs8Qk2MwdeTWqGcsgbWBe": {
        "mint": "C8FscNN86X3ZtSRJHu4qfbQhs8Qk2MwdeTWqGcsgbWBe",
        "name": "Boryoku Dragonz #273",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/273.png"
    },
    "4uLXUvttFK1TKUGmAhGcCNkvUzptsFKEuA5GE3dyBxkH": {
        "mint": "4uLXUvttFK1TKUGmAhGcCNkvUzptsFKEuA5GE3dyBxkH",
        "name": "Boryoku Dragonz #547",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/547.png"
    },
    "3Z2ggF3RxUkGSRDF64G2QatEsa8Yxqqgvh9PpFENNbTR": {
        "mint": "3Z2ggF3RxUkGSRDF64G2QatEsa8Yxqqgvh9PpFENNbTR",
        "name": "Boryoku Dragonz #278",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/278.png"
    },
    "CrAq3M5DgTpa2ipDzKamz5iMYrFcsjKsYcwbnLyCiVVZ": {
        "mint": "CrAq3M5DgTpa2ipDzKamz5iMYrFcsjKsYcwbnLyCiVVZ",
        "name": "Boryoku Dragonz #544",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/544.png"
    },
    "B8D1M7iH4TCG8ty8GateXLRNddbvBdLm7eJSc3X4xAgq": {
        "mint": "B8D1M7iH4TCG8ty8GateXLRNddbvBdLm7eJSc3X4xAgq",
        "name": "Boryoku Dragonz #277",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/277.png"
    },
    "75RPwxisuHZQR8JB1AEb4osNSaqcyXyfKH1VjMhNuEgX": {
        "mint": "75RPwxisuHZQR8JB1AEb4osNSaqcyXyfKH1VjMhNuEgX",
        "name": "Boryoku Dragonz #546",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/546.png"
    },
    "4TBTeesHj7HkzwSeA6Q9rCYtKLLkT8L7NREQFDn4wVFQ": {
        "mint": "4TBTeesHj7HkzwSeA6Q9rCYtKLLkT8L7NREQFDn4wVFQ",
        "name": "Boryoku Dragonz #290",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/290.png"
    },
    "ABmpg1VQHL3Za2RAT6Sbpby9QjN7eV2JtxPbJkTSin8L": {
        "mint": "ABmpg1VQHL3Za2RAT6Sbpby9QjN7eV2JtxPbJkTSin8L",
        "name": "Boryoku Dragonz #543",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/543.png"
    },
    "DUjpNJWyke7rX7yRZX7QVR5oMx3T2cN3nSGPBgavBWPd": {
        "mint": "DUjpNJWyke7rX7yRZX7QVR5oMx3T2cN3nSGPBgavBWPd",
        "name": "Boryoku Dragonz #541",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/541.png"
    },
    "ASvbVHjzGwtBZp676Jo13Wjv2BJaBK4BkHtCTdgy6cSW": {
        "mint": "ASvbVHjzGwtBZp676Jo13Wjv2BJaBK4BkHtCTdgy6cSW",
        "name": "Boryoku Dragonz #294",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/294.png"
    },
    "12CTQgmkLRmngbBjEwXoCyvAhrNLmgFtzGEaGvwUwjKy": {
        "mint": "12CTQgmkLRmngbBjEwXoCyvAhrNLmgFtzGEaGvwUwjKy",
        "name": "Boryoku Dragonz #542",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/542.png"
    },
    "E4aGnaBdF8fNv9VUpkCiTDw8dm6rZaEZL25fHxJbioXa": {
        "mint": "E4aGnaBdF8fNv9VUpkCiTDw8dm6rZaEZL25fHxJbioXa",
        "name": "Boryoku Dragonz #283",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/283.png"
    },
    "BWYuvnwDzS89hZo1JfJc1EDhL4kuM38vwJPtPTbB2EtB": {
        "mint": "BWYuvnwDzS89hZo1JfJc1EDhL4kuM38vwJPtPTbB2EtB",
        "name": "Boryoku Dragonz #539",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/539.png"
    },
    "53A13Vdirpd8uPpqify9U4CYue4kNthXHbnRGGVf3Yrr": {
        "mint": "53A13Vdirpd8uPpqify9U4CYue4kNthXHbnRGGVf3Yrr",
        "name": "Boryoku Dragonz #274",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/274.png"
    },
    "HQhf9ke2Drt37A7aGUo9GjLEjDeJraxrX3wpsofn55Sd": {
        "mint": "HQhf9ke2Drt37A7aGUo9GjLEjDeJraxrX3wpsofn55Sd",
        "name": "Boryoku Dragonz #538",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/538.png"
    },
    "J22ewB6QC1HV7ShsZL9avePCP5eP5k3NTyejyUu1PYAA": {
        "mint": "J22ewB6QC1HV7ShsZL9avePCP5eP5k3NTyejyUu1PYAA",
        "name": "Boryoku Dragonz #287",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/287.png"
    },
    "5UJvyhCsum71CK9ERrH8hcLw6aWzh4SjwWXToquWdWR3": {
        "mint": "5UJvyhCsum71CK9ERrH8hcLw6aWzh4SjwWXToquWdWR3",
        "name": "Boryoku Dragonz #282",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/282.png"
    },
    "9F53QGygxjm8CQ4JMAGa37dMBdeoEq1uetg5rT2mMwwP": {
        "mint": "9F53QGygxjm8CQ4JMAGa37dMBdeoEq1uetg5rT2mMwwP",
        "name": "Boryoku Dragonz #537",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/537.png"
    },
    "AXoqkqG2sHZC3u7SR1Mrk2QePgsJu7HS7Zx9tbcUHJUV": {
        "mint": "AXoqkqG2sHZC3u7SR1Mrk2QePgsJu7HS7Zx9tbcUHJUV",
        "name": "Boryoku Dragonz #292",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/292.png"
    },
    "71KZj4Uio3ZTJeT2VTTjZ1YPtScP6SeLDxYBpewJgpyk": {
        "mint": "71KZj4Uio3ZTJeT2VTTjZ1YPtScP6SeLDxYBpewJgpyk",
        "name": "Boryoku Dragonz #540",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/540.png"
    },
    "4NW1P7Bk4UjphevwZkkdMxuuYr3KhSks1RmFyFtjVaUZ": {
        "mint": "4NW1P7Bk4UjphevwZkkdMxuuYr3KhSks1RmFyFtjVaUZ",
        "name": "Boryoku Dragonz #272",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/272.png"
    },
    "9q3TtHRTxX9NqbTjhQreHkEFDWGRCw4zFjTB5TN45MY9": {
        "mint": "9q3TtHRTxX9NqbTjhQreHkEFDWGRCw4zFjTB5TN45MY9",
        "name": "Boryoku Dragonz #534",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/534.png"
    },
    "9fz5RdVokHVshtZPPFbRittXdFyFeoeHiNHhfPaLaqXx": {
        "mint": "9fz5RdVokHVshtZPPFbRittXdFyFeoeHiNHhfPaLaqXx",
        "name": "Boryoku Dragonz #291",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/291.png"
    },
    "6ewLjYQQ2rqySf7SJdJfxDUkDTskpjWuCi1Nr5UiZiSZ": {
        "mint": "6ewLjYQQ2rqySf7SJdJfxDUkDTskpjWuCi1Nr5UiZiSZ",
        "name": "Boryoku Dragonz #533",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/533.png"
    },
    "3moWV9RMrW1ZyLWH1mU4RGJ6i6vnRRQGENHir4a4d8j6": {
        "mint": "3moWV9RMrW1ZyLWH1mU4RGJ6i6vnRRQGENHir4a4d8j6",
        "name": "Boryoku Dragonz #275",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/275.png"
    },
    "BpkWAsZ9ojCgGoC2ramdW711Z3pCX5ut372obvB1nXs5": {
        "mint": "BpkWAsZ9ojCgGoC2ramdW711Z3pCX5ut372obvB1nXs5",
        "name": "Boryoku Dragonz #536",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/536.png"
    },
    "3S9auyQgJGxREpmcvcdvyQykQnYk2GcSRLJxyKAL7yxt": {
        "mint": "3S9auyQgJGxREpmcvcdvyQykQnYk2GcSRLJxyKAL7yxt",
        "name": "Boryoku Dragonz #285",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/285.png"
    },
    "Fx3RD7ZySckiB5EwjarkoiS9nHozcnHBtYnuJt9cXtNe": {
        "mint": "Fx3RD7ZySckiB5EwjarkoiS9nHozcnHBtYnuJt9cXtNe",
        "name": "Boryoku Dragonz #535",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/535.png"
    },
    "7ESnLjG2pgC9xo3iyaLihv2a1tHahQkSUD8ntYoByrbB": {
        "mint": "7ESnLjG2pgC9xo3iyaLihv2a1tHahQkSUD8ntYoByrbB",
        "name": "Boryoku Dragonz #284",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/284.png"
    },
    "4hVfmgxv3rpfjNhatgoPHMWBcw7ogQ2yzRtVa1qT5gE8": {
        "mint": "4hVfmgxv3rpfjNhatgoPHMWBcw7ogQ2yzRtVa1qT5gE8",
        "name": "Boryoku Dragonz #532",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/532.png"
    },
    "4t4zdc7Pjo41rEK1fuzPiE7cw7TJbRFgkmGMguuYFs28": {
        "mint": "4t4zdc7Pjo41rEK1fuzPiE7cw7TJbRFgkmGMguuYFs28",
        "name": "Boryoku Dragonz #295",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/295.png"
    },
    "AsLkJY1Z4Axzz9ZHTgn94KccbbiTaVZ2SxJxL4nfMs4q": {
        "mint": "AsLkJY1Z4Axzz9ZHTgn94KccbbiTaVZ2SxJxL4nfMs4q",
        "name": "Boryoku Dragonz #528",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/528.png"
    },
    "GoJbrwx4rMbdx3qnmYsjZq5ih11dYUQNgWfSuNtQwqvD": {
        "mint": "GoJbrwx4rMbdx3qnmYsjZq5ih11dYUQNgWfSuNtQwqvD",
        "name": "Boryoku Dragonz #296",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/296.png"
    },
    "BYZDDoFDAqh6sd1oyLCjFMyMgTEVFAUyuVHHnfvQLwEv": {
        "mint": "BYZDDoFDAqh6sd1oyLCjFMyMgTEVFAUyuVHHnfvQLwEv",
        "name": "Boryoku Dragonz #529",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/529.png"
    },
    "9GwktQsGE6zQPLqXoG3RVdtU2SaxTaFHpTf6HN113ELc": {
        "mint": "9GwktQsGE6zQPLqXoG3RVdtU2SaxTaFHpTf6HN113ELc",
        "name": "Boryoku Dragonz #288",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/288.png"
    },
    "7bkxQ6fgKtM8vbNCDAQz7nFRVdSWyZU3Ydd8xS6BT9t2": {
        "mint": "7bkxQ6fgKtM8vbNCDAQz7nFRVdSWyZU3Ydd8xS6BT9t2",
        "name": "Boryoku Dragonz #530",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/530.png"
    },
    "3JQBGFWqqx7tgHfh65Pgn9dwEdRsL2QLXc35GSM8cwh8": {
        "mint": "3JQBGFWqqx7tgHfh65Pgn9dwEdRsL2QLXc35GSM8cwh8",
        "name": "Boryoku Dragonz #276",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/276.png"
    },
    "5Lo5Top9J9LqToi6jvS8Mxcen1ZdRsKuHmRMMQ5wXjL8": {
        "mint": "5Lo5Top9J9LqToi6jvS8Mxcen1ZdRsKuHmRMMQ5wXjL8",
        "name": "Boryoku Dragonz #531",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/531.png"
    },
    "32dSEJEponvcfFT3C5TyBcdH5GKPVSjUFdQyKjPk8AxZ": {
        "mint": "32dSEJEponvcfFT3C5TyBcdH5GKPVSjUFdQyKjPk8AxZ",
        "name": "Boryoku Dragonz #289",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/289.png"
    },
    "28q1Y8PLjDFeaEcmDMo7FZYK31w5goN4E9YHo7B7Nutc": {
        "mint": "28q1Y8PLjDFeaEcmDMo7FZYK31w5goN4E9YHo7B7Nutc",
        "name": "Boryoku Dragonz #526",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/526.png"
    },
    "5Tk6TKNbLN74NzKrQnsMJB1pQ4MaM4n5RDpukZa7ttsX": {
        "mint": "5Tk6TKNbLN74NzKrQnsMJB1pQ4MaM4n5RDpukZa7ttsX",
        "name": "Boryoku Dragonz #298",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/298.png"
    },
    "5PHkYsaLPFiZHVxygT3aKLUjckJ2cmXFpmHLdFv3QbCz": {
        "mint": "5PHkYsaLPFiZHVxygT3aKLUjckJ2cmXFpmHLdFv3QbCz",
        "name": "Boryoku Dragonz #527",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/527.png"
    },
    "Bi1xt2uvPQGwzU2VthfpoH6SGuXGbGwt1HbAzTihTpbc": {
        "mint": "Bi1xt2uvPQGwzU2VthfpoH6SGuXGbGwt1HbAzTihTpbc",
        "name": "Boryoku Dragonz #279",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/279.png"
    },
    "5JaALdAPD6aiyhNsyk4FW9mPymvCF5y81uZj5PGizPo8": {
        "mint": "5JaALdAPD6aiyhNsyk4FW9mPymvCF5y81uZj5PGizPo8",
        "name": "Boryoku Dragonz #297",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/297.png"
    },
    "CXhdhtqXCaPZpRYAU3DgpbPeEuYBfxzzarsRMYxMEzu1": {
        "mint": "CXhdhtqXCaPZpRYAU3DgpbPeEuYBfxzzarsRMYxMEzu1",
        "name": "Boryoku Dragonz #525",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/525.png"
    },
    "DaNmH361uuFRcd9Q4wybLpXHDVTM3qjFS2ES1EAsDiS6": {
        "mint": "DaNmH361uuFRcd9Q4wybLpXHDVTM3qjFS2ES1EAsDiS6",
        "name": "Boryoku Dragonz #293",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/293.png"
    },
    "48NazrdGNzQryZHYG7LvYdYTN77f9UHbepKHrwinPMrH": {
        "mint": "48NazrdGNzQryZHYG7LvYdYTN77f9UHbepKHrwinPMrH",
        "name": "Boryoku Dragonz #524",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/524.png"
    },
    "4oy8wfLaCwS8Lp59wZJbJAFuYs2JqoM3CpevqT3MPgX9": {
        "mint": "4oy8wfLaCwS8Lp59wZJbJAFuYs2JqoM3CpevqT3MPgX9",
        "name": "Boryoku Dragonz #271",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/271.png"
    },
    "2C4ceNdLj7HcSN3UD7VdVrET65wvgbLuB3sMr2p1WJ5Q": {
        "mint": "2C4ceNdLj7HcSN3UD7VdVrET65wvgbLuB3sMr2p1WJ5Q",
        "name": "Boryoku Dragonz #286",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/286.png"
    },
    "Do31eBFgDR8h8WDMge5kU13Ga8UBNGuoQdu9eKzfd7UA": {
        "mint": "Do31eBFgDR8h8WDMge5kU13Ga8UBNGuoQdu9eKzfd7UA",
        "name": "Boryoku Dragonz #523",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/523.png"
    },
    "4NqesyibMnDXiUxbRdENMTffzdpagzSaMGAWzeBLGzZq": {
        "mint": "4NqesyibMnDXiUxbRdENMTffzdpagzSaMGAWzeBLGzZq",
        "name": "Boryoku Dragonz #280",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/280.png"
    },
    "6vJjSRybdWzqdivFagiHmiSP1c4fCyGtbfN8EPR2wUDA": {
        "mint": "6vJjSRybdWzqdivFagiHmiSP1c4fCyGtbfN8EPR2wUDA",
        "name": "Boryoku Dragonz #281",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/281.png"
    },
    "AN57pMFusKUkwYbRaYZMektoQ5CobhhLdHuHXRAhKBYi": {
        "mint": "AN57pMFusKUkwYbRaYZMektoQ5CobhhLdHuHXRAhKBYi",
        "name": "Boryoku Dragonz #522",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/522.png"
    },
    "2r7YhyXGZpbsPyXGcjSzqTGEW1U3xcUeLLN7QFHw21yg": {
        "mint": "2r7YhyXGZpbsPyXGcjSzqTGEW1U3xcUeLLN7QFHw21yg",
        "name": "Boryoku Dragonz #312",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/312.png"
    },
    "7sCDBxMgk6j96fpVLHv6fAJfnpj1JKmZwcc1c2KGhkMy": {
        "mint": "7sCDBxMgk6j96fpVLHv6fAJfnpj1JKmZwcc1c2KGhkMy",
        "name": "Boryoku Dragonz #520",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/520.png"
    },
    "5uGUPv6CcvrCzpDqd5WMB9UtTAGqYg5eC7HfP3Fy3Gya": {
        "mint": "5uGUPv6CcvrCzpDqd5WMB9UtTAGqYg5eC7HfP3Fy3Gya",
        "name": "Boryoku Dragonz #318",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/318.png"
    },
    "8bF4bymCzC8Crqtvp73d1sWfPoHBahrfK7zLPfSSYNCJ": {
        "mint": "8bF4bymCzC8Crqtvp73d1sWfPoHBahrfK7zLPfSSYNCJ",
        "name": "Boryoku Dragonz #320",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/320.png"
    },
    "FiAHk4mJH78fEAk5Ri6RfGzX5YYSEBUVEFJKPAzyBPJA": {
        "mint": "FiAHk4mJH78fEAk5Ri6RfGzX5YYSEBUVEFJKPAzyBPJA",
        "name": "Boryoku Dragonz #517",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/517.png"
    },
    "2jwwDfJAM3h5BVS1ouvmYqRXa2iSpXhb6e89f7WywF7N": {
        "mint": "2jwwDfJAM3h5BVS1ouvmYqRXa2iSpXhb6e89f7WywF7N",
        "name": "Boryoku Dragonz #309",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/309.png"
    },
    "sz9QadiCWQKkKJHn8ymjFjnzAUTy64SgNWT5QcZ6UZC": {
        "mint": "sz9QadiCWQKkKJHn8ymjFjnzAUTy64SgNWT5QcZ6UZC",
        "name": "Boryoku Dragonz #519",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/519.png"
    },
    "HmXi3ZCR25NDx3RSNrb51i58kkgigcEiC3qNXZNWn74x": {
        "mint": "HmXi3ZCR25NDx3RSNrb51i58kkgigcEiC3qNXZNWn74x",
        "name": "Boryoku Dragonz #317",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/317.png"
    },
    "DhJrVLcEpnuQJcnn3KSsdMaG1C1YK1UJBqV4y46vp5Ma": {
        "mint": "DhJrVLcEpnuQJcnn3KSsdMaG1C1YK1UJBqV4y46vp5Ma",
        "name": "Boryoku Dragonz #518",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/518.png"
    },
    "9s8oJXNFMCM686ewrUQQM3pMfQ53sSdHmR811qHokyx7": {
        "mint": "9s8oJXNFMCM686ewrUQQM3pMfQ53sSdHmR811qHokyx7",
        "name": "Boryoku Dragonz #315",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/315.png"
    },
    "4kJtK7xaZ8YoNfYCso67uBNTnnHJ8vrXBaUFS1SP7nN2": {
        "mint": "4kJtK7xaZ8YoNfYCso67uBNTnnHJ8vrXBaUFS1SP7nN2",
        "name": "Boryoku Dragonz #521",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/521.png"
    },
    "76H4CkNrL94zycUCAM117hjLd9QZ6cXo8VQ2M7M63Agh": {
        "mint": "76H4CkNrL94zycUCAM117hjLd9QZ6cXo8VQ2M7M63Agh",
        "name": "Boryoku Dragonz #322",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/322.png"
    },
    "7WPL7kG95jwxBHeBoFKeRXMUqwv8PEJeDkBAQ27G8eEF": {
        "mint": "7WPL7kG95jwxBHeBoFKeRXMUqwv8PEJeDkBAQ27G8eEF",
        "name": "Boryoku Dragonz #310",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/310.png"
    },
    "EaAGd5EmzeZtjggsZLjWPiEKZY9wKy3P3QT5iBTPRLDh": {
        "mint": "EaAGd5EmzeZtjggsZLjWPiEKZY9wKy3P3QT5iBTPRLDh",
        "name": "Boryoku Dragonz #513",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/513.png"
    },
    "BG97Ru76eDiasUQW9dKL4eVuC7DbHWzYX6bj7r4j27NH": {
        "mint": "BG97Ru76eDiasUQW9dKL4eVuC7DbHWzYX6bj7r4j27NH",
        "name": "Boryoku Dragonz #316",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/316.png"
    },
    "GoBTZuBqDZf7PYpcQBSSQ4FDBydYaqsRB5khrUjURdp1": {
        "mint": "GoBTZuBqDZf7PYpcQBSSQ4FDBydYaqsRB5khrUjURdp1",
        "name": "Boryoku Dragonz #515",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/515.png"
    },
    "6F11QgFa8ZJx7PQK2tQ9d8LwhV4VknAp8PmdSqXhp9xp": {
        "mint": "6F11QgFa8ZJx7PQK2tQ9d8LwhV4VknAp8PmdSqXhp9xp",
        "name": "Boryoku Dragonz #311",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/311.png"
    },
    "8CdVGzetdvPjt9RTNUG6F58cWQ1xvevw1JXn5F8KQci7": {
        "mint": "8CdVGzetdvPjt9RTNUG6F58cWQ1xvevw1JXn5F8KQci7",
        "name": "Boryoku Dragonz #514",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/514.png"
    },
    "E3W9W6iMKx2kDNRn8JXkk63vUA5tLRYKJLZgntXnUEM5": {
        "mint": "E3W9W6iMKx2kDNRn8JXkk63vUA5tLRYKJLZgntXnUEM5",
        "name": "Boryoku Dragonz #305",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/305.png"
    },
    "9Xsnz3hnAPBdHpCnbTX5YUE1vSNJZHsv1evTCcS5o2P1": {
        "mint": "9Xsnz3hnAPBdHpCnbTX5YUE1vSNJZHsv1evTCcS5o2P1",
        "name": "Boryoku Dragonz #516",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/516.png"
    },
    "9hmq1837frBo4u1oS8yPNxQRKv8WYsHCvHC4FEY6KqLL": {
        "mint": "9hmq1837frBo4u1oS8yPNxQRKv8WYsHCvHC4FEY6KqLL",
        "name": "Boryoku Dragonz #314",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/314.png"
    },
    "AyzMv8kQsKNLTq7rroaQDAxM4C7P15JvRHUbCU38m9Vw": {
        "mint": "AyzMv8kQsKNLTq7rroaQDAxM4C7P15JvRHUbCU38m9Vw",
        "name": "Boryoku Dragonz #511",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/511.png"
    },
    "366vqtKERajbsijkXXSkgG8JFnHBTyQFhLtskHLoaRV4": {
        "mint": "366vqtKERajbsijkXXSkgG8JFnHBTyQFhLtskHLoaRV4",
        "name": "Boryoku Dragonz #308",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/308.png"
    },
    "5YmCDKPNJ4GnrMCcfM5b6wxUma1rQjUuwDVr8DMw8ngP": {
        "mint": "5YmCDKPNJ4GnrMCcfM5b6wxUma1rQjUuwDVr8DMw8ngP",
        "name": "Boryoku Dragonz #512",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/512.png"
    },
    "6MMbwGQnyQ3yYiWSVs8FjomKHV79chjd9yfAwQbq7KwR": {
        "mint": "6MMbwGQnyQ3yYiWSVs8FjomKHV79chjd9yfAwQbq7KwR",
        "name": "Boryoku Dragonz #319",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/319.png"
    },
    "Ef77C9y84fQGPmGRwYu6TSpPyFA1paThCnEUDRL3PcKP": {
        "mint": "Ef77C9y84fQGPmGRwYu6TSpPyFA1paThCnEUDRL3PcKP",
        "name": "Boryoku Dragonz #306",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/306.png"
    },
    "5qfN19WpuR4pidDzRP25Qp4c8b446sG8y4jj44DvBfTs": {
        "mint": "5qfN19WpuR4pidDzRP25Qp4c8b446sG8y4jj44DvBfTs",
        "name": "Boryoku Dragonz #509",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/509.png"
    },
    "Aa1mKAfe9HqM3RNn6nDkXmbwGRsXspzSuwiYfQRFxUJb": {
        "mint": "Aa1mKAfe9HqM3RNn6nDkXmbwGRsXspzSuwiYfQRFxUJb",
        "name": "Boryoku Dragonz #299",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/299.png"
    },
    "jwLox3CqC3KtL1CBaDjXadmRHxn6xMzufdryVd4zHFH": {
        "mint": "jwLox3CqC3KtL1CBaDjXadmRHxn6xMzufdryVd4zHFH",
        "name": "Boryoku Dragonz #510",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/510.png"
    },
    "6NnWpABptp2tybndG5D5LhVrL8pd1HoWKEhGbJ9VPVvn": {
        "mint": "6NnWpABptp2tybndG5D5LhVrL8pd1HoWKEhGbJ9VPVvn",
        "name": "Boryoku Dragonz #301",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/301.png"
    },
    "GjyLDuZYgb2q7nJSFdMUjkUJhCV3geqN2PWFo9LVgRSN": {
        "mint": "GjyLDuZYgb2q7nJSFdMUjkUJhCV3geqN2PWFo9LVgRSN",
        "name": "Boryoku Dragonz #508",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/508.png"
    },
    "2j2Ao5gCiHs9L97qMjptb2ySJCMosYPPKvSHRcXKq4Mt": {
        "mint": "2j2Ao5gCiHs9L97qMjptb2ySJCMosYPPKvSHRcXKq4Mt",
        "name": "Boryoku Dragonz #326",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/326.png"
    },
    "9wgByTiqUhrnw6TeH44mUimMdFWttwxXKuQWP1MePytE": {
        "mint": "9wgByTiqUhrnw6TeH44mUimMdFWttwxXKuQWP1MePytE",
        "name": "Boryoku Dragonz #507",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/507.png"
    },
    "7Lnn6DbAjWZNFRBZjowtmfGfsLKXoLeovRBhyr1h4Jpu": {
        "mint": "7Lnn6DbAjWZNFRBZjowtmfGfsLKXoLeovRBhyr1h4Jpu",
        "name": "Boryoku Dragonz #324",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/324.png"
    },
    "YW2dgoDjr9tTU4rSJkW8peBbfFr5z4Czfdxz91d3BrY": {
        "mint": "YW2dgoDjr9tTU4rSJkW8peBbfFr5z4Czfdxz91d3BrY",
        "name": "Boryoku Dragonz #506",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/506.png"
    },
    "AJVjWKvJcSActB6z8dJh7oVi8Eb9q4oKhGP2HjHqvL6p": {
        "mint": "AJVjWKvJcSActB6z8dJh7oVi8Eb9q4oKhGP2HjHqvL6p",
        "name": "Boryoku Dragonz #505",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/505.png"
    },
    "8XxCzSrUvaiEkjceghuNLiiCxPQ7qErfj9Qx4hBqEg6v": {
        "mint": "8XxCzSrUvaiEkjceghuNLiiCxPQ7qErfj9Qx4hBqEg6v",
        "name": "Boryoku Dragonz #321",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/321.png"
    },
    "9aVcdGxQAq1dDt9ZW2gczLxGH5Aj2tc5yCsygz2WFjTi": {
        "mint": "9aVcdGxQAq1dDt9ZW2gczLxGH5Aj2tc5yCsygz2WFjTi",
        "name": "Boryoku Dragonz #502",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/502.png"
    },
    "DFR2oyoqcJZpyhFcdgC7cPwQTjGPnH3tkF4gydSu82HB": {
        "mint": "DFR2oyoqcJZpyhFcdgC7cPwQTjGPnH3tkF4gydSu82HB",
        "name": "Boryoku Dragonz #303",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/303.png"
    },
    "DsA6cRSgHWcNy9sc1JipDuYApWX1JUV3kgntU4sUSHJo": {
        "mint": "DsA6cRSgHWcNy9sc1JipDuYApWX1JUV3kgntU4sUSHJo",
        "name": "Boryoku Dragonz #504",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/504.png"
    },
    "B3hjapTCFWRHUwfxJt7eyaRLUcrSEuuvS94jRqMJeVS9": {
        "mint": "B3hjapTCFWRHUwfxJt7eyaRLUcrSEuuvS94jRqMJeVS9",
        "name": "Boryoku Dragonz #304",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/304.png"
    },
    "67BAkZi2X6LiA15RWrggnXD3C34TuqS99KGF3gr91jCq": {
        "mint": "67BAkZi2X6LiA15RWrggnXD3C34TuqS99KGF3gr91jCq",
        "name": "Boryoku Dragonz #313",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/313.png"
    },
    "J6xZU31ZHxUWxawZZku2pNR8hkCSLQVqM6Cz8dPJC1Jt": {
        "mint": "J6xZU31ZHxUWxawZZku2pNR8hkCSLQVqM6Cz8dPJC1Jt",
        "name": "Boryoku Dragonz #503",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/503.png"
    },
    "H7UZVDDApgnPAby17Typr9QFjXNqxDKopKFTxpceKKkJ": {
        "mint": "H7UZVDDApgnPAby17Typr9QFjXNqxDKopKFTxpceKKkJ",
        "name": "Boryoku Dragonz #323",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/323.png"
    },
    "5ULwBc9v4cdYU5xcuxtaNGNg2NJiHUU8dHfDKHDRPpPo": {
        "mint": "5ULwBc9v4cdYU5xcuxtaNGNg2NJiHUU8dHfDKHDRPpPo",
        "name": "Boryoku Dragonz #497",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/497.png"
    },
    "AZ363ZpFnRKAM3V4YkVg7S8G7VCBGgvDEGAd6k672H9C": {
        "mint": "AZ363ZpFnRKAM3V4YkVg7S8G7VCBGgvDEGAd6k672H9C",
        "name": "Boryoku Dragonz #307",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/307.png"
    },
    "3jcRKJSQEwm4V4rixQaVEZmzagpWhPZLE85EhJgRkHZD": {
        "mint": "3jcRKJSQEwm4V4rixQaVEZmzagpWhPZLE85EhJgRkHZD",
        "name": "Boryoku Dragonz #500",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/500.png"
    },
    "ryaScDsM7mtzc6Wch71QLphNQE7H9SDDGK4ApjoE83W": {
        "mint": "ryaScDsM7mtzc6Wch71QLphNQE7H9SDDGK4ApjoE83W",
        "name": "Boryoku Dragonz #302",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/302.png"
    },
    "AQazNR2bpBEdEipGiGVQwVfQKrzT3t8KbRLHAHg5LZVz": {
        "mint": "AQazNR2bpBEdEipGiGVQwVfQKrzT3t8KbRLHAHg5LZVz",
        "name": "Boryoku Dragonz #498",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/498.png"
    },
    "GUETASymkdjos8ybYvKegrF2AZbWNiSckV3j4eJjAJz5": {
        "mint": "GUETASymkdjos8ybYvKegrF2AZbWNiSckV3j4eJjAJz5",
        "name": "Boryoku Dragonz #300",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/300.png"
    },
    "CpbSV51YsRQ1oCe8XMUEs5ned7XZaxxsrTL5ovAAqDxg": {
        "mint": "CpbSV51YsRQ1oCe8XMUEs5ned7XZaxxsrTL5ovAAqDxg",
        "name": "Boryoku Dragonz #499",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/499.png"
    },
    "3GR59NBfMEq9PqgLenEPWjnsC3kczpZLztupYjzHJMr8": {
        "mint": "3GR59NBfMEq9PqgLenEPWjnsC3kczpZLztupYjzHJMr8",
        "name": "Boryoku Dragonz #501",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/501.png"
    },
    "ESzr6c2uAZkuxUKWAYRno5LbQcvArumkQ5zi8UC2oBJ": {
        "mint": "ESzr6c2uAZkuxUKWAYRno5LbQcvArumkQ5zi8UC2oBJ",
        "name": "Boryoku Dragonz #325",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/325.png"
    },
    "6hV9Gnwak2jhWLMZ69Pd9x8r3UspeBp77mY6atFLHP1X": {
        "mint": "6hV9Gnwak2jhWLMZ69Pd9x8r3UspeBp77mY6atFLHP1X",
        "name": "Boryoku Dragonz #495",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/495.png"
    },
    "8M9fGfdzq7wNDNWe91RqcV6KeBZohJ3cKm9G3cH4c6p2": {
        "mint": "8M9fGfdzq7wNDNWe91RqcV6KeBZohJ3cKm9G3cH4c6p2",
        "name": "Boryoku Dragonz #335",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/335.png"
    },
    "BqDyV3bmk62MXLQTvqtrgkGaMLuL1GcGGmWAjTYkymZC": {
        "mint": "BqDyV3bmk62MXLQTvqtrgkGaMLuL1GcGGmWAjTYkymZC",
        "name": "Boryoku Dragonz #339",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/339.png"
    },
    "GtpQmrawpvJuQKbudjyiWw9EWDTQ94PmAHRuWsmXkzV6": {
        "mint": "GtpQmrawpvJuQKbudjyiWw9EWDTQ94PmAHRuWsmXkzV6",
        "name": "Boryoku Dragonz #494",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/494.png"
    },
    "UX9v1Kf4FAgsznReSwkLb6v7yQwmDvZKrabYJ2ecPTp": {
        "mint": "UX9v1Kf4FAgsznReSwkLb6v7yQwmDvZKrabYJ2ecPTp",
        "name": "Boryoku Dragonz #327",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/327.png"
    },
    "3ZhgpsoPUHeW5tGqvMTwxizdYYRuFy5noymdp47i8U21": {
        "mint": "3ZhgpsoPUHeW5tGqvMTwxizdYYRuFy5noymdp47i8U21",
        "name": "Boryoku Dragonz #496",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/496.png"
    },
    "AhY2uQ6fLt7e67AG5U9mMcnqKHwoDmrzTCRLgYqeR31K": {
        "mint": "AhY2uQ6fLt7e67AG5U9mMcnqKHwoDmrzTCRLgYqeR31K",
        "name": "Boryoku Dragonz #331",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/331.png"
    },
    "5BPQM9H2Y665fZYpAnh9CgDmhXf5iP7Kuje1qXngHfeJ": {
        "mint": "5BPQM9H2Y665fZYpAnh9CgDmhXf5iP7Kuje1qXngHfeJ",
        "name": "Boryoku Dragonz #490",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/490.png"
    },
    "CtYaEFeWLa4AuxZr6QaQ2NKvD4wTCzJ6y6LD7VFpaDET": {
        "mint": "CtYaEFeWLa4AuxZr6QaQ2NKvD4wTCzJ6y6LD7VFpaDET",
        "name": "Boryoku Dragonz #489",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/489.png"
    },
    "FqPMcKEtMKPTELiSQcv6xfhveMvmgMPfvnMPRpPvmuz6": {
        "mint": "FqPMcKEtMKPTELiSQcv6xfhveMvmgMPfvnMPRpPvmuz6",
        "name": "Boryoku Dragonz #337",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/337.png"
    },
    "HUNZz6KgQEoqJQFDtuv2gv5wEpYU9hgJnhLpgCb8gzSw": {
        "mint": "HUNZz6KgQEoqJQFDtuv2gv5wEpYU9hgJnhLpgCb8gzSw",
        "name": "Boryoku Dragonz #340",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/340.png"
    },
    "382HVeYzoY95XFaAbkMWrwSpfiXbxoftJo2he1CC3MPM": {
        "mint": "382HVeYzoY95XFaAbkMWrwSpfiXbxoftJo2he1CC3MPM",
        "name": "Boryoku Dragonz #493",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/493.png"
    },
    "BjhDqaJatTJh989RCiRSDadLTbqTak26RB1pde69YxND": {
        "mint": "BjhDqaJatTJh989RCiRSDadLTbqTak26RB1pde69YxND",
        "name": "Boryoku Dragonz #491",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/491.png"
    },
    "9cUFzxy6mvXbdMT9o4E7pkw5DaZu9VQ1cXCr2ByshEgM": {
        "mint": "9cUFzxy6mvXbdMT9o4E7pkw5DaZu9VQ1cXCr2ByshEgM",
        "name": "Boryoku Dragonz #343",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/343.png"
    },
    "Ss1Rw1F5npfv8Ar4TZuSyy61dVMdQmuutoFV6Pb1JYz": {
        "mint": "Ss1Rw1F5npfv8Ar4TZuSyy61dVMdQmuutoFV6Pb1JYz",
        "name": "Boryoku Dragonz #492",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/492.png"
    },
    "Esz3ThdhmwmqDWEA2U4XjvwZWy9pRYcvFKAY8SNNkdGk": {
        "mint": "Esz3ThdhmwmqDWEA2U4XjvwZWy9pRYcvFKAY8SNNkdGk",
        "name": "Boryoku Dragonz #342",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/342.png"
    },
    "D9SXQ5F1oH5mVmKzHFPAirUSDVsYTndPyimQRjJETjnN": {
        "mint": "D9SXQ5F1oH5mVmKzHFPAirUSDVsYTndPyimQRjJETjnN",
        "name": "Boryoku Dragonz #487",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/487.png"
    },
    "7AzYKif6ofm3cHwywcixuAS59zGcdatjqHJwHcBRpKtT": {
        "mint": "7AzYKif6ofm3cHwywcixuAS59zGcdatjqHJwHcBRpKtT",
        "name": "Boryoku Dragonz #329",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/329.png"
    },
    "6CeuXqi8dwfrF3fhyGYUPAi3x9cEyLgQJMELqsMCJYcj": {
        "mint": "6CeuXqi8dwfrF3fhyGYUPAi3x9cEyLgQJMELqsMCJYcj",
        "name": "Boryoku Dragonz #488",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/488.png"
    },
    "6K2fsufdqiJFc2hLNLuzWdh21RMc7kfD6z6T7FFkXChc": {
        "mint": "6K2fsufdqiJFc2hLNLuzWdh21RMc7kfD6z6T7FFkXChc",
        "name": "Boryoku Dragonz #332",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/332.png"
    },
    "GN2mvFF8Y4uKsbfQyUYpsxrvZaEp2f6FXKo3BeXpcvqT": {
        "mint": "GN2mvFF8Y4uKsbfQyUYpsxrvZaEp2f6FXKo3BeXpcvqT",
        "name": "Boryoku Dragonz #486",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/486.png"
    },
    "y5HCy8ga2DAXnk5NiL8hYTHuWuWQUEmQUFWmUb4Z2LC": {
        "mint": "y5HCy8ga2DAXnk5NiL8hYTHuWuWQUEmQUFWmUb4Z2LC",
        "name": "Boryoku Dragonz #333",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/333.png"
    },
    "5cngCNT3jiurj5TgNdpfMVCqxjRFeA2rqYHKAHnNhTes": {
        "mint": "5cngCNT3jiurj5TgNdpfMVCqxjRFeA2rqYHKAHnNhTes",
        "name": "Boryoku Dragonz #485",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/485.png"
    },
    "8A5dkrLJsBmZBM2jtDNeXF2EPHGT62bzPupjtdrVXRBV": {
        "mint": "8A5dkrLJsBmZBM2jtDNeXF2EPHGT62bzPupjtdrVXRBV",
        "name": "Boryoku Dragonz #328",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/328.png"
    },
    "FSRnmCstP2D7N33BZe8L77MQBkWg6qCpGfurmKiiM6FZ": {
        "mint": "FSRnmCstP2D7N33BZe8L77MQBkWg6qCpGfurmKiiM6FZ",
        "name": "Boryoku Dragonz #484",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/484.png"
    },
    "4UfZdnp8eCJhtcgFdpJN2BFwg7be8UBkLgebA8WoTJCQ": {
        "mint": "4UfZdnp8eCJhtcgFdpJN2BFwg7be8UBkLgebA8WoTJCQ",
        "name": "Boryoku Dragonz #481",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/481.png"
    },
    "CQTUj45sHgcPz4VH38qTJU9k4k1QGUzyCx6PTFwpBn81": {
        "mint": "CQTUj45sHgcPz4VH38qTJU9k4k1QGUzyCx6PTFwpBn81",
        "name": "Boryoku Dragonz #341",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/341.png"
    },
    "Gac9eZ3oVw6knUsBbsavCKVvPopbrdvPpqev48bGiY5N": {
        "mint": "Gac9eZ3oVw6knUsBbsavCKVvPopbrdvPpqev48bGiY5N",
        "name": "Boryoku Dragonz #480",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/480.png"
    },
    "FTqcyNBVL1tXhMDLt2eNEHNtwMzSCw3SFeWyCVEzvTw7": {
        "mint": "FTqcyNBVL1tXhMDLt2eNEHNtwMzSCw3SFeWyCVEzvTw7",
        "name": "Boryoku Dragonz #338",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/338.png"
    },
    "4rUXBPAQaFhqDPmtnH1J9PjrrFoxJE8Dha84y923eAaT": {
        "mint": "4rUXBPAQaFhqDPmtnH1J9PjrrFoxJE8Dha84y923eAaT",
        "name": "Boryoku Dragonz #482",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/482.png"
    },
    "7VirGxqDotWHp7NGPGezH3XE7qZLoGtmDMoXPhSZNLSc": {
        "mint": "7VirGxqDotWHp7NGPGezH3XE7qZLoGtmDMoXPhSZNLSc",
        "name": "Boryoku Dragonz #330",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/330.png"
    },
    "ArbmazpWURcdkwrxTfcjWKWG75hcSBRs8a4Sg6fLbnsL": {
        "mint": "ArbmazpWURcdkwrxTfcjWKWG75hcSBRs8a4Sg6fLbnsL",
        "name": "Boryoku Dragonz #483",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/483.png"
    },
    "9SFgGE4yKGw2gTNFiSYcu9bari15YvWU5jBZrMP7xYgv": {
        "mint": "9SFgGE4yKGw2gTNFiSYcu9bari15YvWU5jBZrMP7xYgv",
        "name": "Boryoku Dragonz #334",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/334.png"
    },
    "65ctZ9iaQXfnHiLNDBwCt11JS6z22i85pAfK9gN6oNVE": {
        "mint": "65ctZ9iaQXfnHiLNDBwCt11JS6z22i85pAfK9gN6oNVE",
        "name": "Boryoku Dragonz #474",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/474.png"
    },
    "HMXTMTopaoZNpsbn7Vj2iVd33Re5uLf7VGGPRai2FUVL": {
        "mint": "HMXTMTopaoZNpsbn7Vj2iVd33Re5uLf7VGGPRai2FUVL",
        "name": "Boryoku Dragonz #479",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/479.png"
    },
    "8mG2ipfmXLC1cT7nUP5chR34ciW2PopzRoeicWMhWZ18": {
        "mint": "8mG2ipfmXLC1cT7nUP5chR34ciW2PopzRoeicWMhWZ18",
        "name": "Boryoku Dragonz #336",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/336.png"
    },
    "4jEfTfiKZBMteDR9D6LQP7q4vo6UtgGspWc8DmJ64Wfm": {
        "mint": "4jEfTfiKZBMteDR9D6LQP7q4vo6UtgGspWc8DmJ64Wfm",
        "name": "Boryoku Dragonz #475",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/475.png"
    },
    "2yRAdowR54eeRoKoNjVZC32B87C19mHSJFghV46Sydhg": {
        "mint": "2yRAdowR54eeRoKoNjVZC32B87C19mHSJFghV46Sydhg",
        "name": "Boryoku Dragonz #372",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/372.png"
    },
    "E6KQEK1ixNp4q32FXmaQQ8NQckUWLrqEA7Mhfx5njBH6": {
        "mint": "E6KQEK1ixNp4q32FXmaQQ8NQckUWLrqEA7Mhfx5njBH6",
        "name": "Boryoku Dragonz #476",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/476.png"
    },
    "ErmwJaMXxD4Kz2MH4UcoTY2BbFaKhqs47F8KkLfTg69w": {
        "mint": "ErmwJaMXxD4Kz2MH4UcoTY2BbFaKhqs47F8KkLfTg69w",
        "name": "Boryoku Dragonz #389",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/389.png"
    },
    "CrujD8JanvAtHtVTgGmYEn2PfbhyAwMzCoQh7WWj3X8X": {
        "mint": "CrujD8JanvAtHtVTgGmYEn2PfbhyAwMzCoQh7WWj3X8X",
        "name": "Boryoku Dragonz #478",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/478.png"
    },
    "AJvvaPbGbZwdP5JgFxSdwXbSbdESggAbcp1kxdS5pS48": {
        "mint": "AJvvaPbGbZwdP5JgFxSdwXbSbdESggAbcp1kxdS5pS48",
        "name": "Boryoku Dragonz #374",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/374.png"
    },
    "6ocVZ3VBrQQ6y87whJB9Q9WPU4HtRZ5FjpeLyvQQrtgm": {
        "mint": "6ocVZ3VBrQQ6y87whJB9Q9WPU4HtRZ5FjpeLyvQQrtgm",
        "name": "Boryoku Dragonz #477",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/477.png"
    },
    "7N6P1QKGaYKiHURkEHQFToCGx7phqhtL6Z9oE4KjdDbN": {
        "mint": "7N6P1QKGaYKiHURkEHQFToCGx7phqhtL6Z9oE4KjdDbN",
        "name": "Boryoku Dragonz #354",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/354.png"
    },
    "FahF9KdkpZmJSkg5WMTn8gaTLmeMJwXHhJtB1M7pxf9i": {
        "mint": "FahF9KdkpZmJSkg5WMTn8gaTLmeMJwXHhJtB1M7pxf9i",
        "name": "Boryoku Dragonz #473",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/473.png"
    },
    "6DaoLgE5uNdLZ2biLLaGA2sH8XkAm2147pgXwhtyuya4": {
        "mint": "6DaoLgE5uNdLZ2biLLaGA2sH8XkAm2147pgXwhtyuya4",
        "name": "Boryoku Dragonz #386",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/386.png"
    },
    "E76FygDbcYBTyyMDse3yNqsatALogmnANaqxpTechNqy": {
        "mint": "E76FygDbcYBTyyMDse3yNqsatALogmnANaqxpTechNqy",
        "name": "Boryoku Dragonz #472",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/472.png"
    },
    "AmY2yJ99Lrfyz7GgKQgJ92XoQafaNLHGn37JPHHWhR3P": {
        "mint": "AmY2yJ99Lrfyz7GgKQgJ92XoQafaNLHGn37JPHHWhR3P",
        "name": "Boryoku Dragonz #387",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/387.png"
    },
    "2bUpsiK9mWJV4ZWKz8fQ3LySpXkA2f5QFLpvd9vAkfLW": {
        "mint": "2bUpsiK9mWJV4ZWKz8fQ3LySpXkA2f5QFLpvd9vAkfLW",
        "name": "Boryoku Dragonz #363",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/363.png"
    },
    "4ujuViaQ7ofsbxAt136PedvimMvaTDpxrvViFuLvSa5i": {
        "mint": "4ujuViaQ7ofsbxAt136PedvimMvaTDpxrvViFuLvSa5i",
        "name": "Boryoku Dragonz #470",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/470.png"
    },
    "3pGrg9ZrzfjsAFQLL4Byyz3yV62wfeFjkwrnAWzL5AAa": {
        "mint": "3pGrg9ZrzfjsAFQLL4Byyz3yV62wfeFjkwrnAWzL5AAa",
        "name": "Boryoku Dragonz #471",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/471.png"
    },
    "EzVapuS7s7GwVggfKpZfRpiLzgbhBWEVGe2LyPAtyYYF": {
        "mint": "EzVapuS7s7GwVggfKpZfRpiLzgbhBWEVGe2LyPAtyYYF",
        "name": "Boryoku Dragonz #358",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/358.png"
    },
    "GxB6W64SbopTNHukcgeKa2dtixN8LDL2oMpaFa6YqtTw": {
        "mint": "GxB6W64SbopTNHukcgeKa2dtixN8LDL2oMpaFa6YqtTw",
        "name": "Boryoku Dragonz #468",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/468.png"
    },
    "8G9K5SpMo8qhW2aCBGYZ4AYewj3vZNX2kJLmRpjHAhMs": {
        "mint": "8G9K5SpMo8qhW2aCBGYZ4AYewj3vZNX2kJLmRpjHAhMs",
        "name": "Boryoku Dragonz #385",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/385.png"
    },
    "J1fVmqoKhyT4gzqnqPKyvs9Eo7aNxdbnLY4GsWN2AGNK": {
        "mint": "J1fVmqoKhyT4gzqnqPKyvs9Eo7aNxdbnLY4GsWN2AGNK",
        "name": "Boryoku Dragonz #466",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/466.png"
    },
    "GNBymLUpAkTSAuv9KS7JV2P2xpXpjcUy27psWGRAhYpG": {
        "mint": "GNBymLUpAkTSAuv9KS7JV2P2xpXpjcUy27psWGRAhYpG",
        "name": "Boryoku Dragonz #388",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/388.png"
    },
    "5vGHWSE8U1NjtxejTU6ds8zo2jYgtStugirKya1gtjeD": {
        "mint": "5vGHWSE8U1NjtxejTU6ds8zo2jYgtStugirKya1gtjeD",
        "name": "Boryoku Dragonz #467",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/467.png"
    },
    "2QHoV1dFwCyTiUmxodY4dkiU1NBEctVYRRoMfgGugUC9": {
        "mint": "2QHoV1dFwCyTiUmxodY4dkiU1NBEctVYRRoMfgGugUC9",
        "name": "Boryoku Dragonz #350",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/350.png"
    },
    "3jkPW6S6QWCU5YyRsDsbZR2ZTTqDwwWh1AZA8wFsmuCs": {
        "mint": "3jkPW6S6QWCU5YyRsDsbZR2ZTTqDwwWh1AZA8wFsmuCs",
        "name": "Boryoku Dragonz #469",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/469.png"
    },
    "zn1Go213BwFs1xGnRgz9KzYbeHquCKuE5tQwsCFhsHB": {
        "mint": "zn1Go213BwFs1xGnRgz9KzYbeHquCKuE5tQwsCFhsHB",
        "name": "Boryoku Dragonz #359",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/359.png"
    },
    "CPrg13dcSAoU31i2z6nRqBPPwiMmbisWWddDHhHz1vMf": {
        "mint": "CPrg13dcSAoU31i2z6nRqBPPwiMmbisWWddDHhHz1vMf",
        "name": "Boryoku Dragonz #465",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/465.png"
    },
    "9bBJmPuo62vobJsJGxzr9qgYqfcKi1MvTnB3t8h6c2A6": {
        "mint": "9bBJmPuo62vobJsJGxzr9qgYqfcKi1MvTnB3t8h6c2A6",
        "name": "Boryoku Dragonz #344",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/344.png"
    },
    "8o3sSGB8bWX6zZWHBsvTJz6WTYkjHrbckTmF5ReS1mAy": {
        "mint": "8o3sSGB8bWX6zZWHBsvTJz6WTYkjHrbckTmF5ReS1mAy",
        "name": "Boryoku Dragonz #462",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/462.png"
    },
    "D1S44MD3cw1CBEuK7cJF8NMbp4xr121To6E9v82c54QS": {
        "mint": "D1S44MD3cw1CBEuK7cJF8NMbp4xr121To6E9v82c54QS",
        "name": "Boryoku Dragonz #464",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/464.png"
    },
    "4kt5iabN9FDPVeCTBCdZTHZuXnVF43KSCHwJDCP9zX3g": {
        "mint": "4kt5iabN9FDPVeCTBCdZTHZuXnVF43KSCHwJDCP9zX3g",
        "name": "Boryoku Dragonz #378",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/378.png"
    },
    "2yWw7nVco64sNZ6Q23JbHMixk3keNAzPXXqjb4x11f17": {
        "mint": "2yWw7nVco64sNZ6Q23JbHMixk3keNAzPXXqjb4x11f17",
        "name": "Boryoku Dragonz #463",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/463.png"
    },
    "GBbhYzQNdtjCbaBRmjhYPjGDhPcMo63bba1GVzn5Kei8": {
        "mint": "GBbhYzQNdtjCbaBRmjhYPjGDhPcMo63bba1GVzn5Kei8",
        "name": "Boryoku Dragonz #353",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/353.png"
    },
    "FKErouUbcravz5PC7uj91qQ11B9bN71nGWH1NiS3qk3v": {
        "mint": "FKErouUbcravz5PC7uj91qQ11B9bN71nGWH1NiS3qk3v",
        "name": "Boryoku Dragonz #351",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/351.png"
    },
    "9DZPDMdkqqiMGMBb9mJFq8A6XrE2tTzNj8xpJeZVsvks": {
        "mint": "9DZPDMdkqqiMGMBb9mJFq8A6XrE2tTzNj8xpJeZVsvks",
        "name": "Boryoku Dragonz #458",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/458.png"
    },
    "6ZCZLTkSLV7JR6S18B65LwbxoDgcFQRzNQGg3jM68Xnt": {
        "mint": "6ZCZLTkSLV7JR6S18B65LwbxoDgcFQRzNQGg3jM68Xnt",
        "name": "Boryoku Dragonz #382",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/382.png"
    },
    "8r7D3W5N9arm2nCaKz2k5guebvPTWEsHTbL3w1CDzSt1": {
        "mint": "8r7D3W5N9arm2nCaKz2k5guebvPTWEsHTbL3w1CDzSt1",
        "name": "Boryoku Dragonz #456",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/456.png"
    },
    "5J47WxhZAPavYV2FFqjbmXPYNG4EkkxTvxUSSsP3ry57": {
        "mint": "5J47WxhZAPavYV2FFqjbmXPYNG4EkkxTvxUSSsP3ry57",
        "name": "Boryoku Dragonz #384",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/384.png"
    },
    "7qviXKKYh51cV4QKBvaFaaDwRygGmvF1fEa4WAfxU5LH": {
        "mint": "7qviXKKYh51cV4QKBvaFaaDwRygGmvF1fEa4WAfxU5LH",
        "name": "Boryoku Dragonz #454",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/454.png"
    },
    "BDRs86D3CALdzognUrJh39xfLsUcbh1CjEdWvST6Ebmn": {
        "mint": "BDRs86D3CALdzognUrJh39xfLsUcbh1CjEdWvST6Ebmn",
        "name": "Boryoku Dragonz #368",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/368.png"
    },
    "2Q4aZ9R7tebdakhXdZeM93W2EeJW5ad7wDoD95qRermH": {
        "mint": "2Q4aZ9R7tebdakhXdZeM93W2EeJW5ad7wDoD95qRermH",
        "name": "Boryoku Dragonz #453",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/453.png"
    },
    "CTy9mA6brNUWV7Qo6KyE3DV4EtAoQpJo5dY7JbYHzW7J": {
        "mint": "CTy9mA6brNUWV7Qo6KyE3DV4EtAoQpJo5dY7JbYHzW7J",
        "name": "Boryoku Dragonz #459",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/459.png"
    },
    "7ysPSZS3YjmwbEQf2db9wkkXov4f77GJm53SCiaSCDmQ": {
        "mint": "7ysPSZS3YjmwbEQf2db9wkkXov4f77GJm53SCiaSCDmQ",
        "name": "Boryoku Dragonz #381",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/381.png"
    },
    "Er17AWoHbQn4xw46aEumYj72yRBE6u2YmKTLSzttT1dp": {
        "mint": "Er17AWoHbQn4xw46aEumYj72yRBE6u2YmKTLSzttT1dp",
        "name": "Boryoku Dragonz #455",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/455.png"
    },
    "6vAQXduH52asv6rpzp6jNjYreqG2yC68hgth3yUiAR8Y": {
        "mint": "6vAQXduH52asv6rpzp6jNjYreqG2yC68hgth3yUiAR8Y",
        "name": "Boryoku Dragonz #377",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/377.png"
    },
    "2GqmmfK4tDUC1pu3keeFjk5d9HtjMmwJ49zxDrTsryxc": {
        "mint": "2GqmmfK4tDUC1pu3keeFjk5d9HtjMmwJ49zxDrTsryxc",
        "name": "Boryoku Dragonz #461",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/461.png"
    },
    "317X8rbD6xkZgGqhyaMB9pXwXXDweuXENEA6e5xhQXdW": {
        "mint": "317X8rbD6xkZgGqhyaMB9pXwXXDweuXENEA6e5xhQXdW",
        "name": "Boryoku Dragonz #360",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/360.png"
    },
    "57gU6DZp2SXJLkGSx3UapT8kDMgMnFjSS5o42xEs4VnG": {
        "mint": "57gU6DZp2SXJLkGSx3UapT8kDMgMnFjSS5o42xEs4VnG",
        "name": "Boryoku Dragonz #460",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/460.png"
    },
    "62xNb1N5xjU3ANrf79jv9RGjmMQ84ozaPSWUFXnDC7Lx": {
        "mint": "62xNb1N5xjU3ANrf79jv9RGjmMQ84ozaPSWUFXnDC7Lx",
        "name": "Boryoku Dragonz #349",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/349.png"
    },
    "JMDme7buY3a11BTfGnAR2haY8Q9oBaAiRSVKCmadkaN": {
        "mint": "JMDme7buY3a11BTfGnAR2haY8Q9oBaAiRSVKCmadkaN",
        "name": "Boryoku Dragonz #457",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/457.png"
    },
    "BxWpWWRLavKVqniEonskNk9mgTWp5m9RebPiXJ74Zz8k": {
        "mint": "BxWpWWRLavKVqniEonskNk9mgTWp5m9RebPiXJ74Zz8k",
        "name": "Boryoku Dragonz #376",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/376.png"
    },
    "BmCfLSJqQd4W8oVy28LkUC9ftb2Muak1RRTcExngrqmg": {
        "mint": "BmCfLSJqQd4W8oVy28LkUC9ftb2Muak1RRTcExngrqmg",
        "name": "Boryoku Dragonz #451",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/451.png"
    },
    "GGPAhC3jj9LybDn6CdZKM94Jhq1jcJpsBGTEB9EPrdAX": {
        "mint": "GGPAhC3jj9LybDn6CdZKM94Jhq1jcJpsBGTEB9EPrdAX",
        "name": "Boryoku Dragonz #365",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/365.png"
    },
    "4NDaDEzfguVyyTJrSGjVnmkkWgXwD66J4ATfZGeyVUEd": {
        "mint": "4NDaDEzfguVyyTJrSGjVnmkkWgXwD66J4ATfZGeyVUEd",
        "name": "Boryoku Dragonz #357",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/357.png"
    },
    "6t4KcHTe8ABkmeS8uLQU3nxcx4JYwp7aYLqX6Vm2wpgf": {
        "mint": "6t4KcHTe8ABkmeS8uLQU3nxcx4JYwp7aYLqX6Vm2wpgf",
        "name": "Boryoku Dragonz #452",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/452.png"
    },
    "ECW6g45jd9rEmwgrkjLKThGEXvXyXnaBq4JQ5EhWyU1a": {
        "mint": "ECW6g45jd9rEmwgrkjLKThGEXvXyXnaBq4JQ5EhWyU1a",
        "name": "Boryoku Dragonz #346",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/346.png"
    },
    "D45ioNaNRiMeGiHgT43QpFwd9jP4B44ffoyrmrihXfqq": {
        "mint": "D45ioNaNRiMeGiHgT43QpFwd9jP4B44ffoyrmrihXfqq",
        "name": "Boryoku Dragonz #450",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/450.png"
    },
    "FHTArZW2n7Q6G2iGUe1dajUUSGeCnbTEFAZ8T7XwCGZD": {
        "mint": "FHTArZW2n7Q6G2iGUe1dajUUSGeCnbTEFAZ8T7XwCGZD",
        "name": "Boryoku Dragonz #383",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/383.png"
    },
    "4bJiaTgJTJXzQFo5fCLAYzM8LYP9sMVRWEHu7fvPLXCp": {
        "mint": "4bJiaTgJTJXzQFo5fCLAYzM8LYP9sMVRWEHu7fvPLXCp",
        "name": "Boryoku Dragonz #449",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/449.png"
    },
    "2FvkEUwJBCQxakSKZpfC4FiZcSv5jTnLnaSp1EEa14f7": {
        "mint": "2FvkEUwJBCQxakSKZpfC4FiZcSv5jTnLnaSp1EEa14f7",
        "name": "Boryoku Dragonz #371",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/371.png"
    },
    "DQXV8jsJdKQ2EsK1Bwhsbn9AZcURX52qhZ3wBh6e1mn": {
        "mint": "DQXV8jsJdKQ2EsK1Bwhsbn9AZcURX52qhZ3wBh6e1mn",
        "name": "Boryoku Dragonz #443",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/443.png"
    },
    "CL4Z5Qfb7yB7GLBFr8ABdgLD68xd88S9Fo6cKk55Z9GA": {
        "mint": "CL4Z5Qfb7yB7GLBFr8ABdgLD68xd88S9Fo6cKk55Z9GA",
        "name": "Boryoku Dragonz #345",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/345.png"
    },
    "6DiXLStaYPkV6Gm2Bsur4wBrTZMq9sYss6FTSEnACvZq": {
        "mint": "6DiXLStaYPkV6Gm2Bsur4wBrTZMq9sYss6FTSEnACvZq",
        "name": "Boryoku Dragonz #361",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/361.png"
    },
    "FLmm7znMxLHp44zb2X9EWNxxikin22jsx8Yx6PZxMifT": {
        "mint": "FLmm7znMxLHp44zb2X9EWNxxikin22jsx8Yx6PZxMifT",
        "name": "Boryoku Dragonz #445",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/445.png"
    },
    "3uDs3NhdrCTCngVXxn4BRME1wA4Qj9n7ZqRvckXFTDC9": {
        "mint": "3uDs3NhdrCTCngVXxn4BRME1wA4Qj9n7ZqRvckXFTDC9",
        "name": "Boryoku Dragonz #347",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/347.png"
    },
    "DGpk3818zLW9UrJyyRfBLFSVkYFDgh3sRUiFvz5ASWcZ": {
        "mint": "DGpk3818zLW9UrJyyRfBLFSVkYFDgh3sRUiFvz5ASWcZ",
        "name": "Boryoku Dragonz #442",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/442.png"
    },
    "ECKoZRvBYRQbQiwyk49qbwSCyZUg6azC9As4hn7bUGNg": {
        "mint": "ECKoZRvBYRQbQiwyk49qbwSCyZUg6azC9As4hn7bUGNg",
        "name": "Boryoku Dragonz #369",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/369.png"
    },
    "A7HZhxJoaPgi1JpKGJqy1Udgt9UiJi7pspU4FVpDN94h": {
        "mint": "A7HZhxJoaPgi1JpKGJqy1Udgt9UiJi7pspU4FVpDN94h",
        "name": "Boryoku Dragonz #444",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/444.png"
    },
    "4Segu7Fw7A48iGLLNkugZVbJ8o6dHtuUhCZ7sQaVuVFU": {
        "mint": "4Segu7Fw7A48iGLLNkugZVbJ8o6dHtuUhCZ7sQaVuVFU",
        "name": "Boryoku Dragonz #441",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/441.png"
    },
    "HcopqP9rbwq7SqSrJuFLdaJhapji3m3NXhKvFcYimuZk": {
        "mint": "HcopqP9rbwq7SqSrJuFLdaJhapji3m3NXhKvFcYimuZk",
        "name": "Boryoku Dragonz #373",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/373.png"
    },
    "GkqfGPnZdJRwNi4JZQm7iuRGyAb7uueJNJfLeTmikgRg": {
        "mint": "GkqfGPnZdJRwNi4JZQm7iuRGyAb7uueJNJfLeTmikgRg",
        "name": "Boryoku Dragonz #446",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/446.png"
    },
    "HRKcCMEtBdPGusKyAodSvLhkZwwmsEiMNY8FLi8Un2Gz": {
        "mint": "HRKcCMEtBdPGusKyAodSvLhkZwwmsEiMNY8FLi8Un2Gz",
        "name": "Boryoku Dragonz #352",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/352.png"
    },
    "FWsoswBTh2jMLLAQho4PhUS7bybd7LVAFJ68ch3jZPmi": {
        "mint": "FWsoswBTh2jMLLAQho4PhUS7bybd7LVAFJ68ch3jZPmi",
        "name": "Boryoku Dragonz #447",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/447.png"
    },
    "A9dHrqZQdzBs5GwUFyikVuvGbfzBE76ZyQdjEAgE1KAE": {
        "mint": "A9dHrqZQdzBs5GwUFyikVuvGbfzBE76ZyQdjEAgE1KAE",
        "name": "Boryoku Dragonz #375",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/375.png"
    },
    "5JTg1yFygXQhn8ouWaEPt31W1BSyLWmtGPKEKkBjthWj": {
        "mint": "5JTg1yFygXQhn8ouWaEPt31W1BSyLWmtGPKEKkBjthWj",
        "name": "Boryoku Dragonz #448",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/448.png"
    },
    "EnkBcuJ7Za2g3rweniMywhyLqVw8xmDKkJgPZ8fZgKt6": {
        "mint": "EnkBcuJ7Za2g3rweniMywhyLqVw8xmDKkJgPZ8fZgKt6",
        "name": "Boryoku Dragonz #367",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/367.png"
    },
    "6y5wr7f18crSHEfgz1BkFxuDWtcHPPcGJUXuAYDGY2mJ": {
        "mint": "6y5wr7f18crSHEfgz1BkFxuDWtcHPPcGJUXuAYDGY2mJ",
        "name": "Boryoku Dragonz #440",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/440.png"
    },
    "4PGUVKWtfcVombD7kGcmA26qQvcEuL13pWJZjQ3c1KUY": {
        "mint": "4PGUVKWtfcVombD7kGcmA26qQvcEuL13pWJZjQ3c1KUY",
        "name": "Boryoku Dragonz #348",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/348.png"
    },
    "983tU23uu55RbGBhnnSnUJpjFFEXGpChpuuMEXp9tVX9": {
        "mint": "983tU23uu55RbGBhnnSnUJpjFFEXGpChpuuMEXp9tVX9",
        "name": "Boryoku Dragonz #437",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/437.png"
    },
    "Ggz5MkjwjGgErSoAtJmtGXbJK4VmwYM5fGyCVHFkP1H4": {
        "mint": "Ggz5MkjwjGgErSoAtJmtGXbJK4VmwYM5fGyCVHFkP1H4",
        "name": "Boryoku Dragonz #380",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/380.png"
    },
    "BmgCx5QMietRup4SQn3JhridQAJEv4U3sYfYFNbvjThK": {
        "mint": "BmgCx5QMietRup4SQn3JhridQAJEv4U3sYfYFNbvjThK",
        "name": "Boryoku Dragonz #439",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/439.png"
    },
    "FMCR4eFvg6DHbqxshd2LAAd2H4RtimzZCszXoJLrdpTd": {
        "mint": "FMCR4eFvg6DHbqxshd2LAAd2H4RtimzZCszXoJLrdpTd",
        "name": "Boryoku Dragonz #355",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/355.png"
    },
    "2UGM88u2sgtUmKA3JoYKdG3y1kCz593zLABJ7SkpKxSP": {
        "mint": "2UGM88u2sgtUmKA3JoYKdG3y1kCz593zLABJ7SkpKxSP",
        "name": "Boryoku Dragonz #438",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/438.png"
    },
    "8rZzP5iEgzgCmz9rEqaGJMHN6s7ZyYRjyGVDfMoXRXtM": {
        "mint": "8rZzP5iEgzgCmz9rEqaGJMHN6s7ZyYRjyGVDfMoXRXtM",
        "name": "Boryoku Dragonz #370",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/370.png"
    },
    "6JwB99SLqehkZP3hetYUJPdLd5pKL9zcvJ8FVmEo6t6t": {
        "mint": "6JwB99SLqehkZP3hetYUJPdLd5pKL9zcvJ8FVmEo6t6t",
        "name": "Boryoku Dragonz #436",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/436.png"
    },
    "D2wdMW36x9KsnB3Ng5V5gx9SUL1tbinsCnbcvnDaKoCZ": {
        "mint": "D2wdMW36x9KsnB3Ng5V5gx9SUL1tbinsCnbcvnDaKoCZ",
        "name": "Boryoku Dragonz #366",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/366.png"
    },
    "FShiJTYbjkAkTLCXvNh1EzXVV4ZqHPi7vxYdSAi1S8eF": {
        "mint": "FShiJTYbjkAkTLCXvNh1EzXVV4ZqHPi7vxYdSAi1S8eF",
        "name": "Boryoku Dragonz #435",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/435.png"
    },
    "E2rvA5xJJt1uYez3y9PnRYsFgugJyYTN6VpMb43EPdVt": {
        "mint": "E2rvA5xJJt1uYez3y9PnRYsFgugJyYTN6VpMb43EPdVt",
        "name": "Boryoku Dragonz #364",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/364.png"
    },
    "ArtmsHZZVRYW4sKNxvYN26rLD43oxTAb8VujGpYb7jS2": {
        "mint": "ArtmsHZZVRYW4sKNxvYN26rLD43oxTAb8VujGpYb7jS2",
        "name": "Boryoku Dragonz #434",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/434.png"
    },
    "GFRMJqyNUYY9XjMoz9HjymyQLky5WMSD54pbY31viv5V": {
        "mint": "GFRMJqyNUYY9XjMoz9HjymyQLky5WMSD54pbY31viv5V",
        "name": "Boryoku Dragonz #390",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/390.png"
    },
    "EEvqjonXawKdvFEP2koXH5zKwcGUEB12G3arQAM5JPKy": {
        "mint": "EEvqjonXawKdvFEP2koXH5zKwcGUEB12G3arQAM5JPKy",
        "name": "Boryoku Dragonz #429",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/429.png"
    },
    "CLrr2BkDvpWFT745kWXPU3FibqQ3pJ6YyCWH46EjrJox": {
        "mint": "CLrr2BkDvpWFT745kWXPU3FibqQ3pJ6YyCWH46EjrJox",
        "name": "Boryoku Dragonz #356",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/356.png"
    },
    "3adgMZQKJ9phGpGGHTzTM9F4TPnBKGmvNPcrTspq8Gbk": {
        "mint": "3adgMZQKJ9phGpGGHTzTM9F4TPnBKGmvNPcrTspq8Gbk",
        "name": "Boryoku Dragonz #430",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/430.png"
    },
    "Ag8Qeoa5LvmMXuUMLRsUv1zYg8SEMgsx4ANuoaoC7Ggw": {
        "mint": "Ag8Qeoa5LvmMXuUMLRsUv1zYg8SEMgsx4ANuoaoC7Ggw",
        "name": "Boryoku Dragonz #379",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/379.png"
    },
    "Gq9q4zoomw4QYNuFSL735okBJd9gCTHUd77fmv7UK1cD": {
        "mint": "Gq9q4zoomw4QYNuFSL735okBJd9gCTHUd77fmv7UK1cD",
        "name": "Boryoku Dragonz #433",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/433.png"
    },
    "F1GaJqvjPEH7TLHmLU2zFMaPisZnNzYNfmSu1fAry9K9": {
        "mint": "F1GaJqvjPEH7TLHmLU2zFMaPisZnNzYNfmSu1fAry9K9",
        "name": "Boryoku Dragonz #362",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/362.png"
    },
    "68aymPACtX9JGneZWFHsmVeWkoKDouWKrf8DMUnsjKPE": {
        "mint": "68aymPACtX9JGneZWFHsmVeWkoKDouWKrf8DMUnsjKPE",
        "name": "Boryoku Dragonz #427",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/427.png"
    },
    "B5oSUrhkvmRbCMZYzw9AMqRzy4uTAxL6gT2rDc5oVZVo": {
        "mint": "B5oSUrhkvmRbCMZYzw9AMqRzy4uTAxL6gT2rDc5oVZVo",
        "name": "Boryoku Dragonz #399",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/399.png"
    },
    "8TeRL94UTYDRtyE2JJSb7h7KGwUJLFvsNne4c5qAHyUa": {
        "mint": "8TeRL94UTYDRtyE2JJSb7h7KGwUJLFvsNne4c5qAHyUa",
        "name": "Boryoku Dragonz #425",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/425.png"
    },
    "J9xLcwVewWV8opDq7VHdHSnuRyEa5KWrBK1qzP2z8cXj": {
        "mint": "J9xLcwVewWV8opDq7VHdHSnuRyEa5KWrBK1qzP2z8cXj",
        "name": "Boryoku Dragonz #423",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/423.png"
    },
    "bjeJnfnrgepQqVy19PeKhbxsw1debURCg9gWQsn31pW": {
        "mint": "bjeJnfnrgepQqVy19PeKhbxsw1debURCg9gWQsn31pW",
        "name": "Boryoku Dragonz #394",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/394.png"
    },
    "62wWqCyK4v2hEKkMC9Bn69JnQvjb63ng5h4DUK4nsTiD": {
        "mint": "62wWqCyK4v2hEKkMC9Bn69JnQvjb63ng5h4DUK4nsTiD",
        "name": "Boryoku Dragonz #431",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/431.png"
    },
    "J8Pz3TtuuoMMx1QnHqBvYtS3kFY6ChCfeYfc5g25dR9p": {
        "mint": "J8Pz3TtuuoMMx1QnHqBvYtS3kFY6ChCfeYfc5g25dR9p",
        "name": "Boryoku Dragonz #406",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/406.png"
    },
    "UuRDnnTiCbfBgdGgjduNLM7a7No454CSqnevxzcTsp5": {
        "mint": "UuRDnnTiCbfBgdGgjduNLM7a7No454CSqnevxzcTsp5",
        "name": "Boryoku Dragonz #432",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/432.png"
    },
    "VsiPstNqUkxEbL746rRCrnjv8aH2e5BzbQdDVErLNRi": {
        "mint": "VsiPstNqUkxEbL746rRCrnjv8aH2e5BzbQdDVErLNRi",
        "name": "Boryoku Dragonz #396",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/396.png"
    },
    "HL2LVgCiE2UJhhUbfmGsV62j7JVzdjuyQfKzsf53C8rq": {
        "mint": "HL2LVgCiE2UJhhUbfmGsV62j7JVzdjuyQfKzsf53C8rq",
        "name": "Boryoku Dragonz #411",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/411.png"
    },
    "AHCxeGSCBVZxbJU1TYiNxxsdPmiQpYLxBmZT5vKnp3EG": {
        "mint": "AHCxeGSCBVZxbJU1TYiNxxsdPmiQpYLxBmZT5vKnp3EG",
        "name": "Boryoku Dragonz #424",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/424.png"
    },
    "A7ESKMw2ck6Ts8apYGsPgxXpuPViDGvDoYQvFXnmeXwU": {
        "mint": "A7ESKMw2ck6Ts8apYGsPgxXpuPViDGvDoYQvFXnmeXwU",
        "name": "Boryoku Dragonz #409",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/409.png"
    },
    "3Vg4zC34bWLsxytu9GYSVdsvxAYZEzmzjcEt3vPUeKKX": {
        "mint": "3Vg4zC34bWLsxytu9GYSVdsvxAYZEzmzjcEt3vPUeKKX",
        "name": "Boryoku Dragonz #428",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/428.png"
    },
    "J8AdTDTiXnu2pkLZrWcXMZ8JpnkAiLEPW5qiN4KopCE5": {
        "mint": "J8AdTDTiXnu2pkLZrWcXMZ8JpnkAiLEPW5qiN4KopCE5",
        "name": "Boryoku Dragonz #412",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/412.png"
    },
    "87auKVQ6KbeqKmBNg4WyvndvVCVS1bq7KnjgWVRkFE1Q": {
        "mint": "87auKVQ6KbeqKmBNg4WyvndvVCVS1bq7KnjgWVRkFE1Q",
        "name": "Boryoku Dragonz #422",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/422.png"
    },
    "9hMgds92arC6meXwXf7JeRoyiZU2SkFRRngLM7gRBSyv": {
        "mint": "9hMgds92arC6meXwXf7JeRoyiZU2SkFRRngLM7gRBSyv",
        "name": "Boryoku Dragonz #408",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/408.png"
    },
    "5AdzMJ3JRbsqiP1EYgYvYwiQ82w939buHsAziLsGBWYJ": {
        "mint": "5AdzMJ3JRbsqiP1EYgYvYwiQ82w939buHsAziLsGBWYJ",
        "name": "Boryoku Dragonz #426",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/426.png"
    },
    "2427n1ZzFvotMo78uj6ynNhCmGR3ea8KTMrp9fau4cxi": {
        "mint": "2427n1ZzFvotMo78uj6ynNhCmGR3ea8KTMrp9fau4cxi",
        "name": "Boryoku Dragonz #401",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/401.png"
    },
    "GkDiv2KnxC7arer6yFw8uVimFgqSguWdLq83VA8dJLam": {
        "mint": "GkDiv2KnxC7arer6yFw8uVimFgqSguWdLq83VA8dJLam",
        "name": "Boryoku Dragonz #402",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/402.png"
    },
    "D2Mx6xVTE3WU9KgFJVoX4AYhBcc55Sr2ENHtiZNafVh4": {
        "mint": "D2Mx6xVTE3WU9KgFJVoX4AYhBcc55Sr2ENHtiZNafVh4",
        "name": "Boryoku Dragonz #415",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/415.png"
    },
    "8GJGkkLLXitheVone9unADcyHRYkP7GXwu2s1uYvPd7o": {
        "mint": "8GJGkkLLXitheVone9unADcyHRYkP7GXwu2s1uYvPd7o",
        "name": "Boryoku Dragonz #419",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/419.png"
    },
    "3q9rnKxvRoZVpqw4EBJ2wUg8U2P4WicNeMfPxzZfmLey": {
        "mint": "3q9rnKxvRoZVpqw4EBJ2wUg8U2P4WicNeMfPxzZfmLey",
        "name": "Boryoku Dragonz #407",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/407.png"
    },
    "BNrfnQnk1Qautp6NnKf6RHXGL9MKgs5z5mdJHm1ghPTA": {
        "mint": "BNrfnQnk1Qautp6NnKf6RHXGL9MKgs5z5mdJHm1ghPTA",
        "name": "Boryoku Dragonz #420",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/420.png"
    },
    "ACGfeniTRjPqj8BeNdsqibrLSHnHxqAXap3MSz5cM8aN": {
        "mint": "ACGfeniTRjPqj8BeNdsqibrLSHnHxqAXap3MSz5cM8aN",
        "name": "Boryoku Dragonz #404",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/404.png"
    },
    "AcUzSYQPjupsUQ4gffEFXiL78LRjgWdwoS4qHhwWSzLN": {
        "mint": "AcUzSYQPjupsUQ4gffEFXiL78LRjgWdwoS4qHhwWSzLN",
        "name": "Boryoku Dragonz #403",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/403.png"
    },
    "FdCFjqwS6f71vyn9UePWN9ccZkoywKaSCNRhUTitVKaP": {
        "mint": "FdCFjqwS6f71vyn9UePWN9ccZkoywKaSCNRhUTitVKaP",
        "name": "Boryoku Dragonz #414",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/414.png"
    },
    "CkSAheYq6BcLTzszkPHuP2guajiSKmQHs2Jz6tpzX2AR": {
        "mint": "CkSAheYq6BcLTzszkPHuP2guajiSKmQHs2Jz6tpzX2AR",
        "name": "Boryoku Dragonz #395",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/395.png"
    },
    "7K7an4WhpQJc6WnksaQHN456kjnhWfqqc2Xyagcw7gkS": {
        "mint": "7K7an4WhpQJc6WnksaQHN456kjnhWfqqc2Xyagcw7gkS",
        "name": "Boryoku Dragonz #418",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/418.png"
    },
    "5RYz5NiAhNAzQu4mhnVWNPgtoFjYSCENoFeBgJ1q32bM": {
        "mint": "5RYz5NiAhNAzQu4mhnVWNPgtoFjYSCENoFeBgJ1q32bM",
        "name": "Boryoku Dragonz #413",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/413.png"
    },
    "8xAJQqMzHcsLf6guy94mKDQPgRHcY4zD6n343pufXbGL": {
        "mint": "8xAJQqMzHcsLf6guy94mKDQPgRHcY4zD6n343pufXbGL",
        "name": "Boryoku Dragonz #417",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/417.png"
    },
    "7vJokWtbtJKSDYPPiaG8Zjm5oLx2d68oTiHnvB3DGpvn": {
        "mint": "7vJokWtbtJKSDYPPiaG8Zjm5oLx2d68oTiHnvB3DGpvn",
        "name": "Boryoku Dragonz #410",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/410.png"
    },
    "26j126j7PbaXNBpZAhFj1cFsvYaXeQwsWEZXcS8DZUpN": {
        "mint": "26j126j7PbaXNBpZAhFj1cFsvYaXeQwsWEZXcS8DZUpN",
        "name": "Boryoku Dragonz #416",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/416.png"
    },
    "5NeQnZCsZsKkcJHyGa16S1xV4rvJHAVnUdocxqJ1V7dM": {
        "mint": "5NeQnZCsZsKkcJHyGa16S1xV4rvJHAVnUdocxqJ1V7dM",
        "name": "Boryoku Dragonz #391",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/391.png"
    },
    "Gu5tXgQAYHWkXvY8t3UAC1LoF9eaGKFvj8CvYkS7Aj5X": {
        "mint": "Gu5tXgQAYHWkXvY8t3UAC1LoF9eaGKFvj8CvYkS7Aj5X",
        "name": "Boryoku Dragonz #421",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/421.png"
    },
    "6mWWpFt1r75FKhTL9Fyok1G5FFmyk7S4sDKUq2eCqjMU": {
        "mint": "6mWWpFt1r75FKhTL9Fyok1G5FFmyk7S4sDKUq2eCqjMU",
        "name": "Boryoku Dragonz #398",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/398.png"
    },
    "56cxs4rBir8feM3X3KwpLG8aUToS9po3Ef8FxYGMgSZg": {
        "mint": "56cxs4rBir8feM3X3KwpLG8aUToS9po3Ef8FxYGMgSZg",
        "name": "Boryoku Dragonz #392",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/392.png"
    },
    "J9G1tUzjxtszT5vDeAFXM5NpLGLgJNGFrQ44JsKuwEUG": {
        "mint": "J9G1tUzjxtszT5vDeAFXM5NpLGLgJNGFrQ44JsKuwEUG",
        "name": "Boryoku Dragonz #397",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/397.png"
    },
    "DjkoCAuMQ9prZxRt4SpCSq96Ajp3iSxCoFoZRvCQAGvA": {
        "mint": "DjkoCAuMQ9prZxRt4SpCSq96Ajp3iSxCoFoZRvCQAGvA",
        "name": "Boryoku Dragonz #400",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/400.png"
    },
    "8HP9k4kQhASsiEravEYqPTc2TxGwQ5dF86aV5ACkgbXC": {
        "mint": "8HP9k4kQhASsiEravEYqPTc2TxGwQ5dF86aV5ACkgbXC",
        "name": "Boryoku Dragonz #405",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/405.png"
    },
    "6E7r9FCkB6AY6k6JzGo7j9QzK5XWn72sQwQTdi956PfG": {
        "mint": "6E7r9FCkB6AY6k6JzGo7j9QzK5XWn72sQwQTdi956PfG",
        "name": "Boryoku Dragonz #393",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/393.png"
    },
    "74aiSwzhXcddV8NkrYTDeZ8nc9EQrRqX16QF7HmwnPhd": {
        "mint": "74aiSwzhXcddV8NkrYTDeZ8nc9EQrRqX16QF7HmwnPhd",
        "name": "Boryoku Dragonz #1011",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1011.png"
    },
    "96UUqwUesLh1DrPicyipRyFfoesd8jiPVpUKYpdzu58c": {
        "mint": "96UUqwUesLh1DrPicyipRyFfoesd8jiPVpUKYpdzu58c",
        "name": "Boryoku Dragonz #1012",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1012.png"
    },
    "FBEa5T4hKEUcSAfjof7KUp3G9mw24sEAdviocFakfoH1": {
        "mint": "FBEa5T4hKEUcSAfjof7KUp3G9mw24sEAdviocFakfoH1",
        "name": "Boryoku Dragonz #1013",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1013.png"
    },
    "GVeWYeC2yvWUaoDuCa7QRVVtfXgPvXXn8sCLv5cVobxn": {
        "mint": "GVeWYeC2yvWUaoDuCa7QRVVtfXgPvXXn8sCLv5cVobxn",
        "name": "Boryoku Dragonz #1014",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1014.png"
    },
    "2TzQ8DMQ2HrDFLndeG5SvrMtB9dDdiPxh3DhRr64Y6tU": {
        "mint": "2TzQ8DMQ2HrDFLndeG5SvrMtB9dDdiPxh3DhRr64Y6tU",
        "name": "Boryoku Dragonz #1015",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1015.png"
    },
    "DvoKj5XqwhMaPVd2EdLqFhgrwGxpkK6cWX6mjpzhsKV3": {
        "mint": "DvoKj5XqwhMaPVd2EdLqFhgrwGxpkK6cWX6mjpzhsKV3",
        "name": "Boryoku Dragonz #1016",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1016.png"
    },
    "7L4CBkjrxiUn7CZ5LbgoyUPeHg8eBQRS6Kij7CaLAF4g": {
        "mint": "7L4CBkjrxiUn7CZ5LbgoyUPeHg8eBQRS6Kij7CaLAF4g",
        "name": "Boryoku Dragonz #1017",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1017.png"
    },
    "7P1zu1sZBqXZ8u73vtRiVDwEQ6h2gAcqqkcVsCQMAQMa": {
        "mint": "7P1zu1sZBqXZ8u73vtRiVDwEQ6h2gAcqqkcVsCQMAQMa",
        "name": "Boryoku Dragonz #1018",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Icecream"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1018.png"
    },
    "C1RKQ8TVg8xUviMV6c5neJwh2oAYQMKA1MCu3Fzy7s3b": {
        "mint": "C1RKQ8TVg8xUviMV6c5neJwh2oAYQMKA1MCu3Fzy7s3b",
        "name": "Boryoku Dragonz #1019",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1019.png"
    },
    "5XPPd7pdYXDgBusA7EFS5Lcd1B4yHmDWjDeRf1MLevyW": {
        "mint": "5XPPd7pdYXDgBusA7EFS5Lcd1B4yHmDWjDeRf1MLevyW",
        "name": "Boryoku Dragonz #1020",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1020.png"
    },
    "FVmJA2Y5MuA6YTtSL9wnndzKDPHYyHJYDtGnYctjLT9m": {
        "mint": "FVmJA2Y5MuA6YTtSL9wnndzKDPHYyHJYDtGnYctjLT9m",
        "name": "Boryoku Dragonz #1021",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1021.png"
    },
    "ES5UR7tXsSkQpN1dH9KQEGo1Tg2MgrNLacMN7DLfPx5v": {
        "mint": "ES5UR7tXsSkQpN1dH9KQEGo1Tg2MgrNLacMN7DLfPx5v",
        "name": "Boryoku Dragonz #1022",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1022.png"
    },
    "C7s9mNanncbPBRUg5DUzJEUaggALxSqiE5NXZZi1d324": {
        "mint": "C7s9mNanncbPBRUg5DUzJEUaggALxSqiE5NXZZi1d324",
        "name": "Boryoku Dragonz #1023",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1023.png"
    },
    "HvQiYj1QzUsuTDx5BvFg3sjFuzkBW7AvpQ2eEXLh1eRQ": {
        "mint": "HvQiYj1QzUsuTDx5BvFg3sjFuzkBW7AvpQ2eEXLh1eRQ",
        "name": "Boryoku Dragonz #1024",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1024.png"
    },
    "HqA1uaDghE9ezF7pPjWESxKnjpr1qgAWC9Q1yPYLNkM7": {
        "mint": "HqA1uaDghE9ezF7pPjWESxKnjpr1qgAWC9Q1yPYLNkM7",
        "name": "Boryoku Dragonz #1025",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1025.png"
    },
    "8FWA6qWtyMs2wMD4uD6e4rA6YcXabarvryGKxTqX3oR4": {
        "mint": "8FWA6qWtyMs2wMD4uD6e4rA6YcXabarvryGKxTqX3oR4",
        "name": "Boryoku Dragonz #1026",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1026.png"
    },
    "LREn2tMSgthfDiHjp1hxBg3a7n2p28MtrUzKyYfg7X3": {
        "mint": "LREn2tMSgthfDiHjp1hxBg3a7n2p28MtrUzKyYfg7X3",
        "name": "Boryoku Dragonz #1027",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1027.png"
    },
    "Fat5Vx4VV795u8Jff8aindNr4nzT6tJpWFn1JXJPH2kt": {
        "mint": "Fat5Vx4VV795u8Jff8aindNr4nzT6tJpWFn1JXJPH2kt",
        "name": "Boryoku Dragonz #1028",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1028.png"
    },
    "HL8z8xdUzeB1mWxM2K5m4x6ab3muHFjWEUYUehVzU6GM": {
        "mint": "HL8z8xdUzeB1mWxM2K5m4x6ab3muHFjWEUYUehVzU6GM",
        "name": "Boryoku Dragonz #1029",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1029.png"
    },
    "JAQWFzrbouj33UZDbLFAUVUcXxB1GQdzicrUYTWcqGjv": {
        "mint": "JAQWFzrbouj33UZDbLFAUVUcXxB1GQdzicrUYTWcqGjv",
        "name": "Boryoku Dragonz #1030",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1030.png"
    },
    "CdLoaBNfzrzjyCHj9VdUzQA5pywsPJt6aQVNuBbnqCbW": {
        "mint": "CdLoaBNfzrzjyCHj9VdUzQA5pywsPJt6aQVNuBbnqCbW",
        "name": "Boryoku Dragonz #1031",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1031.png"
    },
    "AvheJWQEch8J85MdpTzjhyeY99QBcFTtSwosjeSaAME5": {
        "mint": "AvheJWQEch8J85MdpTzjhyeY99QBcFTtSwosjeSaAME5",
        "name": "Boryoku Dragonz #1032",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1032.png"
    },
    "9DXcoZXiHzYCJNkLz6Vho12sDNzyntkoZmB9xou3SMNj": {
        "mint": "9DXcoZXiHzYCJNkLz6Vho12sDNzyntkoZmB9xou3SMNj",
        "name": "Boryoku Dragonz #1033",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1033.png"
    },
    "DFNavPdRpEPFqRpqcYAwAvGc9eL9aWB9GLJwidKqZbXo": {
        "mint": "DFNavPdRpEPFqRpqcYAwAvGc9eL9aWB9GLJwidKqZbXo",
        "name": "Boryoku Dragonz #1034",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1034.png"
    },
    "Bggzro1qko8uW8QaVTvYn6K42Z4LpyBUX4Y4jRq24Hbe": {
        "mint": "Bggzro1qko8uW8QaVTvYn6K42Z4LpyBUX4Y4jRq24Hbe",
        "name": "Boryoku Dragonz #1035",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1035.png"
    },
    "E7NTtn3j4FWkVyGk1KYKkFaaDeAAT7W3gSJdicRFq9Bc": {
        "mint": "E7NTtn3j4FWkVyGk1KYKkFaaDeAAT7W3gSJdicRFq9Bc",
        "name": "Boryoku Dragonz #1036",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1036.png"
    },
    "6KugRNG6zrmg5FyWph8DX6tBUQfWgXfG8Xzh6XopNULg": {
        "mint": "6KugRNG6zrmg5FyWph8DX6tBUQfWgXfG8Xzh6XopNULg",
        "name": "Boryoku Dragonz #1037",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1037.png"
    },
    "AJPNHAc7Wgr7uVFz3gfsDAEb2Z4Bqh3z78fCpkuK2SUm": {
        "mint": "AJPNHAc7Wgr7uVFz3gfsDAEb2Z4Bqh3z78fCpkuK2SUm",
        "name": "Boryoku Dragonz #1038",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1038.png"
    },
    "4de5RhfhPHwwLi2RkLZweAMBBw6GGC2fbNBqXTPF5kAN": {
        "mint": "4de5RhfhPHwwLi2RkLZweAMBBw6GGC2fbNBqXTPF5kAN",
        "name": "Boryoku Dragonz #1039",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1039.png"
    },
    "CqfyLEPoeH5VXGa9pfrHMb8u9oSsJN9tKKqo29QVXwXd": {
        "mint": "CqfyLEPoeH5VXGa9pfrHMb8u9oSsJN9tKKqo29QVXwXd",
        "name": "Boryoku Dragonz #1040",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1040.png"
    },
    "DfmLdycLVeYogbh3Pn2oFD1CDhCLt9AMCQri6tmfL9ji": {
        "mint": "DfmLdycLVeYogbh3Pn2oFD1CDhCLt9AMCQri6tmfL9ji",
        "name": "Boryoku Dragonz #1041",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1041.png"
    },
    "4JaXif3bAo8iSJNY57YSvak63ggeewDZmZ5197evrz5j": {
        "mint": "4JaXif3bAo8iSJNY57YSvak63ggeewDZmZ5197evrz5j",
        "name": "Boryoku Dragonz #1042",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1042.png"
    },
    "AiXjpn9B58fVocvXn3ZiUPtMJ9u2t544zUfgetRuv65E": {
        "mint": "AiXjpn9B58fVocvXn3ZiUPtMJ9u2t544zUfgetRuv65E",
        "name": "Boryoku Dragonz #1043",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1043.png"
    },
    "9rS2oAUTrPGug43yWZwTHiLk76jx8QHx27PT9XW914vJ": {
        "mint": "9rS2oAUTrPGug43yWZwTHiLk76jx8QHx27PT9XW914vJ",
        "name": "Boryoku Dragonz #1044",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1044.png"
    },
    "EpoEJGWqmMQrRLnQJisoKX9LNCpgBgPDZYnwAR8fkB9o": {
        "mint": "EpoEJGWqmMQrRLnQJisoKX9LNCpgBgPDZYnwAR8fkB9o",
        "name": "Boryoku Dragonz #1045",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1045.png"
    },
    "63SNj3WQpMwkVe4CCvQV1ANVow5Bk1CxPjcrNoCBFMXR": {
        "mint": "63SNj3WQpMwkVe4CCvQV1ANVow5Bk1CxPjcrNoCBFMXR",
        "name": "Boryoku Dragonz #1046",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Flame"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1046.png"
    },
    "EnWpQvqmXoMhj8MyuJDK8QyY554Spqv5anE5x11pepFD": {
        "mint": "EnWpQvqmXoMhj8MyuJDK8QyY554Spqv5anE5x11pepFD",
        "name": "Boryoku Dragonz #1047",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1047.png"
    },
    "Cst4gQ1CLxFBHdXFeevAiLgMWqW3V5ZezqE9p6c4UGck": {
        "mint": "Cst4gQ1CLxFBHdXFeevAiLgMWqW3V5ZezqE9p6c4UGck",
        "name": "Boryoku Dragonz #1048",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1048.png"
    },
    "2er4V8vsXuwYdnumEP8mEEGAV5rYuUPNxwzSbK2hhN56": {
        "mint": "2er4V8vsXuwYdnumEP8mEEGAV5rYuUPNxwzSbK2hhN56",
        "name": "Boryoku Dragonz #1049",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1049.png"
    },
    "5LZQjzwbB5JxeVu7VHMxUBexvqwLWnTbNTNDo2YvYDod": {
        "mint": "5LZQjzwbB5JxeVu7VHMxUBexvqwLWnTbNTNDo2YvYDod",
        "name": "Boryoku Dragonz #1050",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1050.png"
    },
    "E3gZmYWCZsSjBfRAoM6ZuFchUr6nMLwCK5XNWSCXEcfw": {
        "mint": "E3gZmYWCZsSjBfRAoM6ZuFchUr6nMLwCK5XNWSCXEcfw",
        "name": "Boryoku Dragonz #1051",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1051.png"
    },
    "Eg7WiHXcsL5MUTTGNM5QVH3oUAExb2UnP8VKTy4Him9o": {
        "mint": "Eg7WiHXcsL5MUTTGNM5QVH3oUAExb2UnP8VKTy4Him9o",
        "name": "Boryoku Dragonz #1052",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1052.png"
    },
    "AzqdqTVcSvRamBZiAQz4agsQnQMySrfdxukxLssiwURg": {
        "mint": "AzqdqTVcSvRamBZiAQz4agsQnQMySrfdxukxLssiwURg",
        "name": "Boryoku Dragonz #1053",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1053.png"
    },
    "GYgcNWkGS6KyAbqhw1TAEc3UhmFrCJxnSPFUPdeVEv43": {
        "mint": "GYgcNWkGS6KyAbqhw1TAEc3UhmFrCJxnSPFUPdeVEv43",
        "name": "Boryoku Dragonz #1054",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1054.png"
    },
    "Aka2PHfrhex2kdqYxtXVxN5g1rTjmmtP6BGhKFzVAmzQ": {
        "mint": "Aka2PHfrhex2kdqYxtXVxN5g1rTjmmtP6BGhKFzVAmzQ",
        "name": "Boryoku Dragonz #1055",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1055.png"
    },
    "4k9YpEizRxiDRidfUH14xEHexcyeE2XdNqEzvKbLNQFT": {
        "mint": "4k9YpEizRxiDRidfUH14xEHexcyeE2XdNqEzvKbLNQFT",
        "name": "Boryoku Dragonz #1056",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1056.png"
    },
    "7uZr1tRX3q8WHutGuRvLjPx68b2nvdvLj8GGDbCS5aJf": {
        "mint": "7uZr1tRX3q8WHutGuRvLjPx68b2nvdvLj8GGDbCS5aJf",
        "name": "Boryoku Dragonz #1057",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1057.png"
    },
    "H4v8cayWtDbLcb52Yx2pAaCsiyJQr4jf1Fr5aNFsKttT": {
        "mint": "H4v8cayWtDbLcb52Yx2pAaCsiyJQr4jf1Fr5aNFsKttT",
        "name": "Boryoku Dragonz #1058",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1058.png"
    },
    "4KD8zbxG8qVgg5gn5TRKYeCCwDbejsAc3fxv9HRhmXJ4": {
        "mint": "4KD8zbxG8qVgg5gn5TRKYeCCwDbejsAc3fxv9HRhmXJ4",
        "name": "Boryoku Dragonz #1059",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1059.png"
    },
    "6WqvKADNvmBKY3eTz93tps4EYBnV5ZayMpEPqDVvJqQe": {
        "mint": "6WqvKADNvmBKY3eTz93tps4EYBnV5ZayMpEPqDVvJqQe",
        "name": "Boryoku Dragonz #1060",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1060.png"
    },
    "BNpumnhTTtMvDc3f3XSsdbToWKWyxZjJCHGQm2Z74Z8W": {
        "mint": "BNpumnhTTtMvDc3f3XSsdbToWKWyxZjJCHGQm2Z74Z8W",
        "name": "Boryoku Dragonz #1061",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1061.png"
    },
    "Gh6FMe74sa6VGrQjtdw3CUpfUHQWvSAeaFrgW4dj5Us2": {
        "mint": "Gh6FMe74sa6VGrQjtdw3CUpfUHQWvSAeaFrgW4dj5Us2",
        "name": "Boryoku Dragonz #1062",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1062.png"
    },
    "6m8yUiWsMLhqyuCatjEorXP69wyAVxbap1EKZrWgnMwP": {
        "mint": "6m8yUiWsMLhqyuCatjEorXP69wyAVxbap1EKZrWgnMwP",
        "name": "Boryoku Dragonz #1063",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1063.png"
    },
    "EWmZAWqe8tJVd1FwyanJoXgXBagoyq3Psu7V4NEtJu84": {
        "mint": "EWmZAWqe8tJVd1FwyanJoXgXBagoyq3Psu7V4NEtJu84",
        "name": "Boryoku Dragonz #1064",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1064.png"
    },
    "A76TDzd42aQpdkrKzymCbWz66CwPdvUwjKLKgCwK2XRk": {
        "mint": "A76TDzd42aQpdkrKzymCbWz66CwPdvUwjKLKgCwK2XRk",
        "name": "Boryoku Dragonz #1065",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1065.png"
    },
    "98m8LYs4ayVngAVdP5QKsvxWnYu5M599ZBnp3Xz9xSYB": {
        "mint": "98m8LYs4ayVngAVdP5QKsvxWnYu5M599ZBnp3Xz9xSYB",
        "name": "Boryoku Dragonz #1066",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Bone"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1066.png"
    },
    "6ZkJnsyhCuJ2JVcpmotvZ4rpBzw4VvFNnoNvuwFxwcFu": {
        "mint": "6ZkJnsyhCuJ2JVcpmotvZ4rpBzw4VvFNnoNvuwFxwcFu",
        "name": "Boryoku Dragonz #1067",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1067.png"
    },
    "F7rK75VeJ4CfjC9H9suWzSBDAv8pDvj1XHM1yNBhgNfr": {
        "mint": "F7rK75VeJ4CfjC9H9suWzSBDAv8pDvj1XHM1yNBhgNfr",
        "name": "Boryoku Dragonz #1068",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1068.png"
    },
    "5QQUAJpw8bLEQxRCYqEkkh7VQURUMHnDhuxXjJoNJoMX": {
        "mint": "5QQUAJpw8bLEQxRCYqEkkh7VQURUMHnDhuxXjJoNJoMX",
        "name": "Boryoku Dragonz #1069",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Golden"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Cigar"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1069.png"
    },
    "FPCo9LrtudyU11HiZBXUjS53XaEpeNU8hp2BZS3KAysM": {
        "mint": "FPCo9LrtudyU11HiZBXUjS53XaEpeNU8hp2BZS3KAysM",
        "name": "Boryoku Dragonz #1070",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1070.png"
    },
    "3LxYVerF4q8tSMvTjFW1aSD3CWx1qqBpmqKvAWXPVT56": {
        "mint": "3LxYVerF4q8tSMvTjFW1aSD3CWx1qqBpmqKvAWXPVT56",
        "name": "Boryoku Dragonz #1071",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1071.png"
    },
    "5mQR49rTm95WHyscxvAi12qdDsPcDPU9nWuL3HiHp5mr": {
        "mint": "5mQR49rTm95WHyscxvAi12qdDsPcDPU9nWuL3HiHp5mr",
        "name": "Boryoku Dragonz #1072",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1072.png"
    },
    "FsAVnZVTWqkoL7m4FrmRiFZqqgUhrcvvAmBM7ZcZT1Mr": {
        "mint": "FsAVnZVTWqkoL7m4FrmRiFZqqgUhrcvvAmBM7ZcZT1Mr",
        "name": "Boryoku Dragonz #1073",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1073.png"
    },
    "Fs6nQh4jDZqUegz3bCGDn1s8FUyiAmCdT4v7PEfVdT71": {
        "mint": "Fs6nQh4jDZqUegz3bCGDn1s8FUyiAmCdT4v7PEfVdT71",
        "name": "Boryoku Dragonz #1074",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Purple"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Blue Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1074.png"
    },
    "J95t8w3rVYekVBtQnXFR8KW7yVzSkmT3CT2bjLi6TXei": {
        "mint": "J95t8w3rVYekVBtQnXFR8KW7yVzSkmT3CT2bjLi6TXei",
        "name": "Boryoku Dragonz #1075",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Golden"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1075.png"
    },
    "F3PcrZaF487k3UZwMQxtogiicAT7wgipgnBhqwZbYu8w": {
        "mint": "F3PcrZaF487k3UZwMQxtogiicAT7wgipgnBhqwZbYu8w",
        "name": "Boryoku Dragonz #1076",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1076.png"
    },
    "6JpXtoUGtFbR7feJ2PpzPB96bT7tuJG4o8nYuZGLLFH6": {
        "mint": "6JpXtoUGtFbR7feJ2PpzPB96bT7tuJG4o8nYuZGLLFH6",
        "name": "Boryoku Dragonz #1077",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1077.png"
    },
    "F3VyY1zhiQXJxWCjJvinn5xUK9ziwFpqPtzbTN2TpacY": {
        "mint": "F3VyY1zhiQXJxWCjJvinn5xUK9ziwFpqPtzbTN2TpacY",
        "name": "Boryoku Dragonz #1078",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Nuclear Plant"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1078.png"
    },
    "JAMGJsSarGfvEhxPYmWAPAfGQ1uDJjo1BhMGhB3nh63m": {
        "mint": "JAMGJsSarGfvEhxPYmWAPAfGQ1uDJjo1BhMGhB3nh63m",
        "name": "Boryoku Dragonz #1079",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Grey"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1079.png"
    },
    "77xf9PMu3sGvyVwdzVCNvP5SYV5jxWo7LUyzA3ngn2d7": {
        "mint": "77xf9PMu3sGvyVwdzVCNvP5SYV5jxWo7LUyzA3ngn2d7",
        "name": "Boryoku Dragonz #1080",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Yellow"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1080.png"
    },
    "mUDtfV3qso9Lg95kSDZ8rqx1DK2qWAU1gxJwdPzUMuW": {
        "mint": "mUDtfV3qso9Lg95kSDZ8rqx1DK2qWAU1gxJwdPzUMuW",
        "name": "Boryoku Dragonz #1081",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1081.png"
    },
    "H4gqJYktvBAWgxzGppJ6WukNcAVAGF7suvRwxwYgkaPX": {
        "mint": "H4gqJYktvBAWgxzGppJ6WukNcAVAGF7suvRwxwYgkaPX",
        "name": "Boryoku Dragonz #1082",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1082.png"
    },
    "qm61PChWVdmfnvfZmtBDWrKbMFFJ58UgHWd8M4tPHgx": {
        "mint": "qm61PChWVdmfnvfZmtBDWrKbMFFJ58UgHWd8M4tPHgx",
        "name": "Boryoku Dragonz #1083",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Zombie Blue"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1083.png"
    },
    "3GeApHpJCR1zUcMyPmrFYtZjiqEPTS6YE9uQaT84Xey2": {
        "mint": "3GeApHpJCR1zUcMyPmrFYtZjiqEPTS6YE9uQaT84Xey2",
        "name": "Boryoku Dragonz #1084",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1084.png"
    },
    "5DAi8opFiemvAg42wrSzqMgWf2M4Gx5Dj4C2FLp3GSXn": {
        "mint": "5DAi8opFiemvAg42wrSzqMgWf2M4Gx5Dj4C2FLp3GSXn",
        "name": "Boryoku Dragonz #1085",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "White Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1085.png"
    },
    "EKrSbv7LVx5FyEuxXG27kMSLTJ4PHuW19StsUng3QV3t": {
        "mint": "EKrSbv7LVx5FyEuxXG27kMSLTJ4PHuW19StsUng3QV3t",
        "name": "Boryoku Dragonz #1086",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laser Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1086.png"
    },
    "9zJ9kB1W9UAKAAB3aaHMBTTj5jgSHrpXXH9SskiyKiXx": {
        "mint": "9zJ9kB1W9UAKAAB3aaHMBTTj5jgSHrpXXH9SskiyKiXx",
        "name": "Boryoku Dragonz #1087",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1087.png"
    },
    "GTRc5BdYwhSUHxQj7Lx7Q1rD75JdCc7U2iyzJWmSB7yE": {
        "mint": "GTRc5BdYwhSUHxQj7Lx7Q1rD75JdCc7U2iyzJWmSB7yE",
        "name": "Boryoku Dragonz #1088",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1088.png"
    },
    "4S6Xidtph62DqMKXJCViD384YPoTXThto4C3rTnW3pa6": {
        "mint": "4S6Xidtph62DqMKXJCViD384YPoTXThto4C3rTnW3pa6",
        "name": "Boryoku Dragonz #1089",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1089.png"
    },
    "BYK3981EjgM62QRXK9jqpNfgSTAa9wKDrFo1oYsJm9dB": {
        "mint": "BYK3981EjgM62QRXK9jqpNfgSTAa9wKDrFo1oYsJm9dB",
        "name": "Boryoku Dragonz #1090",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Orange"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Beige"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1090.png"
    },
    "9JbQc6QxtdyvvJJ815dwzciUmrP1CjUB37UNTZcazsgY": {
        "mint": "9JbQc6QxtdyvvJJ815dwzciUmrP1CjUB37UNTZcazsgY",
        "name": "Boryoku Dragonz #1091",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Classic Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1091.png"
    },
    "8xVUqWiZErj3hT9ujJRfrcSqSnzs7psWAwF8woC3ijzj": {
        "mint": "8xVUqWiZErj3hT9ujJRfrcSqSnzs7psWAwF8woC3ijzj",
        "name": "Boryoku Dragonz #1092",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1092.png"
    },
    "Et3bkVrFLDJBxsATN53MhAesN4DfeqVNtpgL1B2WSxDj": {
        "mint": "Et3bkVrFLDJBxsATN53MhAesN4DfeqVNtpgL1B2WSxDj",
        "name": "Boryoku Dragonz #1093",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1093.png"
    },
    "EneJxxZRNhTzZcrHk3UJXfKwDpywJGSUm5DZ2shLdgY9": {
        "mint": "EneJxxZRNhTzZcrHk3UJXfKwDpywJGSUm5DZ2shLdgY9",
        "name": "Boryoku Dragonz #1094",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "3D Glasses"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Silver Chain"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1094.png"
    },
    "4zL8adkQTVupDfoNnd97V2GN9rsZyTMYRe5DAU4QwN4d": {
        "mint": "4zL8adkQTVupDfoNnd97V2GN9rsZyTMYRe5DAU4QwN4d",
        "name": "Boryoku Dragonz #1095",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "Rainbow Puke"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1095.png"
    },
    "9YdxRZGqgvbV91GTLuYA2RJsKPd7TrmNdGccaQ6Jy3hu": {
        "mint": "9YdxRZGqgvbV91GTLuYA2RJsKPd7TrmNdGccaQ6Jy3hu",
        "name": "Boryoku Dragonz #1096",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1096.png"
    },
    "GsnMtDGsW3zc61wbb6W5iD7SN9YuLUsstYAz7anNGLfm": {
        "mint": "GsnMtDGsW3zc61wbb6W5iD7SN9YuLUsstYAz7anNGLfm",
        "name": "Boryoku Dragonz #1097",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Blue"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Pink"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1097.png"
    },
    "JB6Wy7GooznUrpB8nNxnrnbAYVM5AwSfpuzGpwK4qZ4V": {
        "mint": "JB6Wy7GooznUrpB8nNxnrnbAYVM5AwSfpuzGpwK4qZ4V",
        "name": "Boryoku Dragonz #1098",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Collar"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1098.png"
    },
    "6JcQfddRuxBCK5onRZt54MaYSK6Vie2JcJvUJBq8dMbR": {
        "mint": "6JcQfddRuxBCK5onRZt54MaYSK6Vie2JcJvUJBq8dMbR",
        "name": "Boryoku Dragonz #1099",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Laser Ray"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Japanese"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1099.png"
    },
    "7QmeUEv7ba2taNxxRgpBoSxAJLssA1TV1LFhavVvwWTS": {
        "mint": "7QmeUEv7ba2taNxxRgpBoSxAJLssA1TV1LFhavVvwWTS",
        "name": "Boryoku Dragonz #1100",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Green"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1100.png"
    },
    "CBm7GteYyH3F6dsdUy8iC2ZJbJGwbxcmAL7CPnFa3GwF": {
        "mint": "CBm7GteYyH3F6dsdUy8iC2ZJbJGwbxcmAL7CPnFa3GwF",
        "name": "Boryoku Dragonz #1101",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "Eye"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1101.png"
    },
    "3PXFvR3UJWPNjcK8bwVxWR2XjkNTrczLzvAUjmr2Nz5r": {
        "mint": "3PXFvR3UJWPNjcK8bwVxWR2XjkNTrczLzvAUjmr2Nz5r",
        "name": "Boryoku Dragonz #1102",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Skeleton Silver"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Electro"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Teal"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1102.png"
    },
    "GJca1RVZw4TBRCgk4wax76UrSFfjioGBV9MArtRtsC9v": {
        "mint": "GJca1RVZw4TBRCgk4wax76UrSFfjioGBV9MArtRtsC9v",
        "name": "Boryoku Dragonz #1103",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cyber Grey"
            },
            {
                "trait_type": "Head",
                "value": "Octopus"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Lavender"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1103.png"
    },
    "9Heyc6vpvhrbvdgSTE6X4S7AnQkCzpBaeTrignr2Ektk": {
        "mint": "9Heyc6vpvhrbvdgSTE6X4S7AnQkCzpBaeTrignr2Ektk",
        "name": "Boryoku Dragonz #1104",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Red"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Tracker"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Blue"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1104.png"
    },
    "2PjVTwdNtgs2vrvBXFhq5r7ksGo3fWAobu7FhbMyeHwR": {
        "mint": "2PjVTwdNtgs2vrvBXFhq5r7ksGo3fWAobu7FhbMyeHwR",
        "name": "Boryoku Dragonz #1105",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Inferno Black"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Snake Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Pacifier"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Grey"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1105.png"
    },
    "HsCye2eznqkRft6QtQH1SwbDdLiQAahg4RowWEN34u36": {
        "mint": "HsCye2eznqkRft6QtQH1SwbDdLiQAahg4RowWEN34u36",
        "name": "Boryoku Dragonz #1107",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Purple"
            },
            {
                "trait_type": "Head",
                "value": "Croc"
            },
            {
                "trait_type": "Eyes",
                "value": "Vipers"
            },
            {
                "trait_type": "Mouth",
                "value": "Ice Breath"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1107.png"
    },
    "6a8jafouBr23AuDrbXdn5JZ46boySACpmTMk98sjVmxh": {
        "mint": "6a8jafouBr23AuDrbXdn5JZ46boySACpmTMk98sjVmxh",
        "name": "Boryoku Dragonz #1108",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Diamond Purple"
            },
            {
                "trait_type": "Head",
                "value": "Monkey"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1108.png"
    },
    "DqUzpRkSg7LF4VyirSBVykkMN4xByScgthsJozmbNcKz": {
        "mint": "DqUzpRkSg7LF4VyirSBVykkMN4xByScgthsJozmbNcKz",
        "name": "Boryoku Dragonz #1109",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Neon Blue"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Red Eyes"
            },
            {
                "trait_type": "Mouth",
                "value": "Bubble Gum"
            },
            {
                "trait_type": "Neck",
                "value": "None"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1109.png"
    },
    "8orNH4ND7FQJhfWLJzpkjhJhJEmGnNnfKq3Y19QqVvAJ": {
        "mint": "8orNH4ND7FQJhfWLJzpkjhJhJEmGnNnfKq3Y19QqVvAJ",
        "name": "Boryoku Dragonz #1110",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "None"
            },
            {
                "trait_type": "Eyes",
                "value": "Laughing Goggles"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Golden Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1110.png"
    },
    "6dRKFpFjYEcjCdUKDEuLpAWKaKauKojk3rFtDEERaGCe": {
        "mint": "6dRKFpFjYEcjCdUKDEuLpAWKaKauKojk3rFtDEERaGCe",
        "name": "Boryoku Dragonz #1111",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Cosmic Green"
            },
            {
                "trait_type": "Head",
                "value": "Slug"
            },
            {
                "trait_type": "Eyes",
                "value": "Golden Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "Acid"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Town"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/1111.png"
    },
    "8FM5AMXjjSzaTWfkjFAgBpajT19noHc3XDRxj2uNDTHx": {
        "mint": "8FM5AMXjjSzaTWfkjFAgBpajT19noHc3XDRxj2uNDTHx",
        "name": "Boryoku Dragonz #754",
        "traits": [
            {
                "trait_type": "Generation",
                "value": "Genesis"
            },
            {
                "trait_type": "Type",
                "value": "Iron Yellow"
            },
            {
                "trait_type": "Head",
                "value": "Fireball"
            },
            {
                "trait_type": "Eyes",
                "value": "Synthwave Shades"
            },
            {
                "trait_type": "Mouth",
                "value": "None"
            },
            {
                "trait_type": "Neck",
                "value": "Diamond Chain"
            },
            {
                "trait_type": "Background",
                "value": "Tron"
            }
        ],
        "image": "https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/754.png"
    }
}

export default dragonz