import React from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const Nav = () => {
  return (
    <nav>
      <div className="content-wrapper navigation-bar">
        <a href="/">
          <img className="nav--logo" src="../home/boryoku-dragons-logo.png" />
        </a>
        <div className="is-flex nav-main--wrapper">
          <input type="checkbox" className="menu-trigger" />
          <div className="menu-trigger--wrapper">
            <div className="nav-main--menu-label">MENU</div>
          </div>
          <ul className="nav-main">
            <li>
              <Link to="/breed" className="link">
                Breed
              </Link>
            </li>
            <li>
              <Link to="/rarity" className="link">
                Rarity
              </Link>
            </li>
            <li>
              <Link to="/genesis" className="link">
                Dragonz
              </Link>
            </li>
            <li>
              <Link to="/eggz" className="link">
                Eggz
              </Link>
            </li>
            <li>
              <Link to="/merch/redeem" className="link">
                Merch
              </Link>
            </li>{" "}
            <li>
              <a
                href="https://pub.boryokudragonz.io/"
                target="_blank"
                className="link boryoku-pub-link"
              >
                Pub
              </a>
            </li>
          </ul>
          <ul className="social ml-4">
            <li>
              <a
                href="https://discord.gg/jSUWCYm3rF"
                target="_blank"
                rel="noreferrer"
              >
                <img src="../social/discord.svg" alt="Discord" />
              </a>
            </li>
            <li>
              <a
                className="ml-4"
                href="https://twitter.com/boryokudragonz"
                target="_blank"
                rel="noreferrer"
              >
                <img src="../social/twitter.svg" alt="Twitter" />
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
