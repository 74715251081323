import { useState } from "react";
import styled from "styled-components";
import "./Merch.css";
import "../BlockList.css";
import * as anchor from "@project-serum/anchor";
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import { shortenAddress } from "../candy-machine";
import Box from "@material-ui/core/Box";
import Modal from "@material-ui/core/Modal";
import Fade from "@material-ui/core/Fade";
import Backdrop from "@material-ui/core/Backdrop";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import CountryList from "./countries.js";
import Snackbar from "@material-ui/core/Snackbar";
import ErrorOutline from "@material-ui/icons/ErrorOutline";
import CheckCircleOutline from "@material-ui/icons/CheckCircleOutline";
import { getMetadata, getProgram } from "../contracts/utils";
import { decodeMetadata } from "../utils/metadata.service";
import useHoodieNft, { IHoodiezNft, INft } from "../hooks/useHoodieNft";
import { MERCH_PROGRAM_ID, redeemMerch } from "../contracts/merch";
import { PublicKey } from "@solana/web3.js";

const ConnectButton = styled(WalletDialogButton)`
  padding: 0.5rem 1.25rem !important;
  border-radius: 0 !important;
  background-color: var(--bd-color-primary) !important;
  border: 1px solid var(--bd-color-accent) !important;
  box-shadow: 6px 6px 0 rgba(0, 0, 0, 0.3) !important;
`;

let redeemUrl =
  "https://binaramics.com:6969/redeem";
let usdcMint = new PublicKey("EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v");
if (process.env.REACT_APP_ENV === "dev") {
  redeemUrl =
    "https://binaramics.com:6969/redeem";
  /* redeemUrl = "http://localhost:3000/dev/redeem"; */
  usdcMint = new PublicKey("B2AXk7dMgfVyTx6LksETwVqtKpTBXtjnCshFit5VxMWa");
}

export interface HomeProps {
  connection: anchor.web3.Connection;
}

export interface IOrder {
  name: string;
  discord: string;
  phone: string;
  address1: string;
  address2: string;
  country: string;
  city: string;
  state: string;
  postalcode: string;
  size: string;
  hoodie: string;
  txid: string;
}

export interface RedeemProps {
  connection: anchor.web3.Connection;
}

const Stake = (props: RedeemProps) => {
  const connection = props.connection;
  const wallet = useAnchorWallet();
  const vertical = "top";
  const horizontal = "center";
  const { isLoading, nfts, setNfts } = useHoodieNft(connection);

  const countries = CountryList;
  const [open, setOpen] = useState(false);
  const [style, setStyle] = useState("basic");
  const [size, setSize] = useState("");
  const [hoodie, setHoodie] = useState("");
  const [country, setCountry] = useState("");
  const [hasRedeemed, setHasRedeemed] = useState(false);
  const [phoneValue, setPhoneValue] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isRedeemLoading, setIsRedeemLoading] = useState(false);

  const handleCloseErrorMessage = () => {
    setShowErrorMessage(false);
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  const handleChangeStyle = (event: any) => {
    setStyle(event.target.value);
  };

  const handleChangeHoodie = (event: any) => {
    setHoodie(event.target.value);
  };

  const handleChangeSize = (event: any) => {
    setSize(event.target.value);
  };

  const handleChangeCountry = (event: any) => {
    setCountry(event.target.value);
  };

  const handleOpenRedeem = () => {
    setOpen(true);
  };
  const handleCloseRedeem = () => {
    setCountry("");
    setHoodie("");
    setSize("");
    setPhoneValue("");
    setOpen(false);
  };

  const validatePhoneChange = (event: any) => {
    setPhoneValue(event.target.value.replace(/\D/g, ""));
  };

  const onRedeemClick = async () => {
    var name = document.getElementById("name");
    var phone_number = document.getElementById("phone_number");
    var address1 = document.getElementById("address");
    var address2 = document.getElementById("address2");
    var city = document.getElementById("city");
    var state = document.getElementById("state");
    var postal_code = document.getElementById("postal_code");
    var discord = document.getElementById("discord");

    if (name === null || (name as HTMLInputElement).value === "") {
      setErrorMessage("Fill in Name");
      setShowErrorMessage(true);
      return;
    }
    if (discord === null || (discord as HTMLInputElement).value === "") {
      setErrorMessage("Fill in Discord ID");
      setShowErrorMessage(true);
      return;
    }
    if (
      phone_number === null ||
      (phone_number as HTMLInputElement).value === ""
    ) {
      setErrorMessage("Fill in Phone Number");
      setShowErrorMessage(true);
      return;
    }
    if (address1 === null || (address1 as HTMLInputElement).value === "") {
      setErrorMessage("Fill in Address");
      setShowErrorMessage(true);
      return;
    }
    if (city === null || (city as HTMLInputElement).value === "") {
      setErrorMessage("Fill in City");
      setShowErrorMessage(true);
      return;
    }
    if (state === null || (state as HTMLInputElement).value === "") {
      setErrorMessage("Fill in State");
      setShowErrorMessage(true);
      return;
    }
    if (
      postal_code === null ||
      (postal_code as HTMLInputElement).value === ""
    ) {
      setErrorMessage("Fill in Postal Code");
      setShowErrorMessage(true);
      return;
    }
    if (country === null || country === "") {
      setErrorMessage("Please select your Country");
      setShowErrorMessage(true);
      return;
    }
    if (size === null || size === "") {
      setErrorMessage("Please select your Size");
      setShowErrorMessage(true);
      return;
    }
    if (hoodie === null || hoodie === "") {
      setErrorMessage("Please select your Hoodie");
      setShowErrorMessage(true);
      return;
    }
    setIsRedeemLoading(true);

    try {
      const program = await getProgram(
        wallet as anchor.Wallet,
        MERCH_PROGRAM_ID,
        connection
      );

      const txid = await redeemMerch(
        program,
        connection,
        wallet!.publicKey,
        new PublicKey(hoodie),
        usdcMint
      );

      const order: IOrder = {
        txid: txid,
        name: (name as HTMLInputElement).value,
        discord: (discord as HTMLInputElement).value,
        phone: (phone_number as HTMLInputElement).value,
        address1: (address1 as HTMLInputElement).value,
        address2: (address2 as HTMLInputElement).value,
        country: country,
        city: (city as HTMLInputElement).value,
        state: (state as HTMLInputElement).value,
        postalcode: (postal_code as HTMLInputElement).value,
        size: size,
        hoodie: hoodie,
      };

      const response = await fetch(redeemUrl, {
        method: "POST",
        body: JSON.stringify({ txid, order }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        setShowErrorMessage(true);
        setIsRedeemLoading(false);
        console.log(response);
        setErrorMessage(
          `Error processing order, please try again. ${response.status}: ${response.statusText}`
        );
      }
      if (response.ok && response.body !== null) {
        setIsRedeemLoading(false);
        setSuccessMessage(
          "Your order has been successfully sent for redemption."
        );
        setShowSuccessMessage(true);
        handleCloseRedeem();
      }
    } catch (e) {
      setIsRedeemLoading(false);
      console.error(e);
      setShowErrorMessage(true);
      setErrorMessage(`Error processing order, ${e}`);
    }
  };

  const checkEligibility = () => {
    if (!wallet) {
      return (
        <ConnectButton className="mt-3 mb-3">Connect Wallet</ConnectButton>
      );
    }
    if (isLoading || !nfts) {
      return "Checking wallet for hoodie";
    }
    if (nfts.hoodiez.length > 0) {
      return (
        <button
          type="button"
          className="block-list__btn select-btn has-box-shadow-small is-uppercase"
          onClick={handleOpenRedeem}
        >
          Redeem
        </button>
      );
    }
    return "Can't redeem because you got no hoodie.";
  };

  const getHoodieList = () => {
    var menuList = [];
    if (isLoading || !nfts) {
      return;
    }
    if (nfts.hoodiez.length > 0) {
      for (let index in nfts.hoodiez) {
        menuList.push(
          <MenuItem value={nfts.hoodiez[index].mint}>
            {nfts.hoodiez[index].name}
          </MenuItem>
        );
      }
    }
    return menuList;
  };

  return (
    <main>
      <section>
        <Snackbar
          autoHideDuration={60000}
          anchorOrigin={{ vertical, horizontal }}
          open={showSuccessMessage}
          onClose={handleCloseSuccessMessage}
        >
          <div className="notification is-success">
            <CheckCircleOutline className="m-r-sm" />
            {successMessage}
          </div>
        </Snackbar>
        <Snackbar
          autoHideDuration={60000}
          anchorOrigin={{ vertical, horizontal }}
          open={showErrorMessage}
          onClose={handleCloseErrorMessage}
        >
          <div className="notification is-error">
            <ErrorOutline className="m-r-sm" />
            {errorMessage}
          </div>
        </Snackbar>
        {isRedeemLoading ? (
          <div className="loading-container">
            <div className="loading-text">
              COME ALONG - LETS TAKE A RIDE - INTO THE METABUS
            </div>
            <div className="loading-overlay">
              <img className="loading-gif" src="../../metabus.gif" />
            </div>
          </div>
        ) : (
          ""
        )}
        <header className="header">
          <Nav />
          <h1 className="heading-main is-flex-justify-center is-uppercase u-margin-top_large">
            <img src="../title-arrow.svg" />
            Redeem Merch
          </h1>
          <div className="is-flex is-flex-justify-center">
            {!wallet ? (
              <ConnectButton className="mt-3 mb-3">
                Connect Wallet
              </ConnectButton>
            ) : (
              <p className="pb-5">
                Wallet {shortenAddress(wallet.publicKey.toBase58() || "")}
              </p>
            )}
          </div>
        </header>

        <div className="content-wrapper">
          <h1 className="block-list__title heading-main heading-indented mb-4 is-uppercase">
            <img src="../title-arrow.svg" />
            Redeemables
          </h1>
          <div className="block-list-wrapper has-box-shadow">
            <div className="row">
              <div className="col-sm u-text-align_center">
                <div className="block-list mt-4 mb-4">
                  <video width="440" height="440" autoPlay muted loop>
                    <source src="/merch/hoodie-bokulogo.mp4" type="video/mp4" />
                  </video>
                  <div className="mb-1">
                    <div className=" mt-3 mb-3">
                      Boryoku Hoodiez 1st Edition
                    </div>
                    <div className=" mt-3 mb-3">
                      <p>Redemption will</p>
                      <small>1) Incur a 69 USDC fee</small>
                      <br />
                      <small>2) Burn 1x Boryoku Hoodiez 1st Edition NFT</small>
                    </div>

                    {checkEligibility()}
                    <Modal
                      open={open}
                      onClose={handleCloseRedeem}
                      closeAfterTransition
                      BackdropComponent={Backdrop}
                      BackdropProps={{
                        timeout: 500,
                      }}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Fade in={open}>
                        <Box className="merch-modal has-box-shadow">
                          {!hasRedeemed && (
                            <div>
                              <h2>Redeem your hoodie</h2>
                              <div className="merch-modal--form-fields-wrapper">
                                <div className="merch-modal--img-wrapper redeem-modal-video">
                                  <video
                                    className="mt-4"
                                    width="260"
                                    height="260"
                                    autoPlay
                                    muted
                                    loop
                                  >
                                    <source
                                      src="/merch/hoodie-bokulogo.mp4"
                                      type="video/mp4"
                                      className="img-fluid mt-3"
                                    />
                                  </video>
                                </div>
                                <fieldset>
                                  <TextField
                                    className="name-input input-field"
                                    id="name"
                                    label="Name"
                                    type="text"
                                  />
                                  <TextField
                                    className="mt-2 discord-input input-field"
                                    id="discord"
                                    label="Discord ID: e.g abcde#12345"
                                    type="text"
                                  />
                                  <TextField
                                    className="mt-2 phone-number-input input-field"
                                    id="phone_number"
                                    label="Phone Number"
                                    value={phoneValue}
                                    onChange={validatePhoneChange}
                                  />
                                  <TextField
                                    id="address"
                                    label="Address"
                                    className="mt-2 address-input input-field"
                                  />
                                  <TextField
                                    id="address2"
                                    label="Staircase, floor, suite (optional)"
                                    className="mt-2 address-input input-field"
                                    placeholder="Floor, suite, apt (optional)"
                                  />
                                </fieldset>
                              </div>
                              <fieldset className="is-flex mt-2">
                                <FormControl
                                  variant="standard"
                                  className="ml-1 mt-1"
                                >
                                  <InputLabel id="label-style">
                                    Country
                                  </InputLabel>
                                  <Select
                                    value={country}
                                    label="Country"
                                    id="country"
                                    labelId="label-country"
                                    className="country-input input-field"
                                    onChange={handleChangeCountry}
                                  >
                                    {countries.map((item) => (
                                      <MenuItem
                                        key={item}
                                        value={item}
                                        className="is-uppercase"
                                      >
                                        {item}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                                <TextField
                                  id="city"
                                  label="City"
                                  className="fieldset-right-inputbox mt-1 city-input input-field"
                                />
                              </fieldset>
                              <fieldset className="is-flex mt-2">
                                <TextField
                                  id="state"
                                  label="State/Province"
                                  className="mt-1 ml-1 state-input input-field"
                                />
                                <TextField
                                  id="postal_code"
                                  label="Postal Code"
                                  className="fieldset-right-inputbox mt-1 ml-1 postal-code-input input-field"
                                />
                              </fieldset>
                              <fieldset className="is-flex mt-4">
                                <FormControl variant="filled" className="mr-3">
                                  <InputLabel id="label-size">
                                    Hoodie #
                                  </InputLabel>
                                  <Select
                                    value={hoodie}
                                    label="Hoodie"
                                    id="hoodie"
                                    labelId="mint-id"
                                    className="hoodie-input input-field"
                                    onChange={handleChangeHoodie}
                                  >
                                    {getHoodieList()}
                                  </Select>
                                </FormControl>
                                <FormControl variant="filled" className="ml-3">
                                  <InputLabel id="label-size">Size</InputLabel>
                                  <Select
                                    value={size}
                                    label="Size"
                                    id="size"
                                    labelId="label-size"
                                    className="size-input input-field"
                                    onChange={handleChangeSize}
                                  >
                                    <MenuItem value="XSM">XS</MenuItem>
                                    <MenuItem value="SML">S</MenuItem>
                                    <MenuItem value="MED">M</MenuItem>
                                    <MenuItem value="LRG">L</MenuItem>
                                    <MenuItem value="XLG">XL</MenuItem>
                                    <MenuItem value="2XL">2XL</MenuItem>
                                    <MenuItem value="3XL">3XL</MenuItem>
                                  </Select>
                                  <div className="merch-modal-sizing-guide">
                                    <span className="merch-modal-sizing-guide-text">
                                      Sizing guide
                                    </span>
                                    <div className="tooltiptext">
                                      <span className="big-text">
                                        Sizing Guide
                                      </span>
                                      <table className="sizing-table">
                                        <tr>
                                          <th>Size</th>
                                          <th>Body Width (cm)</th>
                                          <th>Body Length (cm)</th>
                                        </tr>
                                        <tr>
                                          <td>XS</td>
                                          <td>57</td>
                                          <td>68.5</td>
                                        </tr>
                                        <tr>
                                          <td>S</td>
                                          <td>60</td>
                                          <td>71</td>
                                        </tr>
                                        <tr>
                                          <td>M</td>
                                          <td>63</td>
                                          <td>73.5</td>
                                        </tr>
                                        <tr>
                                          <td>L</td>
                                          <td>66</td>
                                          <td>76</td>
                                        </tr>
                                        <tr>
                                          <td>XL</td>
                                          <td>69</td>
                                          <td>78.5</td>
                                        </tr>
                                        <tr>
                                          <td>2XL</td>
                                          <td>72</td>
                                          <td>81</td>
                                        </tr>
                                        <tr>
                                          <td>3XL</td>
                                          <td>75</td>
                                          <td>83.5</td>
                                        </tr>
                                      </table>
                                      <span className="small-text">
                                        * measurements can vary within 2.5cm
                                      </span>
                                    </div>
                                  </div>
                                </FormControl>
                              </fieldset>
                              <div className="redeem-btn-container">
                                <button
                                  type="button"
                                  className="block-list__btn select-btn has-box-shadow-small is-uppercase mt-4"
                                  onClick={onRedeemClick}
                                >
                                  Redeem
                                </button>
                              </div>
                            </div>
                          )}
                          {hasRedeemed && (
                            <div className="text-center">
                              <h2 className="mb-4 has-text-accent">
                                Redemption successful!
                              </h2>
                              <p className="mb-4">
                                Your{" "}
                                <strong>
                                  {style}, {size} Hoodie
                                </strong>{" "}
                                has been redeemed and will ship soon.
                              </p>
                              <img
                                src={`/merch/hoodie-${style}.png`}
                                className="img-fluid"
                              />
                            </div>
                          )}
                        </Box>
                      </Fade>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};
export default Stake;
