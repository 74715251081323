import AwesomeSlider from 'react-awesome-slider';
// import 'react-awesome-slider/dist/styles.css';

import "./Legendaries.css";
import legendaries from "./legendaries";

const Legendaries = () => {  
  return (
    <div className="container legendaries-container">
      <AwesomeSlider bullets={false}>
      {legendaries.map((dragon, i) => 
        <div key={i} className="legendary-wrapper">
          <div className="legendary-image-wrapper">
            <img src={dragon.image} className="has-box-shadow img-fluid" />
          </div>
          <div className="legendary-content-wrapper">
            <div className="has-text-secondary">LEGENDARY</div>
            <h1 className="has-text-white is-uppercase">{dragon.name}</h1>
            <div className="legendary-content-lore has-text-secondary m-t-md">{dragon.lore}</div>
          </div>
        </div>)}
        </AwesomeSlider>
    </div>
  );
};

export default Legendaries;
