import React from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="container u-text-align_center">&copy; 2023 Bōryoku Dragonz.</div>
        </footer>
    );
}

export default Footer;