import * as anchor from "@project-serum/anchor";
import { Connection, Keypair, PublicKey } from "@solana/web3.js";
import {
  MintLayout,
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  Token,
} from "@solana/spl-token";

import {
  GetProgramAccountsConfig,
  AccountInfo,
  Transaction,
} from "@solana/web3.js";

import { BOKU_MINT, TOKEN_METADATA_PROGRAM_ID } from "./constants";
import {
  getMetadata,
  getMasterEdition,
  getATokenAddr,
  
  createAssociatedTokenAccountInstruction,
} from "./utils";

export const MERCH_PROGRAM_ID = new PublicKey(
  "mrchTQnMvmPXgChNVHVNiHa8stHhmWJ5kMP9e1P3duY"
);

const PREFIX = "merch";
const seller = new PublicKey("DRGnhq8axWdQqehZmj3tiLuCkuHeUvkS66dPmH7jA9QT");
const sellerReal = new PublicKey("DRGnhq8axWdQqehZmj3tiLuCkuHeUvkS66dPmH7jA9QT");

export interface MerchInfo {
  name: string;
  symbol: string;
  maxSupply: number;
  sold: number;
  uri: string;
  solPrice: number;
  tokenPrice: number;
}



export const getMerchInfo = async (
  program: anchor.Program,
  merchName: string
): Promise<MerchInfo> => {
  const merch = (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX)),
        seller.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(merchName)),
      ],
      program.programId
    )
  )[0];

  const merchAccount = await program.account.merch.fetch(merch);
  return {
    name: merchAccount.name,
    symbol: merchAccount.symbol,
    maxSupply: merchAccount.maxSupply.toNumber(),
    sold: merchAccount.sold.toNumber(),
    uri: merchAccount.uri,
    solPrice: merchAccount.solPrice.toNumber() / 1000 / 1000 / 1000,
    tokenPrice: merchAccount.tokenPrice.toNumber() / 1000 / 1000 / 1000,
  };
};

const DESTINATION = new PublicKey('DRGnhq8axWdQqehZmj3tiLuCkuHeUvkS66dPmH7jA9QT');

export const redeemMerch = async (
  program: anchor.Program,
  connection: Connection,
  walletKey: PublicKey,
  merchMint: PublicKey,
  usdcMint: PublicKey
): Promise<string> => {
  const merchToken = await getATokenAddr(connection, walletKey, merchMint);
  const merchMetadata = await getMetadata(merchMint);

  console.log("metadata: " + merchMetadata.toJSON());

  const redeemerUsdcToken = await getATokenAddr(
    connection,
    walletKey,
    usdcMint
  );

  const mintMerchTokenAccount = await getATokenAddr(
    connection,
    walletKey,
    merchMint
  );

  console.log(mintMerchTokenAccount);

  const treasury = new PublicKey("D81zMXT9MwKgFMg256pB2onDrkaaBFZJwBB3FpvVdmtN");

  const treasuryUSDC = new PublicKey("D81zMXT9MwKgFMg256pB2onDrkaaBFZJwBB3FpvVdmtN");

  const tx = new Transaction().add(
    Token.createBurnInstruction(
      TOKEN_PROGRAM_ID,
      //@ts-ignore
      merchMint,
      mintMerchTokenAccount,
      walletKey,
      [],
      1,
    ),
  Token.createTransferInstruction(TOKEN_PROGRAM_ID, redeemerUsdcToken, treasuryUSDC, walletKey, [], 69000000)); //new txs to our vault
  tx.feePayer = program.provider.wallet.publicKey;
  tx.recentBlockhash = (await connection.getLatestBlockhash()).blockhash;

  let signed = await program.provider.wallet.signTransaction(tx); //sign transaction with user wallet
  let txid = await connection.sendRawTransaction(signed.serialize()); //send transaction to the network

  return txid;
};

export const mintMerch = async (
  program: anchor.Program,
  connection: Connection,
  walletKey: PublicKey,
  proofMint: PublicKey,
  merchName: string
): Promise<string> => {
  const kp = Keypair.generate();
  const mint = kp.publicKey;

  const metadata = await getMetadata(mint);
  const masterEdition = await getMasterEdition(mint);

  const token = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), mint.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];

  const bokuToken = (
    await PublicKey.findProgramAddress(
      [walletKey.toBuffer(), TOKEN_PROGRAM_ID.toBuffer(), BOKU_MINT.toBuffer()],
      ASSOCIATED_TOKEN_PROGRAM_ID
    )
  )[0];

  const merch = (
    await PublicKey.findProgramAddress(
      [
        Buffer.from(anchor.utils.bytes.utf8.encode(PREFIX)),
        seller.toBuffer(),
        Buffer.from(anchor.utils.bytes.utf8.encode(merchName)),
      ],
      program.programId
    )
  )[0];

  const proofMetadata = await getMetadata(proofMint);
  const proofToken = await getATokenAddr(connection, walletKey, proofMint);

  const rent = await connection.getMinimumBalanceForRentExemption(
    MintLayout.span
  );

  try {
    const tx = await program.rpc.mintMerch({
      accounts: {
        buyer: walletKey,
        seller,
        merch,
        mint,
        metadata,
        masterEdition,
        tokenMint: BOKU_MINT,
        tokenAccount: bokuToken,
        proofMint,
        proofToken,
        proofMetadata,
        systemProgram: anchor.web3.SystemProgram.programId,
        tokenProgram: TOKEN_PROGRAM_ID,
        tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
        rent: anchor.web3.SYSVAR_RENT_PUBKEY,
      },
      signers: [kp],
      instructions: [
        anchor.web3.SystemProgram.createAccount({
          fromPubkey: walletKey,
          newAccountPubkey: mint,
          space: MintLayout.span,
          lamports: rent,
          programId: TOKEN_PROGRAM_ID,
        }),
        Token.createInitMintInstruction(
          TOKEN_PROGRAM_ID,
          mint,
          0,
          walletKey,
          walletKey
        ),
        createAssociatedTokenAccountInstruction(
          token,
          walletKey,
          walletKey,
          mint
        ),
        Token.createMintToInstruction(
          TOKEN_PROGRAM_ID,
          mint,
          token,
          walletKey,
          [],
          1
        ),
      ],
    });
    console.log("merch mint tx:", tx);
    return mint.toString();
  } catch (e) {
    console.log("merch mint failed");
    throw e;
  }
};
