import React from "react";
import "./Tokenomics.css";

import Nav from "../partials/Nav";
import Footer from "../partials/Footer";

const getBreedPrice = () => {
  let dateObj = new Date();
  let month = dateObj.getUTCMonth() + 1; 
  let year = dateObj.getUTCFullYear();

  let startYear = 2022

  while (year > startYear){
    month += 12;
    startYear++;
  }
  return Math.round(840 * 1.05**month);
}

const Tokenomics = () => {
  return (
    <div className="container tokenomics-container">
      <h1 className="heading-main is-uppercase">
        <img src="../title-arrow.svg" />
        Tokenomics
      </h1>
      <section className="tokenomics--boku">
        <div>
          <h2 className="tokenomics--boku__heading u-margin-bottom_medium">
            $BOKU<span className="is-uppercase">Brew</span>
          </h2>
          <p>
            Formulated through a sacred ritual utilizing the ruins that are left
            in the village of Boryoku, BOKU will be brewed fresh daily and
            immediately casted to flow into the rightful owners of the Boryoku
            Dragonz.
          </p>
          <p>
            BOKU plays an important part in the resurrection of Boryoku and
            prevents the extinction of its species.
          </p>
        </div>
        <img src="../home/boku.gif" />
      </section>

      <h2 className="heading-secondary u-margin-top_large is-uppercase">
        Supply
      </h2>
      <section className="tokenomics--box__wrapper">
        <div className="tokenomics--box tokenomics--box__supply is-primary">
          <div className="tokenomics--box-title">
            <strong>1111</strong>
            <div>Genesis Dragonz</div>
          </div>
          <div className="tokenomics--box-description">
            <strong>67%</strong>
            <div>7 tokens/day</div>
          </div>
        </div>

        <div className="tokenomics--box tokenomics--box__supply is-primary">
          <div className="tokenomics--box-title">
            <strong>5000</strong>
            <div>Baby Dragonz</div>
          </div>
          <div className="tokenomics--box-description">
            <strong>10%</strong>
          </div>
        </div>

        <div className="tokenomics--box tokenomics--box__supply is-primary">
          <div className="tokenomics--box-title">LP Incentives</div>
          <div className="tokenomics--box-description">
            <strong>13%</strong>
          </div>
        </div>

        <div className="tokenomics--box tokenomics--box__supply is-primary">
          <div className="tokenomics--box-title">Reserves</div>
          <div className="tokenomics--box-description">
            <strong>10%</strong>
          </div>
        </div>
      </section>

      <h2 className="heading-secondary u-margin-top_large is-uppercase">
        Utility
      </h2>
      <section className="tokenomics--box__wrapper">
        <div className="tokenomics--box tokenomics--box__utility is-accent-bright">
          <div className="tokenomics--box-title">
            <strong>Community Dynamics</strong>
          </div>
          <div className="tokenomics--box-description">
            Name and description change, Discord rank upgrades and more...
          </div>
        </div>

        <div className="tokenomics--box tokenomics--box__utility is-accent-bright">
          <div className="tokenomics--box-title">
            <strong>Breeding Game Dynamics</strong>
          </div>
          <div className="tokenomics--box-description">
            Breeding, fast hatching and more...
          </div>
        </div>
      </section>

      <h2 className="heading-secondary u-margin-top_large is-uppercase">
        Breeding Dynamics
      </h2>
      <section>
        <div className="tokenmonics--breeding__wrapper">
          <div className="tokenmonics--breeding__box tokenmonics--breeding__step1">
            Bōryoku Village
          </div>
          <div className="tokenmonics--breeding__separator">
            <img src="../home/breeding-separator.svg" />
          </div>

          <div className="tokenmonics--breeding__step2">
            <div className="tokenmonics--breeding__step2-items">
              <div className="tokenmonics--breeding__box">
                <div className="tokenmonics--breeding__box-image-wrapper">
                  <img src="../home/boryoku-dragonz-parent1.gif" width={60} height={60} />
                </div>
                Genesis Dragon #1
              </div>
              <div className="tokenmonics--breeding__box">
                <div className="tokenmonics--breeding__box-image-wrapper">
                  <img src="../home/boryoku-dragonz-parent2.gif" width={60} height={60}/>
                </div> 
                Genesis Dragon #2
              </div>
            </div>
            <div className="tokenmonics--breeding__box">
              <div className="tokenmonics--breeding__box-image-wrapper">
                <img src="../home/boku.gif" />
              </div>
              {getBreedPrice()} $BOKU<sup> *</sup>
            </div>
          </div>
          <div className="tokenmonics--breeding__separator">
            <img src="../home/breeding-separator.svg" />
          </div>

          <div className="tokenmonics--breeding__box tokenmonics--breeding__step3">
            Breeding Engine
          </div>
          <div className="tokenmonics--breeding__separator">
            <img src="../home/breeding-separator.svg" />
          </div>
          <div className="tokenmonics--breeding__box">
            <div className="tokenmonics--breeding__box-image-wrapper">
              <img src="../home/boryoku-dragonz-egg.gif" width={60} height={60} />
            </div>
            1 x Dragon Egg
          </div>
          <div className="tokenmonics--breeding__step4">
            <div>
              <div className="tokenmonics--breeding__step4-items">
                <div className="tokenmonics--breeding__box">Incubation 21 Days</div>
                <div className="tokenmonics--breeding__box">Fast Hatching 350 $BOKU</div>
              </div>
            </div>
          </div>
          <div className="tokenmonics--breeding__separator">
            <img src="../home/breeding-separator.svg" />
          </div>
          <div className="tokenmonics--breeding__step5">
            <div className="tokenmonics--breeding__box">
                <div className="tokenmonics--breeding__box-image-wrapper">
                  <img src="../home/boryoku-dragonz-baby.gif" />
                </div>
                1 x Baby Dragon
            </div>
          </div>
          

          {/* <div className="tokenmonics--breeding__separator">
            <img src="../home/breeding-separator.svg" />
          </div>
          <div className="tokenmonics--breeding__box">
              <div className="tokenmonics--breeding__box-image-wrapper">
                <img src="../home/boryoku-dragonz-baby.gif" />
              </div>
              1 Baby Dragon
          </div>


          <div className="tokenmonics--breeding__step4">
            <div>
              <div className="tokenmonics--breeding__step4-items">
                <div className="tokenmonics--breeding__box">Dragon Egg</div>
                <div className="tokenmonics--breeding__box">1 Baby</div>
              </div>
              <div className="tokenmonics--breeding__box">
                Fast Hatching 350 BOKU
              </div>
            </div>
          </div> */}
        </div>

        <p>
          <small>
            * 840 BOKU for the first month, and 5% inflation every 30 days for
            the next 3 years
          </small>
        </p>
      </section>

      <h2 className="heading-secondary u-margin-top_large is-uppercase">
        Governance
      </h2>
      <p>
        Every decision is being modelled using economic theory and statistical
        modelling – simulations are drawn to find balance between supply and
        demand. Ultimately, the team uses human judgement to validate model
        outputs and maintain consistency with the long-term vision of the
        project.
      </p>
    </div>
  );
};

export default Tokenomics;
