import Countdown from "react-countdown";

export const Card = (props: any) => {
  const cardClick = () => {
    props.onClick({
      name: props.dragon.name,
      image: props.dragon.image,
      mint: props.dragon.mint,
    });
  };
  return (
    <div className="col-md-6 pb-3">
      <div className="card p-3">
        <div className="d-flex flex-row mb-3">
          <img
            src={props.dragon.image}
            alt="dragon"
            className="card--image has-border-1-accent has-box-shadow-small"
          />
          <div className="d-flex flex-column ml-4">
            <strong>{props.dragon.name}</strong>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-center install mt-3">
          <span>Bred {props.dragon.breeded} times</span>
            <button
              onClick={cardClick}
              data-dismiss="modal"
              className="button is-accent-bright has-box-shadow-small stretched-link"
            >
              Breed
            </button>
          
        </div>
      </div>
    </div>
  );
};
