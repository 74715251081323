import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import { decodeMetadata, getMetadataAccount } from "../utils/metadata.service";

export interface IHoodiezNft {
  mint: string;
  name: string;
}

export interface INft {
  hoodiez: IHoodiezNft[];
}

const useHoodieNft = (connection: Connection) => {
  const wallet = useAnchorWallet();
  const [loading, setLoading] = useState(false);

  const [nfts, setNfts] = useState<INft>();
  async function fetchNfts() {
    const response = await connection.getParsedTokenAccountsByOwner(
      wallet!.publicKey,
      {
        programId: TOKEN_PROGRAM_ID,
      }
    );

    const metadataStrs = await Promise.all(
      response.value
        .filter(
          (accInfo) =>
            accInfo.account.data.parsed.info.tokenAmount.uiAmount !== 0
        )
        .map((accInfo) =>
          getMetadataAccount(accInfo.account.data.parsed.info.mint)
        )
    );

    const metadataPubkeys = metadataStrs.map((m) => new PublicKey(m));


    let hoodiez: IHoodiezNft[] = [];
    await Promise.all(
      metadataPubkeys.map(async (key) => {
        const metadata = await connection.getAccountInfo(key);
        if (metadata) {
          const decoded = await decodeMetadata(metadata!.data);
          if (decoded && decoded.data.name.search("Boryoku Hoodiez") !== -1) {
            try {
                hoodiez.push({
                  mint: decoded.mint,
                  name: decoded.data.name,
                });
              } catch {
                console.log("invalid hoodiez");
              }
          } else {
            console.log(decoded);
          }
        }
      })
    );

    setNfts({  hoodiez });
  }

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
      ) {
        return;
      }

      setLoading(true);
      await fetchNfts();

      setLoading(false);
    })();
  }, [wallet, wallet?.publicKey]);

  return { isLoading: loading, nfts, setNfts };
};

export default useHoodieNft;
