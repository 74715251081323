import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import SearchIcon from "@material-ui/icons/Search";
import ClearIcon from "@material-ui/icons/Clear";
import dragonz from "./genesis_metadata";
import "./Dragonz.css";

const Dragonz = () => {
  const [allData, setAllData] = useState<any[]>(
    dragonz.sort((d1, d2) => +d1.name.split("#")[1] - +d2.name.split("#")[1])
  );
  const [filteredData, setFilteredData] = useState(allData);

  useEffect(() => {
    setFilteredData(dragonz as any);
  }, []);

  const searchHander = (event: any) => {
    if (event.key !== "Enter") return;

    const value: string = event.target.value.toLowerCase();
    if (!value) setFilteredData(allData);

    const result = allData.filter((data) => {
      const lowerCaseName = data.name.toLowerCase();
      return lowerCaseName.search(value) != -1;
    });

    setFilteredData(result);
  };

  const resetHander = (event: any) => {
    setFilteredData(allData);
  };

  return (
    <main>
      <section>
        <header className="header">
          <Nav />
          <h1 className="heading-main is-flex-justify-center is-uppercase u-margin-top_large mb-3">
            <img src="../title-arrow.svg" />
            Genesis Dragonz
          </h1>
        </header>

        <div className="container content-wrapper">
          {filteredData.length === 0 && (
            <div className="is-flex is-flex-justify-center is-flex-align-center p-5">
              <div className="spinner mr-2"></div>loading Dragonz...
            </div>
          )}

          {filteredData.length > 0 && (
            <div className="search-wrapper mt-5">
              <SearchIcon className="mr-2 ml-2" />
              <input
                disabled={filteredData.length === 0}
                autoFocus
                className="search-input"
                placeholder="Dragon Name/ID"
                onKeyDown={searchHander}
              />
              <button className="clear-button" onClick={resetHander}>
                <ClearIcon className="clear-icon mr-2 ml-2" />
              </button>
            </div>
          )}

          <div className="mt-4 row">
            {filteredData.map((row) => (
              <div
                key={row.mint}
                className="col-12 col-sm-6 col-lg-4 pt-3 pb-3"
              >
                <div className="is-flex card--wrapper p-2">
                  <img className="card--image" src={row.image} />
                  <div className="card--info">
                    <p>
                      <strong>{row.name}</strong>
                    </p>
                    <Link
                      className="button is-primary mt-2"
                      to={`/genesis/${row.mint}`}
                    >
                      Details
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default Dragonz;
