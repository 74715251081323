import React, { useEffect } from "react";
import styled from "styled-components";

const AttrBox = styled.div`
    outline: 4px solid white;
    margin: 2px 2px;
`

const AttrImg = styled.img`
    width: 100%;
`

const AttrCaption = styled.div`
    border-top: 4px solid white;
    background-color: rgba(0,0,0,0.5);
    padding: 16px 1vw 20px 1vw;
    text-align: right;
    line-height: 220%;

    @media (max-width: 768px) {
        padding: 12px 1vw 16px 1vw;
        line-height: 170%;
    }
    @media (max-width: 600px) {
        padding: 8px 1vw 12px 1vw;
        line-height: 150%;
    }
`

const AttrName = styled.div`
    font-size: 24px;
    @media (max-width: 1600px) {
        font-size: 20px;
    }
    @media (max-width: 1200px) {
        font-size: 18px;
    }
    @media (max-width: 992px) {
        font-size: 16px;
    }
    @media (max-width: 768px) {
        font-size: 16px;
    }
    @media (max-width: 600px) {
        font-size: 16px;
    }
`

const AttrRarity = styled.div`
    font-weight: bold;
    font-size: 32px;

    @media (max-width: 1600px) {
        font-size: 28px;
    }
    @media (max-width: 992px) {
        font-size: 26px;
    }
    @media (max-width: 768px) {
        font-size: 22px;
    }
    @media (max-width: 600px) {
        font-size: 18px;
    }
`

export interface AttributeInfo {
    imgsrc: string;
    AttributeName: string;
    AttributeRarity: string;
}

const RarityCell = (props: AttributeInfo) => {

    useEffect(() => {
        if (props.AttributeName.length > 12) {
            if (window.innerWidth < 600) {
                document.getElementById(props.AttributeName)!.style.fontSize = "13px"
            }
            else if (window.innerWidth < 768) {
                document.getElementById(props.AttributeName)!.style.fontSize = "13px"
            }
            else if (window.innerWidth < 992) {
                document.getElementById(props.AttributeName)!.style.fontSize = "13px"
            }
            else if (window.innerWidth < 1200) {
                document.getElementById(props.AttributeName)!.style.fontSize = "14px"
            }
            else if (window.innerWidth < 1600) {
                document.getElementById(props.AttributeName)!.style.fontSize = "16px"
            }
            else {
                document.getElementById(props.AttributeName)!.style.fontSize = "18px"
            }   
        }
    })

    return (
        <AttrBox>
            <AttrImg src={props.imgsrc} />
            <AttrCaption>
                <div style={{ display:"table", marginLeft:"auto" }}>
                    <AttrName id={props.AttributeName}>{props.AttributeName}</AttrName>
                    <AttrRarity>{props.AttributeRarity}</AttrRarity>
                </div>
            </AttrCaption>
        </AttrBox>  
    )
}

export default RarityCell;