import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { useEffect, useState } from "react";
import * as anchor from "@project-serum/anchor";
import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { Connection, PublicKey } from "@solana/web3.js";
import { decodeMetadata, getMetadataAccount } from "../utils/metadata.service";
import {
  getEggState,
  getDragonzState,
  BREEDING_PROGRAM_ID,
} from "../contracts/breeding";
import { getProgram } from "../contracts/utils";


export interface IDragonzNft {
  mint: string;
  name: string;
  image: string;
  breeded: number;
  lastBreedTs: number;
}

export interface IEggzNft {
  mint: string;
  name: string;
  dragonz1: string;
  dragonz2: string;
  startTs: number;
}

export interface INft {
  dragonz: IDragonzNft[];
  eggz: IEggzNft[];
}

const useNfts = (connection: Connection) => {
  const wallet = useAnchorWallet();
  const [loading, setLoading] = useState(false);

  const [nfts, setNfts] = useState<INft>();
  async function fetchNfts() {
    const program = await getProgram(
      wallet as anchor.Wallet,
      BREEDING_PROGRAM_ID,
      connection
    );
    const response = await connection.getParsedTokenAccountsByOwner(
      wallet!.publicKey,
      {
        programId: TOKEN_PROGRAM_ID,
      }
    );

    const metadataStrs = await Promise.all(
      response.value
        .filter(
          (accInfo) =>
            accInfo.account.data.parsed.info.tokenAmount.uiAmount !== 0
        )
        .map((accInfo) =>
          getMetadataAccount(accInfo.account.data.parsed.info.mint)
        )
    );

    const metadataPubkeys = metadataStrs.map((m) => new PublicKey(m));

    // const multipleAccounts = await connection.getMultipleAccountsInfo(
    //   metadataPubkeys
    // );
    // const nftMetadatas = multipleAccounts.filter((account) => account !== null);

    let dragonz: IDragonzNft[] = [];
    let eggz: IEggzNft[] = [];
    await Promise.all(
      metadataPubkeys.map(async (key) => {
        const metadata = await connection.getAccountInfo(key);
        if (metadata) {
          const decoded = await decodeMetadata(metadata!.data);
          if (decoded && decoded.data.name.search("Boryoku Eggz") !== -1) {
            try {
              const { dragonz1, dragonz2, startTs } = await getEggState(
                program,
                new PublicKey(decoded.mint)
              );
              eggz.push({
                mint: decoded.mint,
                name: decoded.data.name,
                dragonz1,
                dragonz2,
                startTs,
              });
            } catch {
              console.log("invalid eggz");
            }
          } else if (
            decoded &&
            decoded.data.name.search("Boryoku Babyz") !== -1
          ) {
            console.log("babyz");
          } else if (decoded && decoded.data.symbol.search("BORYOKU") !== -1) {
            try {
              const { breeded, lastBreedTs } = await getDragonzState(
                program,
                new PublicKey(decoded.mint)
              );
              let image = "";
              if (
                decoded &&
                decoded.data.name.search("Boryoku Dragonz") !== -1
              ) {
                const seq = decoded.data.name.split("#")[1];
                image = `https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/dragonz_images/${seq}.png`;
              } else {
                const name = decoded.data.name.split(" ")[1].toLowerCase();
                image = `https://boryoku-dragonz-public.s3.us-east-2.amazonaws.com/legendaries/${name}.gif`;
              }
              dragonz.push({
                mint: decoded.mint,
                name: decoded.data.name,
                image,
                breeded,
                lastBreedTs,
              });
            } catch {
              console.log("invalid dragonz");
            }
          } else {
            //console.log(decoded);
          }
        }
      })
    );

    setNfts({ dragonz, eggz });
  }

  useEffect(() => {
    (async () => {
      if (
        !wallet ||
        !wallet.publicKey ||
        !wallet.signAllTransactions ||
        !wallet.signTransaction
      ) {
        return;
      }

      setLoading(true);
      await fetchNfts();
      setLoading(false);
    })();
  }, [wallet, wallet?.publicKey]);

  return { isLoading: loading, nfts, setNfts };
};

export default useNfts;
