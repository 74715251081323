import "./App.css";
import { useMemo } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Home from "./Home";
import Rarity from "./Rarity";
import Stake from "./Stake";
import Dragonz from "./dragonz";
import DragonDetails from "./dragonz/details";
import Eggz from "./eggz/details";
import Egg from "./eggz";
import MerchStore from "./merch";
import MerchStoreRedeem from "./merch/redeem";

import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletWallet,
  getSolletExtensionWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { createTheme, ThemeProvider } from "@material-ui/core";

const treasury = new anchor.web3.PublicKey(
  "DRGNjvBvnXNiQz9dTppGk1tAsVxtJsvhEmojEfBU3ezf"
);

const config = new anchor.web3.PublicKey(
  "2DMjVFznRAJzB5z3Q153mtJoFo8SJ2HnUu3xnbv3dktg"
);

const candyMachineId = new anchor.web3.PublicKey(
  "FyBnMLevMFBDXe33gJn7Psn844ogdsMfHBgKBaNPVesJ"
);

const network = "devnet" as WalletAdapterNetwork;

let rpcHost =
"https://api.metaplex.solana.com";
if (process.env.REACT_APP_ENV === "dev") {
  rpcHost =
  "https://api.metaplex.solana.com";
}
const connection = new anchor.web3.Connection(rpcHost, {
  commitment: "confirmed",
  confirmTransactionInitialTimeout: 150000,
});

const startDateSeed = parseInt("1635983400000", 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const theme = createTheme({
  palette: {
    type: "dark",
  },
  overrides: {
    MuiButtonBase: {
      root: {
        justifyContent: "flex-start",
      },
    },
    MuiButton: {
      root: {
        textTransform: undefined,
        padding: "12px 16px",
      },
      startIcon: {
        marginRight: 8,
      },
      endIcon: {
        marginLeft: 8,
      },
    },
  },
});

const Main = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );

  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletDialogProvider>
            <Home
              candyMachineId={candyMachineId}
              config={config}
              connection={connection}
              startDate={startDateSeed}
              treasury={treasury}
              txTimeout={txTimeout}
            />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

const Breeding = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );
  return (
    <div id="staking">
      <ThemeProvider theme={theme}>
        <ConnectionProvider endpoint={endpoint}>
          <WalletProvider wallets={wallets} autoConnect={true}>
            <WalletDialogProvider>
              <Stake connection={connection} />
            </WalletDialogProvider>
          </WalletProvider>
        </ConnectionProvider>
      </ThemeProvider>
    </div>
  );
};

const Merch = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );
  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletDialogProvider>
            <MerchStore connection={connection} />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

const MerchRedeem = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [
      getPhantomWallet(),
      getSlopeWallet(),
      getSolflareWallet(),
      getSolletWallet({ network }),
      getSolletExtensionWallet({ network }),
    ],
    []
  );
  return (
    <ThemeProvider theme={theme}>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect={true}>
          <WalletDialogProvider>
            <MerchStoreRedeem connection={connection} />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    </ThemeProvider>
  );
};

const App = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Main}></Route>
        <Route exact path="/rarity" component={Rarity}></Route>
        <Route exact path="/genesis" component={Dragonz}></Route>
        <Route exact path="/eggz" component={Egg}></Route>
        <Route exact path="/secretbreed" component={Breeding}></Route>
        <Route exact path="/merch/redeem" component={MerchRedeem}></Route>
        <Route exact path="/merch" component={Merch}></Route>
        
        <Route
          exact
          path="/genesis/:mint"
          render={(props) => (
            <DragonDetails {...props} connection={connection} />
          )}
        />
        <Route
          exact
          path="/eggz/:mint"
          render={(props) => <Eggz {...props} connection={connection} />}
        />
      </Switch>
    </Router>
  );
};

export default App;
