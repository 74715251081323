import { useState, useEffect } from "react";
import Countdown from "react-countdown";
import { Link, useHistory } from "react-router-dom";
import Nav from "../partials/Nav";
import Footer from "../partials/Footer";
import dragonz from "../dragonz/genesis_metadata_map";
import "../dragonz/Dragonz.css";
import { decodeEggState } from "../contracts/decoder";
import * as anchor from "@project-serum/anchor";
import SearchIcon from "@material-ui/icons/Search";

interface HomeProps {
  connection: anchor.web3.Connection;
  match: any;
}

interface Dragon {
  name: string;
  mint: string;
  image: string;
}

interface Egg {
  mint: string;
  dragonz1: string;
  dragonz2: string;
  startTs: number;
}

const EggDetails = (props: HomeProps) => {
  const { mint } = props.match.params;
  const [egg, setEggData] = useState({
    mint: "",
    dragonz1: "",
    dragonz2: "",
    startTs: 0,
  });

  const [dragonAlpha, setDragonAlphaData] = useState({} as Dragon);
  const [dragonBeta, setDragonBetaData] = useState({} as Dragon);
  const [eggError, setEggError] = useState(false);

  const getEgg = async () => {
    setEggError(false);
    setEggData({
      mint: "",
      dragonz1: "",
      dragonz2: "",
      startTs: 0,
    });
    setDragonAlphaData({} as Dragon);
    setDragonBetaData({} as Dragon);
    try {
      const { startTs, dragonz1, dragonz2 } = await decodeEggState(
        props.connection,
        mint
      );
      setEggData({ mint, dragonz1, dragonz2, startTs });

      const dragonAlphaObj: Dragon = dragonz[dragonz1];
      const dragonBetaObj: Dragon = dragonz[dragonz2];

      setDragonAlphaData(dragonAlphaObj);
      setDragonBetaData(dragonBetaObj);
    } catch (err) {
      setEggError(true);
      console.error("error", err);
    }
  };

  const history = useHistory();
  const searchHander = (event: any) => {
    if (event.key !== "Enter") return;

    const value = event.target.value.trim();

    if (value) {
      history.push(`/eggz/${value}`);
    }
  };

  useEffect(() => {
    getEgg();
  }, [mint]);

  return (
    <main>
      <section>
        <header className="header">
          <Nav />
          <h1 className="heading-main is-flex-justify-center is-uppercase u-margin-top_large mb-3">
            <img src="../title-arrow.svg" />
            Eggz
          </h1>
        </header>

        <div className="container content-wrapper">
          <div className="search-wrapper mt-5">
            <SearchIcon className="mr-2 ml-2" />
            <input
              defaultValue=""
              autoFocus
              className="search-input"
              placeholder="Eggz mint address"
              onKeyDown={searchHander}
            />
          </div>

          <h2 className="row mt-4 eggz-details--title">
            {eggError ? "Egg Not Found" : egg.mint}
          </h2>
          <div className="row dragon-details--wrapper">
            <div className="col-12 col-md-5 col p-5 is-flex is-flex-justify-center is-flex-align-center">
              <img
                className="eggz-img has-box-shadow"
                src="../breeding/egg.gif"
              />
            </div>
            <div className="col-12 col-md-7 col is-flex is-flex-align-center">
              <div className="egg-details--parents-wrapper is-flex-item pt-5 pb-5">
                <div className="row">
                  <div className="col">
                    <img src={dragonAlpha.image} />
                    <div className="dragon-details--info__trait-value">
                      <div>Parent Alpha</div>
                      <Link to={`/genesis/${egg.dragonz1}`}>
                        {dragonAlpha.name}
                      </Link>
                    </div>
                  </div>
                  <div className="col">
                    <img src={dragonBeta.image} />
                    <div className="dragon-details--info__trait-value">
                      <div>Parent Beta</div>
                      <Link to={`/genesis/${egg.dragonz2}`}>
                        {dragonBeta.name}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="mt-4 row">
                  <div className="col-12">
                    <div className="is-flex">
                      <div className="dragon-details--info__trait-type">
                        <strong>Hatching in</strong>
                      </div>
                      <div className="dragon-details--info__trait-value is-flex-item">
                        <Countdown
                          date={21 * 24 * 60 * 60 * 1000 + egg.startTs * 1000}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  );
};

export default EggDetails;
