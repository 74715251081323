import React from "react";
import "./Roadmap.css";

const Roadmap = () => {
  return (
    <div className="container roadmap-container">
      <h1 className="heading-main u-margin-bottom_medium heading-indented is-uppercase">
        <img src="../title-arrow.svg" />
        Roadmap
      </h1>
      <ul className="roadmap-list">
        <li>
          <div className="roadmap-list--phase-wrapper">
            <div className="roadmap-list--checkbox">
              <img src="../home/checked.svg" />
            </div>
            <div className="roadmap-list--phase">
              <strong className="is-uppercase">Phase 1</strong>
            </div>
          </div>
          <div className="roadmap-list--description">
            Mint of 1,111 Boryoku Dragonz
          </div>
        </li>
        <li>
          <div className="roadmap-list--phase-wrapper">
            <div className="roadmap-list--checkbox">
              <img src="../home/checked.svg" />
            </div>
            <div className="roadmap-list--phase">
              <strong className="is-uppercase">Phase 2</strong>
            </div>
          </div>
          <div className="roadmap-list--description">
            Daily $BOKU Airdrop for Breeding Game
          </div>
        </li>
        <li>
          <div className="roadmap-list--phase-wrapper">
            <div className="roadmap-list--checkbox">
              <img src="../home/checked.svg" />
            </div>
            <div className="roadmap-list--phase">
              <strong className="is-uppercase">Phase 3</strong>
            </div>
          </div>
          <div className="roadmap-list--description">Funds committed to LP</div>
        </li>
        <li>
          <div className="roadmap-list--phase-wrapper">
            <div className="roadmap-list--checkbox">
              <img src="../home/checked.svg" />
            </div>
            <div className="roadmap-list--phase">
              <strong className="is-uppercase">Phase 4</strong>
            </div>
          </div>
          <div className="roadmap-list--description">
            Legendary Dragonz Auction
          </div>
        </li>
        <li>
          <div className="roadmap-list--phase-wrapper">
            <div className="roadmap-list--checkbox">
              <img src="../home/checked.svg" />
            </div>
            <div className="roadmap-list--phase">
              <strong className="is-uppercase">Phase 5</strong>
            </div>
          </div>
          <div className="roadmap-list--description">
            Dragon Eggs &amp; Hatching Mechanics
          </div>
        </li>
        <li>
          <div className="roadmap-list--phase-wrapper">
            <div className="roadmap-list--checkbox">
              <img src="../home/checked.svg" />
            </div>
            <div className="roadmap-list--phase">
              <strong className="is-uppercase">Phase 6</strong>
            </div>
          </div>
          <div className="roadmap-list--description">Baby Dragonz hatch</div>
        </li>
      </ul>
    </div>
  );
};

export default Roadmap;
